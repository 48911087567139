export enum ERROR_PAGE {
  IS_403 = '/performance/403',
  IS_404 = '/performance/404',
}

export enum MENU_PAGE_LINK {
  INDEX_PAGE = '/performance',
  LANDING_PAGE = '/performance/index',
  DASHBOARD_PAGE = '/performance/v2/dashboard/cycles',
  NON_ENGAGED_CYCLES_PAGE = '/performance/v2/nonEngagedCycles',
  TWERS_I_SUPPORT_PAGE = '/performance/twerisupport',
  FEEDBACK_MANAGEMENT_PAGE = '/performance/feedback',
  ADMIN_PAGE = '/performance/v2/admin',
}

export enum CYCLE_PAGE_LINK {
  MY_CYCLES = '/performance/cycles',
  NEW_CYCLES_FORM = '/performance/myCycles',
  CYCLE_HISTORY_DETAIL = '/performance/cycles/cycleHistoryDetail',
  OLD_CYCLES_FORM = '/performance/cycles/setCycle',
}

export enum FEEDBACK_PAGE_LINK {
  INDEX = '/performance/feedback',
  DETAIL = '/performance/feedback/detail/',
  GIVEN = '/performance/feedback/given/',
  CLIENT = '/performance/feedback/client/',
  CREATION = '/performance/feedback/creation',
}

export enum DOCUMENT_LINK {
  FAQ_LINK = 'https://docs.google.com/document/d/1NC9YrDsSFBTcIi6qaBOdJz6Lngz4bCB3hoLFAdgP81E/edit#heading=h.nxkwvhz0feqb',
}

export const PATHNAME_AND_PAGE_NAME = [
  { pageName: 'Home | Summit', pathName: MENU_PAGE_LINK.LANDING_PAGE },
  { pageName: 'Dashboard | Summit', pathName: MENU_PAGE_LINK.DASHBOARD_PAGE },
  { pageName: 'Non-engaged cycles | Summit', pathName: MENU_PAGE_LINK.NON_ENGAGED_CYCLES_PAGE },
  { pageName: 'My cycles | Summit', pathName: CYCLE_PAGE_LINK.MY_CYCLES },
  { pageName: 'My cycles | Summit', pathName: CYCLE_PAGE_LINK.NEW_CYCLES_FORM },
  { pageName: 'Twers I support | Summit', pathName: MENU_PAGE_LINK.TWERS_I_SUPPORT_PAGE },
  { pageName: 'Feedback | Summit', pathName: MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE },
  { pageName: 'Admin | Summit', pathName: MENU_PAGE_LINK.ADMIN_PAGE },
];

export const CURRENT_PAGE = (pathname: string) => {
  const isNonEngagedCyclesPage = pathname.includes(MENU_PAGE_LINK.NON_ENGAGED_CYCLES_PAGE);
  const isMyCyclesPage = pathname.includes(CYCLE_PAGE_LINK.MY_CYCLES);
  const isMyCyclesFormPage = pathname
    .toLowerCase()
    .includes(CYCLE_PAGE_LINK.NEW_CYCLES_FORM.toLowerCase());
  return {
    isNonEngagedCyclesPage,
    isMyCyclesPage,
    isMyCyclesFormPage,
  };
};
