import React from 'react';
import EmptyFeedbackIcon from '@@/assets/images/empty-feedback.svg';
import './index.less';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import Locale from '@@/_new_src_/local/cycleDetail/en_US';
import { useSelector } from 'react-redux';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import dayjs from 'dayjs';
import { dateWithYearMonth } from '@@/_new_src_/constants/dateFormatter';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const NoFeedbackContainer = () => {
  const history = useHistory();

  const {
    currentCycleDetail: { project, duration, isOverdueCycle },
  } = useSelector(cycleDetailByVersion);

  const {
    noFeedbackContainer: { title, gotoText, hint },
  } = Locale;

  const goToFeedbackPage = () => {
    history.push(MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE);
  };

  return (
    <div className="no-feedback-container">
      <div className="icon-area">
        <img src={EmptyFeedbackIcon} className="empty-icon" alt="" />
      </div>
      {isOverdueCycle ? (
        <div className={'overdue-no-feedback-hint'}>
          <div className={'no-feedback-collected'}>No feedback collected</div>
          <div className={'detailed-info'}>
            <b>Current project:</b> {project.name} | <b>Cycle duration:</b>{' '}
            {dayjs.utc(duration.startTime).local().format(dateWithYearMonth)} -{' '}
            {dayjs.utc(duration.endTime).local().format(dateWithYearMonth)}
          </div>
        </div>
      ) : (
        <div className="no-feedback-hint">
          <span>{title}</span>
          <div className="feedback-management-area">
            <Button className="go-to-feedback-button" onClick={goToFeedbackPage}>
              {gotoText}
            </Button>
            <span>
              <i className="ri-information-fill" />
              <div dangerouslySetInnerHTML={{ __html: hint }} />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoFeedbackContainer;
