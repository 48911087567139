import { CaretLeftOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Table, Tooltip } from 'antd';
import { TableProps } from 'antd/es';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import editBlueIcon from '@@/assets/images/edit-blue.svg';
import ImportListIcon from '@@/assets/images/import_list.svg';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import perfModalConfirm from '@@/common/component/PerfModalConfirm';
import PerfTextButton from '@@/common/component/PerfTextButton';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION,
  SORT_DIRECTION_FOR_BE,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import { UserInfoContext } from '@@/context/userInfo.context';
import usePerfModal from '@@/hooks/usePerfModal';
import { GroupMemberTableColumnsName } from '@@/types/antdComponent';
import { UserInfo } from '@@/types/user';
import CreateEditGroupModal from '../components/CreateEditGroupModal';
import EditGroupNameOrLeads from '../components/EditGroupModal';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { getPerformancePath } from '@@/common/utils';
import { CUSTOMIZED_GROUP_MANAGEMENT } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { IPageParams } from '@@/_new_src_/types/common';
import { deleteGroupMember, getGroupDetail } from '@@/_new_src_/api/admin';
import { ERROR_PAGE } from '@@/_new_src_/constants/pagePath';
import { ISortParamsForGroupMemberTableColumns } from '@@/types/admin';

interface TBPGroupManagePropsType {
  location: {
    state: {
      uId: number;
      isSupervisor: boolean;
    };
  };
}

interface GroupLeadType {
  email: string;
  name: string;
  userId: number;
}

interface GroupMembersType {
  id: number;
  employeeId: number;
  name: string;
  userId: number;
  email: string;
  createdAt: string;
}

interface GroupMembersInfoType {
  content: GroupMembersType[];
  total: number;
}

interface GroupDetailResponseDataType {
  data: {
    groupName: string;
    groupLeaders: GroupLeadType[];
    groupMemberInfo: GroupMembersInfoType;
  };
}

interface GroupsMemberTableLocale {
  name: string;
  employeeID: string;
  addedAt: string;
  actions: string;
  remove: string;
}

const TBPGroupManage = (props: TBPGroupManagePropsType) => {
  const { trackEvent } = useMatomo();
  const { updateUserInfo } = useContext(UserInfoContext) as UserInfo;
  const history = useHistory();
  const unitId = props.location.state?.uId;
  const { groupId } = useParams<{ groupId: string }>();
  const [currentPagination, setCurrentPagination] = useState<IPageParams>(DEFAULT_TABLE_PAGINATION);
  const [currentSorter, setCurrentSorter] = useState<ISortParamsForGroupMemberTableColumns>({
    sortColumn: GroupMemberTableColumnsName.default,
    sortOrder: SORT_DIRECTION_FOR_BE.descend,
  });
  const [editGroupModalVisible, onOpenEditGroupModal, onCloseEditGroupModal] = usePerfModal() as [
    boolean,
    () => void,
    () => void,
  ];
  const [importMembersModalVisible, onOpenImportMembersModal, onCloseImportMembersModal] =
    usePerfModal() as [boolean, () => void, () => void];

  const {
    data,
    loading,
    run: runGetTBPGroupDetail,
  } = useRequest(() => getGroupDetail(currentSorter, currentPagination, groupId), {
    formatResult: (response: GroupDetailResponseDataType) => response.data,
    manual: true,
  });

  useEffect(() => {
    if (unitId === undefined) {
      history.replace(ERROR_PAGE.IS_404);
    } else {
      const asyncRunGetTBPGroupDetail: () => void = async () => {
        await runGetTBPGroupDetail();
      };
      asyncRunGetTBPGroupDetail();
    }
  }, [runGetTBPGroupDetail, currentSorter, currentPagination, unitId, history]);

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: {
      manageGroupsOfTBP: { editGroupForTBP: locale },
    },
  } = localeResource as {
    admin: {
      manageGroupsOfTBP: {
        editGroupForTBP: {
          title: string;
          groupDetail: {
            edit: string;
            groupLeads: string;
          };
          editGroupMemberList: {
            totalTWers: (total: number) => string;
            importList: string;
            table: {
              name: string;
              employeeID: string;
              addedAt: string;
              actions: string;
              remove: string;
            };
            deleteGroupMemberModel: {
              cancelText: string;
              okText: string;
              contentText: (userName: string, groupName: string) => string;
              successMessage: string;
            };
          };
        };
      };
    };
  };

  const getNameFromEmails = () => data?.groupLeaders.map(groupLead => groupLead.name).join(', ');

  const handleEditGroupSuccess = async () => {
    onCloseEditGroupModal();
    await runGetTBPGroupDetail();
    updateUserInfo();
  };

  const onGoToGroupListPages = useCallback(() => {
    history.push(getPerformancePath('/admin/'));
  }, [history]);

  const removeGroupMember = (groupMemberId: number, name: string) => {
    const modelLocale = locale.editGroupMemberList.deleteGroupMemberModel;
    const closeModal = perfModalConfirm({
      cancelText: modelLocale.cancelText,
      okText: modelLocale.okText,
      className: 'deleteGroupMemberConfirm',
      content: (
        <div className="delete-modal-content">
          {modelLocale.contentText(name, data?.groupName || '')}
        </div>
      ),
      onOk: async () => {
        trackEvent({
          category: CUSTOMIZED_GROUP_MANAGEMENT.category,
          action: CUSTOMIZED_GROUP_MANAGEMENT.action.TBP_DELETES_MEMBER,
        });
        groupMemberId && (await deleteGroupMember(groupMemberId).finally(() => closeModal()));
        perfMessage.success(modelLocale.successMessage);
        await runGetTBPGroupDetail();
      },
      centered: true,
      onCancel: null,
    });
  };

  const createColumns = (tableLocale: GroupsMemberTableLocale): ColumnsType<GroupMembersType> => {
    return [
      {
        title: tableLocale.name,
        dataIndex: 'name',
        width: '30%',
        ellipsis: { showTitle: false },
        ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
        render: (name: string) => {
          return (
            <Tooltip placement="topLeft" title={name}>
              {name}
            </Tooltip>
          );
        },
      },
      {
        title: tableLocale.employeeID,
        dataIndex: 'employeeId',
        width: '30%',
        ellipsis: { showTitle: false },
        ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
        render: (employeeId: string) => {
          return (
            <Tooltip placement="topLeft" title={employeeId}>
              {employeeId}
            </Tooltip>
          );
        },
      },
      {
        title: tableLocale.addedAt,
        dataIndex: 'createdAt',
        width: '30%',
        ellipsis: { showTitle: false },
        ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
        render: (createdAt: string) => (
          <Tooltip placement="topLeft" title={createdAt}>
            {createdAt}
          </Tooltip>
        ),
      },
      {
        title: tableLocale.actions,
        dataIndex: 'actions',
        render: (text: string, { id, name }: { id: number; name: string }) => (
          <PerfTextButton color="red" onClick={() => removeGroupMember(id, name)}>
            {tableLocale.remove}
          </PerfTextButton>
        ),
      },
    ];
  };

  const handleTableChange: TableProps<GroupMembersType>['onChange'] = (
    pagination,
    filters,
    sorter: SorterResult<GroupMembersType> | SorterResult<GroupMembersType>[],
  ) => {
    const pageNumberAndSize: IPageParams = {
      pageNumber: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
    };
    const { field, order } = sorter as SorterResult<GroupMembersType>;
    const sortColumnAndOrder = {
      sortColumn: order
        ? GroupMemberTableColumnsName[field as keyof typeof GroupMemberTableColumnsName]
        : GroupMemberTableColumnsName.default,
      sortOrder: order ? SORT_DIRECTION_FOR_BE[order] : undefined,
    };
    setCurrentPagination(pageNumberAndSize);
    setCurrentSorter(sortColumnAndOrder);
  };

  const handleImportMembersGroupSuccess = () => {
    onCloseEditGroupModal();
    const asyncRunGetTBPGroupDetail: () => void = async () => {
      await runGetTBPGroupDetail();
    };
    asyncRunGetTBPGroupDetail();
  };
  return (
    <LoadingWrapper className loading={loading}>
      <div className={'group-header-container'}>
        <header className="group-header">
          <section className="group-header-content" onClick={onGoToGroupListPages}>
            <span className="group-header-arrow-wrapper">
              <CaretLeftOutlined className="group-header-arrow" />
            </span>
            <b className="group-header-name">{locale.title}</b>
          </section>
        </header>
      </div>
      <div className="group-body">
        <section className="group-detail">
          <section className="name-row">
            <div className="name">{data?.groupName}</div>
            <div className="edit" onClick={onOpenEditGroupModal}>
              <img alt="edit-blue" src={editBlueIcon} />
              <span>{locale.groupDetail.edit}</span>
            </div>
          </section>
          <section className="leads-row">
            <div className="leads-title">{locale.groupDetail.groupLeads}</div>
            <div className="names-list">{getNameFromEmails()}</div>
          </section>
        </section>
        <section className="header-body-margin" />
        <section className="group-member">
          <section className="group-member-count-and-operation">
            <div className="total-count">
              {locale.editGroupMemberList.totalTWers(data?.groupMemberInfo.total || 0)}
            </div>
            <div className="import-list" onClick={onOpenImportMembersModal}>
              <img alt="import_list" src={ImportListIcon} className="import-list-icon" />
              <span>{locale.editGroupMemberList.importList}</span>
            </div>
          </section>
          <Table<GroupMembersType>
            columns={createColumns(locale.editGroupMemberList.table)}
            dataSource={data?.groupMemberInfo.content}
            rowKey={record => record.id}
            onChange={handleTableChange}
            pagination={
              data?.groupMemberInfo.total !== undefined &&
              data?.groupMemberInfo.total > TABLE_DEFAULT_INFO.PAGE_SIZE
                ? {
                    ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
                    total: data?.groupMemberInfo.total,
                    current: currentPagination.pageNumber,
                    pageSize: currentPagination.pageSize,
                  }
                : false
            }
          />
        </section>
      </div>
      <EditGroupNameOrLeads
        visible={editGroupModalVisible}
        onSuccess={handleEditGroupSuccess}
        onCancel={onCloseEditGroupModal}
        groupLeaders={data?.groupLeaders.map(leader => {
          return { key: leader.email, label: leader.name, value: leader.email };
        })}
        groupName={data?.groupName}
        unitId={unitId}
        groupId={groupId}
      />
      {importMembersModalVisible && (
        <CreateEditGroupModal
          visible={importMembersModalVisible}
          onSuccess={handleImportMembersGroupSuccess}
          onCancel={onCloseImportMembersModal}
          unitId={unitId}
          groupId={groupId}
          isEdit
        />
      )}
    </LoadingWrapper>
  );
};

export default TBPGroupManage;
