import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCycleByCycleId, getOngoingCycle } from '@@/_new_src_/api/myCycles';
import { IOngoingResponse } from '@@/_new_src_/store/myCyclesSlice/interface';
import {
  getEditRequestByRequestId,
  getRequestToEditByCycleId,
  postRequestToEdit,
  patchAssessmentByPP,
  patchEditRequestByRequestId,
  postUnmarkAssessmentNonEngagedByTbp,
} from '@@/_new_src_/api/cycleDetail';
import {
  IGetEditRequestByRequestIdResponse,
  IGetRequestToEditData,
  IAssessment,
  IPatchRequestParam,
} from './interface';

export const getOngoingCycleAsync = createAsyncThunk('getOngoingCycle', async () => {
  const response = await getOngoingCycle();
  return response.data as IOngoingResponse;
});

export const getCycleByCycleIdAsync = createAsyncThunk(
  'getCycleByCycleId',
  async (cycleId: number) => {
    const response = await getCycleByCycleId(cycleId);
    return response.data as IOngoingResponse;
  },
);

export const postRequestToEditAsync = createAsyncThunk(
  'postRequestToEdit',
  async (data: { cycleId: number; reason: string }) => {
    await postRequestToEdit(data.cycleId, data.reason);
  },
);

export const getRequestToEditByCycleIdAsync = createAsyncThunk(
  'getRequestToEditByCycleId',
  async (cycleId: number) => {
    const response = await getRequestToEditByCycleId(cycleId);
    return response.data as IGetRequestToEditData;
  },
);
export const getEditRequestByRequestIdAsync = createAsyncThunk(
  'getEditRequestByRequestId',
  async (requestId: number) => {
    const response = await getEditRequestByRequestId(requestId);
    return response.data as IGetEditRequestByRequestIdResponse;
  },
);
export const patchEditRequestByRequestIdAsync = createAsyncThunk(
  'patchEditRequestByRequestId',
  async (params: IPatchRequestParam) => {
    await patchEditRequestByRequestId(params);
  },
);

export const patchAssessmentByPPAsync = createAsyncThunk(
  'updateAssessmentByPP',
  async (data: { cycleId: number; assessment: IAssessment }) => {
    await patchAssessmentByPP(data.cycleId, data.assessment);
  },
);

export const postUnmarkAssessmentNonEngagedByTbpAsync = createAsyncThunk(
  'unmarkAssessmentNonEngagedByTbp',
  async (data: { cycleId: number; assessment: IAssessment }) => {
    await postUnmarkAssessmentNonEngagedByTbp(data.cycleId, data.assessment);
  },
);
