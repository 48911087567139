import { Tabs } from 'antd';
import cls from 'classnames';
import { isEmpty } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import ChangeHistoryIcon from '@@/assets/images/change-history-icon.svg';
import ChangeHistory from '@@/common/component/ChangeHistory';
import RatingSummaryEditor from '@@/common/component/RatingSummaryEditor';
import RevieweeReviewPreview from '@@/common/component/RevieweeReviewEditor/preview';
import SelfReviewPreview from '@@/common/component/ReviewDetailPreview/SelfReviewPreview';
import ReviewInputTab from '@@/common/component/ReviewInputTab';
import { TABS_KEY } from '@@/common/constant/reviewee';
import '../../../reviewees/detail/index.less';
import './index.less';
import RequestDetailV2 from '@@/common/component/RequestDetailV2';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { dashboard, setIsFromDetailPageInitialed } from '@@/redux/slice/dashboardSlice';
import { setRequestPageStatus } from '@@/features/performance/v2/dashboardPage/common';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { CYCLE_STATUS_ENUM, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { dealRequestData } from '@@/_new_src_/store/cycleDetailSlice';
import DealRequest from '@@/_new_src_/page/cycleDetail/component/DealRequest';
import FeedbackPreviewDetail from '@@/_new_src_/page/myCycles/component/FeedbackPreviewDetail';
import {
  cycleFeedbacks,
  setCycleFeedbacks,
  setIsOperationCycle,
} from '@@/_new_src_/store/myCyclesSlice';
import { getCycleFeedbacksAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import CycleStatusBadge from '@@/_new_src_/features/CycleStatusBadge';
import commonLocale from '@@/_new_src_/local/common/en_US';
import { useLocation } from 'react-router';
import { getFeedbackStatus } from '@@/_new_src_/utils/feature';

const { TabPane } = Tabs;

const DashboardCycleDetail = props => {
  const dispatch = useDispatch();
  const { requestId } = props;

  const {
    requestPageStatus,
    openRequestCount,
    changeHistory: { data: changeHistory, loading: changeHistoryLoading },
  } = useSelector(dashboard);

  const dealRequest = useSelector(dealRequestData);

  const {
    currentCycleDetail: {
      cycleVersion,
      cycleDetailLoading,
      id: cycleId,
      status,
      selfAssessment,
      isDoneCycle,
      isOverdueCycle,
      additionalPartnerAssessments = [],
      isNoneEngaged = false,
    },
  } = useSelector(cycleDetailByVersion);
  const {
    loading: isCycleFeedbackLoading,
    success: isGetCycleFeedbackSuccess,
    list: feedbacks = [],
  } = useSelector(cycleFeedbacks);

  const location = useLocation();

  const {
    tag: { nonEngagedCycleByTBPTag },
  } = commonLocale;

  const localeResource = useSelector(selectLocaleResource);

  const isShowV1RequestDetail = useMemo(() => {
    return (
      +dealRequest.cycleId === +cycleId &&
      cycleVersion === CYCLE_VERSION.OLD &&
      location.pathname.includes('/requests')
    );
  }, [dealRequest, cycleId, cycleVersion]);

  const isShowV2RequestDetail = useMemo(() => {
    return +dealRequest.cycleId === +cycleId && cycleVersion === CYCLE_VERSION.NEW;
  }, [dealRequest, cycleId, cycleVersion]);

  const {
    reviewList: locale = {},
    reviewees: { reviewInputTab: reviewInputTabLocale, feedbackTab: feedbackTabLocale },
  } = localeResource || {};

  const [isChangeHistoryExpand, setIsChangeHistoryExpand] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS_KEY.REVIEW_DETAIL);
  const [isSelfPreviewExpand, setIsSelfPreviewExpand] = useState(false);

  const totalReviewInputsNumber = additionalPartnerAssessments?.length || 0;

  useEffect(() => {
    setIsFromDetailPageInitialed(true);
  }, [setIsFromDetailPageInitialed]);

  useEffect(() => {
    cycleId && dispatch(getCycleFeedbacksAsync(+cycleId));
  }, [cycleId]);

  useEffect(() => {
    dispatch(setIsOperationCycle(false));
    return () => {
      dispatch(setCycleFeedbacks([]));
    };
  }, []);

  const onToggleChangeHistoryExpand = useCallback(() => {
    if (activeTab !== TABS_KEY.REVIEW_DETAIL) {
      setIsChangeHistoryExpand(true);
      setActiveTab(TABS_KEY.REVIEW_DETAIL);
    } else {
      setIsChangeHistoryExpand(!isChangeHistoryExpand);
    }
  }, [isChangeHistoryExpand, activeTab]);

  const renderChangeHistoryOperation = useCallback(
    () =>
      !isEmpty(changeHistory) && (
        <div className="review-detail-preview-title-extra">
          <img
            alt=""
            src={ChangeHistoryIcon}
            className="change-history-icon"
            onClick={onToggleChangeHistoryExpand}
          />
        </div>
      ),
    [changeHistory, onToggleChangeHistoryExpand],
  );

  const onTabsChange = useCallback(activeKey => {
    setActiveTab(activeKey);
  }, []);

  const triggerRequestListUpdate = useCallback(
    status => {
      if (requestPageStatus && requestPageStatus.list) {
        const { content, total } = requestPageStatus.list;
        const newContent = content?.map(item =>
          item.requestId === Number(requestId) ? { ...item, status } : item,
        );
        setRequestPageStatus({
          filter: requestPageStatus?.filter,
          list: { total, content: newContent },
        });
      }
    },
    [setRequestPageStatus, requestId, openRequestCount, requestPageStatus],
  );

  const renderReviewDetailTab = useCallback(() => {
    return (
      <TabPane tab={locale.reviewDetailTabTitle} key={TABS_KEY.REVIEW_DETAIL}>
        {!isEmpty(changeHistory) && isChangeHistoryExpand && <ChangeHistory list={changeHistory} />}
        {isShowV1RequestDetail && <RequestDetailV2 data={dealRequest} />}
        {isShowV2RequestDetail && <DealRequest />}
        {isDoneCycle && <RatingSummaryEditor showEditIcon={false} editable={false} isExpand />}
        {(!isEmpty(selfAssessment?.content) || !isEmpty(selfAssessment?.attachmentName)) && (
          <SelfReviewPreview
            fromNewDashBoard
            showEditButton={false}
            isExpand={isSelfPreviewExpand}
            setIsExpand={setIsSelfPreviewExpand}
            pageType="newDashboardPagePreview"
          />
        )}
        <RevieweeReviewPreview
          pageType="newDashboardPagePreview"
          locale={locale}
          fromNewDashBoard
          isExpand
          isNonEngagedReview={isNoneEngaged}
          operation={{
            requestEditButton: false,
            addNotesButton: false,
            editNotesButton: false,
            notesRecordTips: false,
          }}
        />
      </TabPane>
    );
  }, [
    locale,
    changeHistory,
    isChangeHistoryExpand,
    isShowV1RequestDetail,
    isShowV2RequestDetail,
    dealRequest,
    triggerRequestListUpdate,
    isDoneCycle,
    isSelfPreviewExpand,
    isNoneEngaged,
    selfAssessment,
  ]);

  const renderReviewInputTabContent = useCallback(
    () => (
      <TabPane
        tab={reviewInputTabLocale.tabTitle(totalReviewInputsNumber)}
        key={TABS_KEY.REVIEW_INPUT}
      >
        <ReviewInputTab showEditReviewInputButton={false} />
      </TabPane>
    ),
    [reviewInputTabLocale, totalReviewInputsNumber],
  );

  const renderFeedbackTabContent = useCallback(() => {
    if (!isGetCycleFeedbackSuccess) {
      return null;
    }
    const filterFeedbacks = feedbacks.filter(
      feedback => getFeedbackStatus(feedback.feedbackStatus).isSubmittedFeedback,
    );
    if (isEmpty(filterFeedbacks)) {
      return null;
    }
    return (
      <TabPane tab={feedbackTabLocale.tabTitle(filterFeedbacks.length)} key={TABS_KEY.FEEDBACK}>
        <div className="feedback-list">
          {filterFeedbacks.map(item => (
            <FeedbackPreviewDetail key={item.requestId} feedback={item} />
          ))}
        </div>
      </TabPane>
    );
  }, [feedbackTabLocale, feedbacks]);

  const dashboardCycleDetailLoading = useMemo(() => {
    return (
      isCycleFeedbackLoading ||
      cycleDetailLoading ||
      dealRequest.isGetEditRequestLoading ||
      changeHistoryLoading
    );
  }, [
    isCycleFeedbackLoading,
    cycleDetailLoading,
    dealRequest.isGetEditRequestLoading,
    changeHistoryLoading,
  ]);

  return (
    <div className="new-dashboard-reviewee-detail">
      <div className="reviewee-detail-content">
        <LoadingWrapper loading={dashboardCycleDetailLoading}>
          <div
            className={cls('review-detail-preview', { 'review-detail-preview-expand': true })}
            key={+cycleId}
          >
            <div className={cls('review-detail-preview-title')}>
              <div className="review-detail-preview-title-flag">
                <CycleStatusBadge status={isOverdueCycle ? CYCLE_STATUS_ENUM.OVERDUE : status} />

                {isNoneEngaged && (
                  <div className="review-detail-preview-title-non-Engaged-Cycle">
                    {nonEngagedCycleByTBPTag}
                  </div>
                )}
              </div>
              {renderChangeHistoryOperation()}
            </div>
            <Tabs
              type="card"
              centered
              tabBarGutter={9}
              size="large"
              className="review-tabs-container"
              activeKey={activeTab}
              onChange={onTabsChange}
            >
              {renderReviewDetailTab()}
              {totalReviewInputsNumber && renderReviewInputTabContent()}
              {renderFeedbackTabContent()}
            </Tabs>
          </div>
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default memo(DashboardCycleDetail);
