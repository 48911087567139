import React, { useMemo } from 'react';
import { Button } from 'antd';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { CYCLE_DISPLAY_PART } from '@@/_new_src_/constants/myCycles';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IExpectationItem, IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import {
  setCurrentUpdatedExpectation,
  setCycleDisplayPart,
} from '@@/_new_src_/store/myCyclesSlice';
import { isEmpty } from 'lodash';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';

const AddFirstExpectationModal = () => {
  const dispatch = useDispatch();
  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    myCycles: {
      addFirstExpectationModal: { content, btnContext },
    },
  } = localeResource as IMyCyclesLocale;

  const {
    currentCycleDetail: { expectations: expectationListData, isNewCycle },
  } = useSelector(cycleDetailByVersion) as {
    currentCycleDetail: { expectations: IExpectationItem[]; isNewCycle: boolean };
  };

  const addExpectationHandler = () => {
    dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.EXPECTATION_FORM));
    dispatch(setCurrentUpdatedExpectation(null));
  };

  const isShowFirstAddButton = useMemo(() => {
    return isEmpty(expectationListData) && isNewCycle;
  }, [expectationListData, isNewCycle]);

  return (
    <>
      {isShowFirstAddButton && (
        <div className={'add-first-expectation-modal'}>
          <div className={'cycle-expectation-panel'}>
            <div
              className={'main-content'}
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
            <Button
              type={'primary'}
              className={'add-first-expectation-btn'}
              onClick={addExpectationHandler}
            >
              <i className="ri-add-circle-fill" />
              <div>{btnContext}</div>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddFirstExpectationModal;
