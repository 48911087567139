import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IOperationBtnProps } from '@@/_new_src_/constants/common';
import { IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import './index.less';

const FormOperationBtn = (props: IOperationBtnProps) => {
  const {
    confirmBtnText,
    cancelBtnText,
    confirmBtnDisabled,
    handleClickCancel,
    isLoadingSubmit,
    cancelBtnDisabled,
    confirmBtnIconClassName,
    cancelBtnIconClassName,
  } = props;
  const {
    myCycles: { formBtnText: locale },
  } = (useSelector(selectLocaleResource) as IMyCyclesLocale) || {};

  const handleClickCancelButton = () => {
    handleClickCancel && handleClickCancel();
  };

  return (
    <div className="operation">
      <Button
        className="cancel-button"
        icon={<i className={cancelBtnIconClassName || 'ri-close-circle-fill'} />}
        onClick={handleClickCancelButton}
        disabled={cancelBtnDisabled}
      >
        {cancelBtnText || locale.cancel}
      </Button>
      <Button
        type="primary"
        className="submit-button"
        htmlType="submit"
        disabled={confirmBtnDisabled}
        icon={<i className={confirmBtnIconClassName || 'ri-save-fill'} />}
        loading={isLoadingSubmit}
      >
        {confirmBtnText}
      </Button>
    </div>
  );
};

export default FormOperationBtn;
