import React, { memo } from 'react';
import EditIcon from '@@/assets/images/edit.svg';
import './index.less';

const EditCircleButton = props => {
  return (
    <div className="edit-circle-button" {...props}>
      <img src={EditIcon} className="edit-icon" alt="" />
    </div>
  );
};

export default memo(EditCircleButton);
