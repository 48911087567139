import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { isEmpty } from 'lodash';
import LogoBlue from '@@/assets/images/logoBlue.svg';
import LogoWhite from '@@/assets/images/logoWhite.svg';
import UserProfile from '@@/_new_src_/features/UserProfile';
import { hasAccessToMenuTab } from '@@/_new_src_/utils/common/auth';
import {
  CYCLE_PAGE_LINK,
  DOCUMENT_LINK,
  MENU_PAGE_LINK,
  CURRENT_PAGE,
} from '@@/_new_src_/constants/pagePath';
import { userStore } from '@@/_new_src_/store/userSlice';
import { removeAllV2DashboardsLocalStorage } from '@@/hooks/useLocalStorageV2Dashboard';
import './index.less';
import { getMenu, PATH_KEY } from '@@/_new_src_/constants/userEnum';

const HeaderMenu = () => {
  const {
    userInfo,
    PPList: { total },
  } = useSelector(userStore);

  const resetStorage = () => {
    localStorage.removeItem('requestFeedbackListType');
    localStorage.removeItem('selectedYear');
    removeAllV2DashboardsLocalStorage();
  };
  const isLandingPage = location.pathname === MENU_PAGE_LINK.LANDING_PAGE;

  const isPP = useMemo(() => {
    return total !== 0;
  }, [total]);
  const getCurrentPathname = () => {
    const currentPathname = window.location.pathname;
    const isMyCyclesForm = CURRENT_PAGE(currentPathname).isMyCyclesFormPage;
    return isMyCyclesForm ? CYCLE_PAGE_LINK.MY_CYCLES : currentPathname;
  };
  const renderMenuItem = (pathname: string, key: string, text: string, path: string) => {
    const index = path.lastIndexOf('/');
    return (
      <li
        className={cls('menu-item', {
          active: path.includes('dashboard')
            ? pathname.startsWith(path.substring(0, index))
            : pathname.startsWith(path),
        })}
        key={key}
      >
        <Link
          to={{ pathname: path, state: { forceRefresh: true, init: key === PATH_KEY.ADMIN } }}
          onClick={resetStorage}
        >
          {text}
        </Link>
      </li>
    );
  };

  const renderMenuByRole = useCallback(() => {
    return getMenu().map(menu => {
      const currentPathname = getCurrentPathname();

      const { key, role, text, path } = menu;

      if (key === PATH_KEY.TWER_I_SUPPORT && isPP) {
        return renderMenuItem(currentPathname, key, text, path);
      }
      if (key !== PATH_KEY.TWER_I_SUPPORT && isEmpty(role)) {
        return renderMenuItem(currentPathname, key, text, path);
      }
      if (!isEmpty(role) && hasAccessToMenuTab(role, userInfo, key)) {
        return renderMenuItem(currentPathname, key, text, path);
      }
    });
  }, [userInfo]);

  return (
    <div className={'performance-layout-header-container'}>
      <header
        className={cls('performance-layout-header', {
          'performance-layout-header-landing-page': isLandingPage,
        })}
      >
        <div className="left">
          <div className="logo">
            <h1 className="heading-1">Summit</h1>
            <Link to={MENU_PAGE_LINK.LANDING_PAGE} onClick={resetStorage}>
              <img src={isLandingPage ? LogoBlue : LogoWhite} alt="summit" />
            </Link>
          </div>
          <ul className="performance-layout-header-menu">{renderMenuByRole()}</ul>
        </div>
        <div className="right">
          <div className="performance-FAQ">
            <a href={DOCUMENT_LINK.FAQ_LINK} target="_blank" rel="noreferrer">
              FAQ
            </a>
          </div>
          <UserProfile userInfo={userInfo} />
        </div>
      </header>
    </div>
  );
};

export default HeaderMenu;
