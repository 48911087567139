import { exportCycleListBySourceCategory } from '@@/api/v2/dashboard/cycles';
import ExcelExportIcon from '@@/assets/images/excel-export.png';
import NotFoundImage from '@@/assets/images/forbidden.png';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import { EMPLOYEE_STATUS, TALENT_INFORMATION_KEY } from '@@/common/constant/reviewee';
import {
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import { getPerformancePath } from '@@/common/utils';
import { downloadFile } from '@@/common/utils/download';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { Button, Table, Tag, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import React, { memo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './index.less';
import { setCycleVersion } from '@@/_new_src_/store/myCyclesSlice';
import { CYCLE_STATUS_ENUM, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import commonLocale from '@@/_new_src_/local/common/en_US';
import CycleStatusBadge from '@@/_new_src_/features/CycleStatusBadge';
import { formatRangeDuration } from '@@/_new_src_/utils/common/date';
import { getSortOrder } from '@@/_new_src_/utils';

const {
  tag: { assessmentNonEngagedTag, nonEngagedCycleByTBPTag },
} = commonLocale;

const NotFoundContent = ({ locale }) => (
  <div className="not-found-content">
    <img className="not-found-content-image" src={NotFoundImage} alt="" />
    <h3 className="not-found-content-title">{locale.notFoundContent.title}</h3>
    <p className="not-found-content-desc">{locale.notFoundContent.desc}</p>
  </div>
);

function DashboardCyclesList(props) {
  const {
    loading,
    onTableChange,
    cycleList: { content, total },
    isSearched,
    groupId,
    searchFilter,
    isDefaultRequest,
    roleInfo,
    sourceCategory,
    hierarchyId,
    groupType,
    roleName,
    hierarchyType,
  } = props;
  const {
    dashboardV2: {
      reviews: { list: locale },
    },
    reviewees: {
      ratingSummaryEditor: {
        talentInformation: { hpCheckbox, gcCheckbox, gc, hp, noneEngagedCycle },
      },
    },
  } = useSelector(selectLocaleResource);
  const [isExcelExporting, setIsExcelExporting] = useState(false);
  const dispatch = useDispatch();

  const CreateColumns = useCallback(() => {
    return [
      {
        title: locale.revieweeName,
        width: 252,
        ellipsis: { showTitle: false },
        ...getSortOrder(searchFilter, 'REVIEWEE_NAME'),
        render: ({
          assessment: { additionalTalentInformation },
          reviewee: { name, status },
          probationStatus,
          noneEngaged,
          assessmentNonEngaged,
        }) => (
          <div className="talent-and-name">
            {assessmentNonEngaged && (
              <div className={'assessment-non-engagement-tag'}>{assessmentNonEngagedTag}</div>
            )}
            <Tooltip placement="topLeft" title={nonEngagedCycleByTBPTag}>
              {noneEngaged && (
                <div>
                  <Tag className="none-cycle-item" key="cycle-opened-by-TBP">
                    {nonEngagedCycleByTBPTag}
                  </Tag>
                </div>
              )}
            </Tooltip>
            <div className="talent-container">
              <Tooltip placement="topLeft" title={hpCheckbox}>
                {additionalTalentInformation?.includes(TALENT_INFORMATION_KEY.HIGH_POTENTIAL) && (
                  <div>
                    <Tag className="talent-item" key="HP">
                      {hp}
                    </Tag>
                  </div>
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={gcCheckbox}>
                {additionalTalentInformation?.includes(
                  TALENT_INFORMATION_KEY.POTENTIAL_FOR_GRADE_CHANGE,
                ) && (
                  <div>
                    <Tag className="talent-item" key="GC">
                      {gc}
                    </Tag>
                  </div>
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={name}>
                <div className="name">{name}</div>
              </Tooltip>
            </div>
            {status === EMPLOYEE_STATUS.EXITED && (
              <div className="exited-tip">{locale.exitedTip}</div>
            )}
            {probationStatus === EMPLOYEE_STATUS.UNDER_PROBATION && (
              <div className="probation-tip">{locale.probationTip}</div>
            )}
          </div>
        ),
      },
      {
        title: locale.role,
        dataIndex: 'reviewee',
        width: 190,
        ellipsis: { showTitle: false },
        ...getSortOrder(searchFilter, 'ROLE'),
        render: ({ role }) => (
          <Tooltip placement="topLeft" title={role}>
            {role}
          </Tooltip>
        ),
      },
      {
        title: locale.duration,
        width: 235,
        render: ({ duration: { startTime, endTime } } = {}) =>
          formatRangeDuration(startTime, endTime),
      },
      {
        title: locale.status,
        width: 110,
        render: ({ status, overdue }) => (
          <CycleStatusBadge status={overdue ? CYCLE_STATUS_ENUM.OVERDUE : status} isShortStatus />
        ),
      },
      {
        title: locale.rating,
        dataIndex: 'assessment',
        width: 210,
        ellipsis: { showTitle: false },
        ...getSortOrder(searchFilter, 'RATING'),
        render: ({ rating }) => rating,
      },
    ];
  }, [
    gc,
    gcCheckbox,
    hp,
    hpCheckbox,
    locale.duration,
    locale.exitedTip,
    locale.probationTip,
    locale.project,
    locale.rating,
    locale.revieweeName,
    locale.role,
    locale.status,
    noneEngagedCycle,
    searchFilter,
  ]);

  const columns = CreateColumns();
  const history = useHistory();
  const onExportExcel = useCallback(async () => {
    setIsExcelExporting(true);
    const { pageNumber, pageSize, sortColumn, sortOrder } = searchFilter;
    const sortAndPageInfo = { pageNumber, pageSize, sortColumn, sortOrder };
    const { data } = await exportCycleListBySourceCategory({
      sourceCategory,
      groupId,
      hierarchyId,
      sortAndPageInfo,
      searchFilter,
      groupType,
      hierarchyType,
      roleName,
    });
    setIsExcelExporting(false);
    downloadFile(data, {
      filename: `Group Member-${dayjs().format('YYYY-MM-DD')}.xlsx`,
    });
  }, [searchFilter, sourceCategory, groupId, hierarchyId, groupType, roleName, hierarchyType]);

  const onGotoCycleDetailPage = useCallback(
    record => {
      const { id: cycleId, cycleVersion = CYCLE_VERSION.OLD } = record;
      let cyclesDetailUrl = `/dashboard/cycles/${cycleId}?selectedGroupId=${groupId}&selectedRole=${roleInfo}`;
      if (!isEmpty(groupType)) {
        cyclesDetailUrl += `&selectedGroupType=${groupType}`;
      }
      dispatch(setCycleVersion(cycleVersion));
      history.push(`${getPerformancePath(cyclesDetailUrl)}`);
    },
    [history, groupId, roleInfo],
  );

  if (!isSearched) {
    return (
      <LoadingWrapper loading={loading}>
        <div className="dashboard-review-list">
          <span className="not-searched-content">{locale.notSearchedContent}</span>
        </div>
      </LoadingWrapper>
    );
  }

  if (isEmpty(content)) {
    return (
      <LoadingWrapper loading={loading}>
        <div className="dashboard-review-list">
          <NotFoundContent locale={locale} />
        </div>
      </LoadingWrapper>
    );
  }

  return (
    <LoadingWrapper loading={loading}>
      <div className="dashboard-review-list">
        <div className="dashboard-review-list-operation">
          <span>
            {isDefaultRequest && total ? locale.allReviews(total) : locale.total(total || 0)}
          </span>
          <Button
            className="export-excel-button"
            onClick={onExportExcel}
            loading={isExcelExporting}
          >
            <img src={ExcelExportIcon} alt="" />
            <span>{locale.exportExcel}</span>
          </Button>
        </div>
        <Table
          rowKey={item => item.id}
          columns={columns}
          dataSource={content}
          onChange={onTableChange}
          onRow={record => ({
            onClick: () => onGotoCycleDetailPage(record),
          })}
          pagination={
            Number(total) > TABLE_DEFAULT_INFO.PAGE_SIZE
              ? {
                  ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
                  total,
                  current: searchFilter.pageNumber,
                  pageSize: searchFilter.pageSize,
                }
              : false
          }
        />
      </div>
    </LoadingWrapper>
  );
}

DashboardCyclesList.defaultProps = {
  cycleList: {
    content: [],
    total: 0,
  },
};

export default memo(DashboardCyclesList);
