import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedbackDetailAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import { feedbackDetail, setFeedbackDetail } from '@@/_new_src_/store/V2FeedbackSlice';
import './index.less';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import InternalDetail from '@@/_new_src_/page/feedback/component/InternalDetail';
import ClientDetail from '@@/_new_src_/page/feedback/component/ClientDetail';
import { FEEDBACK_TYPE } from '@@/_new_src_/constants/feedback';

const FeedbackDetail = () => {
  const dispatch = useDispatch();
  const { id: feedbackId } = useParams<{ id: string }>();
  const {
    loading,
    feedback: { type },
  } = useSelector(feedbackDetail);

  useEffect(() => {
    feedbackId && dispatch(getFeedbackDetailAsync(+feedbackId));
  }, [feedbackId]);

  useEffect(() => {
    return () => {
      dispatch(setFeedbackDetail({}));
    };
  }, []);

  return (
    <LoadingWrapper loading={loading}>
      {FEEDBACK_TYPE.INTERNAL_FEEDBACK === type && <InternalDetail />}
      {FEEDBACK_TYPE.CLIENT_FEEDBACK === type && <ClientDetail />}
    </LoadingWrapper>
  );
};
export default FeedbackDetail;
