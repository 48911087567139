export default {
  requestToEdit: {
    title: 'Request to edit',
    confirmModalProps: {
      title: 'Are you sure you want to cancel?',
      content: 'You will lose all unsaved changes and leave this page.',
      cancelText: 'Keep editing',
      okText: 'Cancel and go back',
    },
    cancelBtnText: 'Cancel',
    sendRequest: {
      submitEditRequestText: 'Submit edit rights request',
      label: 'Please provide the reasons of your request and describe what needs to be changed: ',
      placeholder: 'Enter the reason for your changes',
      submitBtn: 'Submit Request',
      statusTips: {
        openTips: (time: string) =>
          `Edit rights request submitted on ${time} is pending evaluation & approval by the Talent Business Partner.`,
        rejectedTips: (requestedAt: string, rejectedAt: string, reason: string) =>
          `Edit rights request submitted on ${requestedAt} was rejected by your Talent Business Partner on ${rejectedAt}:<br/>”${reason}”`,
        approvedTipsFirst: (requestedAt: string, approvedAt: string) =>
          `Edit rights request submitted on ${requestedAt} was approved by your Talent Business Partner on ${approvedAt}. You have until <b>`,
        approvedTipsSecond: ` </b> to submit your changes, and the cycle will become non-editable after that date.`,
      },
      statusWords: {
        openStatus: `<b>Your Talent Business Partner will evaluate</b> your request once it's submitted.<br/>If approved, you'll be able to <b>edit them from this same screen.</b> You will be <b>notified by email</b> of your request status.`,
        approvedStatus: `You can now <b>edit the cycle</b> from this same screen.`,
        completedOrRejectedStatusForPPFirst: `
          <p class="info">
            As a Performance Partner, you may request to edit the <b><u>Performance indicator & Assessment summary</u></b> 
            of your Thoughtworker’s completed Performance Cycles.
            You have <b><u>until `,
        completedOrRejectedStatusForPPSecond: `</u></b> to send out request.<br/>Please make sure that the changes you intend to 
            make are <b>discussed & aligned with the Thoughtworker.</b> 
          </p>
          <p class="info">
            <b>Your Talent Business Partner will evaluate</b> your request once it’s submitted. <br/>
            If approved, you’ll be able to <b>edit them from this same screen</b> and you need to submit your changes by 
            <span style="font-weight: 700; color: #CF2969; text-decoration: underline">`,
        completedOrRejectedStatusForPPThird: `</span>.
            You will be <b>notified by email</b> of your request status. 
          </p>`,
        completedOrRejectedStatusForTwerFirst: `
          <p class="info">
            As a Thoughtworker, you may request to edit the
            <b>
              <u> Self assessment</u> & <u>Cycle information </u>
            </b>
            of your completed Performance Cycles. You have 
            <b>until `,
        completedOrRejectedStatusForTwerSecond: `</b> to send out request. <br/>Please make sure that the changes you intend to make are
            <b> discussed & aligned with your Performance Partner.</b>
          </p>
          <p class="info">
            <b>Your Talent Business Partner will evaluate </b> your request once it’s submitted.
            <br />
            If approved, you’ll be able to <b> edit them from this same screen</b> and you need to submit your changes by
             <span style="font-weight: 700; color: #CF2969; text-decoration: underline"> `,
        completedOrRejectedStatusForTwerThird: `</span>. You will be <b>notified by email</b> of your request status. 
          </p>`,
      },
    },

    dealRequest: {
      rejectBtn: 'Reject edit rights request',
      approveBtn: 'Approve edit rights request',
      label: 'Why do you reject this request?',
      placeholder: '',
      confirmBtnText: 'Reject',
      statusTips: {
        openTips: (requesterName: string, requestedAt: string) =>
          `Edit rights request submitted by ${requesterName} on ${requestedAt} is pending your evaluation & approval.`,
        rejectedTips: (
          requesterName: string,
          requestedAt: string,
          rejectedAt: string,
          reason: string,
        ) =>
          `Edit rights request submitted by ${requesterName} on ${requestedAt} was rejected on ${rejectedAt}:<br/>”${reason}”`,
        approvedTips: (requesterName: string, requestedAt: string, approvedAt: string) =>
          `Edit rights request submitted by ${requesterName} on ${requestedAt} was approved on ${approvedAt}.`,
      },
      statusWords: {
        openStatus: (reason: string) =>
          `
            <h4 class="sub-title">Reason for edit rights request</h4>
            <p class="reason">${reason}</p>`,
      },
    },
  },

  noFeedbackContainer: {
    title: 'Start collecting feedback for your current cycle now! Go to:',
    gotoText: 'Feedback management',
    hint: `Feedback management page is where to <b>send, edit, cancel</b> feedback requests`,
  },

  noDoneFeedbackContainer: {
    title: 'Your received feedback will appear here',
    tip: 'You haven’t received any feedback yet',
    viewText: 'View or manage feedback requests',
    feedbackPage: ' via Feedback management page',
  },
};
