import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import {
  DEFAULT_REQUEST_PAGE_STATUS,
  DEFAULT_STATS_PAGE_STATUS,
  V2_DASHBOARD_SEARCH_FILTER,
  V2_REQUEST_PAGE_STATUS,
  V2_STATS_PAGE_STATUS,
  V2_STATS_TIME,
} from './constants';
import { DEFAULT_TABLE_PAGINATION } from '@@/_new_src_/constants/table';

export const setStatsTimeStatusFun = statsTimeParam => {
  let currentStatsTime = statsTimeParam;
  if (isEmpty(statsTimeParam)) {
    const currentTime = dayjs();
    try {
      currentStatsTime = dayjs(sessionStorage.getItem(V2_STATS_TIME) || currentTime);
    } catch (error) {
      currentStatsTime = currentTime;
    }
  }
  sessionStorage.setItem(V2_STATS_TIME, currentStatsTime);
  return currentStatsTime;
};

export const setStatsPageStatusFun = statsPageStatusParam => {
  let currentStatsPageStatus = statsPageStatusParam;
  if (isEmpty(statsPageStatusParam)) {
    try {
      currentStatsPageStatus =
        JSON.parse(sessionStorage.getItem(V2_STATS_PAGE_STATUS)) || DEFAULT_STATS_PAGE_STATUS;
    } catch (error) {
      currentStatsPageStatus = DEFAULT_STATS_PAGE_STATUS;
    }
  }
  sessionStorage.setItem(V2_STATS_PAGE_STATUS, JSON.stringify(currentStatsPageStatus));
  return currentStatsPageStatus;
};

export const setDefaultSearchFilterFun = searchFilterParam => {
  let currentSearchFilter = searchFilterParam;
  if (isEmpty(searchFilterParam)) {
    try {
      currentSearchFilter =
        JSON.parse(sessionStorage.getItem(V2_DASHBOARD_SEARCH_FILTER)) || DEFAULT_TABLE_PAGINATION;
    } catch (error) {
      currentSearchFilter = DEFAULT_TABLE_PAGINATION;
    }
  }
  sessionStorage.setItem(V2_DASHBOARD_SEARCH_FILTER, JSON.stringify(currentSearchFilter));
  return currentSearchFilter;
};

export const setRequestPageStatus = requestPageStatusParam => {
  let currentRequestPageStatus = requestPageStatusParam;
  if (isEmpty(requestPageStatusParam)) {
    try {
      currentRequestPageStatus =
        JSON.parse(sessionStorage.getItem(V2_REQUEST_PAGE_STATUS)) || DEFAULT_REQUEST_PAGE_STATUS;
    } catch (error) {
      currentRequestPageStatus = DEFAULT_REQUEST_PAGE_STATUS;
    }
  }
  sessionStorage.setItem(V2_REQUEST_PAGE_STATUS, JSON.stringify(currentRequestPageStatus));
  return currentRequestPageStatus;
};
