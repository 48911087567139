import { REVIEW_STATISTIC_STATUS } from '@@/common/constant/stats';

export const SORT_FIELD_DASHBOARD = {
  REVIEWEE_NAME: 'REVIEWEE_NAME',
  PROJECT_NAME: 'PROJECT_NAME',
  REVIEW_DURATION: 'REVIEW_DURATION',
  OVERDUE: 'OVERDUE',
  STATUS: 'STATUS',
  RATING: 'RATING',
  DEFAULT: 'DEFAULT',
  REVIEWEE: 'REVIEWEE',
  REVIEWER: 'REVIEWER',
  REQUESTER: 'REQUESTER',
  ROLE: 'ROLE',
  REQUEST_DATE: 'REQUEST_DATE',
};

export const sortFieldMapping = {
  revieweeName: SORT_FIELD_DASHBOARD.REVIEWEE_NAME,
  projectName: SORT_FIELD_DASHBOARD.PROJECT_NAME,
  reviewDuration: SORT_FIELD_DASHBOARD.REVIEW_DURATION,
  overdue: SORT_FIELD_DASHBOARD.OVERDUE,
  status: SORT_FIELD_DASHBOARD.STATUS,
  rating: SORT_FIELD_DASHBOARD.RATING,
};

export const reviewStatisticStatusMapping = {
  expectation: {
    remain: REVIEW_STATISTIC_STATUS.EXPECTATION_INCOMPLETED,
    done: REVIEW_STATISTIC_STATUS.EXPECTATION_COMPLETED,
  },
  assessment: {
    remain: REVIEW_STATISTIC_STATUS.ASSESSMENT_INCOMPLETED,
    done: REVIEW_STATISTIC_STATUS.ASSESSMENT_COMPLETED,
  },
};
