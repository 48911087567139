/* eslint-disable max-lines */
import MyCyclesEn from '@@/_new_src_/local/myCycles/en_US';
import { UserRoleInfo } from '@@/_new_src_/constants/userEnum';

export default {
  myCycles: MyCyclesEn,
  oktaError: {
    deviceTimeNotCorrect:
      'Your local time is not the same as time set in your device. Please check the time and try again.',
    notAssigned: "Sorry, you're not assigned to Summit. Please search it in OKTA to add Summit.",
  },
  api: {
    errorMessage: "we've found some errors, please refresh the page to try again.",
    unauthorizedMessage: 'An error occurred. Please try again.',
  },
  perModal: {
    confirmTitle: 'Are you sure?',
  },
  emptyDescription: 'No Data',
  collapsibleDescription: {
    readMore: 'Read more',
    readLess: 'Read less',
  },
  menu: {
    reviews: 'My cycles',
    reviewees: 'Twers I support',
    dashboard: 'Requests and Cycles',
    stats: 'Stats',
    dashboardReviews: 'Cycles',
    dashboardRequests: 'Requests',
    groupMembers: 'Group Members',
    allReviewees: 'All Reviewees',
    groupManagement: 'Group Management',
    adminManage: 'Admin',
    feedback: 'Feedback management',
    admin: 'Admin',
  },
  myReviews: {
    startReviewRemind: {
      button: 'Start my cycle',
      remindDefaultText: 'you can start a cycle today to track your performance.',
      remindContent: text => `it’s been ${text} since your last performance cycle…`,
      tipCombination: [
        'You can talk with your performance partner(s) first about ',
        'expectations',
      ],
    },
  },
  setReview: {
    title: 'First, set your review.',
    form: {
      role: {
        label: 'My current role is',
        labelOnUpdate: 'Role',
      },
      project: {
        label: 'Which project this cycle refers to?',
        labelOnUpdate: 'Project',
        placeholder: "Select the project's name",
        notFoundContent: 'No project found',
        validateMessage: '* Please select your project.',
        getListFailedMessage: 'get project list failed!',
      },
      reviewer: {
        label: 'Who would you like to be your',
        labelOnUpdate: 'Performance partner',
        labelBold: 'performance partner',
        placeholder: "Enter your performance partner's name or ID",
        notFoundContent: 'No performance partner found',
        validateMessage: '* Please select your performance partner.',
        tooltipText:
          'Your performance partner is a Twer close to your day-to-day work, usually in a leadership position, tenure and/or seniority that is responsible to set your expectations, support your growth and assess your performance considering both aligned expectations and your growth intentions.',
      },
      contributors: {
        label: 'Who would you like to add as',
        labelOnUpdate: 'Additional partner(s)',
        labelBold: 'additional partner(s)',
        placeholder: 'Enter up to 2 additional partners',
        notFoundContent: 'No additional partner found',
        limitPartnerNumber: '* Please select up to 2 additional partners.',
        tooltipText:
          'Choose up to two people as additional partners. The additional partner(s) are close to your day-to-day work, usually in a leadership position, tenure and/or seniority that share the responsible to set your expectations, support your growth and assess your performance considering both aligned expectations and your growth intentions.',
      },
      tooltipReadMore: {
        linkText: 'Read more',
        chinaReadMore: {
          commonText: 'In China this is called ',
          reviewer: 'First Reviewer',
          contributor: 'Secondary Reviewer',
        },
        indiaReadMore: {
          commonText: 'In India this is called ',
          reviewer: 'Success Catalyst',
          contributor: 'Guide',
        },
      },
      reviewDuration: {
        label: 'When do you want to start and finish your cycle?',
        labelOnUpdate: 'Duration',
        validateMessage: {
          require: '* Please select the cycle duration.',
          startMonth: 'The start time must be from past',
          within: 'The cycle duration must be less than one year',
        },
      },
      expectation: {
        label: 'What are the expectations you aligned?',
        labelOnUpdate: 'Expectations',
        validateMessage: '* Please input your expectation.',
        placeholder:
          "Once you've talked with your performance partner(s) and aligned the expectations about your work, write here a brief summary of this discussion and the outcomes. Describe the context of your work, what are your growth expectations, what is expected from you in this performance cycle and how aligned to your grade and role these expectations are.",
        tipCombination: ['Remember!', ' Checkout the', 'grade expectations'],
        copy: {
          tooltip: 'You can copy expectations from the previous cycle.',
          label: 'Copy expectations',
        },
        copyModal: {
          titleText: 'Copy expectation',
          projectTip: 'Copy expectation from your last done cycle:',
          okButtonText: 'Copy',
          cancelButtonText: 'Cancel',
        },
        attachment: {
          tooltip: (fileSize, accept) =>
            `File size should be less than ${fileSize}. Supported file format: ${accept}.`,
          label: 'Add attachment',
          onlyAttachOneFile: 'You can only attach one file.',
          maxSize: size => `The file size cannot exceed ${size}.`,
          maxNameLength: length =>
            `Cannot upload the file because the file name length is more than ${length} characters`,
        },
      },
    },
    footer: {
      cancel: {
        cancelText: 'Cancel',
        confirmOkText: 'Yes, cancel',
        confirmCancelText: 'Not yet',
        confirmMessage: 'The content you entered will be lost.',
      },
      save: {
        saveText: 'Save',
        saveUpdateText: 'Submit Changes',
        confirmOkText: 'Yes, save',
        confirmOkUpdateText: 'Yes, submit changes',
        confirmMessage: {
          default:
            'This performance cycle information will be shared with your performance partners.',
          editDone: 'Changes will be shared with your performance partner.',
          email: 'They will be notified by email.',
        },
        successMessage: 'Saved successfully!',
      },
    },
  },
  reviewList: {
    review: 'Review',
    nonEngagedCycle: 'Non-engaged',
    openedBy: 'Opened by',
    reviewBadge: reviewStatus => `${reviewStatus} Cycle`,
    project: 'Project',
    reviewer: 'Performance Partner',
    contributors: 'Additional Partner(s)',
    duration: 'Cycle Duration',
    expectations: 'Aligned Expectations',
    rating: 'Performance Indicator',
    summary: 'Assessment Summary',
    readAndAcknowledged: 'Read and acknowledged',
    confirmAssessmentResult:
      'Please confirm your assessment results by clicking the “Read and acknowledged”. Or if you have any doubts, please reach out to your performance partner and Talent Business Partner.',
    whatNow: 'What Now?',
    role: 'Role',
    grade: 'Grade',
    assessmentSummaryUnread: 'Assessment summary unread',
    description:
      'If you have any doubt about the assessment result, please reach out to your performance partner(s). Or you can start a new cycle straight away.',
    confirm: {
      cancel: 'Not yet',
      ok: 'Cancel cycle',
      content:
        'Once the cycle is canceled, all the expectations, regular check-ins, feedbacks, self assessment will be lost. Please notify your performance partner(s) that you are canceling this performance cycle.',
      nonEngagedContent:
        'This is a non engaged cycle, once the cycle is canceled, all the expectations, regular check-ins, feedbacks, self assessment will be lost. Please notify your performance partner(s) that you are canceling this performance cycle.',
    },
    cancelSuccessMessage: 'The cycle is canceled successfully!',
    requestEdit: {
      button: 'Request to Edit',
      modal: {
        title: 'Request to Edit',
        okText: 'Send Request',
        textarea: {
          label:
            'Please provide the reasons of your request and describe what needs to be changed: ',
          placeholder: 'Enter the reason for your changes',
        },
        successMessage: 'Your request is sent successfully!',
      },
    },
    showTalentTagsButton: {
      showTalentTags: 'Show Talent Info',
      hideTalentTags: 'Hide Talent Info',
    },
    selfReviewPreview: {
      updateDescription: 'Updated at ',
      title: 'Self Assessment',
      createButtonText: 'Create Self Assessment',
      selfReviewInput: {
        title:
          'Reflect how you are doing against the expectations aligned during this performance cycle and provide your self assessment: ',
        cancelButton: 'Cancel',
        saveButton: 'Save',
        cancelModal: {
          cancelText: 'Not yet',
          content: 'The content you entered will be lost.',
        },
        confirmModal: {
          content:
            'The self assessment you provided will be shared with your performance partner(s).',
          submitButton: 'Yes, submit',
          saveButton: 'Yes, save changes',
          submitSuccessMessage: 'The self assessment has been submitted successfully!',
          saveSuccessMessage: 'The self assessment has been updated successfully!',
        },
      },
    },
    reviewDetailTabTitle: 'Details',
    editText: 'Edit Cycle',
    cancelText: 'Cancel Cycle',
    reviewStatus: status => `${status} Cycle`,
    multiPartnerModalContent: {
      title:
        'Please take a moment to review your additional partners. It is only possible to select up to 2 additional partners.',
      confirmText: 'Edit Cycle',
      desc: 'This is to support your experience of the performance process and help protect the privacy of your performance data. If you need more inputs, you could reach out to them for performance feedback.',
    },
    overdueTextP1: `Cycle overdue. Connect with your performance partner to end it by `,
    overdueTextP2: `.`,
  },
  reviewHistory: {
    title: 'My Performance History',
    empty: 'your past performance cycles will appear here.',
    yearSelectText: 'Year',
    assessmentUpdatedTips: 'Your performance partner has changed your performance assessment.',
    requestRejectedTips:
      'Your request to edit was declined. Please contact the team responsible for the performance process in your region (usually your people team) for more details.',
    detailTitle: 'Project: ',
  },
  reviewees: {
    list: {
      tableColumn: {
        revieweeName: 'Twer',
        role: 'Role',
        grade: 'Grade',
        project: 'Project',
        duration: 'Cycle duration',
        rating: 'Performance indicator',
        status: 'Status',
        statusTip:
          'Your request for edit was accepted. You can now edit this performance assessment.',
        contributorReviewInputCountTip: number =>
          `${number} additional partner(s) have already provided input for this cycle`,
        exitedTip: '(Exited)',
        probationTip: '(Probation)',
        noneEngaged: 'Non Engaged',
      },
      reviewerTypeSelect: {
        all: {
          label: 'Select your role',
          tips: 'Select the role you are playing in the performance cycle, performance partner or additional partner.',
        },
        reviewer: {
          label: 'Performance Partner',
          tips: "As a performance partner, you are responsible to summarise and submit the Twer's performance assessment.",
        },
        contributor: {
          label: 'Additional Partner',
          tips: 'As an additional partner, you should pair with the performance partner during the performance cycle.',
        },
      },
      total: number => `${number} Twer(s) in total`,
      showRating: 'Show performance indicator',
    },
    detail: {
      assessNow: 'Assess Now',
      submitNow: 'Submit Now',
      h1CannotSubmitTooltip: 'You will be able to submit assessment after May 15th.',
      h2CannotSubmitTooltip: 'You will be able to submit assessment after November 15th.',
      probationCannotSubmitTooltip:
        'You can submit assessment after probationary period for the cycle conclusion.',
      provideReviewInput: 'Provide Assessment Input',
      submitTips: name => `${name} will see your assessment only when submitted.`,
      submitAssessmentModal: {
        title: 'Submit Assessment',
        content: {
          main: 'Once submitted, the cycle will be closed.',
          contributorPrefix: 'Additional partner ',
          contributorText: ' will no longer be able to provide assessment input;',
          reviewee:
            'The Twer you assessed will no longer be able to collect performance feedback for this cycle.',
          checkbox: hasContributor =>
            `I have already aligned the performance assessment with the Twer${
              hasContributor ? ' and additional partner(s).' : '.'
            }`,
        },
        cancelText: 'Cancel',
        okText: 'Submit Now',
        successMessage: 'The assessment has been submitted successfully!',
      },
    },
    ratingSummaryEditor: {
      rating: {
        label: 'Performance Indicator',
        requiredMessage: '* Please select rating.',
        placeholder: 'Choose one level from the list',
      },
      summary: {
        label: 'Assessment Summary',
        requiredMessage: '* Please input Summary of Why.',
        placeholder:
          'Please, write a brief summary of the assessment discussion and the outcomes. Indicate the reasons that sustain the assessment and the chosen performance indicator.',
      },
      undo: 'Undo Changes',
      cancel: 'Cancel',
      save: 'Save Draft',
      submitChanges: 'Submit Changes',
      confirmSubmit: {
        content: 'Changes you made will be shared with the Twer.',
        cancelText: 'Cancel',
        okText: 'Yes, submit changes',
        successMessage: 'The assessment has been submitted successfully!',
      },
      confirmCancel: {
        cancelText: 'Not yet',
        okText: 'Cancel Assessment',
        content: 'The content you entered will be lost.',
      },
      saveDraftSuccessMessage: 'The assessment has been saved as draft successfully!',
      talentInformation: {
        label: 'Additional Talent Information:',
        tagsTip:
          'If relevant, use this option to provide additional information about the Twer to support other talent and compensation processes.',
        dropdownButton: '+ Add Talent Information',
        noTalentCheckbox: 'No talent information for this Twer.',
        ne: 'NE',
        noneEngagedCycle: 'Non Engaged',
        hp: 'HP',
        hpCheckbox: 'High Potential',
        hpTip:
          'Does the individual have the competency, aspiration, motivation and engagement/alignment with the business to rapidly take on increasing levels of responsibility and/or leadership?',
        gc: 'GC',
        gcCheckbox: 'Potential for Grade Change',
        gcTip:
          'Has the individual performed consistently above the expectations for their current grade?',
      },
    },
    reviewInputTab: {
      tabTitle: total => `Assessment Input (${total})`,
      emptyList: 'No review inputs from contributors.',
    },
    feedbackTab: {
      tabTitle: total => `Feedback (${total})`,
    },
    feedbackPreview: {
      name: 'Name',
      role: 'Role',
      project: 'Project',
    },
    reviewInputEditor: {
      reviewInput: {
        label: 'Please provide input for this performance assessment',
      },
      cancel: 'Cancel',
      submit: 'Submit',
      saveChanges: 'Save Changes',
      confirmCancel: {
        cancelText: 'Not yet',
        okText: 'Cancel',
        content: 'The content you entered will be lost.',
      },
      confirmSubmit: {
        content:
          'The assessment input you provided will be shared with the other performance partner(s) in this performance cycle.',
        cancelText: 'Cancel',
        okText: 'Yes, submit',
        successMessage: 'The assessment input has been submitted successfully!',
      },
      confirmSaveChanges: {
        content:
          'The assessment input you provided will be shared with the other performance partner(s) in this performance cycle.',
        cancelText: 'Cancel',
        okText: 'Yes, save changes',
        successMessage: 'The assessment input has been updated successfully!',
      },
    },
    reviewInputPreview: {
      title: 'Assessment Input',
      providedDescription: ['Provided by ', ' at '],
      removedRoleTips: '(Removed)',
    },
    checkInNotes: {
      title: 'Check-in Notes',
      add: 'Add check-in notes',
      removedRoleTips: '(Removed)',
      empty: {
        record: 'You can record the check-in conversations here.',
        default: 'No check-in notes',
      },
      notes: {
        label: 'Notes:',
      },
      actions: {
        label: 'Actions:',
      },
      edit: 'Edit',
      checkInNotesModal: {
        title: {
          add: 'Add Check-in Notes',
          edit: 'Edit Check-in Notes',
        },
        okText: {
          add: 'Add Notes',
          edit: 'Save Changes',
        },
        cancelText: 'Cancel',
        notes: {
          label: 'Notes',
          placeholder: 'Summary of your check-in conversation',
        },
        actions: {
          label: 'Actions',
          placeholder: 'List the actions that you need to take as result of this conversation',
        },
      },
    },
    multiPartnerModalContent: {
      title:
        'Please reach out to this Twer and ask them to select up to 2 additional partners only.',
      desc: 'This is to support their experience of the process and help protect the privacy of their data.',
    },
  },
  dashboardV2: {
    syncEmployeeData: 'sync employee',
    syncEmployeeDataSuccessMessage: 'The employee data has been synchronized successfully!',
    syncEmployeeDataErrorMessage: 'Synchronize employee data failed, please try again!',
    selectGroup: { noResultsFound: 'No results found' },
    stats: {
      endTimeDesc: 'Stats as of',
      expectation: {
        title: 'Ongoing Performance Cycles',
        desc: 'Twers on this group',
        allGroupDesc: 'Twers on this region',
        v2Desc: 'of selected Twers',
        doneSubDesc: 'have ongoing cycles',
        remainSubDesc: "don't have ongoing cycles",
        doneDetail: number => `${number} Twers have ongoing cycles`,
        remainDetail: number => `${number} Twers don't have ongoing cycles`,
      },
      assessment: {
        title: 'Completed Performance Cycles',
        desc: 'of the ongoing cycles',
        v2Desc: "of Twer's ongoing cycles",
        doneSubDesc: 'are completed',
        remainSubDesc: "aren't completed",
        doneDetail: number => `${number} Twer's cycles have been completed`,
        remainDetail: number => `${number} Twer's cycles aren't completed yet`,
      },
      list: {
        revieweeName: "Twer's name",
        projectName: 'Project',
        reviewDuration: 'Cycle Duration',
        overdueDuration: 'Overdue Duration',
        status: 'Status',
        overdue: 'Overdue days',
        rating: 'Performance indicator',
        hasOpenReview: 'Has Open Cycle',
        fromAnotherAccount: 'This Twer has an ongoing cycle for another account.',
        notFromAnotherAccount: 'This Twer’s pervious cycle is still open and overdue to close.',
        yes: 'Yes',
        no: 'No',
        emailButton: 'Notify by Email',
        selectedNumber: number =>
          `You’ve selected ${number} ${number === 1 ? 'Twer' : 'Twers'} in this group.`,
        sendNumber: number => `Sending ${number} ${number === 1 ? 'email' : 'emails'}...`,
        content: 'Send an email to remind selected Twers to start a performance cycle.',
        okText: 'Send Email',
        cancelText: 'Not yet',
        successMessage: 'The email has been sent successfully!',
        errorMessage:
          'Error occurred when sending email. You may try again later or contact the support team.',
        probationTip: '(Probation)',
        noneEngagedCycle: 'Non Engaged',
        ne: 'NE',
      },
      filterForm: {
        account: {
          label: 'By account',
          placeholder: "Enter the account's name",
          notFoundContent: 'No account found',
        },
        project: {
          label: 'By project',
          placeholder: "Enter the project's name",
          notFoundContent: 'No project found',
        },
        clearButtonText: 'Clear search',
        findButtonText: 'Find',
      },
      pieChartNoData: 'No Data Found',
      noGroups: 'No groups found under this region.',
      tips: "Click on a chart's segment for details.",
    },
    reviews: {
      filter: {
        title: 'Find Cycles',
        clearText: 'Clear search',
        searchText: 'Find',
        more: 'More options',
        less: 'Less options',
        account: {
          label: 'By account',
          placeholder: "Enter the account's name",
        },
        project: {
          label: 'By project',
          placeholder: "Enter the project's name",
        },
        rating: {
          label: 'By performance indicator',
          placeholder: 'Select performance indicator',
        },
        duration: {
          label: 'By cycle duration',
          placeholder: 'Select a duration',
        },
        startTime: {
          placeholder: 'Select start month',
        },
        endTime: {
          placeholder: 'Select end month',
        },
        reviewees: {
          label: "By Twer's name",
          placeholder: "Enter the Twer's name or ID",
        },
        reviewers: {
          label: "By performance partner's name",
          placeholder: "Enter the performance partner's name or ID",
        },
        role: {
          label: "By Twer's role ",
          placeholder: "Select the Twer's role",
        },
        status: {
          label: 'By cycle status',
          placeholder: 'Select cycle status',
        },
        employeeStatus: {
          label: 'By employee status',
          placeholder: 'Select employee status',
        },
        probationStatus: {
          label: 'By probation status',
          placeholder: 'Select probation status',
        },
        noneEngagement: {
          label: 'By non-engagement',
          placeholder: 'Select non-engagement type(s)',
        },
      },
      list: {
        revieweeName: "Twer's name",
        role: 'Role',
        project: 'Project',
        duration: 'Cycle duration',
        status: 'Status',
        rating: 'Performance indicator',
        exportExcel: 'Export list',
        total: total => `${total} ${total > 1 ? 'cycles' : 'cycle'} found`,
        allReviews: number => `All ${number} ${number > 1 ? 'cycles' : 'cycle'}`,
        notSearchedContent: 'Found cycles will appear here.',
        notFoundContent: {
          title: 'Sorry, we couldn’t find any cycle.',
          desc: 'Please, try another search above.',
        },
        exitedTip: '(Exited)',
        probationTip: '(Probation)',
      },
    },
    requests: {
      list: {
        total: total =>
          `${total} requests to edit closed cycles pending your evaluation & approval`,
        requestDate: 'Request date',
        requester: 'Requester',
        reviewer: 'Performance Partner',
        reviewee: 'Thoughtworker',
        project: 'Project',
        reason: 'Reason for Request',
        operation: 'Permission to Edit',
        approve: 'Approve',
        reject: 'Reject',
        approved: 'Approved',
        rejected: 'Rejected',
        open: 'Pending',
        probationTip: '(Probation)',
        noneEngagedCycle: 'Non Engaged',
        ne: 'NE',
        cycleDuration: 'Cycle duration',
        status: 'Status',
        pendingTooltip: 'Waiting to be approved or rejected',
        approvedTooltip: 'This request was approved',
        rejectedTooltip: 'This request was rejected',
      },
      requestDetail: {
        approve: 'Approve',
        reject: 'Reject',
        descriptionFromReviewer: (requester, reason) =>
          `${requester} has requested to edit the assessment result. Reason for Request: ${reason}`,
        descriptionFromReviewee: (requester, reason) =>
          `${requester} has requested to edit the performance cycle details. Reason for Request: ${reason}`,
      },
    },
    reviewDetailChangeHistory: {
      description: {
        assessment: 'has updated the performance assessment.',
        review: 'has updated the performance cycle details.',
      },
    },
  },
  admin: {
    add: 'Add',
    name: 'Name',
    pleaseSelectGroupMemberName: '* Please select group member’s name.',
    enterGroupMemberName: 'Enter group member’s name or ID',
    nameNotFound: 'name or ID not found',
    addGroupMemberSuccess: 'The group member(s) is/are added successfully!',
    manageGlobalFunctionServiceLineRole: {
      addPeopleIntoExtendedGlobalLeadershipTeam: 'Add people into Global Management Team',
      addPeopleIntoGlobalPeopleTeam: 'Add people into Global People Team',
    },
    manageAdminRole: {
      totalForAdmin: total => `${total} Twer(s) in total`,
      addGlobalAdminAction: 'Add System Admin',
      addRegionalAdminAction: 'Add PS Admin',
      addGlobalFunctionOrServiceLineAdminAction: 'Add OPs Admin',
      deleteAction: 'Remove',
      alreadyOneGlobalFunctionServiceLineAdminError: 'There are already two OPs Admins',
      addAdminModal: {
        nameLabel: 'Name',
        nameNotFound: 'name or ID not found',
        cancel: 'Cancel',
        add: 'Add',
        save: 'Save',
        title: adminType => `Add ${UserRoleInfo[adminType].display}`,
        namePlaceholder: adminType => `Enter ${UserRoleInfo[adminType].display}'s Name`,
        successMessage: adminType =>
          `The ${UserRoleInfo[adminType].display} is added successfully!`,
        errorMessage: adminType => `Enter ${UserRoleInfo[adminType].display}’s Name.`,
        globalFunctionServiceLineAdminTip: 'Operations can have only two Admins.',
        hidePermissions: 'Hide Permissions',
        showPermissions: 'Show Permissions',
      },
      addGlobalAdminModal: {
        title: 'Add System Admin',
        nameLabel: 'Name',
        namePlaceholder: "Enter System Admin's name or ID",
        nameNotFound: 'name or ID not found',
        cancel: 'Cancel',
        add: 'Add',
        successMessage: 'The System Admin is added successfully!',
        errorMessage: '* Please select system admin’s name.',
      },
      addRegionalAdminModal: {
        title: 'Add PS Admin',
        nameLabel: 'Name',
        regionLabel: 'Region',
        namePlaceholder: "Enter PS Admin's name or ID",
        nameNotFound: 'name or ID not found',
        regionPlaceholder: 'Select Region',
        cancel: 'Cancel',
        add: 'Add',
        successMessage: 'The PS Admin is added successfully!',
        errorMessage: '* Please select regional admin’s name.',
        oneRegionCanOnlyHaveOneRegionalAdmin: `One Region can only have one PS Admin.`,
      },
      addGlobalFunctionServiceLineAdminModal: {
        title: 'Add OPs Admin',
        nameLabel: 'Name',
        namePlaceholder: 'Enter OPs Admin’s Name or ID',
        nameNotFound: 'name or ID not found',
        cancel: 'Cancel',
        add: 'Add',
        successMessage: 'The OPs Admin is added successfully!',
        errorMessage: '* Please select OPs admin’s name.',
        assignPeopleModalTip: 'OPs can have only two Admins.',
      },
      deleteAdminConfirmModal: {
        cancelText: 'Hold on',
        okText: 'Yes, remove',
        content:
          'After removing this person, this person will not be able to manage group(s) and role(s) as the corresponding admin.',
        successMessage: type => `The ${UserRoleInfo[type].display} is removed successfully!`,
      },
      adminTableColumn: {
        name: 'Name',
        addedBy: 'Added By',
        addedAt: 'Added At',
        actions: 'Actions',
      },
    },
    manageGroups: {
      correspondingLeadersOfEGLT: 'the Global Head of People',
      correspondingLeadersOfGlobalPeopleTeam: 'the Global Head of People',
      extendedGlobalLeadershipTeam: 'Global Management Team',
      globalPeopleTeam: 'Global People Team',
      table: {
        name: 'Name',
        createdBy: 'Added by',
        createdAt: 'Added at',
        actions: 'Actions',
        addPeople: 'Add People',
        remove: 'Remove',
      },
      regionalAdminModal: {
        name: {
          label: 'Name',
          placeholder: 'Enter group member’s name or ID',
          errorMessage: '* Please select group member’s name.',
          notFoundContent: 'name or ID not found',
        },
        cancel: 'Cancel',
        add: 'Add',
        errorMessage: errorCount => `${errorCount} people cannot be added to this group`,
        successMessage: 'The group member(s) is/are added successfully!',
        addPeople: 'Add People',
        addLeadershipTeamModalTitle: regionName => `Add people into ${regionName} Leadership Team`,
        addPeopleTeamModalTitle: regionName => `Add people into ${regionName} People Team`,
      },
      removePeople: {
        cancelText: 'Hold on',
        okText: 'Yes, remove',
        content: correspondingRoles =>
          `After removing this Twer, ${correspondingRoles} will not be able to see this Twer’s performance cycle from this group.`,
        successMessage: 'Removed successfully',
        leaderShipErrorMessage: `If you want to remove this person, please remove this person from the Market/DEC/Service Line Leadership role first.`,
        talentBPErrorMessage: `If you want to remove this person, please remove this person from the Talent Business Partner role first.`,
      },
      regionalGroupsTypeTitle: ['REGIONAL LEVEL GROUPS'],
      regionalLevelProgram: {
        edit: 'Edit',
      },
      editRegionalLevelGroupModal: {
        title: 'Edit Regional Level Group',
        okText: 'Save',
        cancelText: 'Cancel',
        successMessage: 'Group edited successfully！',
        groupName: {
          label: 'Name this group',
          placeholder: "Enter your group's name",
          requiredMessage: '* Please input group name.',
        },
        groupLead: {
          label: 'Finally, who would be the group’s manager?',
          placeholder: 'Enter your group’s manager name or ID',
          requiredMessage: '* Please add group manager.',
          notFoundContent: 'name or ID not found',
        },
        showPermissions: 'Show Permissions',
        hidePermissions: 'Hide Permissions',
      },
    },
    manageGroupsOfTBP: {
      totalGroups: total => `${total} ${total <= 1 ? 'group' : 'groups'} in total`,
      table: {
        createGroup: 'Create group',
        groupName: 'Group Name',
        groupLeaders: 'Group Leads',
        createdBy: 'Created by',
        createdAt: 'Created at',
        actions: 'Actions',
        edit: 'edit',
        remove: 'remove',
      },
      groupModal: {
        uploadFileErrorMessage: `Please make sure you're using the correct template and you have input at least one employee id.`,
        clickOrDrag: 'Click or drag',
        aSingleExcelFile: 'a single Excel file',
        toThisAreaToUpload: 'to this area to upload',
        youCanOnlyAddPeopleWithoutSpecialRole:
          'Notes: You can only add people without special role(s).',
        ifYouReUploadTheFileWillReplaceThePreviousGroupMemberList:
          'If you re-upload, the file will replace the previous group member list',
        getTemplateHere: 'Get template here',
        groupLeads: 'Group lead(s)',
        enterTheGroupName: 'Enter the group name',
        enterGroupLeadName: 'Enter group lead(s) name or ID',
        title: 'Create new group',
        editTitle: 'Import List',
        okText: 'Create group',
        editOkText: 'Save',
        cancelText: 'Cancel',
        groupName: {
          label: 'Name this group',
          placeholder: "Enter your group's name",
          requiredMessage: '* Please input Group Name.',
        },
        members: {
          label: 'Import members to this group',
          import: 'Import list',
        },
        groupLead: {
          label: 'Finally, who would be the group’s lead?',
          placeholder: 'Enter your group’s lead name or ID',
          requiredMessage: '* Please add group lead.',
          notFoundContent: 'name or ID not found',
        },
        successMessage: name => `Group ${name} created!`,
        editSuccessMessage: 'Group list changed successfully !',
        invalidEmployeeIds: (ids, number) => [
          "We couldn't find employee ID ",
          ids,
          `Please, verify these ${number <= 1 ? 'ID' : 'ID(s)'} and import your list again.`,
        ],
        invalidInsert: ids => [
          'The employee ID ',
          ids,
          `cannot be imported. Please check the list and import your list again.`,
        ],
        template: {
          tips: 'Need a group template?',
          DownloadText: 'View template here',
        },
        importListTips:
          'If you re-upload the excel file, this will replace the previous group member list!',
        showPermissions: 'Show Permissions',
        hidePermissions: 'Hide Permissions',

        talentBusinessPartnerAccess: {
          role: 'Group Lead',
          accessList: ['Can access cycle details', 'Can access cycle completion information'],
          scope: {
            user: 'All Twers',
            preposition: 'in',
            correspondingCategory: `their Group`,
          },
        },
        globalFunctionTBPAccess: {
          role: 'Group Lead',
          accessList: ['Can access cycle details', 'Can access cycle completion information'],
          scope: {
            user: 'All Twers',
            preposition: 'in',
            correspondingCategory: `their Group`,
          },
        },
      },
      editGroupForTBP: {
        title: 'Edit Group',
        groupDetail: {
          edit: 'Edit',
          groupLeads: 'Group lead(s):',
        },
        editGroupModal: {
          title: 'Edit',
          okText: 'Save',
          cancelText: 'Cancel',
          successMessage: 'Group info edited successfully！',
          groupName: {
            label: 'Name this group',
            placeholder: "Enter your group's name",
            requiredMessage: '* Please input group name.',
          },
          groupLead: {
            label: 'Finally, who would be the group’s lead?',
            placeholder: 'Enter your group’s lead name or ID',
            requiredMessage: '* Please add group lead.',
            notFoundContent: 'name or ID not found',
          },
          showPermissions: 'Show Permissions',
          hidePermissions: 'Hide Permissions',
        },
        editGroupMemberList: {
          totalTWers: total => `${total} Twer(s) in total`,
          importList: 'Import List',
          table: {
            name: 'Name',
            employeeID: 'Employee ID',
            addedAt: 'Added at',
            actions: 'Actions',
            remove: 'Remove',
          },
          deleteGroupMemberModel: {
            cancelText: 'Hold on',
            okText: 'Yes, remove ',
            contentText: (userName, groupName) =>
              `Are you sure you want to delete ${userName} from ${groupName}? `,
            successMessage: 'Group member(s) removed successfully !',
          },
        },
      },
      removeGroup: {
        removeAction: 'Remove',
        cancelText: 'Hold on',
        okText: 'Yes, remove',
        successMessage: 'The group is deleted successfully!',
        content: `After removing this group, twer(s) cycle under this group will not be visible to you and other corresponding roles.`,
      },
    },
    automatedGroupsOfTBP: {
      totalGroups: total =>
        `Groups by account (${total} ${total <= 1 ? 'group' : 'groups'} in total)`,
      tableDescribe:
        'As a Talent Business Partner, you can see the account groups that contain Twers for your Market/DEC/Service Line. ',
      manageCLT: 'Manage CLT',
      hideGroups: 'Hide group(s)',
      showGroups: 'Show group(s)',
      hideGroupsTips: 'Please select a group you want to hide first.',
      showGroupsTips: 'Please choose the group(s) you would like to unhide.',
      clientLeadershipTeamTips:
        'CLT can view all Twers’ performance info on the corresponding account.',
      noCLT: 'No CLT, please assign',
      table: {
        name: 'Group name',
        clientLeadershipTeam: 'Client Leadership Team',
        memberCount: 'No. of members',
        createdAt: 'Created at',
        actions: 'Action',
      },
      backTitle: 'See all my current groups',
      detail: {
        tableTotal: total =>
          `${total} ${
            total <= 1 ? 'Twer' : 'Twers'
          } in total - from your market and on this account group`,
        clientLeadershipTeam: 'Client Leadership Team',
        table: {
          employeeId: 'Employee ID',
          name: 'Twer’s name',
          role: 'Role',
        },
      },
      confirmHideAccountGroupPageModal: {
        leaveText: 'Yes, hide',
        showLeaveText: 'Yes, show',
        stayText: 'Hold on',
        titleText: 'Are you sure?',
        hideContentText:
          "Are you sure? By hiding this group(s), you won't be able to see performance information for its members. You can re-enable this view at any time.",
        showContentText:
          'Are you sure? By showing this group(s), you would be able to see performance information for its members. You can re-disable this view at any time.',
      },
      visibleSelector: {
        allVisibleGroups: 'All visible groups',
        allHiddenGroups: 'All hidden groups',
      },
    },
    manageRegionalLevelGroups: {
      totalGroups: total => `${total} ${total <= 1 ? 'group' : 'groups'} in total`,
      editGroupForGroupManager: {
        title: 'Edit Group',
        groupManager: 'Group manager:',
        editGroupMemberList: {
          totalTWers: total => `${total} Twer(s) in total`,
          importList: 'Import List',
          table: {
            name: 'Name',
            employeeID: 'Employee ID',
            addedAt: 'Added at',
            actions: 'Actions',
            remove: 'Remove',
          },
        },
        deleteGroupMemberModel: {
          cancelText: 'Hold on',
          okText: 'Yes, remove ',
          contentText: (userName, groupName) =>
            `Are you sure you want to delete ${userName} from ${groupName}? `,
          successMessage: 'Group member(s) removed successfully !',
        },
      },
    },
    assignCLTModalInfo: {
      modalTitle: 'Manage Client Leadership Team',
      nameLabel: `CLT's name`,
      successMessage: 'The CLT is updated successfully!',
      placeholder: `Enter CLT members' name or ID`,
      permissionList: [
        {
          role: 'Client Leadership Team',
          accessList: ['Can access cycle details', 'Can access cycle completion information'],
          scope: {
            user: 'All Twers',
            preposition: 'on',
            correspondingCategory: `this account(group) within corresponding assignment period.`,
          },
        },
      ],
      memberInOtherAccount:
        'This CLT is also on the Account, the performance info will be exluded from the main view.',
    },
  },
  logout: 'Log out',
  welcome: 'Welcome',
  productUpdateTooltips: 'You will find product updates here!',
  forbidden: {
    title: 'Oops!',
    info: 'For security and privacy reasons this page is not available.',
    desc: 'If you still think you need access to this Thoughtworker’s profile, contact your HRBP person.',
    backToHomePage: 'Back to home page',
  },
  footer: {
    feedbackDesc: ['Talk to us! ', 'Your feedback counts.'],
    playbookDesc: 'Want to learn more about the performance process?',
    performance: 'Performance',
    divider: '|',
    playbook: 'Playbook',
    privacyQuestion: 'How will Summit use my data?',
    privacyAnswer: 'We are currently exploring this.',
    dataDisclaimerShowButton: 'Read our Data Disclaimer',
    contact: 'Contact:',
    email: 'summit-support@thoughtworks.com',
    copyright: ', Inc. All Rights Reserved.',
    dataDisclaimerModalTitle: 'Data Disclaimer',
    dataDisclaimerModalOkButton: 'Got it!',
  },
  reviewSteps: {
    expectationAlignment: {
      title: 'Align expectations with your performance partner(s).',
      label: 'Expectations Alignment',
      desc: 'The performance cycle starts with you aligning expectations with your performance partner(s).',
    },
    regularCheckins: {
      title: 'Arrange regular check-ins with your performance partner(s).',
      label: 'Regular Check-ins',
      desc: 'Have regular conversations with your performance partner(s) to discuss highlights, progress and blockers about your growth and performance.',
    },
    performanceAssessment: {
      title: 'Your assessment is completed.',
      label: 'Performance Assessment',
      desc: 'At the end of the cycle, the performance partner(s) will assess your performance based on previous conversations with you and define a performance indicator.',
    },
  },
  feedback: {
    tabText: {
      request: 'Request Feedback',
      give: 'Give Feedback',
    },
    requestTab: {
      sendRequestButton: 'Send Feedback Request',
      sendRequestToClientsButton: 'Add Client Feedback',
      fromColleagues: 'From Your Colleagues',
      list: {
        name: 'Name',
        role: 'Role',
        requestDate: 'Request Date',
        dueDate: 'Due Date',
        status: 'Status',
        actions: 'Actions',
        edit: 'Edit',
        cancel: 'Cancel',
        delete: 'Delete',
      },
      emptyReview: {
        content: "You don't have a performance cycle open at the moment.",
        tip: 'Please go to "My cycles" page and open a performance cycle first.',
      },
      emptyFeedback: {
        content: 'Start collecting performance related feedback for your current cycle now!',
        project: 'Current Project: ',
        duration: 'Cycle Duration: ',
        colleagues: 'From Your Colleagues',
        clients: 'From Clients',
        tip: 'All performance feedback collected will be accessed by your performance partner(s) of your current cycle.',
      },
      pendingOrCancelledFeedback: {
        content: 'Submitted performance feedback will appear here.',
        tip: "You haven't received any feedback yet.",
        project: 'View Feedback Requests',
      },
      collectMoreFeedback: {
        hint: ' Collect more feedback',
        additionalText: 'via Feedback management page',
      },
      emptyPastReview: {
        content: 'Performance feedback collected for past cycles will appear here.',
      },
      pastReview: {
        reviewDuration: 'Cycle Duration',
        project: 'Project',
        reviewer: 'Performance Partner',
        year: 'Year:',
      },
      noFeedbackForPastReview: {
        content: 'No feedback collected for this cycle.',
      },
      feedbackListForPastReview: {
        name: 'Name',
        role: 'Role',
        requestDate: 'Request Date',
        dueDate: 'Due Date',
        status: 'Status',
        viewLessRequests: 'View Less Requests',
        viewAllRequests: 'View All Requests',
      },
    },
    feedbackRequestModal: {
      title: 'Send Performance Feedback Request',
      editTitle: 'Edit Feedback Request',
      cancelText: 'Cancel',
      okText: 'Send',
      editText: 'Save',
      nameLabel: 'Who would you like to ask for feedback?',
      nameNotFound: 'name or ID not found',
      placeholder: 'Enter a name or employee ID',
      datePlaceHolder: 'Enter to select a date',
      dateLabel: 'When do you want to stop collecting feedback?',
      contextLabel:
        'Share the context about this performance cycle and indicate what feedback you would like to gather:',
      contextPlaceholder:
        'eg. Please provide feedback on how I performed in this project in the past 3 months. ',
      successMessage: 'The feedback is requested successfully!',
      editMessage: 'The feedback is edited successfully!',
      requiredNameMessage: '* Please input name.',
      requiredDateMessage: '* Please input date.',
      project: 'Feedback for Project:',
      duration: 'Duration:',
    },
    feedbackRequestToClientModal: {
      enterClientName: `Enter Provider's name`,
      performanceFeedbackCollectionFromClientPlaceholder: `Once you've talked  with the client and received any feedback about your work, write here a brief summary of this conversation.`,
      title: 'Add performance feedback received offline',
      editTitle: 'Edit performance feedback received offline',
      cancelText: 'Cancel',
      okText: 'Add feedback to your cycle',
      editOkText: 'Save',
      nameLabel: 'Please fill in the feedback provider:',
      contextLabel: 'Please share the feedback collected here:',
      successMessage: 'Client feedback is added successfully!',
      editSuccessMessage: 'Client feedback saved successfully!',
      requiredNameMessage: '* Please input name.',
      requiredFeedbackMessage: '* Please input feedback.', // ?
      project: 'Project: ',
      duration: '| Cycle duration: ',
      lastUpdatedAt: 'Last updated at ',
    },
    cancelRequest: {
      cancelText: 'Not yet',
      okText: 'Cancel request',
      content: 'Cancel request if you don’t need this feedback for your current cycle.',
      successMessage: 'The feedback is canceled successfully!',
    },
    deleteClientFeedback: {
      cancelText: 'Not yet',
      okText: 'Delete feedback',
      content: 'Delete this feedback if you don’t need it for your current cycle.',
      successMessage: 'The feedback is deleted successfully!',
    },
    giveTab: {
      emptyRequestText: 'Performance feedback requests from Twers will appear here.',
      pendingText: 'Pending requests',
      totalText: 'Total requests',
      showPendingAndSubmittedText: 'Only Show Pending and Submitted Requests',
      list: {
        name: 'Name',
        role: 'Role',
        requestDate: 'Request Date',
        dueDate: 'Due Date',
        status: 'Status',
      },
      cancelledMessage: 'The request is canceled.',
      notProvidedMessage: 'The request can not be provided as the cycle is done.',
    },
    submittedFeedbackDetailModalAsRequester: {
      title: 'Feedback from ',
      role: 'Role: ',
      projectName: 'Project: ',
      feedback: 'Feedback: ',
      lastUpdatedAt: 'Last updated at ',
    },
    submittedFeedbackDetailModalAsGiver: {
      title: requesterName => `Performance feedback for ${requesterName}`,
      currentProjectName: 'Project: ',
      reviewDuration: 'Cycle duration: ',
      role: 'Role: ',
      dueDate: 'Due date: ',
      context: 'Context: ',
      giverSelectedProjectName: 'Project where you have worked with this Twer: ',
      feedback: 'Feedback: ',
    },
    writeFeedbackModal: {
      title: 'Performance feedback for',
      editSubmittedTitle: 'Edit Feedback for',
      cancelText: 'Cancel',
      okText: 'Send',
      editSubmittedText: 'Save',
      draftText: 'Or, Save as Draft',
      projectLabel: 'Project: ',
      durationLabel: 'Cycle Duration: ',
      roleLabel: 'Role: ',
      dueDateLabel: 'Due Date: ',
      contextLabel: 'Context:',
      projectSelectLabel:
        'Select the project where you have worked with the Twer during this performance cycle:',
      contentLabel:
        'Please provide any relevant feedback about the Twer’s performance during this performance cycle:',
      selectPlaceholder: 'Select the project from the list',
      selectNotFoundContent: 'No project found',
      requiredContentMessage: '* Please provide inputs.',
      sendMessage: 'The feedback is sent successfully!',
      draftMessage: 'The feedback is saved as draft successfully!',
      dueIn: 'Due in ',
      overdue: 'Overdue ',
      days: ' days',
      day: ' day',
    },
  },
  confirmCancelModal: {
    cancelText: 'Cancel',
    okText: 'OK',
    confirmMessage: 'The content you edited will be lost.',
  },
  confirmUndoModal: {
    title: 'Are you sure you want to undo changes?',
    cancelText: 'Keep editing',
    okText: 'Undo Changes',
    confirmMessage:
      'You will lose all unsaved changes and revert the content to its initial version.',
  },
  confirmLeavePageModal: {
    leaveText: 'Leave',
    stayText: 'Stay',
    titleText: 'Are you sure?',
    contentText: 'The content you entered will be lost if you leave this page.',
  },
  ProductUpdateModal: {
    headerTitle: 'What’s new in Summit?',
    firstTitle:
      'We’ve updated our glossary! Your performance partners will be responsible for your performance cycle.',
    Reviewer: 'Reviewer',
    Contributor: 'Contributor',
    Reviewee: 'Reviewee',
    PerformancePartner: 'Performance partner',
    AdditionalPartner: 'Additional partner',
    TWer: 'Twer',
    Review: 'Review',
    SelfReview: 'Self-review',
    ReviewInput: 'Review Input',
    PerformanceCycle: 'Performance Cycle',
    SelfAssessment: 'Self Assessment',
    AssessmentInput: 'Assessment Input',
    rating: 'Rating',
    performanceIndicator: 'Performance Indicator',
    summaryOfWhy: 'Summary of why',
    assessmentSummary: 'Assessment Summary',
    secondTitleFirst: 'You can now select ',
    secondTitleAdditional: 'up to 2 ',
    additionalPartners: 'additional partners.',
    secondContent:
      'This is to support your experience of the performance process and help protect the privacy of your performance data.',
    watchDemo: 'Watch Demo',
    skipNow: 'Skip for now',
  },
  assignPeopleModal: {
    okText: 'Add',
    unitLabel: 'Unit',
    nameLabel: 'Name',
    unitPlaceholder: 'Select Unit',
    nameNotFound: 'name or ID not found',
    showPermissions: 'Show Permissions',
    hidePermissions: 'Hide Permissions',
  },

  assignPeopleModalToSpecifiedHierarchyModal: {
    errorMessageOfLeaderShipTeamEmpty: 'You need to assign people to Global Management Team first',
    errorMessageOfPeopleTeamEmpty: 'You need to assign people to Global People Team first',
    errorMessageOfPeopleAndLeadershipTeamEmpty:
      'You need to assign people to Global Management Team and Global People Team first',
    talentBusinessPartnerInfo: {
      modalTitle: 'Assign Talent Business Partner',
      hierarchyLabel: 'Global Function/Market/DEC/Service Line',
      hierarchyPlaceholder: 'Select a Global Function/Market/DEC/Service Line',
      nameLabel: 'Name',
      assignPeopleModalPlaceholder: `Enter the Talent Business Partner's name or ID`,
      successMessage: 'The Talent Business Partner(s) is/are added successfully!',
      errorRequireName: `Enter the Talent Business Partner's name`,
    },
    GFSLLeaderShipInfo: {
      modalTitle: 'Assign Leadership',
      hierarchyLabel: 'Global Function/Market/DEC/Service Line',
      hierarchyPlaceholder: 'Select a Global Function/Market/DEC/Service Line',
      nameLabel: 'Name',
      assignPeopleModalPlaceholder: 'Enter lead’s name or ID',
      successMessage:
        'The Global Function/Market/DEC/Service Line Leadership(s) is/are added successfully!',
      errorRequireName: 'Enter the Global Function/Market/DEC/Service Line Lead’s name',
    },
    ChiefExecutiveOfficerInfo: {
      modalTitle: 'Assign Chief Executive Officer',
      nameLabel: 'Name',
      assignPeopleModalPlaceholder: 'Enter Chief Executive Officer’s name or ID',
      assignPeopleModalTip:
        'Global Function/Market/DEC/Service Line can have only one Chief Executive Officer.',
      successMessage: 'The Chief Executive Officer is added successfully!',
      errorLimitAssignedNumberPeople:
        'There is already one people in the Chief Executive Officer, if you want to add more, please delete one first',
      errorRequireName: 'Enter Chief Executive Officer’s name',
    },
    ChiefTalentOfficerInfo: {
      modalTitle: 'Assign Global Head of People',
      nameLabel: 'Name',
      assignPeopleModalPlaceholder: 'Enter Global Head of People’s name or ID',
      assignPeopleModalTip: 'Operations can have only two Global Head of People.',
      successMessage: 'The Global Head of People is added successfully!',
      errorLimitAssignedNumberPeople:
        'There are already two people in the Global Head of People, if you want to add more, please delete one first',
      errorRequireName: 'Enter Global Head of People’s name',
    },
    GlobalTalentBusinessPartnerInfo: {
      modalTitle: 'Assign Global Talent Business Partner',
      assignPeopleModalPlaceholder: 'Enter Global Talent Business Partner’s name or ID',
      alreadyAssignedMessage:
        'There are already five people in the Global Talent Business Partner role, if you want to add more, please delete one first',
      successMessage: 'The Global Talent Business Partner is added successfully!',
      errorRequireName: 'Enter Global Talent Business Partner’s name',
      nameLabel: 'Name',
    },
  },

  roleInfoInLevelPanel: {
    talentBusinessPartnerInfo: {
      modalTitle: 'Assign Talent Business Partner',
      assignPeopleModalPlaceholder: 'Enter Talent Business Partner’s name or ID',
      successMessage: 'The Talent Business Partner is added successfully!',
      errorRequireName: 'Enter Talent Business Partner’s name',
      unitLabel: 'Unit',
      unitPlaceholder: 'Select Unit',
      nameLabel: 'Name',
    },
    muSlOpsLeadershipInfo: {
      modalTitle: 'Assign  Leadership',
      assignPeopleModalPlaceholder: 'Enter Lead’s name or ID',
      successMessage: 'The Market/DEC/Service Line Leadership is added successfully!',
      errorRequireName: 'Enter Lead’s name',
      unitLabel: 'Unit',
      unitPlaceholder: 'Select Unit',
      nameLabel: 'Name',
    },
    regionalTalentBusinessPartnerInfo: {
      modalTitle: 'Assign Regional Talent Business Partner',
      assignPeopleModalPlaceholder: 'Enter Regional Talent Business Partner’s name or ID',
      alreadyAssignedMessage:
        'There are already five people in the Regional Talent Business Partner role, if you want to add more, please delete one first',
      successMessage: 'The Regional Talent Business Partner is added successfully!',
      errorRequireName: 'Enter Regional Talent Business Partner’s name',
      nameLabel: 'Name',
    },
  },

  roleAccessAssignedByRegionalAdmin: {
    regionalTalentBusinessPartnerAccess: regionName => {
      return {
        title: 'Regional Talent Business Partner Permissions',
        permissionList: [
          {
            role: 'Regional Talent Business Partner',
            accessList: ['Can access cycle details', 'Can access cycle completion information'],
            scope: {
              user: 'All Twers',
              preposition: 'in',
              correspondingCategory: ` ${regionName} except the ${regionName} Leadership & People Team`,
            },
          },
        ],
      };
    },
    muSlOpsLeadershipAccess: {
      title: `Market/DEC/Service Line Leadership's Permissions`,
      permissionList: [
        {
          role: 'Market/DEC/Service Line Leadership',
          accessList: ['Can access cycle details', 'Can access cycle completion information'],
          scope: {
            user: 'All Twers',
            preposition: 'under corresponding',
            correspondingCategory: `Market/DEC/Service Line`,
          },
        },
      ],
    },
    talentBusinessPartnerAccess: {
      title: "Talent Business Partner's Permissions",
      permissionList: [
        {
          role: 'Talent Business Partner',
          accessList: [
            'Can access cycle details',
            'Can access cycle completion information',
            'Can create groups',
          ],
          scope: {
            user: 'All Twers',
            preposition: 'under corresponding',
            correspondingCategory: `Market/DEC/Service Line`,
          },
        },
      ],
    },
  },

  roleAccessAssignedByGlobalFunctionServiceLine: {
    talentBusinessPartnerAccess: {
      title: "Talent Business Partner's Permissions",
      permissionList: [
        {
          role: 'Talent Business Partner',
          accessList: [
            'Can access cycle details',
            'Can access cycle completion information',
            'Can create groups',
          ],
          scope: {
            user: 'All Groups',
            preposition: 'under corresponding',
            correspondingCategory: `Global Function/Market/DEC/Service Line`,
          },
        },
      ],
    },
    GFSLLeaderShipAccess: {
      title: "Global Function/Market/DEC/Service Line Leadership's Permissions",
      permissionList: [
        {
          role: 'Global Function/Market/DEC/Service Line Leadership',
          accessList: ['Can access cycle details', 'Can access cycle completion information'],
          scope: {
            user: 'All Groups',
            preposition: 'under corresponding',
            correspondingCategory: `Global Function/Market/DEC/Service Line`,
          },
        },
      ],
    },
    chiefExecutiveOfficerAccess: {
      title: 'Chief Executive Officer Permissions',
      permissionList: [
        {
          role: 'Chief Executive Officer',
          accessList: ['Can access cycle details', 'Can access cycle completion information'],
          scope: {
            user: 'All Twers',
            preposition: 'in',
            correspondingCategory: `Global Management Team`,
          },
        },
      ],
    },
    chiefTalentOfficerAccess: {
      title: "Global Head of People's Permissions",
      permissionList: [
        {
          role: 'Global Head of People',
          accessList: ['Can access cycle details', 'Can access cycle completion information'],
          scope: {
            user: 'All Twers',
            preposition: 'in',
            correspondingCategory: `Global Management Team`,
          },
        },
        {
          accessList: ['Can access cycle details', 'Can access cycle completion information'],
          scope: {
            user: 'All Twers',
            preposition: 'in',
            correspondingCategory: `Global People Team`,
          },
        },
      ],
    },
    globalTalentBusinessPartnerAccess: {
      title: 'Global Talent Business Partner Permissions',
      permissionList: [
        {
          role: 'Global Talent Business Partner',
          accessList: ['Can access cycle details', 'Can access cycle completion information'],
          scope: {
            user: 'All Twers except the Global Management Team',
          },
        },
      ],
    },
  },
  regionalLevelGroupAccess: {
    title: 'Regional Level Group Access',
    permissionList: [
      {
        role: 'Group Manager',
        accessList: [
          'Can access cycle details',
          'Can access cycle completion information',
          'Can manage group member list',
        ],
        scope: {
          user: 'All Twers',
          preposition: 'in',
          correspondingCategory: `this Group`,
        },
      },
    ],
  },
  levelPanel: {
    columns: {
      name: 'Name',
      unit: 'Unit',
      addedBy: 'Added by',
      addedAt: 'Added at',
      actions: 'Actions',
      functionServiceLine: 'Function/Service Line',
    },
    removePeople: {
      removeAction: 'Remove',
      cancelText: 'Hold on',
      okText: 'Yes, remove',
      successMessage: ' Removed successfully',
      confirmContent: roleName => {
        switch (roleName) {
          case 'Managing Director':
            return {
              contentTips: `After deleting this person, this person will no longer have the access to Twers' cycle in the corresponding group(s).`,
            };
          case 'Talent Business Partner':
          case 'Market/DEC/Service Line Leadership':
            return {
              contentTips: `After removing this person, this person will no longer have access to TWers' cycles and groups in this Market/DEC/Service Line.`,
            };
          case 'Regional Talent Business Partner':
            return {
              contentTips: `After deleting this person, this person will no longer have the access to Twers' cycle in the corresponding group(s) and people.`,
            };
          default:
            return {
              contentTips: '',
            };
        }
      },
    },
  },
  rolesPanelForGlobalFunctionSL: {
    columns: {
      name: 'Name',
      functionServiceLine: 'Unit',
      addedBy: 'Added by',
      addedAt: 'Added at',
      actions: 'Actions',
    },
    removePeople: {
      removeAction: 'Remove',
      cancelText: 'Hold on',
      okText: 'Yes, remove',
      successMessage: roleName => `The ${roleName} is removed successfully!`,
      confirmContent: roleName => {
        switch (roleName) {
          case 'Talent Business Partner':
          case 'Global Function/Market/DEC/Service Line Leadership':
            return {
              contentTips: `After removing this person, this person will no longer have access to TWers' cycles and groups in this Global Function/Market/DEC/Service Line.`,
            };
          case 'Global Head of People':
            return {
              contentTips: `After deleting this person, this person will no longer have the access to Twers' cycle in the corresponding group(s).`,
            };
          case 'Global Talent Business Partner':
            return {
              contentTips: `After deleting this person, this person will no longer have the access to Twers' cycle in the corresponding group(s) and people.`,
            };
          default:
            return {
              contentTips: '',
            };
        }
      },
    },
  },
  reviewCycle: {
    wellDone: 'Well done! Your performance cycle is now open.',
    whatsNext: 'What’s next?',
    whatsNextText1: 'Boost your expectations',
    whatsNextText2: 'self-reflecting on your own growth',
    whatsNextText3:
      'and think about which Competencies and Archetypes you would like to practise in this cycle. Add them to your expectations below.',
    goTo: 'Go to',
  },
};
