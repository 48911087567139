import cls from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { find, isEmpty } from 'lodash';
import React, { memo, useCallback, useContext, useMemo } from 'react';
import NoCheckInNotesIcon from '../../../assets/images/no-check-in-notes.svg';
import RecordIcon from '../../../assets/images/record.svg';
import CheckInNotesModal from './CheckInNotesModal';
import './index.less';
import ReviewCheckInNotesInfo from './info';
import {
  selectCheckInNotesModalVisibleInMyRevieweePage,
  selectCheckInNotesModalVisibleInMyReviewPage,
  selectCurrentCheckInNote,
  selectIsEditMode,
  setCheckInNotesModalVisibleInMyRevieweePage,
  setCheckInNotesModalVisibleInMyReviewPage,
  setCurrentCheckInNote,
  setIsEditMode,
} from '@@/redux/slice/checkInNotesSlice';
import { UserInfoContext } from '@@/context/userInfo.context';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { CYCLE_STATUS_ENUM } from '@@/_new_src_/constants/myCycles';

const ReviewCheckInNotes = props => {
  const { className, operation, isCurrentReviewDetail, onCheckInNotesModalSuccess, ...restProps } =
    props;
  const dispatch = useDispatch();

  const {
    currentCycleDetail: { checkInNotes, status, id: cycleId },
  } = useSelector(cycleDetailByVersion);

  const { userId } = useContext(UserInfoContext);
  const {
    reviewees: { checkInNotes: locale },
  } = useSelector(selectLocaleResource);
  const checkInNotesModalVisibleInMyReviewPage = useSelector(
    selectCheckInNotesModalVisibleInMyReviewPage,
  );
  const checkInNotesModalVisibleInMyRevieweePage = useSelector(
    selectCheckInNotesModalVisibleInMyRevieweePage,
  );
  const isEditMode = useSelector(selectIsEditMode);
  const currentNoteInfo = useSelector(selectCurrentCheckInNote);
  const hasNotes = !isEmpty(checkInNotes);
  const readonly = [
    CYCLE_STATUS_ENUM.UNREAD,
    CYCLE_STATUS_ENUM.DONE,
    CYCLE_STATUS_ENUM.CANCELLED,
    CYCLE_STATUS_ENUM.CLOSED,
  ].includes(status);
  const isShowRecordTips =
    operation.notesRecordTips && [CYCLE_STATUS_ENUM.OPEN, CYCLE_STATUS_ENUM.DRAFT].includes(status);
  const checkInNotesModalVisible = useMemo(() => {
    return isCurrentReviewDetail
      ? checkInNotesModalVisibleInMyReviewPage
      : checkInNotesModalVisibleInMyRevieweePage;
  }, [
    checkInNotesModalVisibleInMyReviewPage,
    checkInNotesModalVisibleInMyRevieweePage,
    isCurrentReviewDetail,
  ]);

  const setCurrentNoteInfo = useCallback(
    state => {
      dispatch(setCurrentCheckInNote(state));
    },
    [dispatch],
  );

  const setCheckInNotesModalVisible = useCallback(
    state => {
      if (isCurrentReviewDetail) {
        dispatch(setCheckInNotesModalVisibleInMyReviewPage(state));
      } else {
        dispatch(setCheckInNotesModalVisibleInMyRevieweePage(state));
      }
    },
    [dispatch, isCurrentReviewDetail],
  );

  const setIsCheckInNotesModalEditMode = useCallback(
    state => {
      dispatch(setIsEditMode(state));
    },
    [dispatch],
  );

  const handleCheckInNotesModalSuccess = useCallback(() => {
    setCheckInNotesModalVisible(false);
    setIsCheckInNotesModalEditMode(false);
    setCurrentNoteInfo(null);
    onCheckInNotesModalSuccess();
  }, [
    onCheckInNotesModalSuccess,
    setCheckInNotesModalVisible,
    setCurrentNoteInfo,
    setIsCheckInNotesModalEditMode,
  ]);

  const handleCheckInNotesModalCancel = useCallback(() => {
    setCheckInNotesModalVisible(false);
    setIsCheckInNotesModalEditMode(false);
    setCurrentNoteInfo(null);
  }, [setCheckInNotesModalVisible, setCurrentNoteInfo, setIsCheckInNotesModalEditMode]);

  const handleCheckInNotesEdit = useCallback(
    noteId => {
      const noteInfo = find(checkInNotes, item => item.id === noteId);
      setCurrentNoteInfo(noteInfo);
      setCheckInNotesModalVisible(true);
      setIsCheckInNotesModalEditMode(true);
    },
    [checkInNotes, setCheckInNotesModalVisible, setCurrentNoteInfo, setIsCheckInNotesModalEditMode],
  );

  return (
    <div className={cls('review-check-in-notes', className)} {...restProps}>
      <span className="review-check-in-notes-title">
        {locale.title} {hasNotes && `(${checkInNotes.length})`}
      </span>
      {operation.addNotesButton && !readonly && (
        <button
          type="button"
          className="review-check-in-notes-add-notes-button"
          onClick={() => {
            setCheckInNotesModalVisible(true);
          }}
        >
          <i className="ri-add-circle-line add-icon" />
          <span>{locale.add}</span>
        </button>
      )}
      {hasNotes ? (
        checkInNotes.map((noteInfo, i) => (
          <ReviewCheckInNotesInfo
            key={noteInfo.id}
            locale={locale}
            data={noteInfo}
            defaultExpand={i === 0}
            readonly={readonly}
            operation={operation}
            onCheckInNotesEdit={handleCheckInNotesEdit}
          />
        ))
      ) : (
        <div className="review-check-in-notes-empty">
          <div>
            <img
              src={isShowRecordTips ? RecordIcon : NoCheckInNotesIcon}
              className="review-check-in-notes-empty-icon"
              alt=""
            />
            <p className="review-check-in-notes-empty-content">
              {isShowRecordTips ? locale.empty.record : locale.empty.default}
            </p>
          </div>
        </div>
      )}
      <CheckInNotesModal
        cycleId={cycleId}
        currentNoteInfo={currentNoteInfo}
        visible={userId && checkInNotesModalVisible}
        onCancel={handleCheckInNotesModalCancel}
        onSuccess={handleCheckInNotesModalSuccess}
        isCurrentReviewDetail={isCurrentReviewDetail}
        isEditMode={isEditMode}
      />
    </div>
  );
};

ReviewCheckInNotes.defaultProps = {
  operation: {
    addNotesButton: true,
    editNotesButton: true,
    notesRecordTips: true,
  },
};

export default memo(ReviewCheckInNotes);
