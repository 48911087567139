import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'ahooks';
import { Collapse } from 'antd';
import { DownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import PeopleIcon from '@@/assets/images/icon_leadship_team.svg';
import { PanelType } from '@@/_new_src_/constants/adminEnum';
import { UseRequestResponse } from '@@/types/admin';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import useUserRoleInfo from '@@/hooks/useUserRoleInfo';
import { deleteGroupMember, getGroupDetail } from '@@/_new_src_/api/admin';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import GroupsMemberList, { RefType } from '../GroupsMemberList';
import './index.less';

interface ManageGlobalFunctionSLGroupsTabProps {
  activeTabKey: string;
}

const ManageGlobalFunctionSLGroupsTab = (props: ManageGlobalFunctionSLGroupsTabProps) => {
  const { Panel } = Collapse;
  const { activeTabKey } = props;

  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const { groupIdsForGlobalFunctionAdmin } = useUserRoleInfo();
  const { globalManagementTeamId, globalPeopleTeamId } = groupIdsForGlobalFunctionAdmin as {
    globalManagementTeamId: number;
    globalPeopleTeamId: number;
  };

  const localeResource = useSelector(selectLocaleResource);
  const {
    admin: {
      manageGroups: {
        correspondingLeadersOfEGLT,
        correspondingLeadersOfGlobalPeopleTeam,
        extendedGlobalLeadershipTeam,
        globalPeopleTeam,
      },
    },
  } = localeResource as {
    admin: {
      manageGroups: {
        correspondingLeadersOfEGLT: string;
        correspondingLeadersOfGlobalPeopleTeam: string;
        extendedGlobalLeadershipTeam: string;
        globalPeopleTeam: string;
      };
    };
  };

  useEffect(() => {
    setActiveKey([extendedGlobalLeadershipTeam, globalPeopleTeam]);
    setTimeout(() => setActiveKey([]));
  }, [extendedGlobalLeadershipTeam, globalPeopleTeam]);

  const {
    loading: getOnePagePeopleFromExtendedGlobalLeadershipLoading,
    data: extendedGlobalLeadershipListData,
    run: runGetExtendedGlobalLeadershipList,
  } = useRequest(
    (columnsNameAndOrder, pageNumberAndSize) =>
      getGroupDetail(columnsNameAndOrder, pageNumberAndSize, globalManagementTeamId),
    {
      manual: true,
      formatResult: (res: UseRequestResponse) => res.data,
    },
  );

  const {
    loading: getOnePagePeopleFromGlobalPeopleTeamLoading,
    data: globalPeopleTeamListData,
    run: runGetGlobalPeopleTeamList,
  } = useRequest(
    (columnsNameAndOrder, pageNumberAndSize) =>
      getGroupDetail(columnsNameAndOrder, pageNumberAndSize, globalPeopleTeamId),
    {
      manual: true,
      formatResult: (res: UseRequestResponse) => res.data,
    },
  );

  const renderPanelHeader = (name: string, count: number) => {
    const isActive = activeKey?.includes(name);
    return (
      <>
        <img className="user-filled-icon" src={PeopleIcon} alt="" />
        <span className="header-text">{`${name} (${count})`}</span>
        <DownOutlined className="down-icon" rotate={isActive ? 180 : 0} />
      </>
    );
  };

  const onCollapseChange = (currentActiveKey: string | string[]) => {
    setActiveKey(currentActiveKey);
  };

  const addEGLTModalRef: React.RefObject<RefType> = React.createRef();
  const addPeopleTeamModalRef: React.RefObject<RefType> = React.createRef();

  function renderEGLTPanelExtra() {
    return (
      <div
        className="panel-button"
        onClick={e => {
          e.stopPropagation();
          addEGLTModalRef.current?.open();
        }}
      >
        <PlusCircleOutlined className="icon plus-icon" />
        <span>Add People</span>
      </div>
    );
  }

  function renderPeopleTeamPanelExtra() {
    return (
      <div
        className="panel-button"
        onClick={e => {
          e.stopPropagation();
          addPeopleTeamModalRef.current?.open();
        }}
      >
        <PlusCircleOutlined className="icon plus-icon" />
        <span>Add People</span>
      </div>
    );
  }

  return (
    <LoadingWrapper
      className="modal-loading"
      loading={
        getOnePagePeopleFromExtendedGlobalLeadershipLoading ||
        getOnePagePeopleFromGlobalPeopleTeamLoading
      }
    >
      <Collapse className="custom-collapse" ghost activeKey={activeKey} onChange={onCollapseChange}>
        <Panel
          className="custom-panel"
          header={renderPanelHeader(
            extendedGlobalLeadershipTeam,
            extendedGlobalLeadershipListData?.groupMemberInfo.total || 0,
          )}
          key={extendedGlobalLeadershipTeam}
          extra={renderEGLTPanelExtra()}
          showArrow={false}
        >
          <GroupsMemberList
            activeTabKey={activeTabKey}
            loading={getOnePagePeopleFromExtendedGlobalLeadershipLoading}
            listData={extendedGlobalLeadershipListData}
            getTeamMemberList={runGetExtendedGlobalLeadershipList}
            onAddEGLTPeopleTeamRef={addEGLTModalRef}
            selectedGroupId={globalManagementTeamId}
            deleteTeamMemberApi={deleteGroupMember}
            isEGLT
            correspondingRoles={correspondingLeadersOfEGLT}
            selectedGroupName={PanelType.GLOBAL_MANAGEMENT_TEAM}
          />
        </Panel>
        <Panel
          className="custom-panel"
          header={renderPanelHeader(
            globalPeopleTeam,
            globalPeopleTeamListData?.groupMemberInfo.total || 0,
          )}
          key={globalPeopleTeam}
          extra={renderPeopleTeamPanelExtra()}
          showArrow={false}
        >
          <GroupsMemberList
            activeTabKey={activeTabKey}
            loading={getOnePagePeopleFromGlobalPeopleTeamLoading}
            listData={globalPeopleTeamListData}
            getTeamMemberList={runGetGlobalPeopleTeamList}
            selectedGroupId={globalPeopleTeamId}
            onAddEGLTPeopleTeamRef={addPeopleTeamModalRef}
            deleteTeamMemberApi={deleteGroupMember}
            isEGLT={false}
            correspondingRoles={correspondingLeadersOfGlobalPeopleTeam}
            selectedGroupName={PanelType.GLOBAL_PEOPLE_TEAM}
          />
        </Panel>
      </Collapse>
    </LoadingWrapper>
  );
};

export default ManageGlobalFunctionSLGroupsTab;
