import InformationIcon from '@@/assets/images/information.svg';
import HideViewIcon from '@@/assets/images/not-view.svg';
import ViewIcon from '@@/assets/images/view.svg';
import { UserInfoContext } from '@@/context/userInfo.context';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  selectShowTalentInfo,
  setIsEditRatingSummary,
  setShowTalentInfo,
} from '@@/redux/slice/revieweeSlice';
import cls from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TALENT_INFORMATION_KEY } from '../../constant/reviewee';
import EditCircleButton from '../EditCircleButton';
import FieldBlockInfo from '../FieldBlockInfo';
import PerfTextButton from '../PerfTextButton';
import './preview.less';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { isEmptyOrNil } from '@@/_new_src_/utils';
import commonLocale from '@@/_new_src_/local/common/en_US';
import { Button } from 'antd';
import nonEngagedCyclesLocale from '@@/_new_src_/local/nonEngagedCycles/en_US';
import { useLocation } from 'react-router-dom';
import { setIsEditByTbp } from '@@/_new_src_/store/nonEngagedCyclesSlice';
import RequestToEditTag from '@@/_new_src_/features/RequestToEditTag';
import { dashboardCycleDetail } from '@@/redux/slice/dashboardSlice';
import { CURRENT_PAGE } from '@@/_new_src_/constants/pagePath';

const prefix = 'rating-summary-preview';
const RatingSummaryPreview = props => {
  const {
    locale,
    onEdit,
    isExpand,
    showEditIcon,
    isShowRequestEditButton,
    isShowDoneReviewEditButton,
    onOpenRequestEditModal,
  } = props;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isNonEngagedCyclesPage } = CURRENT_PAGE(pathname);

  const {
    currentCycleDetail: {
      status = '',
      assessment = {},
      isTwer,
      canRequestToEdit,
      canEdit,
      isDraftCycle,
      isUnreadCycle,
      assessmentNonEngaged,
    } = {},
  } = useSelector(cycleDetailByVersion);
  const {
    tag: { assessmentNonEngagedTag },
  } = commonLocale;
  const {
    nonEngagedCycles: { unmarkByTbp },
  } = nonEngagedCyclesLocale;

  const showTalentInfo = useSelector(selectShowTalentInfo);
  const { userId } = useContext(UserInfoContext);

  const [showHideTalentButton, setShowHideTalentButton] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const {
    reviewList: { showTalentTagsButton },
    reviewees: {
      ratingSummaryEditor: { talentInformation },
    },
  } = useSelector(selectLocaleResource) || {};

  const {
    data: { canBeUnmarked },
  } = useSelector(dashboardCycleDetail);

  const onClickHideButton = () => {
    dispatch(setShowTalentInfo(false));
    setShowHideTalentButton(false);
  };

  const handleClickUnmark = () => {
    setIsVisible(false);
    dispatch(setIsEditByTbp(true));
    dispatch(setIsEditRatingSummary(true));
  };

  useEffect(() => {
    return onClickHideButton;
  }, []);

  const onClickShowButton = () => {
    dispatch(setShowTalentInfo(true));
    setShowHideTalentButton(true);
  };

  const talentInfo = () => {
    if (
      isEmptyOrNil(assessment?.additionalTalentInformation) ||
      assessment?.additionalTalentInformation.includes(TALENT_INFORMATION_KEY.NO_TALENT_INFORMATION)
    ) {
      return (
        <span className="no-talent">
          <img src={InformationIcon} className="icon" alt="" />
          {talentInformation.noTalentCheckbox}
        </span>
      );
    }
    return (
      <>
        {assessment?.additionalTalentInformation.includes(
          TALENT_INFORMATION_KEY.HIGH_POTENTIAL,
        ) && <div className="talent-item">{talentInformation.hpCheckbox}</div>}
        {assessment?.additionalTalentInformation.includes(
          TALENT_INFORMATION_KEY.POTENTIAL_FOR_GRADE_CHANGE,
        ) && <div className="talent-item">{talentInformation.gcCheckbox}</div>}
      </>
    );
  };

  const buttonDisplayByUserId = () => {
    return (
      <>
        {!isTwer && !showTalentInfo && (
          <PerfTextButton
            className="talent-button show-button"
            color="blue"
            onClick={onClickShowButton}
            icon={<img src={ViewIcon} className="view-icon" alt="" />}
          >
            {showTalentTagsButton.showTalentTags}
          </PerfTextButton>
        )}
        {showHideTalentButton && (
          <PerfTextButton
            className="talent-button"
            color="blue"
            onClick={onClickHideButton}
            icon={<img src={HideViewIcon} className="hide-view-icon" alt="" />}
            onMouseEnter={() => setShowHideTalentButton(true)}
            onMouseLeave={() => setShowHideTalentButton(false)}
          >
            {showTalentTagsButton.hideTalentTags}
          </PerfTextButton>
        )}

        {!isTwer && showTalentInfo && (
          <div
            className={cls('talent-container', {
              shadow: showHideTalentButton,
            })}
            onMouseEnter={() => setShowHideTalentButton(true)}
            onMouseLeave={() => setShowHideTalentButton(false)}
          >
            {talentInfo()}
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={cls(prefix, `${prefix}-${status.toLowerCase()}`, {
        [`${prefix}-expand`]: isExpand,
      })}
    >
      {(isDraftCycle && showEditIcon) || isShowDoneReviewEditButton ? (
        <div className={`${prefix}-icon`} onClick={onEdit}>
          <EditCircleButton />
        </div>
      ) : (
        isShowRequestEditButton && (
          <RequestToEditTag
            isDoneCycle={true}
            canRequestToEdit={canRequestToEdit}
            canEdit={canEdit}
            onClick={onOpenRequestEditModal}
          />
        )
      )}
      <div className={'tag-and-operation-block'}>
        <div>
          {assessmentNonEngaged && (
            <div className={'assessment-non-engagement-tag'}>{assessmentNonEngagedTag}</div>
          )}
          <FieldBlockInfo
            className="summary-info"
            label={locale.rating}
            content={assessment?.rating}
          />
        </div>
        {isNonEngagedCyclesPage && isVisible && assessmentNonEngaged && canBeUnmarked && (
          <Button className={'unmark-assessment-non-engaged-button'} onClick={handleClickUnmark}>
            <i className="ri-edit-fill" />
            <div className={'text'}>{unmarkByTbp}</div>
          </Button>
        )}
      </div>
      {isExpand && (
        <div className="summary-info summary-container">
          <FieldBlockInfo
            isExpand={isExpand}
            label={locale.summary}
            content={assessment?.summary}
            collapsible={isUnreadCycle}
          />
          {userId && buttonDisplayByUserId()}
        </div>
      )}
    </div>
  );
};

RatingSummaryPreview.defaultProps = {
  locale: {},
  showEditIcon: true,
};
export default RatingSummaryPreview;
