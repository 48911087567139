import { IV2Project } from '@@/_new_src_/types/common';
import { IProject } from '@@/_new_src_/store/commonSlice/interface';
import {
  ASSESSMENT_NON_ENGAGED_DEADLINE,
  OVERDUE_DEADLINE,
  REQUEST_TO_EDIT_DEADLINE,
} from '@@/_new_src_/constants/common';
import { isNil } from 'lodash';
import { getThDay } from '@@/_new_src_/utils';
import { FEEDBACK_STATUS } from '@@/_new_src_/constants/feedback';

export const formatV2Project = (project: IProject) => {
  const { accountId = '', assignmentId = '', projectName = '' } = project;
  return {
    account: accountId,
    assignmentId: assignmentId,
    name: projectName,
  };
};

export const formatV2ProjectListData = (projectList: IProject[]) => {
  return projectList.map(project => formatV2Project(project));
};

export const getV1ProjectParams = (project: IV2Project) => {
  const { account = '', assignmentId = '', name = '' } = project;
  return {
    projectAccount: account,
    projectId: assignmentId,
    projectName: name,
  };
};

export const getRequestToEditTips = (
  assessmentDate: string | null,
  p1: string,
  p2: string,
  p3?: string,
) => {
  if (isNil(assessmentDate)) return '';

  const month = new Date(assessmentDate).getMonth();
  const firstHalfYear = month < 8 && month > 1;
  const deadline = firstHalfYear
    ? REQUEST_TO_EDIT_DEADLINE.julyDeadline
    : REQUEST_TO_EDIT_DEADLINE.januaryDeadline;

  let overdueText = p1 + deadline + p2;

  if (p3) {
    overdueText += deadline + p3;
  }

  return overdueText;
};

export const getAssessmentNonEngagedTips = (p1: string, p2?: string) => {
  const month = new Date().getMonth() + 1;
  let assessmentNonEngagedText = p1;

  if (month < 6) {
    assessmentNonEngagedText += ASSESSMENT_NON_ENGAGED_DEADLINE.januaryDeadline;
  } else {
    assessmentNonEngagedText += ASSESSMENT_NON_ENGAGED_DEADLINE.julyDeadline;
  }
  assessmentNonEngagedText += p2;
  return assessmentNonEngagedText;
};

export const getExpectationNonEngagedTips = (
  expectationNonEngagedAt: string,
  p1: string,
  p2?: string,
) => {
  let expectationNonEngagedText = p1;

  const date = new Date(expectationNonEngagedAt);
  const day = getThDay(date.getDate());
  const month = date.toLocaleDateString('en-US', { month: 'long' });

  expectationNonEngagedText += `${month} ${day}`;
  expectationNonEngagedText += p2;

  return expectationNonEngagedText;
};

export const getOverdueTips = (p1: string, p2?: string) => {
  const month = new Date().getMonth() + 1;
  let overdueText = p1;

  if (month < 6) {
    overdueText += OVERDUE_DEADLINE.januaryDeadline;
  } else {
    overdueText += OVERDUE_DEADLINE.julyDeadline;
  }
  overdueText += p2;
  return overdueText;
};

export const getFeedbackStatus = (status: string) => {
  const { PENDING, DRAFTED, SUBMITTED, NOT_PROVIDED, CANCELLED } = FEEDBACK_STATUS;
  return {
    isDraftedFeedback: status === DRAFTED,
    isSubmittedFeedback: status === SUBMITTED,
    isPendingFeedback: status === PENDING,
    isCancelledFeedback: status === CANCELLED,
    isNotProvidedFeedback: status === NOT_PROVIDED,
  };
};
