import AutoComplete from '@@/common/component/AutoComplete';
import DatePicker from '@@/common/component/DatePicker';
import { EXPECTATION_MAX_LENGTH, LIMIT_PARTNER_NUM } from '@@/common/constant/review';
import { UserInfoContext } from '@@/context/userInfo.context';
import { SearchedPeopleInfo } from '@@/features/performance/v2/admin/common/AddOrEditModal/type';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { getUserList } from '@@/_new_src_/api/user';
import ProjectSelect from '@@/_new_src_/features/ProjectSelect';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import FormOperationBtn from '@@/_new_src_/features/FormOperationBtn';
import useLocalStorageCycle from '@@/_new_src_/hooks/useLocalStorageCycle';
import {
  confirmModalType,
  projectList,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import {
  attachment,
  cycleInformation,
  isUpdateInformationSuccess,
  selfAssessmentAttachment,
  setAttachment,
  setCycleDisplayPart,
  setEditDoneCycleChanged,
  setIsUpdateInformationSuccess,
} from '@@/_new_src_/store/myCyclesSlice';
import {
  postAddInformationAsync,
  putUpdateInformationAsync,
} from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import {
  ICycleFormFieldsValue,
  IInformationFormDefault,
  IMyCyclesLocale,
  ISelectorValue,
} from '@@/_new_src_/types/myCycles';
import { Form, Select } from 'antd';
import cls from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { get, isEmpty, isEqual, isNil, omit } from 'lodash';
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  CYCLE_DISPLAY_PART,
  EXPECTATION_DESCRIPTION_MAX_LENGTH,
  FORM_HANDLE_TYPE,
  FORM_LABEL,
  HELPER_TEXT_OPTIONS,
} from '@@/_new_src_/constants/myCycles';
import {
  ICreateInformationProps,
  IPersonInfo,
  IUpdateInformationProps,
} from '@@/_new_src_/store/myCyclesSlice/interface';
import UploadAttachment from '@@/_new_src_/features/uploadAttachment';
import HelperButton from '../../../../components/HelperButton';
import HelperModal from '../HelperModal';
import './index.less';
import { setCurrentCycleDetail } from '@@/redux/slice/cycleDetailByVersionSlice';
import {
  getCycleStatus,
  getRequestToEditStatus,
  goToCycleDetailPage,
} from '@@/_new_src_/utils/feature/cycle';
import { isEmptyOrNil } from '@@/_new_src_/utils';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MY_CYCLE_LIST_API } from '@@/common/constant/matomo';
import {
  doneCycleSelfAssessmentContent,
  isSelfAssessmentChanged,
  sendRequestData,
  setIsSelfAssessmentChanged,
} from '@@/_new_src_/store/cycleDetailSlice';
import { AttachmentType } from '@@/_new_src_/constants/attachmentType';
import { clearCurrentReviewState } from '@@/redux/slice/reviewSlice';
import { IV2Project } from '@@/_new_src_/types/common';
import { getProjectListAsync } from '@@/_new_src_/store/commonSlice/asyncThunk';
import { useRouteMatch } from 'react-router-dom';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import {
  formatRangeDateForProbationCycle,
  formatRangeDateToSecond,
} from '@@/_new_src_/utils/common/date';
import { RuleObject } from 'antd/lib/form';
import { HOME_COUNTRY, PROBATION_STATUS } from '@@/_new_src_/constants/userEnum';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { RangePicker } = DatePicker;
dayjs.extend(utc);

const CycleInformationForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackEvent } = useMatomo();
  const [form] = Form.useForm();

  const {
    probationStatus,
    hireDate,
    passedProbationDate,
    homeCountry,
    userId: currentUserId,
    currentOpeningCycleId,
  } = useContext(UserInfoContext);

  const { loading: updateCycleInformationLoading, success: isUpdateCycleSuccess } = useSelector(
    isUpdateInformationSuccess,
  );

  const selfAssessmentChanged = useSelector(isSelfAssessmentChanged);
  const { list: curCycleProjectList } = useSelector(projectList);

  const [expectationDescriptionText, setExpectationDescriptionText] = useState('');
  const [currentHelpOptionKey, setCurrentHelpOptionKey] = useState<string>('noHelperDisplay');
  const [isNotValidFields, setIsNotValidFields] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const isReviewHistoryDetailPage = useRouteMatch(CYCLE_PAGE_LINK.CYCLE_HISTORY_DETAIL);

  const {
    myCycles: {
      cycleInformationForm: locale,
      formBtnText: operationBtnLocale,
      cycleModal: { cancelEdit: cancelEditLocale, doneCycleCancelEdit: doneCycleCancelEditLocale },
    },
  } = useSelector(selectLocaleResource) as IMyCyclesLocale;

  const currentModalType = useSelector(confirmModalType);

  const { filename, url: attachmentUrl, loading: uploadLoading } = useSelector(attachment);
  const currentAttachment = useSelector(attachment);

  const {
    performancePartnerState,
    setPerformancePartnerState,
    additionalPartnersState,
    setAdditionalPartnersState,
    projectState,
    setProjectState,
    durationState,
    setDurationState,
    contextAttachmentState,
    contextContentState,
    setContextContentState,
    removeLocalCycle,
  } = useLocalStorageCycle();

  const cycleInfo = useSelector(cycleInformation);
  const cycleId = get(cycleInfo, 'id') || NaN;
  const curCycleStatus = get(cycleInfo, 'status');
  const isProbation = get(cycleInfo, 'isProbation');
  const project = get(cycleInfo, 'project');
  const context = get(cycleInfo, 'context');
  const duration = get(cycleInfo, 'duration');
  const performancePartner = get(cycleInfo, 'performancePartner');
  const additionalPartners = get(cycleInfo, 'additionalPartners');
  const { status } = useSelector(sendRequestData);
  const { isApprovedRequestToEdit } = getRequestToEditStatus(status);
  const [isEdit, setIsEdit] = useState(false);
  const [expectationDescriptionHtml, setExpectationDescriptionHtml] = useState(context?.content);
  const [defaultFormInfo, setDefaultFormInfo] = useState<IInformationFormDefault | null>(null);
  const defaultPerformancePartner = get(defaultFormInfo, 'performancePartner');
  const defaultAdditionalPartners = get(defaultFormInfo, 'additionalPartners');
  const defaultProject = get(defaultFormInfo, 'project', null);
  const defaultDuration = get(defaultFormInfo, 'duration');
  const defaultContextContent = get(defaultFormInfo, 'contextContent');
  const defaultContextAttachment = get(defaultFormInfo, 'contextAttachment');
  const assessmentContent = useSelector(doneCycleSelfAssessmentContent);
  const doneCycleSelfAssessmentAttachment = useSelector(selfAssessmentAttachment);

  const isCHNUser = useMemo(() => {
    return homeCountry === HOME_COUNTRY.CHINA || homeCountry === HOME_COUNTRY.HK;
  }, [homeCountry]);
  const isCycleDurationReadOnly = useMemo(() => {
    if (isEdit && isCHNUser) {
      return isProbation;
    }

    return isProbation
      ? isCHNUser && isProbation
      : isCHNUser && probationStatus === PROBATION_STATUS.UNDER_PROBATION;
  }, [isCHNUser, probationStatus, isProbation]);

  const { isNewCycle, isUnreadCycle } = getCycleStatus(curCycleStatus);

  useEffect(() => {
    dispatch(getProjectListAsync());
  }, []);

  useEffect(() => {
    if (isApprovedRequestToEdit) {
      setIsEdit(!!cycleId);
    } else {
      setIsEdit(!!cycleId && !isUnreadCycle);
    }
  }, [cycleId, isApprovedRequestToEdit]);

  const curDurationTime = useMemo(() => {
    if (isCycleDurationReadOnly && !isEmptyOrNil(passedProbationDate) && !isEmptyOrNil(hireDate)) {
      return [hireDate, passedProbationDate];
    }

    if (isEdit && !isEmptyOrNil(duration)) {
      return [duration.startTime, duration.endTime];
    }

    if (!isEdit && !isEmptyOrNil(durationState)) {
      return durationState as string[];
    }

    return [];
  }, [isCycleDurationReadOnly, duration, isEdit, passedProbationDate, hireDate]);

  useEffect(() => {
    if (isEdit && cycleInfo) {
      !isApprovedRequestToEdit && removeLocalCycle();
      const contextContent = get(context, 'content') || '';
      const contextAttachment = omit(context, 'content') || null;
      setDefaultFormInfo({
        performancePartner,
        additionalPartners,
        project,
        duration: curDurationTime as string[],
        contextContent,
        contextAttachment,
      });
    } else {
      const isNotEditDefaultInfo = {
        performancePartner: performancePartnerState || null,
        additionalPartners: additionalPartnersState || [],
        project: projectState || null,
        duration: curDurationTime as string[],
        contextAttachment: contextAttachmentState || {
          attachmentName: '',
          attachmentStorageUrl: '',
        },
        contextContent: contextContentState || '',
      };
      setDefaultFormInfo(isNotEditDefaultInfo);
    }
  }, [isEdit]);

  const getFormAdditionalPartners = (data: Array<IPersonInfo>) => {
    if (data && data.length > 0) {
      return data.map(item => {
        return {
          value: item?.id,
          label: item?.name,
          key: item?.id,
        };
      });
    } else {
      return [];
    }
  };

  const getStorageAdditionalPartners = (data: Array<ISelectorValue>) => {
    if (data && data.length > 0) {
      return data.map(item => {
        return {
          id: item.value,
          name: item.label,
        };
      });
    } else {
      return [];
    }
  };

  const formatCurrentFormData = (type?: string) => {
    const {
      duration: fieldDuration = [],
      performancePartner: fieldPerformancePartner,
      additionalPartners: fieldAdditionalPartners = [],
      project: fieldProject,
    } = form.getFieldsValue() as ICycleFormFieldsValue;

    const [defaultStartTime, defaultEndTime] = formatRangeDateForProbationCycle(
      dayjs(defaultFormInfo?.duration?.[0]),
      dayjs(defaultFormInfo?.duration?.[1]),
    );

    const [startTime, endTime] = isCycleDurationReadOnly
      ? [defaultStartTime, defaultEndTime]
      : formatRangeDateToSecond(fieldDuration);

    if (type === FORM_HANDLE_TYPE.CANCEL) {
      const curPerformancePartner = fieldPerformancePartner && {
        id: fieldPerformancePartner.value,
        name: fieldPerformancePartner.label,
      };
      const curAdditionalPartners = getStorageAdditionalPartners(fieldAdditionalPartners);
      const curAttachment = {
        attachmentName: filename || '',
        attachmentStorageUrl: attachmentUrl || '',
      };
      return (
        (!isEmpty(fieldDuration) &&
          !isEqual(
            formatRangeDateToSecond(fieldDuration),
            formatRangeDateToSecond([
              dayjs.utc(defaultFormInfo?.duration?.[0]),
              dayjs.utc(defaultFormInfo?.duration?.[1]),
            ]),
          )) ||
        !isEqual(curPerformancePartner, defaultPerformancePartner) ||
        !isEqual(curAdditionalPartners, defaultAdditionalPartners) ||
        !isEqual(fieldProject, defaultProject) ||
        !isEqual(expectationDescriptionHtml, defaultContextContent) ||
        !isEqual(curAttachment, defaultContextAttachment)
      );
    } else {
      const currentAttachmentStorageName =
        (attachmentUrl && attachmentUrl.substring(attachmentUrl.lastIndexOf('/') + 1)) || '';

      return {
        performancePartnerId: fieldPerformancePartner?.value,
        additionalPartnerIds:
          fieldAdditionalPartners?.map((item: { label: string; value: number }) => item.value) ||
          [],
        startTime: startTime,
        endTime: endTime,
        assignmentId: fieldProject?.assignmentId || '',
        context: expectationDescriptionHtml || '',
        contextAttachmentName: filename,
        contextAttachmentStorageName: currentAttachmentStorageName,
      } as ICreateInformationProps;
    }
  };

  const onFormFieldsChange = () => {
    const requiredFieldKeys = isCycleDurationReadOnly
      ? [FORM_LABEL.PERFORMANCE_PARTNER, FORM_LABEL.PROJECT]
      : [FORM_LABEL.PERFORMANCE_PARTNER, FORM_LABEL.PROJECT, FORM_LABEL.CYCLE_DURATION];
    const requiredFieldValues = Object.values(form.getFieldsValue(requiredFieldKeys));
    const emptyFields = requiredFieldValues.filter(item => isEmpty(item) || !item);

    const fieldKeys = Object.keys(form.getFieldsValue());
    const isNotValidFormFields =
      !isEmpty(form.getFieldsError(fieldKeys).filter(({ errors }) => errors.length)) ||
      !!emptyFields.length;
    setIsNotValidFields(isNotValidFormFields);
  };

  useEffect(() => {
    if (!isEmptyOrNil(defaultFormInfo) && !isEmptyOrNil(curCycleProjectList)) {
      const initFieldsValue = {
        performancePartner: defaultPerformancePartner
          ? {
              value: defaultPerformancePartner?.id,
              label: defaultPerformancePartner?.name,
              key: defaultPerformancePartner?.id,
            }
          : null,
        additionalPartners: getFormAdditionalPartners(defaultAdditionalPartners || []),
        project: defaultProject,
        duration:
          isEmpty(defaultDuration) || isNil(defaultDuration)
            ? []
            : [dayjs.utc(defaultDuration[0]), dayjs.utc(defaultDuration[1])],
      };
      form.setFieldsValue(initFieldsValue);
      const contextAttachment = get(defaultFormInfo, 'contextAttachment');
      !isEmpty(contextAttachment) &&
        !isNil(contextAttachment) &&
        dispatch(
          setAttachment({
            type: AttachmentType.CYCLE_INFORMATION,
            data: {
              success: false,
              filename: get(contextAttachment, 'attachmentName'),
              url: get(contextAttachment, 'attachmentStorageUrl'),
            },
          }),
        );
      onFormFieldsChange();
    }
  }, [defaultFormInfo, curCycleProjectList]);

  useEffect(() => {
    dispatch(
      setEditDoneCycleChanged(
        formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL) || selfAssessmentChanged,
      ),
    );
    return () => {
      dispatch(setEditDoneCycleChanged(false));
    };
  }, [formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL), selfAssessmentChanged]);

  const onChangePerformancePartner = (value: ISelectorValue) => {
    const curPerformancePartner = {
      id: +value?.value,
      name: value?.label,
    };
    setPerformancePartnerState(curPerformancePartner);
  };
  const onChangeAdditionalPartners = (value: ISelectorValue[]) => {
    const curAdditionalPartners = getStorageAdditionalPartners(value || []);
    setAdditionalPartnersState(curAdditionalPartners);
  };
  const onChangeProject = (value: IV2Project | undefined) => {
    setProjectState(value);
  };

  const updateExpectationHtml = (html: string) => {
    setExpectationDescriptionHtml(html);
    !isEdit && setContextContentState(html);
  };

  useEffect(() => {
    setIsChanged(formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL) as boolean);
  }, [form.getFieldsValue()]);

  const handleConfirmCancelEditDoneCycle = () => {
    dispatch(setEditDoneCycleChanged(false));
    if (isReviewHistoryDetailPage) {
      window.location.reload();
      return;
    }
  };

  const handleConfirmCancel = () => {
    dispatch(setEditDoneCycleChanged(false));
    if (isEdit && isNewCycle) {
      dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY));
    } else if (isEdit && isUnreadCycle) {
      window.location.reload();
    } else {
      dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.DEFAULT_DISPLAY));
      dispatch(setCurrentCycleDetail({}));
      const goToCycleDetailPageLink = goToCycleDetailPage({
        currentOpeningCycleId,
        cycleId,
      });
      history.push(goToCycleDetailPageLink);
    }
    dispatch(setIsSelfAssessmentChanged(false));
    dispatch(clearCurrentReviewState());
    dispatch(setConfirmModalStatus(false));
    dispatch(setConfirmModalType(''));
    formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL) && removeLocalCycle();
  };

  const handleClickCancel = () => {
    if (formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL) || selfAssessmentChanged) {
      dispatch(setConfirmModalStatus(true));
      if (isReviewHistoryDetailPage) {
        dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.UNDO_CHANGE));
      } else {
        dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.CANCEL_EDIT_CYCLE));
      }
    } else {
      if (isReviewHistoryDetailPage) {
        handleConfirmCancelEditDoneCycle();
      } else {
        handleConfirmCancel();
      }
    }
  };

  const handleSubmitCycle = () => {
    const payloadData = formatCurrentFormData(FORM_HANDLE_TYPE.SUBMIT) as ICreateInformationProps;
    if (isEdit && cycleId && !isEmpty(payloadData)) {
      let editPayloadData = {
        ...payloadData,
        cycleId,
      } as IUpdateInformationProps;
      if (isApprovedRequestToEdit) {
        editPayloadData = {
          ...editPayloadData,
          selfAssessmentDTO: {
            content: assessmentContent,
            attachmentName: doneCycleSelfAssessmentAttachment.filename,
            attachmentStorageName: doneCycleSelfAssessmentAttachment.storageName,
          },
        };
      }
      !isApprovedRequestToEdit &&
        trackEvent({
          category: MY_CYCLE_LIST_API.category,
          action: MY_CYCLE_LIST_API.action.TWER_EDITS_V2_CYCLE_INFORMATION,
        });
      dispatch(putUpdateInformationAsync(editPayloadData));
    } else {
      trackEvent({
        category: MY_CYCLE_LIST_API.category,
        action: MY_CYCLE_LIST_API.action.TWER_CREATES_V2_CYCLE_WITH_NEW_STATUS,
      });
      dispatch(postAddInformationAsync(payloadData));
    }
  };
  useEffect(() => {
    if (isUpdateCycleSuccess) {
      removeLocalCycle();
      dispatch(
        setAttachment({ type: AttachmentType.CYCLE_INFORMATION, data: { isInitialValue: true } }),
      );
      dispatch(setIsUpdateInformationSuccess({}));
      if (isApprovedRequestToEdit) {
        window.location.reload();
      } else {
        const goToCycleDetailPageLink = goToCycleDetailPage({
          currentOpeningCycleId,
          cycleId,
        });
        history.push(goToCycleDetailPageLink);
      }
    }
  }, [cycleInfo, isUpdateCycleSuccess]);

  const durationValidator = useCallback(
    (_: RuleObject, duration: Dayjs[]) => {
      if (!duration) return Promise.resolve();
      const MONTH_OF_ONE_YEAR = 12;
      const [selectedStartMonth, selectedEndMonth] = duration;
      const isStartMonthExceedsCurrentMonth = selectedStartMonth
        .startOf('month')
        .isAfter(dayjs(), 'month');

      const isDurationMoreThanOneYear =
        selectedEndMonth.diff(selectedStartMonth, 'month') >= MONTH_OF_ONE_YEAR;

      if (isStartMonthExceedsCurrentMonth) {
        return Promise.reject(locale.duration.validateStartMonth);
      }
      if (isDurationMoreThanOneYear) {
        return Promise.reject(locale.duration.validateDuration);
      }
      return Promise.resolve();
    },
    [locale.duration.validateStartMonth, locale.duration.validateDuration],
  );

  const getDisabledDate = useCallback(
    (currentDate: string | number | Date | dayjs.Dayjs | null | undefined) => {
      const LAST_MONTH_OF_THE_YEAR = 11;
      const cycleCreatedTime =
        (isEdit && (get(cycleInfo, 'createdTime', '') as string)) || undefined;
      const currentUtcDate = dayjs.utc(currentDate);
      const availableStartUtcDate = dayjs
        .utc(cycleCreatedTime)
        .subtract(LAST_MONTH_OF_THE_YEAR, 'month');
      const availableEndUtcDate = dayjs('2023-12-31');
      const utcUserHireDate = dayjs.utc(hireDate);

      return (
        currentUtcDate < availableStartUtcDate ||
        currentUtcDate > availableEndUtcDate ||
        currentUtcDate < utcUserHireDate
      );
    },
    [hireDate, get(cycleInfo, 'createdTime'), isEdit],
  );

  const isNotValidExpectation =
    !!expectationDescriptionHtml &&
    expectationDescriptionText.trim().length > EXPECTATION_DESCRIPTION_MAX_LENGTH;

  const isDisableSave = useMemo((): boolean => {
    return (
      (isNotValidFields || isNotValidExpectation || uploadLoading || !isChanged) &&
      !selfAssessmentChanged
    );
  }, [isNotValidFields, isNotValidExpectation, uploadLoading, isChanged, selfAssessmentChanged]);

  const renderTwerOption = useCallback(
    (optionList: SearchedPeopleInfo[], Option: typeof Select.Option) => {
      const alreadySelectedPP = form.getFieldValue('performancePartner')?.value as number;
      const alreadySelectedAP = form
        .getFieldValue('additionalPartners')
        ?.map((item: { value: number }) => item.value) as number[];
      return optionList.map(({ userId = 0, name = '' }) => {
        const isSelf: boolean = currentUserId === userId;
        const isSelected = alreadySelectedAP?.includes(userId) || alreadySelectedPP === userId;
        return (
          <Option key={String(userId)} label={name} value={userId} disabled={isSelf || isSelected}>
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [form, currentUserId],
  );

  return (
    <>
      <div className="cycle-information-wrapper">
        <div className="form-title">
          <div
            className={cls('circle-icon', { 'edit-done-cycle-icon': isApprovedRequestToEdit })}
          />
          <span>{locale.formTitle}</span>
        </div>
        <Form
          className={cls('cycle-information-form', 'set-review', {
            'edit-done-cycle': isApprovedRequestToEdit,
          })}
          layout="vertical"
          form={form}
          onFinish={handleSubmitCycle}
          onFieldsChange={onFormFieldsChange}
        >
          <div className={`cycle-information-form-item`}>
            {currentHelpOptionKey === HELPER_TEXT_OPTIONS.PERFORMANCE_PARTNER_INTRO && (
              <HelperModal />
            )}
            <Form.Item
              name="performancePartner"
              className="performance-partner-wrapper"
              label={
                <>
                  <div>
                    <span>locale.performancePartner.label</span>
                    <span className="require-mark">&nbsp;*</span>
                  </div>
                  <HelperButton
                    showButton={!isApprovedRequestToEdit}
                    helpOptionKey={HELPER_TEXT_OPTIONS.PERFORMANCE_PARTNER_INTRO}
                    currentHelpOptionKey={currentHelpOptionKey}
                    setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                  />
                </>
              }
            >
              <AutoComplete
                placeholder={locale.performancePartner.placeholder}
                notFoundContent={locale.performancePartner.notFoundContent}
                showArrow={false}
                defaultActiveFirstOption={false}
                showSearch
                fetchApi={getUserList}
                onChange={onChangePerformancePartner}
                aria-label={'Performance partner input'}
                aria-expanded="false"
              >
                {(list: SearchedPeopleInfo[], Option: typeof Select.Option) =>
                  renderTwerOption(list, Option)
                }
              </AutoComplete>
            </Form.Item>

            {currentHelpOptionKey === HELPER_TEXT_OPTIONS.ADDITIONAL_PARTNER_INTRO && (
              <HelperModal />
            )}
            <Form.Item
              name="additionalPartners"
              className="additional-partners-wrapper"
              label={
                <>
                  <div id="additionalPartners-label">
                    <span>locale.additionalPartner.label</span>
                    <span className="label-optional">&nbsp;{locale.optional}</span>
                  </div>
                  <HelperButton
                    showButton={!isApprovedRequestToEdit}
                    helpOptionKey={HELPER_TEXT_OPTIONS.ADDITIONAL_PARTNER_INTRO}
                    currentHelpOptionKey={currentHelpOptionKey}
                    setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                  />
                </>
              }
            >
              <AutoComplete
                limitNum={LIMIT_PARTNER_NUM}
                mode="multiple"
                placeholder={locale.additionalPartner.placeholder}
                notFoundContent={locale.additionalPartner.notFoundContent}
                fetchApi={getUserList}
                onChange={onChangeAdditionalPartners}
                aria-label={'Additional partners'}
                aria-expanded="false"
              >
                {(list: SearchedPeopleInfo[], Option: typeof Select.Option) =>
                  renderTwerOption(list, Option)
                }
              </AutoComplete>
            </Form.Item>

            <Form.Item
              label={
                <div>
                  <span>locale.project.label</span>
                  <span className="require-mark">&nbsp;*</span>
                </div>
              }
              className="project-select"
              name="project"
            >
              <ProjectSelect
                bordered={true}
                optionList={curCycleProjectList}
                notFoundContent={locale.project.noProjectFound}
                onChange={onChangeProject}
                aria-label={'Project input'}
                aria-expanded="false"
                placeholder={locale.project.placeholder}
                showArrow={true}
              />
            </Form.Item>

            {isCycleDurationReadOnly && (
              <>
                {currentHelpOptionKey === HELPER_TEXT_OPTIONS.CYCLE_DURATION_INTRO && (
                  <HelperModal />
                )}
                <Form.Item
                  name="duration"
                  className={cls('cycle-duration', {
                    'probation-disabled-cycle': true,
                  })}
                  label={
                    <>
                      <div id="durationReadonly-label">
                        <span>locale.duration.label</span>
                        <span className="require-mark">&nbsp;*</span>
                      </div>
                      <HelperButton
                        showButton={!isApprovedRequestToEdit}
                        helpOptionKey={HELPER_TEXT_OPTIONS.CYCLE_DURATION_INTRO}
                        currentHelpOptionKey={currentHelpOptionKey}
                        setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                      />
                    </>
                  }
                >
                  <RangePicker
                    picker="date"
                    size="middle"
                    style={{ width: '100%' }}
                    disabled={isCycleDurationReadOnly}
                  />
                </Form.Item>
              </>
            )}

            {!isCycleDurationReadOnly && (
              <>
                {currentHelpOptionKey === HELPER_TEXT_OPTIONS.CYCLE_DURATION_INTRO && (
                  <HelperModal />
                )}
                <Form.Item
                  name="duration"
                  className="cycle-duration"
                  label={
                    <>
                      <div id="duration-label">
                        <span>locale.duration.label</span>
                        <span className="require-mark">&nbsp;*</span>
                      </div>
                      <HelperButton
                        showButton={!isApprovedRequestToEdit}
                        helpOptionKey={HELPER_TEXT_OPTIONS.CYCLE_DURATION_INTRO}
                        currentHelpOptionKey={currentHelpOptionKey}
                        setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                      />
                    </>
                  }
                  validateFirst
                  rules={[
                    {
                      validator: durationValidator,
                    },
                  ]}
                >
                  <RangePicker
                    picker="month"
                    size="middle"
                    style={{ width: '100%' }}
                    disabledDate={getDisabledDate}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
                    onChange={value => {
                      const newValue = value as Dayjs[];
                      setDurationState(formatRangeDateToSecond(newValue) as Array<never>);
                    }}
                  />
                </Form.Item>
              </>
            )}
          </div>
          {currentHelpOptionKey === HELPER_TEXT_OPTIONS.CYCLE_NOTES_OR_CONTEXT_INTRO && (
            <HelperModal />
          )}
          <Form.Item
            name="expectation"
            className="expectation-description"
            label={
              <>
                <div id="expectation-label">
                  <span className="label">locale.expectation.label</span>
                  <span className="label-optional">&nbsp;{locale.optional}</span>
                </div>
                <HelperButton
                  showButton={!isApprovedRequestToEdit}
                  helpOptionKey={HELPER_TEXT_OPTIONS.CYCLE_NOTES_OR_CONTEXT_INTRO}
                  currentHelpOptionKey={currentHelpOptionKey}
                  setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                />
              </>
            }
          >
            <TinyMceEditor
              initialValue={defaultContextContent || ''}
              updateHtmlInfo={updateExpectationHtml}
              updateTextInfo={setExpectationDescriptionText}
              maxLength={EXPECTATION_MAX_LENGTH}
              placeholder={locale.expectation.placeholder}
              customPlaceholderStyle={`color: #484848;
            font-weight: 349;
            font-style: italic;
            font-size: 16px;`}
              height={400}
            />
          </Form.Item>
          <UploadAttachment
            attachmentType={AttachmentType.CYCLE_INFORMATION}
            defaultInfo={defaultContextAttachment}
            attachmentClassName={'information-attachment-tooltip'}
            attachmentInfo={currentAttachment}
          />
          <Form.Item>
            <FormOperationBtn
              confirmBtnText={
                isEdit
                  ? operationBtnLocale.editExpectationOrInformation
                  : operationBtnLocale.submitInformation
              }
              confirmBtnDisabled={isDisableSave}
              cancelBtnDisabled={uploadLoading}
              handleClickCancel={handleClickCancel}
              cancelBtnText={
                isReviewHistoryDetailPage
                  ? operationBtnLocale.undoModifyDoneCycle
                  : operationBtnLocale.cancel
              }
              isLoadingSubmit={updateCycleInformationLoading}
            />
          </Form.Item>
        </Form>
        {currentModalType === CONFIRM_MODAL_TYPE_INFO.CANCEL_EDIT_CYCLE && (
          <ConfirmModal {...cancelEditLocale} handleOkFunc={handleConfirmCancel} />
        )}
        {currentModalType === CONFIRM_MODAL_TYPE_INFO.UNDO_CHANGE && (
          <ConfirmModal
            {...doneCycleCancelEditLocale}
            handleOkFunc={handleConfirmCancelEditDoneCycle}
          />
        )}
      </div>
    </>
  );
};

export default memo(CycleInformationForm);
