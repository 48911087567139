import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';
import { getCurrentCycleDetailByVersionAsync } from '@@/redux/slice/cycleDetailByVersionSlice';
import {
  CYCLE_DISPLAY_PART,
  CYCLE_NOTIFICATION_LINK,
  CYCLE_VERSION,
} from '@@/_new_src_/constants/myCycles';
import CycleExpectationFormPage from '@@/_new_src_/page/myCycles/component/CycleExpectationFormPage';
import CycleInfoDisplayPage from '@@/_new_src_/page/myCycles/component/CycleInfoDisplayPage';
import CycleInformationPage from '@@/_new_src_/page/myCycles/component/CycleInformationPage';
import {
  cycleDetailData,
  cycleDisplayPart,
  isUpdateInformationSuccess,
  isUpdatingExpectation,
  overdueAndNonEngagedTip,
  setCycleDisplayPart,
  setIsOperationCycle,
} from '@@/_new_src_/store/myCyclesSlice';
import {
  getCycleByCycleIdAsync,
  getOngoingCycleAsync,
} from '@@/_new_src_/store/myCyclesSlice/asyncThunk';

import StepsBar from '@@/_new_src_/page/myCycles/component/StepsBar';
import Notification from '@@/_new_src_/components/Notification';
import locale from '@@/_new_src_/local/myCycles/en_US';
import OverdueNotification from '@@/_new_src_/page/myCycles/component/OverdueNotification';
import { userInfo } from '@@/_new_src_/store/userSlice';
import {
  cycleInfoNotificationVisible,
  setCycleInfoNotificationVisible,
} from '@@/_new_src_/store/persistenceSlice';
import './index.less';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { getAssessmentNonEngagedTips } from '@@/_new_src_/utils/feature';

const NewMyCycles = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams();
  const { cycleId: cycleIdByPathname } = params as { cycleId: number };

  const {
    assessmentNonEngagedNotification: assessmentNonEngagedNotificationLocale,
    expectationsAlignmentNotification: { title, description },
  } = locale;

  const { userId, hasOverdueCycle } = useSelector(userInfo);

  const cycleDetail = useSelector(cycleDetailData);
  const currentPart = useSelector(cycleDisplayPart);
  const isCycleInfoNotificationVisible = useSelector(cycleInfoNotificationVisible);
  const { hasAssessmentNonEngagedCycle, latestAssessmentNonEngagedCycleId } =
    useSelector(overdueAndNonEngagedTip);
  const [showAssessmentNonEngagedTip, setShowAssessmentNonEngagedTip] = useState(
    hasAssessmentNonEngagedCycle,
  );
  const { loading: isCycleInformationLoading } = useSelector(isUpdateInformationSuccess);
  const isLoadingExpectation = useSelector(isUpdatingExpectation);
  const { loading: isCycleDetailLoading } = useSelector(cycleDetailData);

  useEffect(() => {
    if (cycleIdByPathname) {
      dispatch(getCycleByCycleIdAsync(+cycleIdByPathname));
    } else {
      dispatch(getOngoingCycleAsync());
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPart]);

  useEffect(() => {
    if (!isEmpty(cycleDetail.data)) {
      const data = {
        userId,
        cycleVersion: CYCLE_VERSION.NEW,
        cycleDetailByVersion1: {},
        cycleDetailByVersion2: cycleDetail,
      };
      // ts error need change function file type
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cycleDetail.data && dispatch(getCurrentCycleDetailByVersionAsync(data));
      const { isNewCycle } = getCycleStatus(cycleDetail.data?.status || '');

      if (currentPart === CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY && !isNewCycle) {
        history.push(CYCLE_PAGE_LINK.MY_CYCLES);
      }
      if (currentPart === 'null' && !isNewCycle) {
        history.push(CYCLE_PAGE_LINK.MY_CYCLES);
      }
      if (currentPart === 'null' && isNewCycle) {
        dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY));
      }
    }
  }, [cycleDetail.data]);

  useEffect(() => {
    if (currentPart === CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY) {
      dispatch(setIsOperationCycle(true));
    }
  }, [currentPart]);

  const showStepsBar = useMemo(() => {
    return currentPart === CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY;
  }, [currentPart]);

  const showOverdueNotification = useMemo(() => {
    return currentPart === CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY && hasOverdueCycle;
  }, [currentPart, hasOverdueCycle]);

  const isPageLoading = useMemo(() => {
    return isLoadingExpectation || isCycleInformationLoading || isCycleDetailLoading;
  }, [isLoadingExpectation, isCycleInformationLoading, isCycleDetailLoading]);

  const assessmentNonEngagedNotificationProps = useMemo(() => {
    const cycleDetailLink = latestAssessmentNonEngagedCycleId
      ? CYCLE_PAGE_LINK.CYCLE_HISTORY_DETAIL + `/${latestAssessmentNonEngagedCycleId}`
      : '';
    const visible =
      currentPart === CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY &&
      showAssessmentNonEngagedTip &&
      !hasOverdueCycle;
    const { title, firstContent, secondContent } = assessmentNonEngagedNotificationLocale;
    const content = getAssessmentNonEngagedTips(firstContent(cycleDetailLink), secondContent);

    return {
      title: title,
      content: content,
      visible: visible && !isPageLoading,
      hideNotification: () => {
        setShowAssessmentNonEngagedTip(false);
      },
    };
  }, [
    isPageLoading,
    currentPart,
    showAssessmentNonEngagedTip,
    hasOverdueCycle,
    latestAssessmentNonEngagedCycleId,
  ]);

  const expectationsAlignmentConfirmProps = useMemo(() => {
    const expectationsAlignmentNotificationVisible = isNil(isCycleInfoNotificationVisible)
      ? true
      : isCycleInfoNotificationVisible;

    return {
      title: title,
      content: description(CYCLE_NOTIFICATION_LINK.URL),
      visible: expectationsAlignmentNotificationVisible && !isPageLoading,
      hideNotification: () => {
        dispatch(setCycleInfoNotificationVisible(false));
      },
    };
  }, [
    isPageLoading,
    isCycleInfoNotificationVisible,
    currentPart,
    hasOverdueCycle,
    showAssessmentNonEngagedTip,
  ]);

  return (
    <div className="my-cycles">
      {showStepsBar && <StepsBar />}
      {showOverdueNotification && <OverdueNotification />}
      <Notification {...assessmentNonEngagedNotificationProps} />
      <Notification {...expectationsAlignmentConfirmProps} />

      <CycleInformationPage />
      <CycleExpectationFormPage />
      <CycleInfoDisplayPage />
    </div>
  );
};

export default NewMyCycles;
