import { ITabInfo } from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';
import { nonEngagedCyclesTableSortColumnsName } from '@@/types/antdComponent';
import { SORT_DIRECTION_FOR_BE, TABLE_DEFAULT_INFO } from '@@/_new_src_/constants/table';

export enum NON_ENGAGED_CYCLES_TAB_INDEX {
  OVERDUE = '0',
  ASSESSMENT_NON_ENGAGED = '1',
  EXPECTATION_NON_ENGAGED = '2',
}

export const NON_ENGAGED_CYCLES_TAB: Record<string, string> = {
  '0': 'OVERDUE',
  '1': 'ASSESSMENT_NON_ENGAGED',
  '2': 'EXPECTATION_NON_ENGAGED',
};

export const DEFAULT_TABLE_CONFIG = {
  DEFAULT_PAGE_NUMBER: TABLE_DEFAULT_INFO.PAGE_NUMBER,
  DEFAULT_PAGE_SIZE: TABLE_DEFAULT_INFO.PAGE_SIZE,
  SORT_COLUMN_FOR_ASSESSMENT_NON_ENGAGED: nonEngagedCyclesTableSortColumnsName.actions,
  SORT_COLUMN_FOR_EXPECTATION_NON_ENGAGED: nonEngagedCyclesTableSortColumnsName.cycleDuration,
  DEFAULT_SORT_COLUMN: nonEngagedCyclesTableSortColumnsName.superOrgName,
  DEFAULT_SORT_ORDER: SORT_DIRECTION_FOR_BE.ascend,
};

export const TAB_INFO = ({
  sortColumn,
  sortOrder,
}: {
  sortColumn?: string;
  sortOrder?: string;
}) => {
  return {
    employees: [],
    sortColumn: sortColumn || DEFAULT_TABLE_CONFIG.DEFAULT_SORT_COLUMN,
    sortOrder: sortOrder || DEFAULT_TABLE_CONFIG.DEFAULT_SORT_ORDER,
    pageNumber: DEFAULT_TABLE_CONFIG.DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_TABLE_CONFIG.DEFAULT_PAGE_SIZE,
  } as ITabInfo;
};
