import { Button } from 'antd';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, isEqualWith } from 'lodash';
import perfMessage from '../../PerfMessage/perfMessage';
import perfModalConfirm from '../../PerfModalConfirm';
import {
  selectCurrentSelfReviewText,
  selectDoneSelfReviewText,
  setCurrentSelfReviewText,
  setDoneSelfReviewText,
} from '@@/redux/slice/reviewSlice';
import './index.less';
import CustomPrompt from '../../CustomPrompt';
import { EXPECTATION_MAX_LENGTH } from '../../../constant/review';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MY_CYCLE_LIST_API } from '@@/common/constant/matomo';
import UploadAttachment from '@@/_new_src_/features/uploadAttachment';

import {
  doneCycleSelfAssessmentContent,
  sendRequestData,
  setDoneCycleSelfAssessmentContent,
  setIsSelfAssessmentChanged,
} from '@@/_new_src_/store/cycleDetailSlice';
import { getRequestToEditStatus } from '@@/_new_src_/utils/feature/cycle';
import {
  selfAssessmentAttachment,
  setEditDoneCycleChanged,
  setSelfAssessmentAttachment as selfAssessmentAttachmentStore,
} from '@@/_new_src_/store/myCyclesSlice';
import { postSelfAssessment, putSelfAssessment } from '@@/_new_src_/api/cycleDetail';
import { AttachmentType } from '@@/_new_src_/constants/attachmentType';
import { useRouteMatch } from 'react-router';

function SelfReviewInput(
  { onCancelSelfReviewInput, onSubmitSelfReviewInput, isEditDoneReview },
  ref,
) {
  const { trackEvent } = useMatomo();
  const dispatch = useDispatch();
  const isInCycleDetail = useRouteMatch('/performance/cycles/cycleHistoryDetail/');

  const selfReviewStorage = isInCycleDetail ? null : localStorage.getItem('SELF_REVIEW');

  const {
    reviewList: {
      selfReviewPreview: { selfReviewInput: locale },
    },
    confirmCancelModal,
  } = useSelector(selectLocaleResource);
  const { currentCycleDetail: { id: cycleId, selfAssessment, isDoneCycle } = {} } =
    useSelector(cycleDetailByVersion);
  const currentSelfReviewId = get(selfAssessment, 'id');
  const content = get(selfAssessment, 'content');
  const isEdit = currentSelfReviewId !== undefined;
  const currentSelfReviewText = useSelector(selectCurrentSelfReviewText);
  const doneSelfReviewText = useSelector(selectDoneSelfReviewText);
  const editorInitValue = isInCycleDetail
    ? useRef(content || '')
    : useRef(selfReviewStorage || currentSelfReviewText || content || '');
  const { status: editRequeststatus } = useSelector(sendRequestData);
  const { isApprovedRequestToEdit } = getRequestToEditStatus(editRequeststatus);
  const attachment = useSelector(selfAssessmentAttachment);
  const assessmentContent = useSelector(doneCycleSelfAssessmentContent);

  const [selfContentsTextInfo, setSelfContentText] = useState(null);

  const selfReviewText = useMemo(() => {
    return isEditDoneReview ? doneSelfReviewText : currentSelfReviewText;
  }, [currentSelfReviewText, doneSelfReviewText, isEditDoneReview]);
  const initSelfAssessment = {
    content: selfAssessment?.content,
    attachmentName: selfAssessment?.attachmentName,
  };

  const isSelfReviewTextEqualWithBackend = useMemo(
    () => isEqualWith(content, selfReviewText, (a, b) => (!a && !b) || a === b),
    [content, selfReviewText],
  );

  useEffect(() => {
    const attachmentUrl = selfAssessment?.attachmentStorageUrl;
    const currentAttachmentStorageName =
      (attachmentUrl && attachmentUrl.substring(attachmentUrl.lastIndexOf('/') + 1)) || '';
    dispatch(
      selfAssessmentAttachmentStore({
        filename: selfAssessment?.attachmentName,
        url: selfAssessment?.attachmentStorageUrl,
        storageName: currentAttachmentStorageName,
      }),
    );
  }, [selfAssessment]);

  const getIsDisableSave = useCallback(() => {
    return (
      (isEmpty(selfContentsTextInfo) && !attachment.filename && !attachment.storageName) ||
      (selfContentsTextInfo && selfContentsTextInfo.length > +EXPECTATION_MAX_LENGTH)
    );
  }, [selfContentsTextInfo, attachment]);

  useEffect(() => {
    dispatch(
      setIsSelfAssessmentChanged(
        initSelfAssessment.content !== assessmentContent ||
          initSelfAssessment.attachmentName !== attachment.filename,
      ),
    );
  }, [attachment, assessmentContent]);

  useEffect(() => {
    dispatch(setDoneCycleSelfAssessmentContent(currentSelfReviewText));
  }, [currentSelfReviewText]);

  const setSelfReviewText = useCallback(
    value => {
      if (isEditDoneReview) {
        dispatch(setDoneSelfReviewText(value));
        return;
      }
      dispatch(setCurrentSelfReviewText(value));
    },
    [dispatch, isEditDoneReview],
  );

  const cancelInput = useCallback(
    redirectFunction => {
      if (isSelfReviewTextEqualWithBackend) {
        onCancelSelfReviewInput();
        if (redirectFunction) {
          redirectFunction();
        }
        return;
      }
      perfModalConfirm({
        content: confirmCancelModal.confirmMessage,
        okText: confirmCancelModal.okText,
        cancelText: confirmCancelModal.cancelText,
        onOk: () => {
          onCancelSelfReviewInput();
          localStorage.removeItem('SELF_REVIEW');
          if (redirectFunction) {
            redirectFunction();
          }
        },
        centered: true,
      });
    },
    [
      confirmCancelModal.cancelText,
      confirmCancelModal.confirmMessage,
      confirmCancelModal.okText,
      isSelfReviewTextEqualWithBackend,
      onCancelSelfReviewInput,
    ],
  );

  const onCancel = useCallback(() => {
    cancelInput();
  }, [cancelInput]);

  useImperativeHandle(ref, () => ({
    cancelInput,
  }));

  const onSubmit = useCallback(() => {
    const hideModal = perfModalConfirm({
      width: 600,
      centered: true,
      content: locale.confirmModal.content,
      okText: isEdit ? locale.confirmModal.saveButton : locale.confirmModal.submitButton,
      cancelText: locale.cancelButton,
      onOk: async () => {
        const selfAssessmentDto = {
          content: currentSelfReviewText,
          attachmentName: attachment.filename,
          attachmentStorageName: attachment.storageName,
        };
        trackEvent({
          category: MY_CYCLE_LIST_API.category,
          action: isEdit
            ? MY_CYCLE_LIST_API.action.TWER_EDITS_SELF_ASSESSMENT
            : MY_CYCLE_LIST_API.action.TWER_CREATES_SELF_ASSESSMENT,
        });
        isEdit
          ? await putSelfAssessment(currentSelfReviewId, selfAssessmentDto)
          : await postSelfAssessment(cycleId, selfAssessmentDto);
        hideModal();
        perfMessage.success(
          isEdit
            ? locale.confirmModal.saveSuccessMessage
            : locale.confirmModal.submitSuccessMessage,
        );
        onSubmitSelfReviewInput();
        localStorage.removeItem('SELF_REVIEW');
      },
    });
  }, [
    attachment,
    locale,
    isEdit,
    cycleId,
    selfReviewText,
    currentSelfReviewId,
    onSubmitSelfReviewInput,
  ]);

  const updateSelfContentHtml = html => {
    setSelfReviewText(html);
    !isInCycleDetail && localStorage.setItem('SELF_REVIEW', html);
  };

  const updateSelfContentText = text => {
    setSelfContentText(text);
  };

  useEffect(() => {
    if (isDoneCycle) {
      if (isSelfReviewTextEqualWithBackend) {
        dispatch(setEditDoneCycleChanged(false));
      } else {
        dispatch(setEditDoneCycleChanged(true));
      }
    }
    return () => {
      dispatch(setEditDoneCycleChanged(false));
    };
  }, [isSelfReviewTextEqualWithBackend, isDoneCycle]);

  return (
    <div className="self-review-container">
      <CustomPrompt when={isEditDoneReview && !isSelfReviewTextEqualWithBackend} />
      <div className="title">{locale.title}</div>
      <UploadAttachment
        attachmentType={AttachmentType.SELF_ASSESSMENT}
        defaultInfo={selfAssessment}
        attachmentInfo={attachment}
      />
      <TinyMceEditor
        initialValue={editorInitValue.current}
        updateHtmlInfo={updateSelfContentHtml}
        updateTextInfo={updateSelfContentText}
        maxLength={EXPECTATION_MAX_LENGTH}
      />
      <div className="operation">
        {!isEditDoneReview && !isApprovedRequestToEdit && (
          <>
            <Button className="cancel" onClick={onCancel}>
              {locale.cancelButton}
            </Button>
            <Button
              type="primary"
              className="submit"
              onClick={onSubmit}
              disabled={getIsDisableSave()}
            >
              {locale.saveButton}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default memo(forwardRef(SelfReviewInput));
