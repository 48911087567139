import roleIcon from '@@/assets/images/role.svg';
import DarkSelect from '@@/common/component/DarkSelect';
import { UserInfoContext } from '@@/context/userInfo.context';
import {
  DASHBOARD_CYCLES_PATH,
  DASHBOARD_REQUESTS_PATH,
} from '@@/features/performance/v2/dashboardPage/common/constants';
import {
  clearGroupSelection,
  getRoleOptionList,
  setCurrentRoleSelection,
  setDisplayGroupSelect,
} from '@@/redux/slice/dashboardSlice';
import { find, get, isEmpty } from 'lodash';
import * as queryString from 'query-string';
import React, { memo, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import '../../../reviewees/detail/index.less';
import { GLOBAL_MANAGEMENT_TEAM, GLOBAL_PEOPLE_TEAM } from '../common/constants';
import './index.less';
import { dashboardAccessibleRoles } from '@@/_new_src_/constants/userEnum';
import { ERROR_PAGE } from '@@/_new_src_/constants/pagePath';

const DashboardHeaderRoleSelect = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const roleOptionList = useSelector(state => state.dashboard.roleOptionList);
  const currentRoleSelection = useSelector(state => state.dashboard.currentRoleSelection);

  const userInfo = useContext(UserInfoContext);
  const { userRoleInfoInV2GlobalFunctionAdmin } = userInfo;
  const extendedGlobalLeadershipTeamInfo =
    userRoleInfoInV2GlobalFunctionAdmin.length &&
    userRoleInfoInV2GlobalFunctionAdmin.find(item => item.hierarchyName === GLOBAL_MANAGEMENT_TEAM);
  const globalPeopleTeamInfo =
    userRoleInfoInV2GlobalFunctionAdmin.length &&
    userRoleInfoInV2GlobalFunctionAdmin.find(item => item.hierarchyName === GLOBAL_PEOPLE_TEAM);

  const onRoleSelect = useCallback(
    async (roleSelected, selectedOption) => {
      dispatch(clearGroupSelection());
      dispatch(setDisplayGroupSelect(false));
      dispatch(setCurrentRoleSelection(selectedOption.data));
      const isOtherGlobalFunctions = roleSelected.label?.includes('Other Global Functions');
      history.replace({
        pathname: isOtherGlobalFunctions ? DASHBOARD_REQUESTS_PATH : DASHBOARD_CYCLES_PATH,
        search: '',
      });
    },
    [dispatch, setDisplayGroupSelect],
  );

  const getUserRolesInfo = () => {
    return get(userInfo, 'userRolesInfoInV2', []).filter(item =>
      dashboardAccessibleRoles.includes(item.role),
    );
  };

  useEffect(() => {
    async function getAllRoleList() {
      try {
        await dispatch(
          getRoleOptionList({
            roles: getUserRolesInfo(),
            globalFunctionAdminRoleInfo: {
              extendedGlobalLeadershipTeamInfo,
              globalPeopleTeamInfo,
            },
          }),
        );
      } catch (e) {
        console.error(e);
      }
    }
    getAllRoleList();
  }, []);

  useEffect(() => {
    if (isEmpty(roleOptionList)) {
      return;
    }
    let selectedOption = roleOptionList[0];

    const { search } = location;
    if (search) {
      const { selectedRole = '' } = queryString.parse(search);
      if (!isEmpty(selectedRole)) {
        const selectedRoleOption = find(roleOptionList, item =>
          item.roleInfo.includes(selectedRole),
        );
        if (selectedRoleOption === undefined) {
          history.push({ pathname: ERROR_PAGE.IS_403 });
          return;
        }
        selectedOption = selectedRoleOption;
      }
    }
    if (!currentRoleSelection.roleType) {
      dispatch(setCurrentRoleSelection(selectedOption));
    }
  }, [roleOptionList]);

  return (
    <div className="dashboard-header-role-select">
      <img src={roleIcon} className="role-icon" alt="" />
      <DarkSelect
        value={{
          label: currentRoleSelection?.displayRoleName,
          value: currentRoleSelection?.roleInfo,
        }}
        onChange={onRoleSelect}
        optionList={roleOptionList.map(item => ({
          label: item.displayRoleName,
          value: item.roleInfo,
          data: item,
        }))}
      />
    </div>
  );
};

export default memo(DashboardHeaderRoleSelect);
