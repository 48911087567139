import { Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useCallback, useState } from 'react';
import * as XLSX from 'xlsx';
import ExcelImportIcon from '@@/assets/images/excel-import.png';
import { GROUP_MEMBER_TEMPLATE_FILE_URL } from '../../constant/dashboard';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import WarningText from '@@/_new_src_/components/WarningText';

const { Dragger } = Upload;

interface PropsType {
  getFileList: (file: UploadFile[]) => void;
  getExcelData: (data: unknown[]) => void;
  clearCreateGroupError: () => void;
}

function UploadFiles(props: PropsType) {
  const { getFileList, clearCreateGroupError, getExcelData } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const localeResource = useSelector(selectLocaleResource) as unknown;

  const {
    admin: {
      manageGroupsOfTBP: { groupModal: locale },
    },
  } = localeResource as {
    admin: {
      manageGroupsOfTBP: {
        groupModal: {
          ifYouReUploadTheFileWillReplaceThePreviousGroupMemberList: string;
          youCanOnlyAddPeopleWithoutSpecialRole: string;
          toThisAreaToUpload: string;
          aSingleExcelFile: string;
          clickOrDrag: string;
          getTemplateHere: string;
          members: {
            label: string;
            import: string;
          };
        };
      };
    };
  };
  const readAndFormatExcelData = (file: File) => {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onload = event => {
      try {
        const data = event.target && event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetNames = workbook.SheetNames;
        let outputData = XLSX.utils.sheet_to_json(workbook.Sheets[-1]);
        sheetNames.forEach(name => {
          outputData = outputData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[name]));
        });
        getExcelData(outputData);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error while get data from importing...');
      }
    };
  };

  const beforeUnloadFun = (file: File) => {
    readAndFormatExcelData(file);
    return false;
  };

  const handleChangeUploadFile = useCallback(
    (info: { file: UploadFile; fileList: UploadFile[] }) => {
      if (info.file) {
        setFileList(info.fileList.slice(-1));
        getFileList(info.fileList.slice(-1));
        clearCreateGroupError();
      }
    },
    [clearCreateGroupError, getFileList],
  );

  return (
    <div className="upload-file-wrapper">
      <div className="upload-label-section">
        <div className="upload-label">{locale.members.label} </div>
        <div className="file-template">
          <a target="_blank" rel="noopener noreferrer" href={GROUP_MEMBER_TEMPLATE_FILE_URL}>
            {locale.getTemplateHere}
          </a>
        </div>
      </div>
      <Dragger
        className="importSection"
        beforeUpload={beforeUnloadFun}
        onChange={handleChangeUploadFile}
        fileList={fileList}
        multiple={false}
        accept=".xlsx"
      >
        <div className="ant-upload-drag-icon">
          <div className="import-members-wrapper">
            <button type="button">
              <img src={ExcelImportIcon} alt="" />
              <span>{locale.members.import}</span>
            </button>
          </div>
        </div>
        <p className="ant-upload-text">
          <span>{locale.clickOrDrag} </span>
          <b>{locale.aSingleExcelFile}</b>
          <span> {locale.toThisAreaToUpload}</span>
        </p>
        <p className="ant-upload-text">{locale.youCanOnlyAddPeopleWithoutSpecialRole}</p>
        <WarningText
          warningText={locale.ifYouReUploadTheFileWillReplaceThePreviousGroupMemberList}
        />
      </Dragger>
    </div>
  );
}

export default UploadFiles;
