import { getLatestDoneReviewExpectation, updateReviewDetailById } from '@@/api/review';
import { getUserList, getUserProjectList } from '@@/api/user';
import DatePicker from '@@/common/component/DatePicker';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { LIMIT_PARTNER_NUM } from '@@/common/constant/review';
import { ReviewStepsContext } from '@@/context/reviewSteps.context';
import { UserInfoContext } from '@@/context/userInfo.context';
import useLocalStorageReview from '@@/hooks/useLocalStorageReview';
import usePerfModal from '@@/hooks/usePerfModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  selectCurrentSelfReviewText,
  selectDoneSelfReviewText,
  setCyclesCycleDetailV1,
} from '@@/redux/slice/reviewSlice';
import { CopyOutlined } from '@ant-design/icons';
import { useClickAway } from 'ahooks';
import { Button, Form, Tooltip } from 'antd';
import cls from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { get, isArray, isEmpty, isEqual, isNil, omit, omitBy } from 'lodash';
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EXPECTATION_MAX_LENGTH, REVIEWER_TYPE } from '@@/common/constant/review';
import { REVIEW_STEP } from '@@/common/constant/steps';
import AutoComplete from '../AutoComplete';
import CustomPrompt from '../CustomPrompt';
import ExpectationLink from '../ExpectationLink';
import perfMessage from '../PerfMessage/perfMessage';
import perfModalConfirm from '../PerfModalConfirm';
import CopyExpectationModal from './CopyExpectationModal';
import './index.less';
import { MY_CYCLE_DETAIL_API, MY_CYCLE_LIST_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ProjectSelect from '@@/_new_src_/features/ProjectSelect';
import {
  formatV2Project,
  formatV2ProjectListData,
  getV1ProjectParams,
} from '@@/_new_src_/utils/feature';
import {
  selfAssessmentAttachment,
  setV1ExpectationAttachment,
  v1ExpectationAttachment,
} from '@@/_new_src_/store/myCyclesSlice';
import { AttachmentType } from '@@/_new_src_/constants/attachmentType';
import UploadAttachment from '@@/_new_src_/features/uploadAttachment';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { getUserInfoAsync } from '@@/_new_src_/store/userSlice/asyncThunk';
import {
  formatRangeDateForProbationCycle,
  formatRangeDateToSecond,
  formatRangeUTCDate,
  getUTCTimestamp,
} from '@@/_new_src_/utils/common/date';
import { HOME_COUNTRY, PROBATION_STATUS } from '@@/_new_src_/constants/userEnum';
import { postV1SetNewCycle } from '@@/_new_src_/api/myCycles';
const { RangePicker } = DatePicker;
dayjs.extend(utc);

const RevieweeReviewEditor = props => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const {
    review,
    updatedMode,
    classConfig,
    hasSubmitSelfReview,
    isEditDoneReviewByReviewee,
    selfReviewAttachment,
  } = props;
  const localeResource = useSelector(selectLocaleResource);

  const userInfo = useContext(UserInfoContext);
  const { probationStatus, hireDate, passedProbationDate, futureCycleVersion } = userInfo;

  const {
    setReview: { form: formText, footer },
    confirmCancelModal,
  } = localeResource;
  const expectationLocale = formText.expectation;
  const reviewStepsContext = useContext(ReviewStepsContext);
  const [projectList, setProjectList] = useState([]);
  const [latestDoneReviewExpectationDetail, setLatestDoneReviewExpectationDetail] = useState({});
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);

  const [lastDoneExpectations, setLastDoneExpectations] = useState(null);
  const [expectationsHtmlInfo, setExpectationHtml] = useState(null);
  const [expectationsTextInfo, setExpectationText] = useState(null);
  const [expectationInitialValue, setExpectationInitialValue] = useState(null);
  const [showCopyExpectationModal, onOpenCopyExpectationModal, onCloseCopyExpectationModal] =
    usePerfModal();
  const [form] = Form.useForm();

  const { isDoneCycle: isDoneReview } = getCycleStatus(review.status);
  const reviewerAutoCompleteRef = useRef(null);
  const contributorAutoCompleteRef = useRef(null);
  const reviewerUnderlineRef = useRef(null);
  const contributorUnderlineRef = useRef(null);
  const {
    reviewerState,
    setReviewerState,
    contributorsState,
    setContributorsState,
    projectState,
    setProjectState,
    reviewDurationState,
    setReviewDurationState,
    expectationState,
    setExpectationState,
    isLocalReviewEmpty,
  } = useLocalStorageReview();
  const currentSelfReviewText = useSelector(selectCurrentSelfReviewText);
  const doneSelfReviewText = useSelector(selectDoneSelfReviewText);
  const selfAssessAttachment = useSelector(selfAssessmentAttachment);

  const attachment = useSelector(v1ExpectationAttachment);

  const selfReviewText = useMemo(() => {
    return isEditDoneReviewByReviewee ? doneSelfReviewText : currentSelfReviewText;
  }, [currentSelfReviewText, doneSelfReviewText, isEditDoneReviewByReviewee]);

  useEffect(() => {
    const attachmentUrl = review?.expectation?.attachmentUrl;
    const currentAttachmentStorageName =
      (attachmentUrl && attachmentUrl.substring(attachmentUrl.lastIndexOf('/') + 1)) || '';
    dispatch(
      setV1ExpectationAttachment({
        filename: review?.expectation?.attachmentName,
        url: attachmentUrl,
        storageName: currentAttachmentStorageName,
      }),
    );
  }, [review]);

  useEffect(() => {
    getLatestDoneReviewExpectation().then(response =>
      setLatestDoneReviewExpectationDetail(response.data),
    );
  }, []);

  const { homeCountry } = userInfo;

  const isCHNUser = useMemo(() => {
    return homeCountry === HOME_COUNTRY.CHINA || homeCountry === HOME_COUNTRY.HK;
  }, [homeCountry]);

  const isCycleDurationReadOnly = useMemo(() => {
    if (updatedMode && isCHNUser) {
      return review.probationStatus === PROBATION_STATUS.UNDER_PROBATION;
    }
    return isCHNUser && probationStatus === PROBATION_STATUS.UNDER_PROBATION;
  }, [isCHNUser, probationStatus, review.probationStatus, updatedMode]);

  const onClickCopyExpectationPreview = () => {
    const lastDoneExpectationText = get(latestDoneReviewExpectationDetail, 'expectation.text', '');
    setLastDoneExpectations(lastDoneExpectationText);
  };

  const updateExpectationHtml = html => {
    setExpectationHtml(html);
    setExpectationState(html);
  };

  const updateExpectationText = text => {
    setExpectationText(text);
  };

  const updateLastDoneInfo = html => {
    setLastDoneExpectations(html);
  };

  const isCopyDisabled =
    isEmpty(latestDoneReviewExpectationDetail) ||
    !latestDoneReviewExpectationDetail?.expectation.text;

  const expectationLabel = (
    <div className="expectation-label">
      <span className="label">
        {updatedMode ? expectationLocale.labelOnUpdate : expectationLocale.label}
      </span>
      <div className="operation">
        <div className="operation-wrapper">
          <Tooltip
            overlayClassName="copy-expectations-tooltip"
            placement="bottomLeft"
            title={expectationLocale.copy.tooltip}
          >
            <Button
              className="copy-expectations-button"
              onClick={onOpenCopyExpectationModal}
              disabled={isCopyDisabled}
            >
              <CopyOutlined
                className={isCopyDisabled ? 'operation-icon grey' : 'operation-icon'}
                rotate={180}
              />
              {expectationLocale.copy.label}
            </Button>
          </Tooltip>
        </div>
        <div>
          <b>{formText.expectation.tipCombination[0]}</b>
          {formText.expectation.tipCombination[1]}
          <ExpectationLink linkText={formText.expectation.tipCombination[2]} />
        </div>
      </div>
      <UploadAttachment
        attachmentType={AttachmentType.V1_CYCLE_EXPECTATION}
        defaultInfo={{
          attachmentName: attachment?.filename,
          attachmentStorageUrl: attachment?.url,
        }}
        attachmentInfo={attachment}
      />
      <CopyExpectationModal
        visible={showCopyExpectationModal}
        onCancel={onCloseCopyExpectationModal}
        onOk={onClickCopyExpectationPreview}
        projectName={get(latestDoneReviewExpectationDetail, 'project.projectName')}
        duration={get(latestDoneReviewExpectationDetail, 'duration')}
        expectationText={get(latestDoneReviewExpectationDetail, 'expectation.text')}
      />
    </div>
  );

  const RoleSection = updatedMode ? (
    <>
      <div className="role-label">
        <label>{formText.role.labelOnUpdate}</label>
      </div>
      <div className="role-content">{get(review, 'reviewee.role')}</div>
    </>
  ) : (
    <>
      <div className="role-label">
        <label>{formText.role.label}</label>
      </div>
      <div className="role-content">{userInfo.role}</div>
    </>
  );

  const setReviewDetail = useCallback(() => {
    const {
      project,
      duration: { startTime, endTime },
      reviewers: { reviewer, contributors },
      expectation: { text: expectation } = {},
    } = review;
    form.setFieldsValue({
      project: formatV2Project(project),
      reviewer: {
        value: reviewer.id,
        label: reviewer.name,
      },
      contributors: contributors.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
      reviewDuration: [dayjs.utc(startTime), dayjs.utc(endTime)],
    });
    setExpectationInitialValue(expectation);
    setProjectState(formatV2Project(project));
    setReviewerState({ value: reviewer.id, label: reviewer.name, key: reviewer.id });
    setContributorsState(
      contributors.map(({ id, name }) => ({
        value: id,
        label: name,
        key: id,
      })),
    );
    setReviewDurationState([startTime, endTime]);
    setExpectationState(expectation);
  }, [form, review]);

  useEffect(() => {
    if (!isLocalReviewEmpty) {
      form.setFieldsValue({
        reviewer: isEmpty(reviewerState) ? undefined : reviewerState,
        contributors: isEmpty(contributorsState) ? undefined : contributorsState,
        project: projectState,
        reviewDuration: isEmpty(reviewDurationState)
          ? undefined
          : [dayjs.utc(reviewDurationState[0]), dayjs.utc(reviewDurationState[1])],
      });
      setExpectationInitialValue(expectationState);
    } else if (updatedMode && !isEmpty(review.project)) {
      setReviewDetail();
    }
    form?.validateFields(['contributors']);
  }, [updatedMode, setReviewDetail, review.project, isLocalReviewEmpty, form]);

  useEffect(() => {
    async function getProjectList() {
      const res = await getUserProjectList();
      let data = isArray(res.data) ? formatV2ProjectListData(res.data) : [];
      setProjectList(data);
    }
    getProjectList().then();
  }, []);

  const updateReviewStepsStatus = useCallback(() => {
    if (updatedMode) {
      reviewStepsContext.setCurrent(REVIEW_STEP.REGULAR_CHECKINS);
      if (!isEmpty(review.checkInNotes)) {
        reviewStepsContext.setAddedCheckinNotes(true);
      }
    }
  }, [updatedMode, review, reviewStepsContext]);

  useEffect(() => {
    updateReviewStepsStatus();
  }, [updateReviewStepsStatus]);

  const onSubmitReview = useCallback(
    fieldsValue => {
      const okText = updatedMode ? footer.save.confirmOkUpdateText : footer.save.confirmOkText;
      const confirmText = isDoneReview
        ? footer.save.confirmMessage.editDone
        : footer.save.confirmMessage.default;
      setIsSubmitModalVisible(true);
      perfModalConfirm({
        centered: true,
        content: (
          <>
            <p>{confirmText}</p>
            {!updatedMode && <p>{footer.save.confirmMessage.email}</p>}
          </>
        ),
        okText,
        onOk: async () => {
          // start time & end time & current time is timestamp with timezone offset
          const [probationStartTime, probationEndTime] = updatedMode
            ? formatRangeDateForProbationCycle(
                dayjs(review.duration.startTime),
                dayjs(review.duration.endTime),
              )
            : formatRangeDateForProbationCycle(dayjs(hireDate), dayjs(passedProbationDate));
          const [startTime, endTime] = formatRangeDateToSecond(fieldsValue.reviewDuration);

          const params = omitBy(
            {
              reviewerId: fieldsValue.reviewer.value,
              contributorIds: fieldsValue.contributors
                ? fieldsValue.contributors.map(({ value }) => value)
                : [],
              expectation: expectationsHtmlInfo,
              expectationAttachmentName: attachment.filename,
              expectationAttachmentStorageObjectName: attachment.storageName,
              startTime: isCycleDurationReadOnly ? probationStartTime : startTime,
              endTime: isCycleDurationReadOnly ? probationEndTime : endTime,
              currentTime: getUTCTimestamp(dayjs()),
              ...getV1ProjectParams(fieldsValue.project),
              selfReviewAttachment: get(selfReviewAttachment, 'type')
                ? selfReviewAttachment
                : undefined,
            },
            isNil,
          );
          if (updatedMode) {
            const {
              filename: expectationFilename = '',
              storageName: expectationStorageObjectName = '',
            } = attachment;
            const { filename = '', storageName } = selfAssessAttachment;
            params.expectationAttachmentName = expectationFilename;
            params.expectationAttachmentStorageObjectName = expectationStorageObjectName;
            if (selfReviewText?.trim()) {
              params.selfReviewContent = selfReviewText.trim();
            }
            if (isEditDoneReviewByReviewee) {
              params.selfAssessmentAttachmentName = filename;
              params.selfAssessmentAttachmentStorageObjectName = storageName;
            }
          } else {
            params.role = userInfo.role;
          }
          if (isDoneReview) {
            trackEvent({
              category: MY_CYCLE_DETAIL_API.category,
              action: MY_CYCLE_DETAIL_API.action.TWER_EDITS_DONE_CYCLE_AFTER_REQUEST_IS_APPROVED,
            });
          } else {
            trackEvent({
              category: MY_CYCLE_LIST_API.category,
              action: updatedMode
                ? MY_CYCLE_LIST_API.action.TWER_EDITS_V1_CYCLE
                : MY_CYCLE_LIST_API.action.TWER_OPENS_V1_CYCLE,
            });
          }

          const handler = updatedMode
            ? await updateReviewDetailById(review.id, params)
            : await postV1SetNewCycle(params);
          await handler;
          perfMessage.success(footer.save.successMessage);
          dispatch(
            setCyclesCycleDetailV1({
              data: {},
              loading: false,
            }),
          );
          dispatch(getUserInfoAsync());
          if (props.onEditSuccess) {
            props.onEditSuccess();
          }
        },
        onCancel: () => setIsSubmitModalVisible(false),
      });
    },
    [
      updatedMode,
      footer.save.confirmOkUpdateText,
      footer.save.confirmOkText,
      footer.save.confirmMessage.editDone,
      footer.save.confirmMessage.default,
      footer.save.confirmMessage.email,
      footer.save.successMessage,
      isDoneReview,
      review,
      hireDate,
      passedProbationDate,
      isCycleDurationReadOnly,
      selfReviewAttachment,
      props,
      selfReviewText,
      isEditDoneReviewByReviewee,
      userInfo.role,
      expectationsHtmlInfo,
      selfAssessAttachment,
      attachment,
    ],
  );

  const renderReviewerOption = useCallback(
    (optionList, Option, reviewerType) =>
      optionList.map(({ userId, name }) => {
        const isSelf = userInfo.userId === userId;
        const reviewers = form.getFieldValue(reviewerType);
        const isSelected =
          Array.isArray(reviewers) && !isEmpty(reviewers)
            ? reviewers.map(({ value }) => value).includes(userId)
            : get(reviewers, 'value') === userId;

        return (
          <Option key={String(userId)} label={name} value={userId} disabled={isSelf || isSelected}>
            {`${name}, ${userId}`}
          </Option>
        );
      }),
    [form, userInfo.userId],
  );

  const durationValidator = useCallback(
    (_, duration) => {
      if (!duration) return Promise.resolve();
      const MONTH_OF_ONE_YEAR = 12;
      const [selectedStartMonth, selectedEndMonth] = duration;
      const isStartMonthExceedsCurrentMonth = selectedStartMonth
        .startOf('months')
        .isAfter(dayjs(), 'months');

      const isDurationMoreThanOneYear =
        selectedEndMonth.diff(selectedStartMonth, 'months') >= MONTH_OF_ONE_YEAR;

      if (isStartMonthExceedsCurrentMonth) {
        return Promise.reject(formText.reviewDuration.validateMessage.startMonth);
      }
      if (isDurationMoreThanOneYear) {
        return Promise.reject(formText.reviewDuration.validateMessage.within);
      }
      return Promise.resolve();
    },
    [
      formText.reviewDuration.validateMessage.startMonth,
      formText.reviewDuration.validateMessage.within,
    ],
  );

  const getDisabledDate = useCallback(
    currentDate => {
      const LAST_MONTH_OF_THE_YEAR = 11;
      const reviewCreatedTime = (updatedMode && review.createdTime) || undefined;
      const currentUtcDate = dayjs.utc(currentDate);
      const availableStartUtcDate = dayjs
        .utc(reviewCreatedTime)
        .subtract(LAST_MONTH_OF_THE_YEAR, 'months');
      const availableEndUtcDate = dayjs('2023-12-31');
      const utcUserHireDate = dayjs.utc(hireDate);

      return (
        currentUtcDate < availableStartUtcDate ||
        (CYCLE_VERSION.NEW === futureCycleVersion && currentUtcDate > availableEndUtcDate) ||
        currentUtcDate < utcUserHireDate
      );
    },
    [hireDate, review.createdTime, updatedMode],
  );

  const showCancelConfirm = useMemo(() => {
    if (updatedMode) {
      if (isEmpty(review)) {
        return false;
      }
      const {
        project: { id: projectId, name: projectName, account: projectAccount },
        duration: { startTime, endTime },
        reviewers: { reviewer, contributors },
        expectation: { text: expectation } = {},
      } = review;
      const projectFromBackend = { projectId, projectName, projectAccount };
      const reviewerFromBackend = {
        value: reviewer.id,
        label: reviewer.name,
        key: reviewer.id,
      };
      const contributorsFromBackend = contributors.map(({ id, name }) => ({
        value: id,
        label: name,
        key: id,
      }));
      const reviewDurationFromBackend = formatRangeDateToSecond([
        dayjs.utc(startTime),
        dayjs.utc(endTime),
      ]);
      const isDurationEqual = isCycleDurationReadOnly
        ? true
        : isEqual(reviewDurationFromBackend, reviewDurationState);
      return !(
        isEqual(projectFromBackend, projectState) &&
        isEqual(reviewerFromBackend, reviewerState) &&
        isDurationEqual &&
        isEqual(expectation, expectationState) &&
        isEqual(contributorsFromBackend, contributorsState)
      );
    }
    if (
      !isEmpty(projectState) ||
      !isEmpty(reviewerState) ||
      !isEmpty(reviewDurationState) ||
      expectationState
    ) {
      return true;
    }
    return false;
  }, [
    contributorsState,
    expectationState,
    isCycleDurationReadOnly,
    projectState,
    review,
    reviewDurationState,
    reviewerState,
    updatedMode,
  ]);

  const onCancel = useCallback(() => {
    if (showCancelConfirm) {
      setIsCancelModalVisible(true);
      perfModalConfirm({
        content: confirmCancelModal.confirmMessage,
        okText: confirmCancelModal.okText,
        cancelText: confirmCancelModal.cancelText,
        onOk: () => {
          if (props.onEditCancel) {
            props.onEditCancel(true);
          }
        },
        onCancel: () => setIsCancelModalVisible(false),
        centered: true,
      });
    } else if (props.onEditCancel) {
      props.onEditCancel(false);
    }
  }, [
    confirmCancelModal.cancelText,
    confirmCancelModal.confirmMessage,
    confirmCancelModal.okText,
    props,
    showCancelConfirm,
  ]);

  const hasDuration =
    !isEmpty(reviewDurationState) || probationStatus === PROBATION_STATUS.UNDER_PROBATION;

  const getIsDisableSave = useCallback(() => {
    if (!form) {
      return true;
    }

    const contributorsErr = form.getFieldError('contributors');

    if (updatedMode && isEditDoneReviewByReviewee && !selfReviewText?.trim()) {
      if (contributorsErr?.length) {
        return true;
      }
      return hasSubmitSelfReview && !selfReviewAttachment;
    }

    const fields = Object.keys(omit(form.getFieldsValue(), ['expectation']));
    const errorList = form.getFieldsError(fields).filter(({ errors }) => errors.length);
    const hasError = !isEmpty(errorList);

    if (isEmpty(reviewerState) || isEmpty(projectState) || !hasDuration) {
      return true;
    }

    if (!hasError) {
      return (
        (isEmpty(expectationsTextInfo) && !attachment.filename && !attachment.storageName) ||
        (expectationsTextInfo && expectationsTextInfo.length > +EXPECTATION_MAX_LENGTH)
      );
    }

    return true;
  }, [
    form,
    updatedMode,
    isEditDoneReviewByReviewee,
    selfReviewText,
    reviewerState,
    projectState,
    hasDuration,
    hasSubmitSelfReview,
    selfReviewAttachment,
    expectationsTextInfo,
    attachment,
  ]);

  const onClickAutoCompleteUnderlineWord = (e, ref) => {
    e.preventDefault();
    e.stopPropagation();
    ref.current?.setTooltipVisible(true);
  };

  useClickAway(() => {
    reviewerAutoCompleteRef.current?.setTooltipVisible(false);
  }, [
    reviewerUnderlineRef,
    document.querySelector('.reviewer-name-wrapper .ant-form-item-control-input'),
  ]);

  useClickAway(() => {
    contributorAutoCompleteRef.current?.setTooltipVisible(false);
  }, [
    contributorUnderlineRef,
    document.querySelector('.contributor-name-wrapper .ant-form-item-control-input'),
  ]);

  const defaultStartTime = updatedMode ? review.duration?.startTime : hireDate;
  const defaultEndTime = updatedMode ? review.duration?.endTime : passedProbationDate;

  return (
    <Form
      className={cls('reviewee-review-editor', classConfig.name)}
      layout="vertical"
      form={form}
      hideRequiredMark
      onFinish={onSubmitReview}
    >
      <CustomPrompt when={showCancelConfirm && !isCancelModalVisible && !isSubmitModalVisible} />
      <div className={`${classConfig.name}-form-item-wrapper`}>
        <Form.Item
          name="reviewer"
          className="reviewer-name-wrapper"
          // the required mark need in the tooltip previous
          label={
            <>
              {updatedMode ? (
                <span
                  className="underline-text"
                  onClick={e => onClickAutoCompleteUnderlineWord(e, reviewerAutoCompleteRef)}
                  ref={reviewerUnderlineRef}
                >
                  {formText.reviewer.labelOnUpdate}
                </span>
              ) : (
                <>
                  {formText.reviewer.label}&nbsp;
                  <span
                    className="underline-text"
                    onClick={e => onClickAutoCompleteUnderlineWord(e, reviewerAutoCompleteRef)}
                    ref={reviewerUnderlineRef}
                  >
                    {formText.reviewer.labelBold}
                  </span>
                  ?
                </>
              )}
              <span className="required-mark">*</span>
            </>
          }
          rules={[
            {
              required: true,
              message: formText.reviewer.validateMessage,
            },
          ]}
        >
          <AutoComplete
            placeholder={formText.reviewer.placeholder}
            notFoundContent={formText.reviewer.notFoundContent}
            showArrow={false}
            defaultActiveFirstOption={false}
            showSearch
            fetchApi={getUserList}
            onChange={setReviewerState}
            hasTooltip
            tooltipType={REVIEWER_TYPE.REVIEWER}
            isEditDoneReviewByReviewee={isEditDoneReviewByReviewee}
            ref={reviewerAutoCompleteRef}
            ariaLabel={'Performance partner input'}
          >
            {(list, Option) => renderReviewerOption(list, Option, 'contributors')}
          </AutoComplete>
        </Form.Item>
        <Form.Item
          name="contributors"
          className="contributor-name-wrapper"
          label={
            <div className="label-content">
              {updatedMode ? (
                <span
                  className="underline-text"
                  onClick={e => onClickAutoCompleteUnderlineWord(e, contributorAutoCompleteRef)}
                  ref={contributorUnderlineRef}
                >
                  {formText.contributors.labelOnUpdate}
                </span>
              ) : (
                <>
                  {formText.contributors.label}&nbsp;
                  <span
                    className="underline-text"
                    onClick={e => onClickAutoCompleteUnderlineWord(e, contributorAutoCompleteRef)}
                    ref={contributorUnderlineRef}
                  >
                    {formText.contributors.labelBold}
                  </span>
                  ?
                </>
              )}
            </div>
          }
          rules={[
            () => ({
              validator(_, value) {
                if (value?.length > LIMIT_PARTNER_NUM) {
                  return Promise.reject(new Error(formText.contributors.limitPartnerNumber));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <AutoComplete
            limitNum={LIMIT_PARTNER_NUM}
            mode="multiple"
            placeholder={formText.contributors.placeholder}
            notFoundContent={formText.contributors.notFoundContent}
            hasTooltip
            fetchApi={getUserList}
            onChange={setContributorsState}
            isEditDoneReviewByReviewee={isEditDoneReviewByReviewee}
            tooltipType={REVIEWER_TYPE.CONTRIBUTOR}
            ref={contributorAutoCompleteRef}
            ariaLabel={'Additional partners'}
          >
            {(list, Option) => renderReviewerOption(list, Option, 'reviewer')}
          </AutoComplete>
        </Form.Item>
        <Form.Item
          label={updatedMode ? formText.project.labelOnUpdate : formText.project.label}
          className="project-select"
          name="project"
          rules={[
            {
              required: true,
              message: formText.project.validateMessage,
            },
          ]}
        >
          <ProjectSelect
            bordered={classConfig.bordered}
            optionList={projectList}
            placeholder={formText.project.placeholder}
            notFoundContent={formText.project.notFoundContent}
            onChange={setProjectState}
            ariaLabel={'Project input'}
          />
        </Form.Item>
        {isCycleDurationReadOnly && (
          <Form.Item
            className={cls('review-duration', {
              'probation-disabled-cycle': true,
            })}
            label={
              updatedMode ? formText.reviewDuration.labelOnUpdate : formText.reviewDuration.label
            }
          >
            <RangePicker
              picker="day"
              size="middle"
              style={{ width: '100%' }}
              defaultValue={[
                dayjs(defaultStartTime, 'YYYY-MM-DD'),
                dayjs(defaultEndTime, 'YYYY-MM-DD'),
              ]}
              disabled={isCycleDurationReadOnly}
            />
          </Form.Item>
        )}
        {!isCycleDurationReadOnly && (
          <Form.Item
            name="reviewDuration"
            className="review-duration"
            label={
              updatedMode ? formText.reviewDuration.labelOnUpdate : formText.reviewDuration.label
            }
            validateFirst
            rules={[
              {
                type: 'array',
                required: true,
                message: formText.reviewDuration.validateMessage.require,
              },
              {
                validator: durationValidator,
              },
            ]}
          >
            <RangePicker
              picker="month"
              size="middle"
              style={{ width: '100%' }}
              disabledDate={getDisabledDate}
              getPopupContainer={trigger => trigger.parentNode}
              onChange={value => {
                setReviewDurationState(formatRangeUTCDate(value));
              }}
            />
          </Form.Item>
        )}
        <div>{RoleSection}</div>
      </div>
      <Form.Item name="expectation" className="expectation" label={expectationLabel}>
        <TinyMceEditor
          lastDoneInfo={lastDoneExpectations}
          initialValue={expectationInitialValue}
          updateHtmlInfo={updateExpectationHtml}
          updateTextInfo={updateExpectationText}
          updateLastDoneInfo={updateLastDoneInfo}
          maxLength={EXPECTATION_MAX_LENGTH}
        />
      </Form.Item>
      <Form.Item className="footer-operation" shouldUpdate>
        {() => (
          <>
            <div className="buttons">
              <Button onClick={onCancel}>{footer.cancel.cancelText}</Button>
              <Button
                type="primary"
                className="save"
                htmlType="submit"
                disabled={getIsDisableSave()}
              >
                {updatedMode ? footer.save.saveUpdateText : footer.save.saveText}
              </Button>
            </div>
          </>
        )}
      </Form.Item>
    </Form>
  );
};

RevieweeReviewEditor.defaultProps = {
  review: {},
  classConfig: {
    name: 'done-editor',
    bordered: true,
  },
  expectationAttachment: {
    maxNameLength: 100,
    maxSize: 1024 * 1024 * 10, // KB
    accept: ['.png', '.jpeg', '.jpg', '.pdf'],
  },
};

export default memo(RevieweeReviewEditor);
