export default {
  expectationsAlignmentNotification: {
    title: 'Expectations alignment just got better!',
    description: (
      url: string,
    ) => `<b>Starting with this performance cycle</b>, we’ve introduced a new and improved way
                align your expectations.
                <br />
                You can now add <b>cycle information upfront</b>, define
                <b>expectations one by one </b>
                in a more <b>structured way</b>.<br /> You will also be presented with a new way of aligning your cycle
                expectations with the broader organisational context: <a target="_blank" href=${url}>Performance Categories</a>.`,
  },
  completedCycleBanner: {
    title: 'Congrats! Your’ve just completed a performance cycle!',
    content: (
      date: string,
      linkToPathways: string,
    ) => `<b>What’s next?</b> Your next cycle will be automatically started on ${date}. It’s time to
          review your previous cycle and prepare for the next cycle. Here are some useful links that
          can help you do the preparation: <a href="https://campus.thoughtworks.net/learn/courses/47635/performance-at-thoughtworks/lessons/71898:380/aligning-expectation-eng?hash=5d956b991b2e3a2efd25d47adcb37b61d728fa3f&generated_by=13883"
          target="_blank">How to set up expectations?</a> ,
          <a href=${linkToPathways}
          target="_blank">Plan or update your personal growth</a>.`,
  },
  expectationNonEngagedCycleTipContent: `Your performance expectations were not aligned and published on time. <br />Please <b>update the expectations with your personal context ASAP</b> so your cycle will be more meaningful to your growth and performance journey.`,
  cycleStepsBar: {
    cycleInformation: {
      title: 'Cycle Information',
      label: 'Cycle information',
      desc: 'Fill in the essential information and additional context of your performance cycle.',
    },
    expectationAlignment: {
      title: 'Expectations Alignment',
      label: 'Expectations alignment',
      desc: 'Align your expectations with your performance partner, reflect them in the system, and open your cycle.',
    },
    checkinAndFeedback: {
      title: 'Check-in & Feedback',
      label: 'Check-in & feedback',
      desc: 'Have regular conversations with your performance partner(s) to discuss highlights, progress and blockers about your growth and performance.',
    },
    getAssessment: {
      title: 'Assessment',
      label: 'Assessment',
      desc: 'Provide your self-assessment, and the performance partner will assess your performance.',
    },
  },
  draftedTipForProbation: `This cycle is currently in draft stage, only visible to yourself.\nAfter <b>publishing your expectations</b>, your cycle will be opened and accessible to your performance partner and other relevant roles. You can continue editing the cycle details even after publication.`,
  draftedTip: (date: string) =>
    `This cycle is currently in draft stage, only visible to yourself.\n After <b>finishing setting up and publishing your expectations</b>, your cycle will be opened and accessible to relevant roles.\n Please ensure you <b>publish</b> expectations and open the cycle before <b>${date}</b>. You can continue editing the cycle details even after publication.`,
  nonEngagedCycle: 'Non-engaged',
  cycleExpectation: {
    title: 'Cycle expectations',
    wording: `Currently your cycle is only visible to yourself. \n Remember to <b>align your expectations</b> with your <b>performance partner</b> and <b>additional partner(s)</b> to make your cycle open to the relevant roles.`,
    nonEngagedWording: `Currently this cycle is only visible to this Twer and Talent Business Partner(s) of the relevant market. \n Remember to support the Twer <b>align expectations</b> with the <b>performance partner</b> and <b>additional partner(s)</b> to make the cycle open to all relevant roles.`,
    addExpectation: 'Add another expectation',
    edit: 'Edit expectation',
    delete: 'Delete expectation',
    empty: 'No expectations',
    deleteLastExpectationWhenOpen:
      'You need to have at least one expectation in\n your open cycle. If you want to change your\n last expectation, you can edit it completely.',
  },
  cycleInformation: {
    title: 'Cycle information',
    twerName: `Twer's name`,
    performancePartner: 'Performance partner',
    additionalPartners: 'Additional partner(s)',
    project: 'Project',
    duration: 'Cycle duration',
    context: 'Additional cycle context',
    editBtn: 'Edit cycle information',
    autoTip: `The <span style="text-decoration: underline">performance partner</span>, <span style="text-decoration: underline">cycle duration</span> are auto populated and are not editable`,
    na: 'N/A',
  },
  cycleStatus: {
    cycleBadge: (cycleStatus: string) => `${cycleStatus} Cycle`,
    cancelCycleText: 'Cancel cycle',
    nonEngagedToolTip:
      'Non-engaged cycle cannot be cancelled, please contact your Talent Business Partner if you want to cancel this cycle.',
  },
  cancelNonEngagedContent: `Once the cycle is canceled, all the expectations, regular check-ins, feedback, self-assessment <b>will be lost</b>. Please notify the Twer and performance partner that you are canceling this performance cycle.`,
  cycleModal: {
    doneCycleCancelEdit: {
      title: 'Are you sure you want to undo changes?',
      content: 'You will lose all unsaved changes and revert the content to its initial version.',
      cancelText: 'Keep editing',
      okText: 'Undo changes',
    },
    cancelEdit: {
      title: 'Cancel editing?',
      content: 'The content you edited will be lost.',
      cancelText: 'No, resume editing',
      okText: 'Yes, cancel editing',
    },
    cancelCycle: {
      title: 'Cancel this cycle?',
      content: `Once the cycle is canceled, all the expectations, regular check-ins, feedback, self-assessment <b>will be lost</b>. Please notify your performance partner that you are canceling this performance cycle.`,
      cancelText: 'No, keep it',
      okText: 'Yes, cancel this cycle',
    },
    doneAddCycle: {
      title: 'Do you want to publish expectations and open the cycle?',
      content:
        'The details of this performance cycle will be shared with your performance partner and other relevant roles. You can continue editing the cycle details after publication.',
      cancelText: "No, I'm not done yet",
      okText: 'Yes, publish expectations and open the cycle',
    },
    nonEngagedDoneAddCycle: {
      title: 'Are you done supporting the Twer on aligning expectations?',
      content: `This performance cycle details will be shared with the performance partner and other relevant roles.`,
      cancelText: "No, I'm not done yet",
      okText: "Yes, I'm done adding and aligning expectations",
    },
    deleteExpectation: {
      title: (isLast: boolean) => (isLast ? 'Delete the last expectation?' : 'Delete expectation?'),
      content: (isLast: boolean) =>
        isLast
          ? `Once you delete the last expectation, you will be <b>back to the expectations alignment stage</b>, and your cycle will not be visible to your performance partner and other relevant roles <b>until you redo the alignment</b>.`
          : 'All the content you added in this expectation will be lost.',
      cancelText: 'No, keep it',
      okText: 'Yes, delete this expectation',
    },
  },
  cycleInformationForm: {
    formTitle: 'Cycle information',
    twerName: {
      label: `Twer's name`,
    },
    performancePartner: {
      label: 'Who is your performance partner',
      nonEngagedLabel: 'Who would be the performance partner?',
      placeholder: `Enter your performance partner's name or ID`,
      notFoundContent: 'No performance partner found',
      requireMessage: '* Please select your performance partner.',
    },
    additionalPartner: {
      label: 'Who would you like to add as additional partner(s)?',
      nonEngagedLabel: 'Who would be the additional partner(s)?',
      placeholder: 'Enter up to 2 additional partners',
      notFoundContent: 'No additional partner found',
      limitNumberMessage: '* Please select up to 2 additional partners.',
    },
    optional: '(optional)',
    project: {
      label: 'Which project does this cycle refer to?',
      nonEngagedLabel: 'Which project does this cycle refer to?',
      placeholder: 'Select the project name',
      noProjectFound: 'No project found',
      requireMessage: '* Please select your project.',
      getProjectFailedMessage: 'get project list failed!',
    },
    duration: {
      label: 'When will you start and finish your cycle?',
      nonEngagedLabel: 'When should this cycle start and end?',
      startMonth: 'Start month',
      endMonth: 'End month',
      requireMessage: '* Please select the cycle duration.',
      validateStartMonth: 'The start time must be from past',
      validateDuration: 'The cycle duration must be less than one year',
    },
    expectation: {
      label: 'Any other things to be aware of when setting your expectations?',
      nonEngagedLabel: 'Any other things to be aware of when setting up expectations?',
      placeholder: 'Enter anything you want to mention here',
    },
    attachment: {
      tooltip: (fileSize: number, accept: string) =>
        `File size should be less than ${fileSize}. Supported file format: ${accept}.`,
      label: 'Add attachment',
      onlyAttachOneFile: 'You can only attach one file.',
      maxSize: (size: number) => `The file size cannot exceed ${size}.`,
      maxNameLength: (length: string) =>
        `Cannot upload the file because the file name length is more than ${length} characters`,
    },
  },
  cycleExpectationForm: {
    formTitle: 'Cycle expectation',
    expectationTitle: {
      title: 'Expectation title',
      placeholder: 'Enter a short summary of this expectation (maximum 130 characters)',
    },
    expectationDetails: {
      title: 'Expectation details',
      placeholder: `You can consider to include:      
      · What is the expectation about? 
      · Actions to be taken to meet this expectation 
      · How do you know if it is done?`,
    },
    expectationCategories: {
      title: 'What performance categories will this expectation impact?',
    },
    cancelBtnText: 'Cancel',
    addBtnText: 'Add expectation to your cycle',
  },
  helperModalMenu: {
    'PERFORMANCE PARTNER INTRO': [
      `<ul><li>The performance partner is someone in a leadership position and/or with higher tenure and/or seniority than you. </li>
      <li>They are responsible to <b>align your expectations</b>, and <b>support your growth</b> and <b>assess your performance</b> considering the aligned expectations and your growth intention. They will have a long term relationship with you.</li>
      <li>You are assigned with one performance partner by your Talent Business Partner(TBP). If you have questions with the assigning, please reach out to your TBP.</li></ul>`,
    ],
    'ADDITIONAL PARTNER INTRO': [
      `<ul><li>Additional partners is someone in a leadership position and/or with <b>higher responsibility in the account that you are staffed</b>.</li>
      <li>They support your performance for the time you are staffed in the account/project, and <b>provide assessment input</b> to your performance partner.</li>
      <li>You can <b>choose up to two people</b> as additional partners. And update additional partners while your project assignment get changed.</li></ul>`,
    ],
    'CYCLE DURATION INTRO': [
      `<b>What is this?</b> The cycle duration refers to the start month and end month of your cycle, you can adjust the cycle duration if needed while the cycle is going`,
      `<b>What is the standard duration?</b> There are at least two cycles a year, and the standard cycles are: Dec/Jan to May/June and June/July to Nov/Dec`,
    ],
    'CYCLE NOTES OR CONTEXT INTRO': [
      `<div><div>You can add other information that may be an important consideration for the performance cycle. Recommended information to add as cycle context:</div>
        <div class = 'point'><ul><li><b>Personal context:</b> Strengths, learning goals, growth aspirations and actions</li><li><b>Project context:</b> Specific considerations like a planned release/ milestone, special focus on performance improvements, capacity to work on tech debt, planned client visits etc.</li><li><b>Grade context:</b> Global/regional expectations for the grade you’re in</li></ul></div></div>`,
    ],
    'EXPECTATION DETAIL INTRO': [
      `<div><div>Your expectations reflect the specific <b>goals you want to achieve</b> within the cycle, the input of your expectations is from:</div>
        <div class = 'point'><ul><li><a href='https://central.thoughtworks.net/home/grade-descriptions' target="_blank" >Grade descriptions </a></li><li>Project & Account / Team & Functional Area Context</li><li>Your growth aspirations</li></ul></div></div>`,
      `<div><div><b>To draft your expectation details</b>, you can consider including:</div>
        <div class = 'point'><ul><li>What is the expectation about</li><li>Actions to be taken to meet this expectation</li><li>How do you know if it is done</li></ul></div></div>`,
    ],
    'PERFORMANCE CATEGORY INTRO': [
      `<b>What is this?</b> The performance categories are the general organizational expectations Thoughtworks has for all TWers. These expectations are expressed in the Thoughtworks Why and are represented in the performance process by the performance categories.`,
      `<b>Why is this valuable?</b> Performance categories align our daily work with Thoughtworks goal and purpose, they provide consistency and transparency for setting expectations and assessing performance.`,
      `<b>How should you fill it?</b> According to the correlation between your expectation and the performance categories, you can attach each of your expectation with one or multiple performance categories to connect your daily work with organizational goals`,
    ],
  },
  formBtnText: {
    cancel: 'Cancel',
    addExpectation: 'Add expectation to your cycle',
    submitInformation: 'Submit cycle information',
    nonEngagedAddExpectation: 'Add expectation to the cycle',
    editExpectationOrInformation: 'Save changes',
    undoModifyDoneCycle: 'Undo Changes',
  },

  performanceCategory: {
    valuesLeadership: {
      title: 'Values & leadership behavior',
      description: `The way we work together to achieve our purpose can be described through our values and leadership behaviours. We all have a part to play in <b>strengthening our culture and uniqueness</b> whilst <b>helping Thoughtworks to evolve</b>. 
      The behaviours which embody our values and shared responsibilities as Thoughtworkers are described in our <a href='https://central.thoughtworks.net/home/code-of-conduct' class='category-description-link' target="_blank"> Code of Conduct.</a></br>
      The leadership behaviours we value most <b>bring our Why to life</b> in everything we do and say. The behaviours that support that are detailed in our <a href='https://docs.google.com/presentation/u/0/d/1vOF77JVF6VEl50MRrgIUFLOQUvksjkBXIwkLTh0QT_4/edit' class='category-description-link' target="_blank">Leadership Expectations.</a>      
      <div class='category-description-end'> See expectation <a href='https://docs.google.com/presentation/d/11jkTL7i-1BFoGsmoU-6yxXr4zbLB-TD2CwZoRxjVqoc/edit#slide=id.g190af50d2f0_0_0' class='category-description-link' target="_blank" >examples</a> 
      of this category.</div>`,
    },
    commercialSuccess: {
      title: 'Commercial success',
      description: `Outcomes related to business impact. Contribute to business success, growth and sustainability. 
      <b>Develop, promote, support and follow practices</b> to ensure the <b>financial health of the business.</b> 
      This includes support and participation in TW business processes such as recruiting, performance cycles, 
      timely timesheets and expenses, following internal policies, etc.
      <div class='category-description-end'> See expectation <a href='https://docs.google.com/presentation/d/11jkTL7i-1BFoGsmoU-6yxXr4zbLB-TD2CwZoRxjVqoc/edit#slide=id.g190af50d2f0_0_9' class='category-description-link' target="_blank" >examples</a> 
      of this category.</div.`,
    },
    awesomePartner: {
      title: 'Awesome partner',
      description: `Outcomes related to <b>client impact,</b> both internal and external clients. Deliver constant 
      value and build strong engagement and relationships with clients.
      <div class='category-description-end'> See expectation <a href='https://docs.google.com/presentation/d/11jkTL7i-1BFoGsmoU-6yxXr4zbLB-TD2CwZoRxjVqoc/edit#slide=id.g190af50d2f0_0_17' class='category-description-link' target="_blank" >examples</a> 
      of this category.</div.`,
    },
    revolutionizeTech: {
      title: 'Revolutionize tech',
      description: `Outcomes about craft and technical impact. <b>Breadth and depth of expected technical 
      contributions and development.</b> In operational roles tech and craft is related to a specific area of 
      expertise (e.g. finance, recruiting, etc). Technical influence and impact in projects, user experience, 
      teams, functional areas, internal and external communities of practice. Develop, implement, support 
      and follow best practices in your area of expertise. Pursuit of excellence and innovation.
      <div class='category-description-end'> See expectation <a href='https://docs.google.com/presentation/d/11jkTL7i-1BFoGsmoU-6yxXr4zbLB-TD2CwZoRxjVqoc/edit#slide=id.g190af50d2f0_0_25' class='category-description-link' target="_blank" >examples</a> 
      of this category.</div.`,
    },
    socialChange: {
      title: 'Social change',
      description: `Outcomes regarding DEISSC impact. Behaviors, contributions and impact related to <b>social 
      change and sustainability, diversity, equity and inclusion.</b> Developing, promoting, supporting and 
      following DEISSC practices. Engagement in developing your personal journey. Influence and impact in 
      projects, internal and external communities and also drive for the responsible use of technology.
      <div class='category-description-end'> See expectation <a href='https://docs.google.com/presentation/d/11jkTL7i-1BFoGsmoU-6yxXr4zbLB-TD2CwZoRxjVqoc/edit#slide=id.g190af50d2f0_0_33' class='category-description-link' target="_blank" >examples</a> 
      of this category.</div.`,
    },
    diverseVibrantCommunity: {
      title: 'Diverse, vibrant community',
      description: `Cultivation, people and community impact. Outcomes related to fostering a vibrant 
      and inclusive community of diverse and passionate technologists. Expectations related to <b>cultivation 
      behaviors, supporting others growth and community engagement.</b> Develop, promote, support and follow 
      cultivation practices (pairing, sharing knowledge, sponsoring, etc). Support the growth of others. 
      Contribute, engage and promote the broader Thoughtworks community (internally and externally).
      <div class='category-description-end'> See expectation <a href='https://docs.google.com/presentation/d/11jkTL7i-1BFoGsmoU-6yxXr4zbLB-TD2CwZoRxjVqoc/edit#slide=id.g190af50d2f0_0_40' class='category-description-link' target="_blank" >examples</a> 
      of this category.</div.`,
    },
  },
  addAnotherExpectationModal: {
    addContent: 'Add another expectation',
    doneContent: `Publish expectations and open my cycle`,
    supportDoneContent: `I'm done supporting expectation alignment`,
  },
  helpButton: {
    showHelp: 'Show help',
    hideHelp: 'Hide help',
  },
  addFirstExpectationModal: {
    title: 'Cycle expectations',
    content: `Starting with this performance cycle, you can create \n <b>individual, separate expectations</b> \n that will make up your overall performance cycle expectations \n <i>Useful links: </i><a rel="noopener noreferrer" target="_blank" href="https://central.thoughtworks.net/home/grade-descriptions" 
    style="text-decoration: underline;font-style:italic">Grade descriptions</a> , <a rel="noopener noreferrer"
    target="_blank" href="https://campus.thoughtworks.net/learn/courses/47635/performance-at-thoughtworks/lessons/71898:380/aligning-expectation-eng?hash=5d956b991b2e3a2efd25d47adcb37b61d728fa3f&generated_by=13883" 
    style="text-decoration: underline;font-style:italic">Performance Categories in expectations</a>`,
    nonEngagedContent: `Starting with this performance cycle, you can support on creating \n <b>individual, separate expectations</b> \n that will make up overall performance cycle expectations for this Twer `,
    btnContext: 'Add your first performance expectation',
    nonEngagedBtnContext: 'Add the first performance expectation',
  },
  backToNonEngagedList: 'Back to non-engaged Twer list',
  backToNonEngagedCycleList: 'Back to non-engaged Cycle list',
  feedbackType: {
    internal: 'This is requested feedback',
    client: 'This is offline feedback',
  },
  feedbackPreview: {
    title: {
      name: 'Name',
      role: 'Role',
      project: 'Project',
    },
    noInput: `No input`,
    content: 'Content',
    context: 'Context',
    expectationLabel: 'Relevant cycle expectations',
  },
  overdueWarning: {
    currentCycleOverdueTipsBeginning: 'This cycle',
    historyCycleOverdueTipsBeginning: (href: string) =>
      `Your previous <a class='overdue-cycle-detail' href="${href}" style="text-decoration: underline">cycle</a> `,
    firstParagraph: `
     is overdue now because the assessment wasn't submitted on time. Please assist your performance partner
     <b>in submitting the assessment before
    `,
    secondParagraph: `.</b> Not doing so,<b> will result in non-engagement,</b> then get  <b>no performance indicator</b> in performance as per guideline. Need support? Reach out to your 
    <a href="https://docs.google.com/spreadsheets/d/17hGNpK_Reb8Id0TslzM2jWwpBlUIi_DTRzOtEcr8J4M/edit?usp=sharing" style="text-decoration: underline" target="_blank">Talent Business Partner</a> as soon as possible.`,
  },
  assessmentNonEngagedNotification: {
    title: 'Your previous cycle is done with non-engaged',
    firstContent: (
      cycleDetailUrl: string,
    ) => `Your previous <a href="${cycleDetailUrl}">cycle</a> is done, considered non-engaged and assessed as <b>No performance indicator</b>. If you have <b>legitimate reasons</b> of delaying the assessment submission, please reach out to your
               <a href="https://docs.google.com/spreadsheets/d/17hGNpK_Reb8Id0TslzM2jWwpBlUIi_DTRzOtEcr8J4M/edit?usp=sharing" target="_blank">Talent Business Partner</a> as soon as possible. You have until <b>`,
    secondContent: ` </b> to request to review your performance cycle situation.`,
  },
  assessmentNonEngagedNotificationAsPp: {
    title: 'Some cycles are done with assessment non-engaged',
    firstContent: `Some cycles that your supported is done, but considered <b>non-engaged</b> and assessed as No performance indicator. The <b>impact on your own performance</b> will be considered accordingly. If you have 
              <b>legitimate reasons</b> of delaying the assessment submission, please reach out to your <a href="https://docs.google.com/spreadsheets/d/17hGNpK_Reb8Id0TslzM2jWwpBlUIi_DTRzOtEcr8J4M/edit?usp=sharing" target="_blank">Talent Business Partner</a> as soon as possible. You have until <b>`,
    secondContent: ` </b> to request to review situations of those affected cycles.`,
  },
};
