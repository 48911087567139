import { get, isEmpty } from 'lodash';
import React, { memo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import ReviewDetailPreview from '@@/common/component/ReviewDetailPreview';
import ReviewInputTab from '@@/common/component/ReviewInputTab';
import '../../../reviewees/detail/index.less';
import { getPerformancePath } from '@@/common/utils';
import { dashboard } from '@@/redux/slice/dashboardSlice';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { useSelector } from 'react-redux';
import CycleDetailByIdLayout from '@@/features/performance/reviews/cycleDetailByIdLayout';
import { GET_CYCLE_DETAIL_API_PATH } from '@@/common/constant/review';
import GoBackBanner from '@@/_new_src_/features/GoBackBanner';

const NonEngagedCycleDetail = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { dashboardCycleDetail } = useSelector(dashboard);
  const {
    currentCycleDetail: { cycleDetailLoading, additionalPartnerAssessments = [], twer },
  } = useSelector(cycleDetailByVersion);

  const renderReviewInputTabContent = useCallback(() => {
    if (!isEmpty(additionalPartnerAssessments)) {
      return <ReviewInputTab review={dashboardCycleDetail.data} />;
    }
    return null;
  }, [additionalPartnerAssessments, dashboardCycleDetail.data]);

  const onBackToList = () => {
    history.push({
      pathname: getPerformancePath('/nonEngagedCycles'),
      state: { isFromNonEngagedCycleDetail: state?.isFromNonEngagedCycleDetail },
    });
  };

  return (
    <CycleDetailByIdLayout v1ApiPath={GET_CYCLE_DETAIL_API_PATH.isPerformanceReviews}>
      <GoBackBanner
        title={get(twer, 'name', '')}
        goToBackFun={onBackToList}
        onKeyPress={onBackToList}
      />
      <div className="reviewee-detail-content">
        <LoadingWrapper loading={cycleDetailLoading}>
          <ReviewDetailPreview
            defaultExpand
            showFeedbackTabThoughNoSubmittedFeedback={false}
            reviewInputTab={renderReviewInputTabContent()}
            onCancelReviewSuccess={onBackToList}
            operation={{
              ratingSummaryEditButton: false,
              requestEditButton: false,
              addNotesButton: false,
              editNotesButton: false,
              notesRecordTips: false,
            }}
          />
        </LoadingWrapper>
      </div>
    </CycleDetailByIdLayout>
  );
};

export default memo(NonEngagedCycleDetail);
