import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import './index.less';
import { UserInfoContext } from '@@/context/userInfo.context';
import ExpectationLink from '@@/common/component/ExpectationLink';
import useLocalStorageReview from '@@/hooks/useLocalStorageReview';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { reviewState } from '@@/redux/slice/reviewSlice';
import * as Api from '@@/api/review';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';

const StartReviewRemind = () => {
  const userInfo = useContext(UserInfoContext);
  const {
    myReviews: { startReviewRemind },
  } = useSelector(selectLocaleResource);
  const { removeLocalReview } = useLocalStorageReview();
  const [noReviewDays, setNoReviewDays] = useState(null);

  const { cyclesCycleDetailV1: { list: currentReviewList } = {} } = useSelector(reviewState);

  const haveOpenReview = useMemo(() => {
    if (currentReviewList && currentReviewList.length > 0) {
      return currentReviewList?.some(({ status }) => getCycleStatus(status).isOpenCycle);
    }
    return false;
  }, [currentReviewList]);

  const getNoReviewDays = useCallback(async () => {
    if (!haveOpenReview) {
      const { data } = await Api.getNoReviewDays();
      setNoReviewDays(data.noReviewDays);
    }
  }, [haveOpenReview]);

  useEffect(() => {
    getNoReviewDays().then();
  }, [getNoReviewDays]);

  const remindText = useMemo(() => {
    if (noReviewDays > 0 && noReviewDays <= 90) {
      return `${noReviewDays} day${noReviewDays === 1 ? '' : 's'}`;
    }
    if (noReviewDays > 90) {
      return 'more than 3 months';
    }
    return '';
  }, [noReviewDays]);

  return (
    <>
      {userInfo.futureCycleVersion === CYCLE_VERSION.OLD && !haveOpenReview && (
        <div className="start-review-remind">
          <div className="remind-content">
            <div className="desc">
              <span className="name">{`${userInfo.name}, `}</span>
              <span>
                {noReviewDays === 0
                  ? startReviewRemind.remindDefaultText
                  : startReviewRemind.remindContent(remindText)}
              </span>
            </div>
            <div className="tips">
              {startReviewRemind.tipCombination[0]}
              <ExpectationLink linkText={startReviewRemind.tipCombination[1]} />.
            </div>
          </div>
          <Link to={CYCLE_PAGE_LINK.OLD_CYCLES_FORM}>
            <Button type="primary" onClick={() => removeLocalReview()}>
              {startReviewRemind.button}
              <i className="ri-arrow-right-line"></i>
            </Button>
          </Link>
        </div>
      )}
    </>
  );
};
export default memo(StartReviewRemind);
