import React, { useEffect, useState } from 'react';
import { Collapse, Tooltip } from 'antd';
import ExpandIcon from '@@/assets/images/expand.svg';
import HideIcon from '@@/assets/images/hide.svg';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';
import locale from '@@/_new_src_/local/feedback/en_US';
import { useDispatch, useSelector } from 'react-redux';
import {
  pastCyclesPageStore,
  pastCycleStore,
  setPastCyclesPageSelectedCycle,
} from '@@/_new_src_/store/myCyclesSlice';
import './index.less';
import PastFeedbackList from '@@/_new_src_/page/feedback/component/PastFeedbackList';
import {
  FEEDBACK_SORT_COLUMNS_NAME,
  RequestFeedbackListOptions,
} from '@@/_new_src_/constants/feedback';
import { V2FeedbackStore, setRequestFeedbacks } from '@@/_new_src_/store/V2FeedbackSlice';
import { getCurrentCycleFeedbackListAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import EmptyRequestIcon from '@@/assets/images/empty-request.svg';
import { getNewDateFormatByProbation } from '@@/_new_src_/utils/common/date';
import { SORT_DIRECTION_FOR_BE } from '@@/_new_src_/constants/table';
import { getFeedbackStatus } from '@@/_new_src_/utils/feature';

const PastCyclesContainer = (props: { isReviewHistoryYearEmpty: boolean }) => {
  const dispatch = useDispatch();

  const {
    pastCycles: {
      cycleDuration,
      project,
      performancePartner,
      emptyPastCycle: { content: emptyPastCycleContent },
    },
  } = locale;

  const { pastCycles: cycleHistory } = useSelector(pastCycleStore);
  const { selectedCycle } = useSelector(pastCyclesPageStore);
  const {
    requestFeedbacks,
    requestFeedbacks: { success: getPastFeedbackSuccess },
    pastFeedbacks,
  } = useSelector(V2FeedbackStore);

  const [panelKey, setPanelKey] = useState(selectedCycle);

  const handlerPanelChange = (key: string | string[]) => {
    if (!key || isEmpty(key)) {
      dispatch(setPastCyclesPageSelectedCycle(key));
      return;
    }
    setPanelKey(key[0]);
    if (pastFeedbacks[key[0]]) {
      dispatch(setPastCyclesPageSelectedCycle(key));

      const currentDoneFeedbackIds = [] as number[];
      pastFeedbacks[key[0]].forEach(item => {
        const { isSubmittedFeedback } = getFeedbackStatus(item.status);
        if (isSubmittedFeedback) {
          currentDoneFeedbackIds.push(item.feedbackId);
        }
      });

      dispatch(
        setRequestFeedbacks({
          ...requestFeedbacks,
          doneFeedbackIds: currentDoneFeedbackIds,
        }),
      );
    } else {
      dispatch(
        getCurrentCycleFeedbackListAsync({
          type: RequestFeedbackListOptions[1].value,
          cycleId: parseInt(key[0]),
          sortColumnName: FEEDBACK_SORT_COLUMNS_NAME.default,
          sortOrder: SORT_DIRECTION_FOR_BE.ascend,
          pageSize: 100000,
          pageNumber: 1,
        }),
      );
      dispatch(setPastCyclesPageSelectedCycle(''));
    }
  };

  const renderEmptyPastReview = () => (
    <div className="empty-review-container">
      <img src={EmptyRequestIcon} className="empty-icon" alt="" />
      <div className="empty-past-review-content">{emptyPastCycleContent}</div>
    </div>
  );

  useEffect(() => {
    getPastFeedbackSuccess && dispatch(setPastCyclesPageSelectedCycle(panelKey));
  }, [getPastFeedbackSuccess, panelKey]);

  return (
    <>
      {props.isReviewHistoryYearEmpty ? (
        renderEmptyPastReview()
      ) : (
        <div className={'past-cycles-container'}>
          <Collapse
            accordion
            expandIcon={({ isActive }) =>
              isActive ? <img src={ExpandIcon} alt="expand" /> : <img src={HideIcon} alt="hide" />
            }
            className="past-reviews"
            activeKey={selectedCycle}
            onChange={handlerPanelChange}
          >
            {cycleHistory.map(pastCycle => {
              return (
                <Collapse.Panel
                  header={
                    <div className="collapse-header">
                      <div className="past-review-duration">
                        <b>{cycleDuration}</b>
                        <span>
                          {dayjs(get(pastCycle, 'duration.startTime')).format(
                            getNewDateFormatByProbation(pastCycle.isProbation),
                          )}
                          &nbsp;-&nbsp;
                          {dayjs(get(pastCycle, 'duration.endTime')).format(
                            getNewDateFormatByProbation(pastCycle.isProbation),
                          )}
                        </span>
                      </div>
                      <div className="past-review-project">
                        <b>{project} </b>
                        <Tooltip title={pastCycle.projectName}>
                          <span>{pastCycle.projectName}</span>
                        </Tooltip>
                      </div>
                      <div className="past-review-reviewer">
                        <b>{performancePartner} </b>
                        <Tooltip title={pastCycle.performancePartner}>
                          <span>{pastCycle.performancePartner}</span>
                        </Tooltip>
                      </div>
                    </div>
                  }
                  key={pastCycle.id}
                >
                  {(selectedCycle === pastCycle.id.toString() ||
                    selectedCycle[0] === pastCycle.id.toString()) && (
                    <PastFeedbackList cycleId={pastCycle.id.toString()} />
                  )}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </div>
      )}
    </>
  );
};

export default PastCyclesContainer;
