import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import '../../../reviewees/detail/index.less';
import { CYCLES, REQUESTS, STATS } from '../../contants';
import DashboardHeaderGroupSelect from '../DashboardHeaderGroupSelect';
import DashboardHeaderMenu from '../DashboardHeaderMenu';
import DashboardHeaderRoleSelect from '../DashboardHeaderRoleSelect';
import './index.less';

const DashboardHeader = () => {
  const location = useLocation();

  const checkCurrentRoutePage = () => {
    const pathnameElements = location.pathname.split('/');
    const { length } = pathnameElements;

    const isStatsDetailRoute = pathnameElements[length - 2] === STATS;
    const isCyclesDetailRoute = pathnameElements[length - 2] === CYCLES;
    const isRequestDetailRoute = pathnameElements[length - 3] === REQUESTS;

    return isStatsDetailRoute || isCyclesDetailRoute || isRequestDetailRoute;
  };
  const isCycleOrRequestDetailPage = checkCurrentRoutePage();

  return isCycleOrRequestDetailPage ? null : (
    <div className={'dashboard-header-container'}>
      <div className="dashboard-header">
        <div className="role-and-group">
          <DashboardHeaderRoleSelect />
          <DashboardHeaderGroupSelect />
        </div>
        <DashboardHeaderMenu />
      </div>
    </div>
  );
};

export default memo(DashboardHeader);
