import AuthV2Guard from '@@/common/component/AuthGuardV2';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import {
  clearGroupSelection as clearGroupSelectionAction,
  clearRoleSelection as clearRoleSelectionAction,
  getChildrenLoading,
  getCurrentGroupSelection,
  getCurrentRoleSelection,
  getLoading,
  setDisplayGroupSelect as setDisplayGroupSelectAction,
} from '@@/redux/slice/dashboardSlice';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { get } from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DashboardHeader from './DashboardHeader';
import './index.less';
import { setCurrentCycleDetail as setCurrentCycleDetailAction } from '@@/redux/slice/cycleDetailByVersionSlice';
import { dashboardAccessibleRoles } from '@@/_new_src_/constants/userEnum';

@AuthV2Guard(dashboardAccessibleRoles)
@withRouter
class DashboardPage extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { setCurrentCycleDetail } = this.props;
    setCurrentCycleDetail({});
  }

  componentWillUnmount() {
    const { clearRoleSelection, clearGroupSelection, setDisplayGroupSelect } = this.props;
    clearRoleSelection();
    clearGroupSelection();
    setDisplayGroupSelect(false);
  }

  isShowMainCont = () => {
    const { currentRoleSelection, currentGroupSelection } = this.props;
    let isShowMainContent = currentRoleSelection.roleId && currentGroupSelection.groupId;
    const detailPage = [
      '/performance/v2/dashboard/stats/',
      '/performance/v2/dashboard/cycles/',
      '/performance/v2/dashboard/requests/',
    ];

    let paths = location.pathname.split('/');
    let isLastNumber = +paths.pop() > 0;

    let isDetailPage =
      detailPage.filter(detailPath => location.pathname.includes(detailPath)) && isLastNumber;
    return isDetailPage ? true : isShowMainContent;
  };

  render() {
    const { loading, childrenLoading, children, localeResource } = this.props;
    let isShowMainContent = this.isShowMainCont();
    return (
      <LoadingWrapper loading={loading}>
        <div className="dashboard-page">
          <DashboardHeader />
          <div className="dashboard-root-container">
            {isShowMainContent ? (
              <LoadingWrapper
                className={'dashboard-root-container-loading'}
                loading={childrenLoading}
              >
                {children}
              </LoadingWrapper>
            ) : (
              <div className="choose-group-tips">
                {get(localeResource, 'dashboard.stats.noGroups')}
              </div>
            )}
          </div>
        </div>
      </LoadingWrapper>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setDisplayGroupSelect: value => dispatch(setDisplayGroupSelectAction(value)),
    clearRoleSelection: value => dispatch(clearRoleSelectionAction(value)),
    clearGroupSelection: value => dispatch(clearGroupSelectionAction(value)),
    setCurrentCycleDetail: value => dispatch(setCurrentCycleDetailAction(value)),
  };
};
const mapStateToProps = state => {
  return {
    localeResource: selectLocaleResource(state),
    loading: getLoading(state),
    childrenLoading: getChildrenLoading(state),
    currentRoleSelection: getCurrentRoleSelection(state),
    currentGroupSelection: getCurrentGroupSelection(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
