import { getUserList } from '@@/api/user';
import AutoComplete from '@@/common/component/AutoComplete';
import DatePicker from '@@/common/component/DatePicker';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import PerfModal from '@@/common/component/PerfModal';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { SEND_REQUEST } from '@@/common/constant/qualaroo';
import { FEEDBACK_MAX_LENGTH } from '@@/common/constant/review';
import { trigSurvey } from '@@/common/qualarooTrigger';
import { UserInfoContext } from '@@/context/userInfo.context';
import {
  selectDueDate,
  selectNameList,
  setDueDate,
  setNameList,
} from '@@/redux/slice/feedbackSlice';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { Form } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isEqual } from 'lodash';
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import { FEEDBACK_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  feedbackDetail,
  requestFeedbacks,
  setFeedbackDetail,
  setV1FeedbackModalInfo,
  V1FeedbackModalInfo,
} from '@@/_new_src_/store/V2FeedbackSlice';
import { FEEDBACK_TYPE } from '@@/_new_src_/constants/feedback';
import {
  getCurrentCycleFeedbackListAsync,
  getFeedbackDetailAsync,
  postV1SendFeedbackRequestAsync,
  putV1EditFeedbackRequestAsync,
} from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import { cycleDetailData } from '@@/_new_src_/store/myCyclesSlice';
import {
  formatRangeDuration,
  getDateFormatByProbation,
  getUTCTimestamp,
} from '@@/_new_src_/utils/common/date';

dayjs.extend(utc);

const FeedbackRequestModal = () => {
  const { trackEvent } = useMatomo();
  const dispatch = useDispatch();

  const nameList = useSelector(selectNameList);
  const dueDate = useSelector(selectDueDate);
  const [feedbackInitialValue, setFeedbackInitialValue] = useState(null);
  const [feedbackHtmlInfo, setFeedbackHtml] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');

  const [form] = Form.useForm();
  const userInfo = useContext(UserInfoContext);
  const {
    feedback: { feedbackRequestModal: locale },
  } = useSelector(selectLocaleResource);

  const {
    isEdit,
    isFeedbackRequestModalVisible: visible,
    feedbackId,
    cycleId,
    type,
    loading,
    success,
  } = useSelector(V1FeedbackModalInfo);

  const { tableConfig } = useSelector(requestFeedbacks);

  const {
    loading: isGetFeedbackDetailLoading,
    cycle: { projectName, duration, probation },
    feedback: { providerName, context, dueDate: feedbackDueDate, providerId },
  } = useSelector(feedbackDetail);

  const {
    data: { project, duration: cycleDuration, isProbation },
  } = useSelector(cycleDetailData);

  useEffect(() => {
    visible && isEdit && feedbackId && dispatch(getFeedbackDetailAsync(feedbackId));
  }, [visible, isEdit, feedbackId]);

  const resetModal = () => {
    dispatch(setV1FeedbackModalInfo({}));
    dispatch(setFeedbackDetail({}));
  };

  useEffect(() => {
    return () => {
      resetModal();
    };
  }, []);

  const currentModalInfo = useMemo(() => {
    return {
      cycleInfo: {
        projectName: isEdit ? projectName : project?.name,
        duration: isEdit ? duration : cycleDuration,
        probation: isEdit ? probation : isProbation,
      },
      title: isEdit ? locale.editTitle : locale.title,
      okText: isEdit ? locale.editText : locale.okText,
      successMessage: isEdit ? locale.editMessage : locale.successMessage,
    };
  }, [isEdit, projectName, project]);

  const needConfirm = useMemo(() => {
    const isFormDataChanged =
      feedbackHtmlInfo !== context ||
      !isEqual(dayjs.utc(dueDate).unix(), dayjs.utc(feedbackDueDate).unix());
    return isFormDataChanged;
  }, [feedbackHtmlInfo, dueDate, context, feedbackDueDate]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (isEdit) {
      if (isEmpty(dueDate)) {
        dispatch(setDueDate(dayjs.utc(feedbackDueDate).unix()));
        form.setFieldsValue({ date: dayjs.utc(feedbackDueDate) });
      } else {
        form.setFieldsValue({ date: dayjs.utc(dueDate) });
      }
      if (isEmpty(feedbackHtmlInfo)) {
        setFeedbackInitialValue(context);
        setFeedbackHtml(context);
      }
      if (!nameList) {
        setNameList(providerName);
      }
      form.setFieldsValue({
        name: [
          {
            key: providerId,
            label: providerName,
            value: providerId,
          },
        ],
      });
    } else {
      form.setFieldsValue({
        name: nameList,
        date: dueDate,
      });
    }
  }, [visible, providerId]);

  const onReset = useCallback(() => {
    form.resetFields();
    dispatch(setDueDate());
    dispatch(setNameList());
    setFeedbackInitialValue(null);
    setFeedbackHtml(null);
    resetModal();
  }, [dispatch, form]);

  const handleSend = () => {
    const values = form.getFieldsValue();
    const requestToIds = values.name.map(item => item.value);

    let currentParams = {
      type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
      dueTime: getUTCTimestamp(dayjs(values.date).endOf('days')),
      context: feedbackHtmlInfo,
    };

    trackEvent({
      category: FEEDBACK_API.category,
      action: isEdit
        ? FEEDBACK_API.action.TWER_EDITS_REQUEST_FEEDBACK
        : FEEDBACK_API.action.TWER_SENDS_REQUEST_FEEDBACK,
    });

    if (isEdit) {
      const editFeedback = {
        ...currentParams,
        feedbackId: feedbackId,
      };
      dispatch(putV1EditFeedbackRequestAsync(editFeedback));
    } else {
      const addFeedback = {
        ...currentParams,
        reviewId: cycleId,
        requestToIds: requestToIds,
      };
      dispatch(postV1SendFeedbackRequestAsync(addFeedback));
      trigSurvey(SEND_REQUEST);
    }
  };

  useEffect(() => {
    const isUpdateClientSuccess = type === FEEDBACK_TYPE.INTERNAL_FEEDBACK && success;
    if (isUpdateClientSuccess) {
      perfMessage.success(currentModalInfo.successMessage);
      dispatch(
        getCurrentCycleFeedbackListAsync({
          cycleId: cycleId,
          ...tableConfig,
        }),
      );
      onReset();
    }
  }, [type, success]);

  const getNeedConfirm = useCallback(() => {
    if (isEdit) {
      return needConfirm;
    }
    return !(isEmpty(nameList) && feedbackHtmlInfo === null && !dueDate);
  }, [dueDate, isEdit, nameList, needConfirm, feedbackHtmlInfo]);

  const getSubmitDisabled = useCallback(() => {
    if (!visible) {
      return;
    }
    if (feedbackText.length > FEEDBACK_MAX_LENGTH) return true;
    const formField = form && form.getFieldValue();

    return isEmpty(formField['name']) || isEmpty(formField['date']);
  }, [visible, form, feedbackText]);

  const renderNameOptions = useCallback(
    (list, Option) => {
      return list.map(({ userId, name }) => {
        const isSelf = userInfo.userId === userId;
        return (
          <Option key={String(userId)} label={name} value={userId} disabled={isSelf}>
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [userInfo.userId],
  );

  return (
    <>
      {!isGetFeedbackDetailLoading && visible && (
        <PerfModal
          title={currentModalInfo.title}
          okText={currentModalInfo.okText}
          cancelText={locale.cancelText}
          destroyOnClose
          centered
          className="feedback-request-modal"
          onOk={handleSend}
          afterClose={onReset}
          okButtonProps={{
            loading,
            disabled: getSubmitDisabled(),
          }}
          maskClosable={false}
          needConfirm={getNeedConfirm()}
          onCancel={onReset}
          visible={visible}
        >
          <div className="project-info">
            {`${locale.project} ${currentModalInfo.cycleInfo.projectName}, ${
              locale.duration
            } ${formatRangeDuration(
              currentModalInfo.cycleInfo.duration?.startTime,
              currentModalInfo.cycleInfo.duration?.endTime,
              {
                format: getDateFormatByProbation(currentModalInfo.cycleInfo.probation),
              },
            )}`}
          </div>
          {visible && (
            <Form form={form} colon={false} className="feedback-request-form" layout="vertical">
              <div className="name-date-section">
                <Form.Item
                  className="required-label name-item"
                  name="name"
                  label={locale.nameLabel}
                  placeholder={locale.placeholder}
                  rules={[
                    {
                      required: true,
                      message: locale.requiredNameMessage,
                    },
                  ]}
                >
                  <AutoComplete
                    mode="multiple"
                    notFoundContent={locale.nameNotFound}
                    fetchApi={getUserList}
                    placeholder={locale.placeholder}
                    disabled={isEdit}
                    onChange={value => dispatch(setNameList(value))}
                  >
                    {renderNameOptions}
                  </AutoComplete>
                </Form.Item>
                <Form.Item
                  className="required-label date-item"
                  name="date"
                  label={locale.dateLabel}
                  rules={[
                    {
                      required: true,
                      message: locale.requiredDateMessage,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    disabledDate={current => current < dayjs().startOf('day').add(1, 'd')}
                    showToday={false}
                    onChange={value => dispatch(setDueDate(value ? dayjs(value).unix() : null))}
                  />
                </Form.Item>
              </div>
              <Form.Item name="context" label={locale.contextLabel}>
                <TinyMceEditor
                  id={
                    isEdit ? 'feedbackRequestEdit-' + feedbackId : 'feedbackRequestNew-' + cycleId
                  }
                  initialValue={feedbackInitialValue}
                  updateTextInfo={setFeedbackText}
                  updateHtmlInfo={setFeedbackHtml}
                  maxLength={FEEDBACK_MAX_LENGTH}
                  placeholder={locale.contextPlaceholder}
                  height={206}
                />
              </Form.Item>
            </Form>
          )}
        </PerfModal>
      )}
    </>
  );
};

export default memo(FeedbackRequestModal);
