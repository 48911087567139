import React, { useEffect } from 'react';
import './index.less';
import dayjs from 'dayjs';
import locale from '@@/_new_src_/local/feedback/en_US';
import CycleSelector from '@@/_new_src_/page/feedback/component/CycleSelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  V2FeedbackStore,
  setClientFeedback,
  setInternalFeedback,
  setV1FeedbackModalInfo,
} from '@@/_new_src_/store/V2FeedbackSlice';
import { Table, TableProps, Tooltip } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';

import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  SORT_DIRECTION_FOR_BE,
  SORT_DIRECTION_FOR_FE,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import { IFeedback } from '@@/_new_src_/store/V2FeedbackSlice/interface';
import {
  FEEDBACK_TYPE,
  FEEDBACK_VERSION,
  FEEDBACK_SORT_COLUMNS_NAME,
  FeedbackStatusColorMap,
  FeedbackStatusLabel,
  isCurrentCyclesPage,
  isPastCyclesPage,
  RequestFeedbackListOptions,
} from '@@/_new_src_/constants/feedback';
import { getCurrentCycleFeedbackListAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import { isEmpty } from 'lodash';
import MoreFeedbackButton from '../MoreFeedbackButton';
import { setConfirmModalStatus } from '@@/_new_src_/store/commonSlice';
import { useHistory } from 'react-router';
import PastCyclesContainer from '@@/_new_src_/page/feedback/component/PastCyclesContainer';
import YearSelector from '@@/_new_src_/page/feedback/component/YearSelector';
import { pastCyclesPageStore, pastYearStore } from '@@/_new_src_/store/myCyclesSlice';
import { getInputInSelectDom, updateAriaForSelect } from '@@/_new_src_/utils/common/axe';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { onEnter } from '@@/_new_src_/utils';

interface IFeedbackList {
  cycleId: number;
}

const RequestList = (props: IFeedbackList) => {
  const { cycleId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    feedback: {
      requestedFeedbackColumnName: {
        providerName,
        providerRole,
        requestedDate,
        dueDate,
        completionDate,
        status,
        actions,
      },
      actions: { edit, cancel, view },
    },
  } = locale;

  const {
    internalFeedback: internalFeedbackStore,
    clientFeedback: clientFeedbackStore,
    requestFeedbacks: {
      loading: isGetRequestListLoading,
      content: feedbackList,
      total: feedbackTotalCount,
      tableConfig,
    },
  } = useSelector(V2FeedbackStore);
  const { cycleFilterValue } = useSelector(pastCyclesPageStore);

  const sortDirection = SORT_DIRECTION_FOR_FE[tableConfig.sortOrder];

  const {
    pastYear: { reviewHistoryYear },
  } = useSelector(pastYearStore);
  const pastCycles = () => {
    return cycleFilterValue === RequestFeedbackListOptions[1].value;
  };

  const goToClientFeedbackPage = (id: number, version: number) => {
    if (version === FEEDBACK_VERSION.V2) {
      const clientLink = FEEDBACK_PAGE_LINK.CLIENT + id.toString();
      history.push(clientLink);
    } else {
      dispatch(
        setV1FeedbackModalInfo({
          isClientFeedbackModalVisible: true,
          feedbackId: id,
          isEdit: true,
          cycleId: cycleId,
        }),
      );
    }
  };

  const onCancelModal = (feedback: IFeedback) => {
    if (feedback.type === FEEDBACK_TYPE.CLIENT_FEEDBACK) {
      dispatch(setConfirmModalStatus(true));
      dispatch(
        setClientFeedback({
          ...clientFeedbackStore,
          cancelingFeedbackId: feedback.feedbackId,
        }),
      );
    }
  };

  const handleInternalFeedbackCancel = (feedback: IFeedback) => {
    if (feedback.type === FEEDBACK_TYPE.INTERNAL_FEEDBACK) {
      dispatch(setConfirmModalStatus(true));
      dispatch(
        setInternalFeedback({
          ...internalFeedbackStore,
          status: feedback.status,
          cancelingFeedbackId: feedback.feedbackId,
        }),
      );
    }
  };
  const handleInternalFeedbackEdit = (feedback: IFeedback) => {
    if (feedback.type === FEEDBACK_TYPE.INTERNAL_FEEDBACK) {
      if (feedback.version === FEEDBACK_VERSION.V2) {
        dispatch(
          setInternalFeedback({
            ...internalFeedbackStore,
            status: feedback.status,
          }),
        );
        history.push(`/performance/feedback/creation/${feedback.feedbackId}`);
      } else {
        dispatch(
          setV1FeedbackModalInfo({
            isFeedbackRequestModalVisible: true,
            feedbackId: feedback.feedbackId,
            isEdit: true,
            cycleId: cycleId,
          }),
        );
      }
    }
  };
  const goToDetailPage = (id: number, version: number) => {
    if (version === FEEDBACK_VERSION.V2) {
      const detailLink = FEEDBACK_PAGE_LINK.DETAIL + id.toString();
      history.push(detailLink);
    } else {
      dispatch(
        setV1FeedbackModalInfo({
          isSubmittedFeedbackDetailModalVisible: true,
          feedbackId: id,
          isCurrentCycle: true,
          cycleId: cycleId,
        }),
      );
    }
  };

  const requestFeedbackListColumn = [
    {
      title: providerName,
      dataIndex: 'providerName',
      key: 'providerName',
      defaultSortOrder:
        tableConfig.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.providerName ? sortDirection : '',
      ellipsis: {
        showTitle: false,
      },
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: providerRole,
      dataIndex: 'providerRole',
      key: 'providerRole',
      defaultSortOrder:
        tableConfig.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.providerRole ? sortDirection : '',
      ellipsis: {
        showTitle: false,
      },
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: requestedDate,
      dataIndex: 'requestDate',
      key: 'requestDate',
      defaultSortOrder:
        tableConfig.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.requestDate ? sortDirection : '',
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (requestDate: string) => dayjs(requestDate).format('YYYY-MM-DD'),
    },
    {
      title: dueDate,
      dataIndex: 'dueDate',
      key: 'dueDate',
      defaultSortOrder:
        tableConfig.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.dueDate ? sortDirection : '',
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (dueDate: string) => dayjs.utc(dueDate).format('YYYY-MM-DD'),
    },
    {
      title: completionDate,
      dataIndex: 'completionDate',
      key: 'completionDate',
      defaultSortOrder:
        tableConfig.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.completionDate
          ? sortDirection
          : '',
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (completionDate: string) =>
        isEmpty(completionDate) ? '' : dayjs.utc(completionDate).format('YYYY-MM-DD'),
    },
    {
      title: status,
      dataIndex: 'status',
      key: 'status',
      defaultSortOrder:
        tableConfig.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.status ? sortDirection : '',
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (status: string) => (
        <div className="status-section">
          <div className="dot" style={{ background: FeedbackStatusColorMap[status] }} />
          <div>{FeedbackStatusLabel[status]}</div>
        </div>
      ),
    },
    {
      title: actions,
      width: 150,
      key: 'action',
      render: (item: IFeedback) => {
        if (
          item.type === FEEDBACK_TYPE.CLIENT_FEEDBACK &&
          item.status !== FeedbackStatusLabel.Cancelled
        ) {
          return (
            <div className="action-section">
              <div
                tabIndex={0}
                aria-label={'edit client feedback'}
                onKeyDown={e =>
                  onEnter(e, () => goToClientFeedbackPage(item.feedbackId, item.version))
                }
                className="edit"
                onClick={() => goToClientFeedbackPage(item.feedbackId, item.version)}
              >
                {edit}
              </div>
              <div>
                <div
                  tabIndex={0}
                  aria-label={'cancel client feedback'}
                  onKeyDown={e => onEnter(e, () => onCancelModal(item))}
                  className="cancel"
                  onClick={e => {
                    e.stopPropagation();
                    onCancelModal(item);
                  }}
                >
                  {cancel}
                </div>
              </div>
            </div>
          );
        }
        switch (item.status) {
          case 'Drafted':
            return (
              <div className="action-section">
                <div
                  tabIndex={0}
                  aria-label={'cancel internal feedback'}
                  onKeyDown={e => onEnter(e, () => handleInternalFeedbackCancel(item))}
                  className="cancel"
                  onClick={e => {
                    e.stopPropagation();
                    handleInternalFeedbackCancel(item);
                  }}
                >
                  {cancel}
                </div>
              </div>
            );
          case 'Pending':
            return (
              <div className="action-section">
                <div
                  tabIndex={0}
                  aria-label={'edit request feedback'}
                  onKeyDown={e => onEnter(e, () => handleInternalFeedbackEdit(item))}
                  className="edit"
                  onClick={e => {
                    e.stopPropagation();
                    handleInternalFeedbackEdit(item);
                  }}
                >
                  {edit}
                </div>
                <div
                  tabIndex={0}
                  aria-label={'cancel internal feedback'}
                  onKeyDown={e => onEnter(e, () => handleInternalFeedbackCancel(item))}
                  className="cancel"
                  onClick={e => {
                    e.stopPropagation();
                    handleInternalFeedbackCancel(item);
                  }}
                >
                  {cancel}
                </div>
              </div>
            );
          case 'Submitted':
            return (
              <div className="action-section">
                <div
                  tabIndex={0}
                  aria-label={'view request feedback'}
                  onKeyDown={e => onEnter(e, () => goToDetailPage(item.feedbackId, item.version))}
                  className="view"
                  onClick={() => {
                    goToDetailPage(item.feedbackId, item.version);
                  }}
                >
                  {view}
                </div>
              </div>
            );
          default:
            return null;
        }
      },
    },
  ];

  const handleTableChange: TableProps<IFeedback>['onChange'] = (pagination, _, sorter) => {
    const { columnKey, order } = sorter as SorterResult<IFeedback>;
    const sortColumnName = order
      ? FEEDBACK_SORT_COLUMNS_NAME[columnKey as keyof typeof FEEDBACK_SORT_COLUMNS_NAME]
      : FEEDBACK_SORT_COLUMNS_NAME.default;
    const sortOrder = order ? SORT_DIRECTION_FOR_BE[order] : SORT_DIRECTION_FOR_BE.ascend;
    const pageNumber = pagination.current || TABLE_DEFAULT_INFO.PAGE_NUMBER;
    const pageSize = pagination.pageSize || TABLE_DEFAULT_INFO.PAGE_SIZE;

    dispatch(
      getCurrentCycleFeedbackListAsync({
        cycleId: cycleId,
        sortColumnName: sortColumnName,
        sortOrder: sortOrder,
        pageSize: pageSize,
        pageNumber: pageNumber,
      }),
    );
  };

  useEffect(() => {
    const tableSelectDom = getInputInSelectDom('request-list-table');
    if (feedbackTotalCount !== 0 && !isGetRequestListLoading) {
      updateAriaForSelect({ inputWrapper: tableSelectDom, ariaLabelText: 'table page' });
    }
  }, [isGetRequestListLoading, feedbackTotalCount]);

  const renderFeedbackTable = () => (
    <Table
      className={'request-list-table'}
      columns={requestFeedbackListColumn}
      rowClassName={record =>
        record.type === FEEDBACK_TYPE.INTERNAL_FEEDBACK ? 'internal-row' : ''
      }
      dataSource={feedbackList}
      rowKey={item => item.feedbackId}
      onChange={handleTableChange}
      pagination={{
        ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
        total: feedbackTotalCount || TABLE_DEFAULT_INFO.TOTAL,
        current: tableConfig.pageNumber || TABLE_DEFAULT_INFO.PAGE_NUMBER,
        pageSize: tableConfig.pageSize || TABLE_DEFAULT_INFO.PAGE_SIZE,
      }}
    />
  );

  return (
    <div className="v2-feedback-list">
      {isCurrentCyclesPage(cycleFilterValue) && <MoreFeedbackButton />}
      <div className="v2-feedback-content">
        <div className="v2-operation-bar">
          <div className="cycle-drop-down">
            <CycleSelector className={'feedback-list-selector'} />
            {!pastCycles() && (
              <span className="amount-requested-feedbacks">{`${feedbackTotalCount} ${locale.feedback.requestedFeedback}`}</span>
            )}
          </div>
          {isPastCyclesPage(cycleFilterValue) && !isEmpty(reviewHistoryYear) && <YearSelector />}
          {/* todo: when add export feedback list function can release this annotate
           <div className="excel-export">
            <i className="ri-download-line"></i>
            <span className="export-button-context">{`${locale.feedback.exportFeedback}`}</span>
          </div> */}
        </div>
        {isCurrentCyclesPage(cycleFilterValue) && (
          <div className="v2-feedback-table">{renderFeedbackTable()}</div>
        )}
        {isPastCyclesPage(cycleFilterValue) && (
          <PastCyclesContainer isReviewHistoryYearEmpty={isEmpty(reviewHistoryYear)} />
        )}
      </div>
    </div>
  );
};

export default RequestList;
