import { CYCLE_STATUS_ENUM } from '@@/_new_src_/constants/myCycles';

export const CYCLE_STATUS: Record<
  string,
  {
    className: string;
    des: string;
  }
> = {
  NEW: {
    className: 'status-new',
    des: 'New',
  },
  OPEN: {
    className: 'status-opened',
    des: 'Open',
  },
  UNREAD: {
    className: 'status-unread',
    des: 'Unread',
  },
  DONE: {
    className: 'status-done',
    des: 'Done',
  },
  CLOSED: {
    className: 'status-close',
    des: 'Closed',
  },
  CANCELLED: {
    className: 'status-cancelled',
    des: 'Cancelled',
  },
  DRAFT: {
    className: 'status-draft',
    des: 'Draft',
  },
  OVERDUE: {
    className: 'status-overdue',
    des: 'Overdue',
  },
};

export const CYCLE_STATUS_DESCRIPTION = {
  [CYCLE_STATUS_ENUM.DONE]: 'Done',
  [CYCLE_STATUS_ENUM.OPEN]: 'Opened',
  [CYCLE_STATUS_ENUM.DRAFT]: 'Pending',
  [CYCLE_STATUS_ENUM.CANCELLED]: 'Cancelled',
  [CYCLE_STATUS_ENUM.UNREAD]: 'Done',
  [CYCLE_STATUS_ENUM.CLOSED]: 'Closed',
  [CYCLE_STATUS_ENUM.NEW]: 'Drafted',
  [CYCLE_STATUS_ENUM.OVERDUE]: 'Overdue',
};

export const CYCLE_TYPE = {
  CURRENT_CYCLE: 'CURRENT_REVIEW',
  HISTORY_CYCLE: 'HISTORY_REVIEW',
};
