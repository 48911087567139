import { Spin } from 'antd';
import React from 'react';

const LoadingWrapper = ({ loading, children, className, ...rest }) => (
  <Spin className={className} spinning={loading} {...rest} size="large">
    {children}
  </Spin>
);

export default LoadingWrapper;
