import FieldBlockInfo from '@@/common/component/FieldBlockInfo';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import PerfModal from '@@/common/component/PerfModal';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { GIVE_FEEDBACK_MAX_LENGTH } from '@@/common/constant/review';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEqual, isEqualWith } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import WarningText from '@@/_new_src_/components/WarningText';
import { FEEDBACK_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ProjectSelect from '@@/_new_src_/features/ProjectSelect';
import {
  V2FeedbackStore,
  setFeedbackDetail,
  setPostV1Feedback,
  setV1FeedbackModalInfo,
} from '@@/_new_src_/store/V2FeedbackSlice';
import { projectList } from '@@/_new_src_/store/commonSlice';
import {
  getFeedbackDetailAsync,
  getGivenFeedbackRequestsAsync,
  postV1FeedbackAsync,
} from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import { dateWithYearMonthDay } from '@@/_new_src_/constants/dateFormatter';
import { getProjectListAsync } from '@@/_new_src_/store/commonSlice/asyncThunk';
import {
  formatRangeDuration,
  formatTimeFun,
  getDateFormatByProbation,
  getUTCFormattedTime,
} from '@@/_new_src_/utils/common/date';
import { getFeedbackStatus } from '@@/_new_src_/utils/feature';

dayjs.extend(utc);

const WriteFeedbackModal = () => {
  const { trackEvent } = useMatomo();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    feedback: { writeFeedbackModal: locale },
  } = useSelector(selectLocaleResource);

  const { list: curCycleProjectList } = useSelector(projectList);

  const {
    feedbackDetail: { loading: isFeedbackDetailLoading, cycle, feedback, project },
    givenFeedbacksStore: {
      tableConfig: { givenFeedbackSortColumn, status, sortOrder, pageSize, pageNumber },
    },
    postV1Feedback: { loading: isPostV1FeedbackLoading, success: isPostV1FeedbackSuccess },
    V1FeedbackModalInfo: { isWriteFeedbackModalVisible: visible, feedbackId },
  } = useSelector(V2FeedbackStore);

  const { isSubmittedFeedback } = getFeedbackStatus(feedback.feedbackStatus);

  const [isDraft, setIsDraft] = useState(false);
  const [feedbackHtml, setFeedbackHtml] = useState('');
  const [selectProject, setSelectProject] = useState(undefined);

  const [feedbackTextInfo, setFeedbackTextInfo] = useState(null);

  useEffect(() => {
    visible && feedbackId && dispatch(getFeedbackDetailAsync(feedbackId));
    visible && feedbackId && dispatch(getProjectListAsync());
  }, [visible, feedbackId]);

  const onReset = () => {
    form.resetFields();
    dispatch(setPostV1Feedback({}));
    dispatch(setFeedbackDetail({}));
    dispatch(setV1FeedbackModalInfo({}));
    setFeedbackHtml('');
    setSelectProject(undefined);
  };
  useEffect(() => {
    return () => {
      onReset();
    };
  }, []);

  const isBeforeDueDate = dayjs(getUTCFormattedTime(dayjs())).isBefore(dayjs.utc(feedback.dueDate));

  const feedbackProjectFromBackend = useMemo(
    () => (project.assignmentId ? project : undefined),
    [project],
  );

  useEffect(() => {
    if (!visible) {
      return;
    }
    form.setFieldsValue({ project: feedbackProjectFromBackend, content: feedback.content });
    setSelectProject(feedbackProjectFromBackend);
    setFeedbackHtml(feedback.content);
  }, [visible, feedback.content]);

  const onClickSendOrDraftButton = isDraft => {
    setIsDraft(isDraft);
    const values = form.getFieldsValue();
    const currentProject = values.project;

    const postData = {
      feedbackId: feedbackId,
      feedbackContent: feedbackHtml?.trim(),
      isDraft,
      feedbackProjectId: currentProject?.assignmentId || '',
      feedbackProjectName: currentProject?.name || '',
      feedbackProjectAccount: currentProject?.account || '',
    };

    trackEvent({
      category: FEEDBACK_API.category,
      action: FEEDBACK_API.action.FEEDBACK_PROVIDER_SUBMITS_FEEDBACK,
    });

    dispatch(postV1FeedbackAsync(postData));
  };

  useEffect(() => {
    if (isPostV1FeedbackSuccess) {
      perfMessage.success(isDraft ? locale.draftMessage : locale.sendMessage);
      onReset();
      dispatch(
        getGivenFeedbackRequestsAsync({
          status: status,
          givenFeedbackSortColumn: givenFeedbackSortColumn,
          sortOrder: sortOrder,
          pageSize: pageSize,
          pageNumber: pageNumber,
        }),
      );
    }
  }, [isPostV1FeedbackSuccess, isDraft]);

  const getNeedConfirm = useCallback(() => {
    if (!visible) {
      return false;
    }
    if (!isEqualWith(feedbackHtml, feedback.content, (a, b) => (!a && !b) || a === b)) {
      return true;
    }
    return !isEqual(selectProject, feedbackProjectFromBackend);
  }, [feedback.content, feedbackHtml, feedbackProjectFromBackend, selectProject]);

  const diffDays = Math.ceil(
    Math.abs(dayjs(getUTCFormattedTime(dayjs())).diff(dayjs.utc(feedback.dueDate), 'days', true)),
  );

  const updateFeedbackHtml = html => {
    setFeedbackHtml(html);
  };

  const updateFeedbackText = text => {
    setFeedbackTextInfo(text);
  };

  const canSave = useMemo(() => {
    return (
      selectProject &&
      feedbackTextInfo?.trim() &&
      feedbackTextInfo.length < GIVE_FEEDBACK_MAX_LENGTH + 1
    );
  }, [selectProject, feedbackTextInfo]);

  const dueDateInfo = () => (
    <>
      {isBeforeDueDate ? locale.dueIn : locale.overdue}
      {diffDays}
      {diffDays === 1 ? locale.day : locale.days}
    </>
  );

  return (
    <>
      {!isFeedbackDetailLoading && visible && (
        <PerfModal
          title={`${!isSubmittedFeedback ? locale.title : locale.editSubmittedTitle} ${
            feedback.requestName
          }`}
          okText={!isSubmittedFeedback ? locale.okText : locale.editSubmittedText}
          cancelText={locale.cancelText}
          destroyOnClose
          className="write-feedback-modal"
          onOk={() => onClickSendOrDraftButton(false)}
          afterClose={onReset}
          okButtonProps={{
            isPostV1FeedbackLoading,
            disabled: !canSave,
          }}
          maskClosable={false}
          needConfirm={getNeedConfirm()}
          visible={visible}
          onCancel={onReset}
        >
          <div className="review-info">
            <div className="left-section">
              <div className="item">
                <span className="info-label">{locale.projectLabel}&nbsp;</span>
                <span>{cycle.projectName}</span>
              </div>
              <div className="item">
                <span className="info-label">{locale.roleLabel}&nbsp;</span>
                <span>{feedback.requestRole}</span>
              </div>
            </div>
            <div className="right-section">
              <div className="item">
                <span className="info-label">{locale.durationLabel}&nbsp;</span>
                <span>
                  {formatRangeDuration(cycle.duration.startTime, cycle.duration.endTime, {
                    format: getDateFormatByProbation(cycle.probation),
                  })}
                </span>
              </div>
              <div className="item">
                <span className="info-label">{locale.dueDateLabel}&nbsp;</span>
                <span>{formatTimeFun(feedback.dueDate, dateWithYearMonthDay)}</span>
                {!isSubmittedFeedback &&
                  ((isBeforeDueDate && diffDays <= 3) || !isBeforeDueDate) && (
                    <WarningText warningText={dueDateInfo()} className={'due-time'} />
                  )}
              </div>
            </div>
          </div>
          {feedback.context && (
            <FieldBlockInfo
              isExpand
              className="feedback-info feedback-context"
              label={locale.contextLabel}
              content={feedback.context}
              collapsible
            />
          )}
          <Form form={form} colon={false} className="write-feedback-form" layout="vertical">
            <Form.Item className="required-label" name="project" label={locale.projectSelectLabel}>
              <ProjectSelect
                allowClear
                size="large"
                dropdownClassName="feedback-select-option"
                suffixIcon={<CaretDownOutlined style={{ color: '#808285' }} />}
                placeholder={locale.selectPlaceholder}
                notFoundContent={locale.selectNotFoundContent}
                onChange={value => setSelectProject(value)}
                bordered={true}
                showArrow={true}
                optionList={curCycleProjectList}
                aria-label={'Project input'}
                aria-expanded="false"
              />
            </Form.Item>
            <Form.Item className="required-label" name="content" label={locale.contentLabel}>
              <TinyMceEditor
                id={'feedbackId-' + feedbackId}
                initialValue={feedback.content}
                updateHtmlInfo={updateFeedbackHtml}
                updateTextInfo={updateFeedbackText}
                maxLength={GIVE_FEEDBACK_MAX_LENGTH}
                height={266}
              />
            </Form.Item>
          </Form>
          {!isSubmittedFeedback && (
            <Button
              className="save-draft-button"
              loading={isPostV1FeedbackLoading}
              type="text"
              onClick={() => onClickSendOrDraftButton(true)}
              disabled={!canSave}
            >
              {locale.draftText}
            </Button>
          )}
        </PerfModal>
      )}
    </>
  );
};

export default memo(WriteFeedbackModal);
