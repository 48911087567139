import { PRIVACY_NOTICE_LINK } from '@@/common/constant/dashboard';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedbackIconImg from '../../../assets/images/feedback_icon.png';
import TWLogoImg from '../../../assets/images/new-tw-logo-white.svg';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { EMAIL_LINK, PLAYBOOK_LINK, REVIEW_FEEDBACK_LINK } from '../../constant/review';
import PerfModal from '../PerfModal';
import './index.less';

const Footer = () => {
  const { footer: locale } = useSelector(selectLocaleResource);
  const [isDataDisclaimerVisible, setIsDataDisclaimerVisible] = useState(false);

  const openDataDisclaimerModal = () => setIsDataDisclaimerVisible(true);
  const closeDataDisclaimerModal = () => setIsDataDisclaimerVisible(false);

  const dataDisclaimerContent = (
    <div>
      <div className="data-disclaimer-content">
        <div>
          This notice describes the information collected in <b>Summit</b> related to your
          performance, how the information is used, with whom it is shared and how it is stored.
        </div>
        <div className="data-disclaimer-content-title">
          What kind of information is collected and stored
        </div>
        <div className="data-disclaimer-content-text">
          The following information relating to your performance is collected and stored in Summit:
        </div>
        <ul className="data-disclaimer-content-list">
          <li>Name, Emp ID, role, grade, tenure, gender</li>
          <li>Expectations for your role, grade and project</li>
          <li>Notes of check-in conversations</li>
          <li>Performance feedback</li>
          <li>Performance indicator and notes</li>
          <li>Additional talent information</li>
        </ul>
        <div className="data-disclaimer-content-title">Use of this information</div>
        <div className="data-disclaimer-content-text">
          The information is used for the following purposes:
        </div>
        <ul className="data-disclaimer-content-list">
          <li>Enable you to do your job;</li>
          <li>Staff management and promotions;</li>
          <li>
            As part of the compensation process, where decisions about salary review are made;
          </li>
          <li>Gathering evidence for possible grievance or disciplinary hearings;</li>
          <li>Compliance with any procedures, laws and regulations which apply to us;</li>
          <li>Establishing, exercising or defending our legal rights;</li>
          <li>Otherwise, for the lawful operation of our business.</li>
        </ul>
        <div className="data-disclaimer-content-title">Who the information is shared with</div>
        <div className="data-disclaimer-content-text">
          The information is shared with the following parties:
        </div>
        <ul className="data-disclaimer-content-list">
          <li>
            Your Performance Partner for the performance cycle in which that person is assigned as
            Performance Partner
          </li>
          <li>
            Your Additional Performance Partner(s) for the performance cycle in which that
            person/people are assigned as an Additional Performance Partner
          </li>
          <li>Your Country People Team including the Head of People</li>
          <li>Your Country Leadership Team</li>
          <li>
            The Regional Group Manager for Summit. This is a member of the account, market unit,
            service line, office or regional leadership team. Please confirm with your regional
            People Team for details of who the Regional Group Manager is for your country.
          </li>
        </ul>
        <div className="data-disclaimer-content-title">How the information is secured</div>
        <ul className="data-disclaimer-content-list">
          <li>
            Performance data is encrypted and permissions are managed in the Google Cloud Platform.
          </li>
          <li>
            In the Summit application, data is divided into permissions based on roles. Only people
            who are related to your performance cycle can see your performance information (see
            details above).
          </li>
        </ul>
        <div className="data-disclaimer-content-notice">
          For more information about how your personal data is stored and used at Thoughtworks,
          please refer to&nbsp;
          <a
            className="privacy-notice-link"
            href={PRIVACY_NOTICE_LINK}
            target="_blank"
            rel="noreferrer"
            aria-label="privacy notice link"
          >
            Thoughtworks Employee Privacy Notice
          </a>
        </div>
        <div className="data-disclaimer-content-notice">
          If you have further questions please contact your regional People Team or the Summit
          delivery team (
          <a
            className="summit-support-email-link"
            href={EMAIL_LINK}
            target="_blank"
            rel="noreferrer"
            aria-label="summit support email link"
          >
            summit-support@thoughtworks.com
          </a>
          )
        </div>
      </div>
      <div className="data-disclaimer-long-content-blur-wrapper" />
    </div>
  );

  return (
    <footer className="performance-layout-footer">
      <a
        aria-label="link to give feedback"
        target="_blank"
        rel="noopener noreferrer"
        className="feedback-icon"
        href={REVIEW_FEEDBACK_LINK}
        aria-describedby="feedback-image"
      >
        <img id="feeback-image" src={FeedbackIconImg} alt="feedback icon" />
      </a>
      <div className="feedback-desc">
        <span>{locale.feedbackDesc[0]}</span>
        <span>{locale.feedbackDesc[1]}</span>
      </div>
      <div className="playbook-and-data-disclaimer-entry">
        <div className="playbook">
          <b className="learn-more"> {locale.playbookDesc}</b>
          <a
            className="link-playbook"
            rel="noopener noreferrer"
            target="_blank"
            href={PLAYBOOK_LINK}
            aria-label="performance | playbook"
          >
            <span className="left-text">{locale.performance}</span>
            <span className="playbook-divider">&nbsp;{locale.divider}&nbsp;</span>
            <span className="right-text">{locale.playbook}</span>
          </a>
        </div>
        <div className="data-disclaimer-entry">
          <div className="data-privacy-question">{locale.privacyQuestion}</div>
          <div className="data-privacy-answer">{locale.privacyAnswer}</div>
          <button
            type="button"
            className="data-disclaimer-button"
            onClick={openDataDisclaimerModal}
          >
            {locale.dataDisclaimerShowButton}
          </button>
        </div>
      </div>
      <div className="black-bar">
        <span className="contact">{locale.contact}&nbsp;</span>
        <a
          className="email"
          rel="noopener noreferrer"
          target="_blank"
          href={EMAIL_LINK}
          aria-label="summitsupportthoughtworkscom"
        >
          {locale.email}
        </a>
        <span className="email-divider">&nbsp;{locale.divider}&nbsp;</span>
        <span className="corp-tag">©</span>
        <img src={TWLogoImg} alt="TW logo" />
        <span className="copyright">{locale.copyright}</span>
      </div>

      <PerfModal
        className="data-disclaimer-modal"
        title={locale.dataDisclaimerModalTitle}
        visible={isDataDisclaimerVisible}
        okText={locale.dataDisclaimerModalOkButton}
        onCancel={closeDataDisclaimerModal}
        onOk={closeDataDisclaimerModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        destroyOnClose
      >
        {dataDisclaimerContent}
      </PerfModal>
    </footer>
  );
};

export default Footer;
