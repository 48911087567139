import { useRequest } from 'ahooks';
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import { getV2AttachmentStorageUrl } from '@@/api/review';
import './index.less';

const ERROR_CODE_MAP = {
  403: '403 Access Denied',
  404: 'The file does not exist',
};

const IS_ALL_PEOPLE = 'isAllPeople';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const NewAttachmentPage = () => {
  const { filename } = useParams();

  const query = useQuery();

  const { error, loading } = useRequest(
    () => getV2AttachmentStorageUrl(filename, true, query.get(IS_ALL_PEOPLE)),
    {
      onSuccess: response => {
        window.location.href = response.data;
      },
    },
  );

  return (
    <div className="attachment-page-container">
      <LoadingWrapper loading={loading} />
      {ERROR_CODE_MAP[error?.error.response.status]}
    </div>
  );
};

export default NewAttachmentPage;
