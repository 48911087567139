import { get } from 'lodash';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { formatV2Project } from '@@/_new_src_/utils/feature';
import {
  getCycleStatus,
  getNewAdditionalPartnerAssessments,
} from '@@/_new_src_/utils/feature/cycle';
import { PROBATION_STATUS } from '@@/_new_src_/constants/userEnum';

export const getCurrentCycleDetailData = ({
  cycleVersion,
  cycleDetailByVersion1,
  cycleDetailByVersion2,
}) => {
  let cycleInfo = {
    cycleDetailLoading:
      cycleVersion === CYCLE_VERSION.NEW
        ? cycleDetailByVersion2.loading
        : cycleDetailByVersion1.loading,
  };

  const newCycleDetail = cycleDetailByVersion2.data;
  const oldCycleDetail = cycleDetailByVersion1.data;
  const currentCycleData = cycleVersion === CYCLE_VERSION.NEW ? newCycleDetail : oldCycleDetail;
  if (get(currentCycleData, 'id') === undefined) {
    return cycleInfo;
  }

  cycleInfo = {
    ...cycleInfo,
    cycleVersion: currentCycleData.cycleVersion,
    id: currentCycleData.id,
    status: currentCycleData.status,
    duration: currentCycleData.duration,
    assessment: currentCycleData.assessment,
    canRequestToEdit: currentCycleData.canRequestToEdit,
    canEdit: currentCycleData.canEdit,
    feedbacks: currentCycleData.feedbacks === null ? [] : currentCycleData.feedbacks,
    editRequestRejected: currentCycleData.editRequestRejected,
    checkInNotes: currentCycleData.checkInNotes,
    createdTime: currentCycleData.createdTime,
    openBy: currentCycleData.openBy,
    assessmentUpdated: currentCycleData.assessmentUpdated,
    overdue: currentCycleData.overdue,
    assessmentNonEngaged: currentCycleData.assessmentNonEngaged,
    lastUpdatedAt: currentCycleData.lastUpdatedAt,
    createdAt: currentCycleData.createdAt,
  };
  if (cycleVersion === CYCLE_VERSION.NEW) {
    cycleInfo.isNoneEngaged = newCycleDetail.isNoneEngaged;
    cycleInfo.twer = newCycleDetail.twer;
    cycleInfo.expectations = newCycleDetail.expectations; // expectations is array
    cycleInfo.performancePartner = newCycleDetail.performancePartner;
    cycleInfo.additionalPartners = newCycleDetail.additionalPartners;
    cycleInfo.project = newCycleDetail.project;
    cycleInfo.selfAssessment = newCycleDetail.selfAssessment;
    cycleInfo.isProbation = newCycleDetail.isProbation;
    cycleInfo.additionalPartnerAssessments = newCycleDetail.additionalPartnerAssessments;
    cycleInfo.assessDate = newCycleDetail.assessDate;
    cycleInfo.createBySystem = newCycleDetail.createBySystem || false;
  } else {
    cycleInfo.isNoneEngaged = oldCycleDetail.noneEngaged;
    cycleInfo.twer = oldCycleDetail.reviewee;
    cycleInfo.expectations = oldCycleDetail.expectation; // expectation is object
    cycleInfo.performancePartner = oldCycleDetail.reviewers.reviewer;
    cycleInfo.additionalPartners = oldCycleDetail.reviewers.contributors;
    cycleInfo.project = formatV2Project(oldCycleDetail.project);
    cycleInfo.selfAssessment = {
      ...oldCycleDetail.selfReview,
      attachmentStorageUrl: get(oldCycleDetail, 'selfReview.attachmentUrl', ''),
      updatedAt: get(oldCycleDetail, 'selfReview.updatedTime', ''),
      createdAt: get(oldCycleDetail, 'selfReview.createdTime', ''),
    };
    cycleInfo.isProbation = oldCycleDetail.probationStatus === PROBATION_STATUS.UNDER_PROBATION;
    cycleInfo.additionalPartnerAssessments = getNewAdditionalPartnerAssessments(
      oldCycleDetail.contributorReviewInputs,
    );
    cycleInfo.createBySystem = false;
  }
  return cycleInfo;
};

export const getCycleOwnerRole = ({
  userId,
  cycleInfo: { performancePartner, additionalPartners = [], twer },
}) => {
  let owner = {};
  owner.isPerformancePartner = get(performancePartner, 'id') === userId || false;
  owner.isAdditionalPartners = additionalPartners.some(item => {
    return item.id === userId;
  });
  owner.isTwer = get(twer, 'id') === userId || false;
  return owner;
};

export const getCycleDetailByVersion = ({
  userId,
  cycleVersion,
  cycleDetailByVersion1,
  cycleDetailByVersion2,
}) => {
  let cycleInfo = getCurrentCycleDetailData({
    cycleVersion,
    cycleDetailByVersion1,
    cycleDetailByVersion2,
  });
  const { isPerformancePartner, isAdditionalPartners, isTwer } = getCycleOwnerRole({
    userId,
    cycleInfo,
  });
  const {
    isNewCycle,
    isOpenCycle,
    isCancelledCycle,
    isUnreadCycle,
    isDoneCycle,
    isDraftCycle,
    isClosedCycle,
    isOpenOrDraftCycle,
    isOverdueCycle,
    isAssessmentNonEngagedCycle,
  } = getCycleStatus(
    cycleInfo?.status,
    cycleInfo?.overdue,
    cycleInfo?.assessmentNonEngaged,
    cycleInfo?.createdAt,
  );

  return {
    ...cycleInfo,
    isPerformancePartner,
    isAdditionalPartners,
    isTwer,
    isNewCycle,
    isOpenCycle,
    isCancelledCycle,
    isUnreadCycle,
    isDoneCycle,
    isDraftCycle,
    isClosedCycle,
    isOpenOrDraftCycle,
    isOverdueCycle,
    isAssessmentNonEngagedCycle,
  };
};
