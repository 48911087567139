export default {
  api: {
    errorMessage: '',
    unauthorizedMessage: '',
  },
  perModal: {
    confirmTitle: '',
  },
  emptyDescription: '暂无数据',
  menu: {
    reviews: '',
    reviewees: '',
    dashboard: '',
    stats: '',
    dashboardReviews: '',
    groupMembers: '',
    allReviewees: '',
    groupManagement: '',
  },
  myReviews: {
    welcome: {},
    introduction: {
      title: '',
      subTitle: '',
      align: '',
      description: ['', ''],
      ratingDescription: {},
    },
    startReview: {
      button: '',
      tipCombination: ['', '', ''],
    },
  },
  setReview: {
    title: '',
    form: {
      project: {
        label: '',
        placeholder: '',
        notFoundContent: '',
      },
      reviewerInfo: {
        label: '',
        placeholder: '',
        notFoundContent: '',
        validateMessage: '',
      },
      reviewDuration: {
        label: '',
        validateMessage: {
          require: '',
          within: '',
        },
      },
      expectation: {
        label: '',
        validateMessage: '',
        placeholder: '',
        tipCombination: ['', ''],
      },
    },
    footer: {
      cancel: {
        cancelText: '取消',
        confirmOkText: '',
        confirmCancelText: '',
        confirmMessage: '',
      },
      save: {
        saveText: '',
        saveUpdateText: '',
        confirmOkText: '',
        confirmOkUpdateText: '',
        confirmMessage: '',
        successMessage: '',
      },
    },
  },
  reviewList: {
    review: '',
    project: '',
    reviewer: '',
    duration: '',
    myExpectations: '',
    expectations: '',
    rating: '',
    summary: '',
    markAsRead: '',
    whatNow: '',
    description: '',
    confirm: {
      cancel: '',
      ok: '',
      content: '',
    },
    cancelSuccessMessage: '',
  },
  reviewHistory: {
    title: '',
    empty: '',
  },
  reviewees: {
    list: {
      revieweeName: '',
      role: '',
      grade: '',
      project: '',
      duration: '',
      rating: '',
      status: '',
    },
    detail: {
      assessNow: '',
      submitNow: '',
      submitTips: name => name,
      confirmSubmit: {
        content: [],
        cancelText: '',
        okText: '',
        successMessage: '',
      },
    },
    draftEditor: {
      rating: {
        label: '',
        requiredMessage: '',
        placeholder: '',
      },
      summary: {
        label: '',
        requiredMessage: '',
        maxMessage: '',
        placeholder: '',
      },
      cancel: '',
      save: '',
      confirmCancel: {
        cancelText: '',
        okText: '',
        content: '',
      },
      saveSuccessMessage: '',
    },
  },
  dashboard: {
    syncEmployeeData: '',
    syncEmployeeDataSuccessMessage: '',
    syncEmployeeDataErrorMessage: '',
    stats: {
      expectation: {
        title: '',
        desc: '',
        subDesc: '',
      },
      assessment: {
        title: '',
        desc: '',
        subDesc: '',
      },
      empty: '',
      tips: '',
    },
    groupModal: {
      title: {
        create: '',
        edit: '',
      },
      okText: {
        create: '',
        edit: '',
      },
      cancelText: '',
      groupName: {
        label: '',
        placeholder: '',
        requiredMessage: '',
      },
      members: {
        import: '',
        label: '',
        placeholder: '',
        requiredMessage: '',
      },
      groupLead: {
        label: '',
        placeholder: '',
        requiredMessage: '',
        notFoundContent: '',
      },
      successMessage: {
        create: total => `${total}`,
        edit: total => `${total}`,
      },
    },
    deleteGroup: {
      okText: '',
      cancelText: '',
      content: '',
      successMessage: '',
    },
    reviews: {
      filter: {
        title: '',
        account: {
          label: '',
          placeholder: '',
        },
        project: {
          label: 'By project',
          placeholder: '',
        },
        clearText: '',
        searchText: '',
        more: '',
      },
    },
  },
  logout: '登出',
  welcome: '',
  forbidden: {
    title: '',
    info: '',
    desc: '',
    backToHomePage: '',
  },
  footer: {
    feedbackDesc: [],
  },
};
