import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Collapse, Tooltip } from 'antd';
import cls from 'classnames';
import { orderBy } from 'lodash';

import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { setConfirmModalStatus, setConfirmModalType } from '@@/_new_src_/store/commonSlice';
import {
  myCycles,
  setCurrentUpdatedExpectation,
  setCycleDisplayPart,
  setIsUpdateInformationSuccess,
} from '@@/_new_src_/store/myCyclesSlice';

import { CATEGORY_LABEL, CYCLE_DISPLAY_PART } from '@@/_new_src_/constants/myCycles';
import { ExpectationItemPropsType, IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import './index.less';

const { Panel } = Collapse;

const ExpectationItem = (props: ExpectationItemPropsType) => {
  const { expectationItem } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    myCycles: { cycleExpectation: cycleExpectationLocale },
  } = localeResource as IMyCyclesLocale;

  const {
    expectationList: expectations,
    currentCycleId: curCycleId,
    currentCycleStatus: status,
    isOperationCycle: showOperation,
  } = useSelector(myCycles);

  const { isNewCycle } = getCycleStatus(status);
  const isLastExpectationAndNotNewCycle = !isNewCycle && expectations.length === 1;

  const [isActive, setIsActive] = useState(false);

  const renderPanelHeader = () => {
    const orderByName = orderBy(expectationItem.categoryIds, item => CATEGORY_LABEL[item].title);
    return (
      <>
        <div className="title">
          <span className="header-text">{expectationItem.title}</span>
          <div
            className="ri-arrow-down-s-line arrow-icon"
            style={{ rotate: isActive ? '180deg' : '0deg' }}
          />
        </div>
        <div className="category-labels">
          {orderByName.map((item, index) => {
            const { iconBackGroundColor, icon, contentBackGroundColor, title } =
              CATEGORY_LABEL[item];
            return (
              <div className="label" key={index}>
                <div
                  className="label-icon"
                  style={{
                    backgroundColor: iconBackGroundColor,
                  }}
                  dangerouslySetInnerHTML={{ __html: icon }}
                />
                <div
                  className="label-content"
                  style={{
                    backgroundColor: contentBackGroundColor,
                  }}
                >
                  <div>{title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const onClickDeleteButton = () => {
    dispatch(setCurrentUpdatedExpectation(expectationItem));
    dispatch(setConfirmModalStatus(true));
    dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.DELETE_EXPECTATION));
  };
  const onClickEditButton = () => {
    dispatch(setCurrentUpdatedExpectation(expectationItem));
    dispatch(setIsUpdateInformationSuccess({}));
    dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.EXPECTATION_FORM));
    if (!isNewCycle) {
      history.push(`${CYCLE_PAGE_LINK.NEW_CYCLES_FORM}/${curCycleId}`);
    }
  };

  return (
    <>
      <div className="expectation-item">
        <Collapse
          bordered={false}
          className="expectation-collapse"
          onChange={() => {
            setIsActive(!isActive);
          }}
        >
          <Panel
            header={renderPanelHeader()}
            key={expectationItem.id}
            className="expectation-panel"
            showArrow={false}
          >
            <div className="expectation-content">
              <div className="line" />
              {expectationItem.content && (
                <div
                  className={cls('global-rich-text content', {
                    'no-operation-buttons-content': !showOperation,
                  })}
                  dangerouslySetInnerHTML={{ __html: expectationItem.content }}
                />
              )}
            </div>
            <div className="test-store">{showOperation}</div>
            {showOperation && (
              <div className="expectations-operation">
                <div className="line" />
                <div className="operation-buttons">
                  <div className="edit-button" onClick={onClickEditButton}>
                    <div className="ri-edit-line" />
                    <span className="operation-text">{cycleExpectationLocale.edit}</span>
                  </div>
                  {isLastExpectationAndNotNewCycle && (
                    <div className="disabled-delete-button">
                      <div className="ri-delete-bin-line" />
                      <Tooltip
                        title={cycleExpectationLocale.deleteLastExpectationWhenOpen}
                        overlayClassName={'delete-tooltip'}
                      >
                        <span className="operation-text">{cycleExpectationLocale.delete}</span>
                      </Tooltip>
                    </div>
                  )}
                  {!isLastExpectationAndNotNewCycle && (
                    <div className="delete-button" onClick={onClickDeleteButton}>
                      <div className="ri-delete-bin-line" />
                      <span className="operation-text">{cycleExpectationLocale.delete}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Panel>
        </Collapse>
      </div>
    </>
  );
};
export default ExpectationItem;
