import { Form, Input } from 'antd';
import { isEmpty } from 'lodash';
import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import History from '@@/assets/images/history.svg';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import PerfModal from '@@/common/component/PerfModal';
import {
  selectClientContent,
  selectClientContentHtml,
  selectClientName,
  setClientContent,
  setClientContentHtml,
  setClientName,
} from '@@/redux/slice/feedbackSlice';
import './index.less';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { FEEDBACK_MAX_LENGTH } from '@@/common/constant/review';
import { FEEDBACK_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FEEDBACK_TYPE } from '@@/_new_src_/constants/feedback';
import {
  getCurrentCycleFeedbackListAsync,
  getFeedbackDetailAsync,
  postV1AddClientFeedbackAsync,
  putV1EditClientFeedbackAsync,
} from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import {
  feedbackDetail,
  requestFeedbacks,
  setClientFeedback,
  setFeedbackDetail,
  setInternalFeedback,
  setV1FeedbackModalInfo,
  V1FeedbackModalInfo,
} from '@@/_new_src_/store/V2FeedbackSlice';
import { dateWithYearMonthTime } from '@@/_new_src_/constants/dateFormatter';
import { cycleDetailData } from '@@/_new_src_/store/myCyclesSlice';
import {
  formatRangeDuration,
  formatTimeFun,
  getDateFormatByProbation,
} from '@@/_new_src_/utils/common/date';

const FeedbackRequestToClientsModal = () => {
  const dispatch = useDispatch();
  const clientName = useSelector(selectClientName);
  const clientContent = useSelector(selectClientContent);
  const clientContentHtml = useSelector(selectClientContentHtml);
  const [clientInitialValue, setClientInitialValue] = useState(clientContentHtml);
  const { trackEvent } = useMatomo();

  const [form] = Form.useForm();
  const {
    feedback: { feedbackRequestToClientModal: locale },
  } = useSelector(selectLocaleResource);

  const {
    isEdit,
    isClientFeedbackModalVisible: visible,
    feedbackId,
    cycleId,
    type,
    loading,
    success,
  } = useSelector(V1FeedbackModalInfo);

  const { tableConfig } = useSelector(requestFeedbacks);

  const {
    loading: isFeedbackDetailLoading,
    cycle: { projectName, duration, probation },
    feedback: { providerName, content, updatedAt },
  } = useSelector(feedbackDetail);

  const {
    data: { project, duration: cycleDuration, isProbation },
  } = useSelector(cycleDetailData);

  useEffect(() => {
    visible && isEdit && feedbackId && dispatch(getFeedbackDetailAsync(feedbackId));
  }, [visible, isEdit && feedbackId]);

  const resetModal = () => {
    dispatch(setV1FeedbackModalInfo({}));
    dispatch(setFeedbackDetail({}));
  };

  useEffect(() => {
    return () => {
      resetModal();
    };
  }, []);

  const currentModalInfo = useMemo(() => {
    return {
      cycleInfo: {
        projectName: isEdit ? projectName : project?.name,
        duration: isEdit ? duration : cycleDuration,
        probation: isEdit ? probation : isProbation,
      },
      title: isEdit ? locale.editTitle : locale.title,
      okText: isEdit ? locale.editOkText : locale.okText,
    };
  }, [isEdit, projectName, project, duration, isProbation]);

  const updateClientContentHtml = html => {
    dispatch(setClientContentHtml(html));
  };

  const updateClientContentText = text => {
    dispatch(setClientContent(text));
  };

  const onReset = useCallback(() => {
    form.resetFields();
    setClientInitialValue();
    dispatch(setClientName());
    dispatch(setClientContent());
    dispatch(setClientContentHtml());
    dispatch(setClientFeedback({}));
    dispatch(setInternalFeedback({}));
    resetModal();
  }, [dispatch, form]);

  const handleAddOrSave = () => {
    let currentParams = {
      requestTo: clientName?.trim(),
      content: clientContentHtml || content,
    };
    trackEvent({
      category: FEEDBACK_API.category,
      action: isEdit
        ? FEEDBACK_API.action.TWER_EDITS_CLIENT_FEEDBACK
        : FEEDBACK_API.action.TWER_ADDS_CLIENT_FEEDBACK,
    });
    if (isEdit) {
      const editParam = {
        feedbackId: feedbackId,
        params: {
          ...currentParams,
        },
      };
      dispatch(putV1EditClientFeedbackAsync(editParam));
    } else {
      const addParam = {
        ...currentParams,
        reviewId: cycleId,
      };
      dispatch(postV1AddClientFeedbackAsync(addParam));
    }
  };

  useEffect(() => {
    const isUpdateClientSuccess = type === FEEDBACK_TYPE.CLIENT_FEEDBACK && success;
    if (isUpdateClientSuccess) {
      perfMessage.success(isEdit ? locale.editSuccessMessage : locale.successMessage);
      dispatch(
        getCurrentCycleFeedbackListAsync({
          cycleId: cycleId,
          ...tableConfig,
        }),
      );
      onReset();
    }
  }, [type, success, isEdit]);

  const getSubmitDisabled = useCallback(() => {
    return (
      isEmpty(clientName?.trim()) ||
      isEmpty(clientContent?.trim()) ||
      (isEdit && clientName === providerName && clientContent?.trim() === content)
    );
  }, [isEdit, clientName, clientContent, providerName, content]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (isEdit) {
      if (isEmpty(clientName)) {
        dispatch(setClientName(providerName));
        form.setFieldsValue({
          name: providerName,
        });
      }
      if (isEmpty(clientContent)) {
        dispatch(setClientContentHtml(content));
        setClientInitialValue(content);
      }
    }
    form.setFieldsValue({
      name: clientName,
    });
  }, [clientContent, clientName, dispatch, providerName, content, form, isEdit, visible]);

  const needConfirm = useCallback(() => {
    if (isEdit) {
      return !(clientName === providerName && clientContentHtml === content);
    }
    return !(isEmpty(clientName) && isEmpty(clientContent));
  }, [clientName, clientContent, isEdit, providerName, content]);

  return (
    <>
      {!isFeedbackDetailLoading && visible && (
        <PerfModal
          title={currentModalInfo.title}
          okText={
            <div className="save-button">
              <i className="ri-save-fill add-icon" />
              {currentModalInfo.okText}
            </div>
          }
          cancelText={
            <div className="cancel-button">
              <i className="ri-close-circle-fill cancel-icon" />
              {locale.cancelText}
            </div>
          }
          destroyOnClose
          centered
          className="feedback-request-to-clients-modal"
          onOk={handleAddOrSave}
          afterClose={onReset}
          okButtonProps={{
            loading,
            disabled: getSubmitDisabled(),
          }}
          maskClosable={false}
          needConfirm={needConfirm()}
          onCancel={onReset}
          visible={visible}
        >
          <div className="project-info">
            <span>{locale.project}</span>
            <p>{currentModalInfo.cycleInfo.projectName}</p>
            <span>{locale.duration}</span>
            <p>
              {`${formatRangeDuration(
                currentModalInfo.cycleInfo.duration?.startTime,
                currentModalInfo.cycleInfo.duration?.endTime,
                {
                  format: getDateFormatByProbation(currentModalInfo.cycleInfo.probation),
                },
              )}`}
            </p>
          </div>
          <Form form={form}>
            <label className="required-label">{locale.nameLabel}</label>
            <Form.Item className="name-section" name="name">
              <Input
                maxLength={100}
                type="text"
                onChange={e => dispatch(setClientName(e.target.value))}
                placeholder={locale.enterClientName}
              />
            </Form.Item>
            <label className="required-label">{locale.contextLabel}</label>
            <Form.Item name="content" className="feedback-section">
              <TinyMceEditor
                initialValue={clientInitialValue}
                updateHtmlInfo={updateClientContentHtml}
                updateTextInfo={updateClientContentText}
                maxLength={FEEDBACK_MAX_LENGTH}
              />
            </Form.Item>
          </Form>
          {isEdit && (
            <div className="last-updated-at">
              <img alt="" src={History} className="feedback-time-icon" />
              <span>{locale.lastUpdatedAt}</span>
              {formatTimeFun(updatedAt, dateWithYearMonthTime)}
            </div>
          )}
        </PerfModal>
      )}
    </>
  );
};

export default memo(FeedbackRequestToClientsModal);
