import { V2GLOBALFUNCTION, V2REGION, V2SUPERVISOR, V2UNIT } from '@@/_new_src_/constants/adminEnum';
import { CYCLE_PAGE_LINK, MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import commonLocale from '@@/_new_src_/local/common/en_US';
const { menu: menuLocale } = commonLocale;

export enum USER_ROLE_ENUM {
  NORMAL_USER = 'NORMAL_USER',
  GROUP_LEADER = 'GROUP_LEADER',
  PS_ADMIN = 'PS_ADMIN',
  GLOBAL_ADMIN = 'GLOBAL_ADMIN',
  OPS_ADMIN = 'OPs Admin',
  TALENT_BP = 'Talent Business Partner',
  CLIENT_LEADERSHIP_TEAM = 'Client Leadership Team',
  MU_SL_OPS = 'MU_SL_Ops',
  REGIONAL_LEVEL_GROUP_MANAGER = 'Regional Level Group Manager',
  GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP = 'Global Function/Market/DEC/Service Line Leadership',
  GLOBAL_HEAD_OF_PEOPLE = 'Global Head of People',
  GLOBAL_TALENT_BP = 'Global Talent Business Partner',
  REGIONAL_TALENT_BP = 'Regional Talent Business Partner',
}

export const UserRoleInfo = {
  [USER_ROLE_ENUM.NORMAL_USER]: {
    id: 1,
    display: 'Normal User',
  },
  [USER_ROLE_ENUM.GROUP_LEADER]: {
    id: 2,
    display: 'Group Leader',
  },
  [USER_ROLE_ENUM.PS_ADMIN]: {
    id: 3,
    display: 'PS Admin',
  },
  [USER_ROLE_ENUM.GLOBAL_ADMIN]: {
    id: 4,
    display: 'System Admin',
  },
  [USER_ROLE_ENUM.TALENT_BP]: {
    id: 10,
    display: 'Talent Business Partner',
    maxNumber: 5,
  },
  [USER_ROLE_ENUM.MU_SL_OPS]: {
    id: 11,
    display: 'MU/SL/Ops',
    maxNumber: 2,
  },
  [USER_ROLE_ENUM.REGIONAL_TALENT_BP]: {
    id: 12,
    display: 'Regional Talent Business Partner',
  },
  [USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER]: {
    id: 13,
    display: 'Regional Level Group Manager',
  },
  [USER_ROLE_ENUM.OPS_ADMIN]: {
    id: 14,
    display: 'OPs Admin',
    maxNumber: 2,
  },
  [USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE]: {
    id: 16,
    display: 'Global Head of People',
    maxNumber: 2,
  },
  [USER_ROLE_ENUM.GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP]: {
    id: 17,
    display: 'Global Function/Market/DEC/Service Line Leadership',
    maxNumber: 2,
  },
  [USER_ROLE_ENUM.CLIENT_LEADERSHIP_TEAM]: {
    id: 18,
    display: 'Client Leadership Team',
  },
  [USER_ROLE_ENUM.GLOBAL_TALENT_BP]: {
    id: 19,
    display: 'Global Talent Business Partner',
  },
};

export enum HOME_COUNTRY {
  BRAZIL = 'BRA',
  CHINA = 'CHN',
  HK = 'HKG',
  INDIA = 'IND',
  SPAIN = 'ESP',
  OTHER = 'Other',
}

export enum PROBATION_STATUS {
  UNDER_PROBATION = 'UNDER PROBATION',
  NOT_UNDER_PROBATION = 'NOT UNDER PROBATION',
}

export enum PATH_KEY {
  DASHBOARD = 'Requests and Cycles',
  NON_ENGAGED_CYCLES = 'NON_ENGAGED_CYCLES',
  CYCLES = 'CYCLES',
  TWER_I_SUPPORT = 'TWER_I_SUPPORT',
  FEEDBACK = 'FEEDBACK',
  ADMIN = 'ADMIN',
}

export const dashboardAccessibleRoles = [
  USER_ROLE_ENUM.TALENT_BP,
  USER_ROLE_ENUM.REGIONAL_TALENT_BP,
  USER_ROLE_ENUM.GROUP_LEADER,
  USER_ROLE_ENUM.MU_SL_OPS,
  USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER,
  USER_ROLE_ENUM.GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP,
  USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE,
  USER_ROLE_ENUM.CLIENT_LEADERSHIP_TEAM,
  USER_ROLE_ENUM.GLOBAL_TALENT_BP,
];

export const cycleManagementAccessibleRoles = [
  {
    role: USER_ROLE_ENUM.TALENT_BP,
    roleHierarchy: V2UNIT,
  },
  {
    role: USER_ROLE_ENUM.TALENT_BP,
    roleHierarchy: V2SUPERVISOR,
  },
  {
    role: USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE,
    roleHierarchy: V2GLOBALFUNCTION,
  },
  {
    role: USER_ROLE_ENUM.REGIONAL_TALENT_BP,
    roleHierarchy: V2REGION,
  },
];

export const adminAccessibleRoles = [
  USER_ROLE_ENUM.PS_ADMIN,
  USER_ROLE_ENUM.TALENT_BP,
  USER_ROLE_ENUM.GLOBAL_ADMIN,
  USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER,
  USER_ROLE_ENUM.OPS_ADMIN,
];

export const getMenu = () => [
  {
    key: PATH_KEY.DASHBOARD,
    text: menuLocale.dashboard,
    path: MENU_PAGE_LINK.DASHBOARD_PAGE,
    role: dashboardAccessibleRoles,
  },
  {
    key: PATH_KEY.NON_ENGAGED_CYCLES,
    text: menuLocale.nonEngagedCycles,
    path: MENU_PAGE_LINK.NON_ENGAGED_CYCLES_PAGE,
    role: cycleManagementAccessibleRoles,
  },
  {
    key: PATH_KEY.CYCLES,
    text: menuLocale.myCycles,
    path: CYCLE_PAGE_LINK.MY_CYCLES,
    role: [],
  },
  {
    key: PATH_KEY.TWER_I_SUPPORT,
    text: menuLocale.twersISupport,
    path: MENU_PAGE_LINK.TWERS_I_SUPPORT_PAGE,
    role: [],
  },
  {
    key: PATH_KEY.FEEDBACK,
    text: menuLocale.feedbackManage,
    path: MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE,
    role: [],
  },
  {
    key: PATH_KEY.ADMIN,
    text: menuLocale.admin,
    path: MENU_PAGE_LINK.ADMIN_PAGE,
    role: adminAccessibleRoles,
  },
];
