export default {
  common: {
    readMore: 'Read more',
    readLess: 'Read less',
    emptyPlaceholder: 'N/A',
    requestToEdit: 'Request to Edit',
  },
  menu: {
    dashboard: 'Cycles and requests',
    nonEngagedCycles: 'Non-engaged cycles',
    myCycles: 'My cycles',
    twersISupport: 'Twers I support',
    feedbackManage: 'Feedback management',
    admin: 'Admin',
  },
  confirmModal: {
    overdue: {
      title: 'You need to finish some overdue cycles',
      firstContent: `  Some cycles that you support are overdue because the assessment weren't submitted on time. <b>Please submit the assessment before `,
      secondContent: ` </b>. Not doing so, will result in “No performance indicator” assigned to the TWers and this <b>will be considered in your own performance</b> as per guideline.
    Need support? Reach out to your <a href="" style="text-decoration: underline">Talent Business Partner</a> as soon as possible.`,
      okText: 'Okay, head to the overdue cycles',
    },
    assessmentNonEngaged: {
      title: 'Your previous cycle is done with non-engagement',
      firstContent: `Your previous cycle is done, considered non-engaged and assessed as <b>No performance indicator.</b> If you have <b>legitimate reasons</b> of delaying the assessment submission, please reach out to your 
          <a href="" style="text-decoration: underline">Talent Business Partner</a> as soon as possible. 
          You have until  <b>`,
      secondContent: `</b> to request to review your performance cycle situation. `,
      firstNotes: `<p style="font-weight: 400;font-size: 16px;font-style: italic">Notes: The system will consider this message read if you don’t click the button below until `,
      secondNotes: `.</p>`,
      okText: 'Okay, got it',
    },
    expectationNonEngaged: {
      title: 'Your cycle status changed from Drafted to Opened',
      firstContent: `Your cycle has been <b>automatically opened </b>because the expectations were not set up and published by `,
      secondContent: `, and the system filled in a standardized grade expectation on your behalf to facilitate the ongoing performance process. <br><br>Please <b>update the expectations with your personal context ASAP</b> so your cycle will be more meaningful to your growth and performance journey. You'll be assessed based on the standardized expectations if you keep them until the assessment phase.`,
      okText: 'Okay, got it',
    },
  },
  tag: {
    assessmentNonEngagedTag: 'Assessment non-engaged',
    nonEngagedCycleByTBPTag: 'Cycle opened by TBP',
  },
};
