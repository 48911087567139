import { Button } from 'antd';
import React, { memo, useCallback, useState } from 'react';
import { addReviewInput, updateReviewInput } from '@@/api/review';
import perfMessage from '../PerfMessage/perfMessage';
import perfModalConfirm from '../PerfModalConfirm';
import CustomPrompt from '../CustomPrompt';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { ASSESSMENT_INPUT_MAX_LENGTH } from '@@/common/constant/review';
import { TWER_I_SUPPORT_BY_AP_API, TWER_I_SUPPORT_BY_PP_API } from '@@/common/constant/matomo';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function ReviewInputEditor({
  cycleId,
  reviewInput,
  onReviewInputChange,
  onCancelReviewInput,
  onSubmitReviewInput,
  updatedMode,
  currentReviewInputId,
  reviewInputFromBackend,
}) {
  const { trackEvent } = useMatomo();

  const {
    currentCycleDetail: { isPerformancePartner, isAdditionalPartners },
  } = useSelector(cycleDetailByVersion);

  const {
    reviewees: { reviewInputEditor: locale },
    confirmCancelModal,
  } = useSelector(selectLocaleResource);

  const [reviewInputHtmlInfo, setReviewInputHtml] = useState(null);
  const [reviewInputTextInfo, setReviewInputText] = useState(null);
  const [reviewInputInitialValue] = useState(reviewInput);

  const onCancel = useCallback(() => {
    if (reviewInput === reviewInputFromBackend) {
      onCancelReviewInput();
      return;
    }
    perfModalConfirm({
      centered: true,
      content: confirmCancelModal.confirmMessage,
      okText: confirmCancelModal.okText,
      cancelText: confirmCancelModal.cancelText,
      onOk: onCancelReviewInput,
    });
  }, [
    confirmCancelModal.cancelText,
    confirmCancelModal.confirmMessage,
    confirmCancelModal.okText,
    onCancelReviewInput,
    reviewInput,
    reviewInputFromBackend,
  ]);

  const onSubmit = useCallback(() => {
    const localeConfirmSubmit = locale.confirmSubmit;
    const localeConfirmSaveChanges = locale.confirmSaveChanges;
    const perfModalProps = {
      content: updatedMode ? localeConfirmSaveChanges.content : localeConfirmSubmit.content,
      okText: updatedMode ? localeConfirmSaveChanges.okText : localeConfirmSubmit.okText,
      cancelText: updatedMode
        ? localeConfirmSaveChanges.cancelText
        : localeConfirmSubmit.cancelText,
    };
    const hideModal = perfModalConfirm({
      centered: true,
      width: 600,
      ...perfModalProps,
      onOk: async () => {
        if (updatedMode) {
          isPerformancePartner &&
            trackEvent({
              category: TWER_I_SUPPORT_BY_PP_API.category,
              action: TWER_I_SUPPORT_BY_PP_API.action.PP_EDITS_DRAFT_ASSESSMENT_SUMMARY,
            });
          isAdditionalPartners &&
            trackEvent({
              category: TWER_I_SUPPORT_BY_AP_API.category,
              action: TWER_I_SUPPORT_BY_AP_API.action.AP_EDITS_ASSESSMENT_INPUT,
            });
          await updateReviewInput(currentReviewInputId, reviewInputHtmlInfo).finally(() => {
            hideModal();
          });
          perfMessage.success(localeConfirmSaveChanges.successMessage);
          onSubmitReviewInput(currentReviewInputId);
        } else {
          isPerformancePartner &&
            trackEvent({
              category: TWER_I_SUPPORT_BY_PP_API.category,
              action: TWER_I_SUPPORT_BY_PP_API.action.PP_ADDS_DRAFT_ASSESSMENT_SUMMARY,
            });
          isAdditionalPartners &&
            trackEvent({
              category: TWER_I_SUPPORT_BY_AP_API.category,
              action: TWER_I_SUPPORT_BY_AP_API.action.AP_ADDS_ASSESSMENT_INPUT,
            });
          const { data: id } = await addReviewInput(cycleId, reviewInputHtmlInfo).finally(() => {
            hideModal();
          });
          perfMessage.success(localeConfirmSubmit.successMessage);
          onSubmitReviewInput(id);
        }
      },
    });
  }, [
    locale,
    onSubmitReviewInput,
    cycleId,
    reviewInput,
    currentReviewInputId,
    updatedMode,
    reviewInputHtmlInfo,
  ]);

  const updateReviewInputHtml = html => {
    setReviewInputHtml(html);
    onReviewInputChange(html);
  };

  const updateReviewInputText = text => {
    setReviewInputText(text);
  };

  return (
    <div className="component-review-input-editor">
      <CustomPrompt when={reviewInput !== reviewInputFromBackend} />
      <div className="review-input-title">{locale.reviewInput.label}</div>
      <TinyMceEditor
        initialValue={reviewInputInitialValue}
        updateHtmlInfo={updateReviewInputHtml}
        updateTextInfo={updateReviewInputText}
        maxLength={ASSESSMENT_INPUT_MAX_LENGTH}
      />
      <div className="operation">
        <Button className="cancel" onClick={onCancel}>
          {locale.cancel}
        </Button>
        <Button
          type="primary"
          className="submit"
          onClick={onSubmit}
          disabled={
            !reviewInputTextInfo || reviewInputTextInfo?.length > ASSESSMENT_INPUT_MAX_LENGTH
          }
        >
          {updatedMode ? locale.saveChanges : locale.submit}
        </Button>
      </div>
    </div>
  );
}

ReviewInputEditor.defaultProps = {
  updatedMode: false,
  review: {
    id: '',
  },
  reviewInput: '',
  onCancelReviewInput: () => {},
  onSubmitReviewInput: () => {},
};
export default memo(ReviewInputEditor);
