import { isEmpty } from 'lodash';
import React, { memo, useEffect } from 'react';
import ReviewInputEditor from '../ReviewInputEditor';
import ReviewInputPreview from '../ReviewInputEditor/preview';
import './index.less';
import { useSelector } from 'react-redux';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';

const ReviewInputTab = props => {
  const { showEditReviewInputButton, reviewInputEditorProps, isProvideReviewInput } = props;

  const {
    currentCycleDetail: { id: cycleId, additionalPartnerAssessments = [] },
  } = useSelector(cycleDetailByVersion);

  useEffect(() => {
    if (isProvideReviewInput) {
      window.scrollTo(0, 0);
    }
  }, [isProvideReviewInput]);
  return (
    <div className="review-input-container">
      {isProvideReviewInput && <ReviewInputEditor cycleId={cycleId} {...reviewInputEditorProps} />}
      {!isEmpty(additionalPartnerAssessments) && (
        <div className="contributor-review-input-list">
          {additionalPartnerAssessments.map(item =>
            reviewInputEditorProps.currentReviewInputId === item.id ? (
              <ReviewInputEditor
                updatedMode
                key={item.id}
                cycleId={cycleId}
                {...reviewInputEditorProps}
              />
            ) : (
              <ReviewInputPreview
                key={item.id}
                reviewInputDetail={item}
                onEditReviewInput={reviewInputEditorProps.onEditReviewInput}
                showEditReviewInputButton={showEditReviewInputButton}
                recentSubmittedReviewInputId={reviewInputEditorProps.recentSubmittedReviewInputId}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
};

ReviewInputTab.defaultProps = {
  reviewInputEditorProps: {
    onEditReviewInput: () => {},
    recentSubmittedReviewInputId: '',
  },
  showEditReviewInputButton: true,
};

export default memo(ReviewInputTab);
