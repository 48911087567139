import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Form, Row, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';
import { EMPLOYEE_STATUS, EMPLOYEE_STATUS_DISPLAY } from '@@/common/constant/reviewee';
import { NON_ENGAGED_SELECTIONS } from '@@/common/constant/nonEngaged';
import {
  CYCLE_STATUS_ENUM,
  CYCLE_SCORE_RATING,
  CYCLE_SCORE_RATING_FOR_PROBATION,
  NO_PERFORMANCE_INDICATOR,
  CYCLE_SCORE_RATING_V1,
} from '@@/_new_src_/constants/myCycles';
import { CYCLE_STATUS_DESCRIPTION } from '@@/_new_src_/constants/CycleStatus';
import { HOME_COUNTRY } from '@@/_new_src_/constants/userEnum';
import { formatRangeUTCDate } from '@@/_new_src_/utils/common/date';
import { getUserList, getUserListApiWithExited, getUserRoles } from '@@/api/user';
import { getAccountFilter, getProjectFilter } from '@@/api/v2/dashboard/cycles';
import { UserInfoContext } from '@@/context/userInfo.context';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import AutoComplete from '@@/common/component/AutoComplete';
import ClearIcon from '@@/common/component/ClearIcon';
import DatePicker from '@@/common/component/DatePicker';
import './index.less';

const autoCompleteProps = {
  showArrow: false,
  showSearch: true,
  notFoundContent: null,
  clearIcon: <ClearIcon />,
};

const selectProps = {
  showArrow: true,
  allowClear: true,
  mode: 'multiple',
  notFoundContent: null,
  suffixIcon: <CaretDownOutlined />,
  clearIcon: <ClearIcon />,
};

const datePickerProps = {
  picker: 'month',
  size: 'large',
  style: { width: '100%' },
  getPopupContainer: trigger => trigger.parentNode,
  clearIcon: <ClearIcon />,
};

function DashboardCyclesFilter(props) {
  const {
    dashboardV2: {
      reviews: { filter: locale },
    },
  } = useSelector(selectLocaleResource);
  const { groupId, searchFilter, onClear, onSearch } = props;
  const [isExpand, setIsExpand] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const userInfo = useContext(UserInfoContext);
  const { homeCountry } = userInfo;
  const isCHNUser = useMemo(() => {
    return homeCountry === HOME_COUNTRY.CHINA || homeCountry === HOME_COUNTRY.HK;
  }, [homeCountry]);

  const [form] = Form.useForm();
  const onSearchInfo = useCallback(
    async values => {
      const { project, nonEngagement, ...otherValues } = values;
      const [startTime, endTime] = formatRangeUTCDate([values.startTime, values.endTime]);
      let noneEngaged;
      let assessmentNonEngaged;
      if (nonEngagement && nonEngagement instanceof Array) {
        if (nonEngagement.includes(NON_ENGAGED_SELECTIONS.OPENED_BY_TBP)) {
          noneEngaged = true;
        }
        if (nonEngagement.includes(NON_ENGAGED_SELECTIONS.NON_ENGAGED)) {
          assessmentNonEngaged = true;
        }
      }
      let queryParam = {
        ...otherValues,
        project: get(project, 'value'),
        startTime,
        endTime,
        noneEngaged,
        assessmentNonEngaged,
      };
      await onSearch(queryParam);
    },
    [props],
  );

  const onToggleExpand = useCallback(() => {
    setIsExpand(!isExpand);
    window.scrollTo(0, 0);
  }, [isExpand]);

  const onClearFilter = useCallback(() => {
    form.resetFields();
    onClear();
    setIsExpand(false);
    window.scrollTo(0, 0);
  }, [form, onClear, setIsExpand]);

  const getFilterApiBySourceCategory = async ({ keyword, filterType }) => {
    return filterType === 'account'
      ? await getAccountFilter(keyword)
      : await getProjectFilter(keyword);
  };

  useEffect(() => {
    getUserRoles().then(({ data }) => {
      setUserRoles(data);
    });
  }, []);

  useEffect(() => {
    onClearFilter();
  }, [groupId]);

  useEffect(() => {
    const { account, project, startTime, endTime, ...values } = searchFilter;

    let accountValue = [];
    if (!isEmpty(account)) {
      accountValue = account.map(item => {
        return { label: item.label, value: item.value, key: item.key };
      });
    }

    form.setFieldsValue({
      ...values,
      account: accountValue,
      project: project && { label: project, value: project },
      startTime: startTime && dayjs.utc(startTime),
      endTime: endTime && dayjs.utc(endTime),
    });
  }, [searchFilter, form]);

  return (
    <div className="new-dashboard-reviews-filter">
      <h2 className="new-dashboard-reviews-filter-title">{locale.title}</h2>
      <Form
        className="filter-form"
        onFinish={onSearchInfo}
        form={form}
        layout="vertical"
        hideRequiredMark
      >
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item name="account" label={locale.account.label}>
              <AutoComplete
                mode="multiple"
                {...autoCompleteProps}
                filterType="account"
                fetchApi={getFilterApiBySourceCategory}
                placeholder={locale.account.placeholder}
                limitNum={2}
                aria-label="cycle account"
                aria-expanded="false"
                aria-autocomplete="none"
              >
                {(list, Option) =>
                  list?.map(({ accountName, accountId }) => (
                    <Option key={accountId} label={accountName} value={accountName}>
                      {accountName}
                    </Option>
                  ))
                }
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="project" label={locale.project.label}>
              <AutoComplete
                {...autoCompleteProps}
                filterType="project"
                fetchApi={getFilterApiBySourceCategory}
                placeholder={locale.project.placeholder}
                aria-label="cycle project"
                aria-expanded="false"
                aria-autocomplete="none"
              >
                {(list, Option) =>
                  list?.map((value, index) => (
                    <Option key={String(index)} label={value} value={value}>
                      {value}
                    </Option>
                  ))
                }
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        <div hidden={!isExpand}>
          <Row gutter={40}>
            <Col span={12}>
              <Form.Item name="reviewee" label={locale.reviewees.label}>
                <AutoComplete
                  mode="multiple"
                  {...autoCompleteProps}
                  placeholder={locale.reviewees.placeholder}
                  fetchApi={getUserListApiWithExited()}
                >
                  {(list, Option) =>
                    list.map(({ userId, name }) => (
                      <Option key={String(userId)} label={name} value={userId}>
                        {`${name}, ${userId}`}
                      </Option>
                    ))
                  }
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="revieweeRoleList" label={locale.role.label}>
                <Select {...selectProps} placeholder={locale.role.placeholder}>
                  {userRoles.map(role => (
                    <Select.Option key={role} value={role}>
                      {role}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col span={12}>
              <Form.Item name="reviewer" label={locale.reviewers.label}>
                <AutoComplete
                  mode="multiple"
                  {...autoCompleteProps}
                  placeholder={locale.reviewers.placeholder}
                  fetchApi={getUserList}
                >
                  {(list, Option) =>
                    list.map(({ userId, name }) => (
                      <Option key={String(userId)} label={name} value={userId}>
                        {`${name}, ${userId}`}
                      </Option>
                    ))
                  }
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={12} className="duration">
              <Form.Item name="startTime" label={locale.duration.label}>
                <DatePicker {...datePickerProps} placeholder={locale.startTime.placeholder} />
              </Form.Item>
              <Form.Item name="endTime" label={' '}>
                <DatePicker {...datePickerProps} placeholder={locale.endTime.placeholder} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={40}>
            <Col span={12}>
              <Form.Item name="statusList" label={locale.status.label}>
                <Select {...selectProps} placeholder={locale.status.placeholder}>
                  {[
                    CYCLE_STATUS_ENUM.OPEN,
                    CYCLE_STATUS_ENUM.DRAFT,
                    CYCLE_STATUS_ENUM.OVERDUE,
                    CYCLE_STATUS_ENUM.DONE,
                    CYCLE_STATUS_ENUM.CLOSED,
                  ].map(status => (
                    <Select.Option key={status} value={status}>
                      {CYCLE_STATUS_DESCRIPTION[status]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="ratingList" label={locale.rating.label}>
                <Select {...selectProps} placeholder={locale.rating.placeholder}>
                  {Object.values({
                    ...CYCLE_SCORE_RATING_V1,
                    ...CYCLE_SCORE_RATING,
                    ...NO_PERFORMANCE_INDICATOR,
                    ...CYCLE_SCORE_RATING_FOR_PROBATION,
                  }).map(rating => (
                    <Select.Option key={rating} value={rating}>
                      {rating}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col span={12}>
              <Form.Item name="employeeStatus" label={locale.employeeStatus.label}>
                <Select
                  showArrow
                  allowClear
                  suffixIcon={<CaretDownOutlined />}
                  clearIcon={<ClearIcon />}
                  placeholder={locale.employeeStatus.placeholder}
                >
                  <Select.Option key={EMPLOYEE_STATUS.EXITED} value={EMPLOYEE_STATUS.EXITED}>
                    {EMPLOYEE_STATUS_DISPLAY[EMPLOYEE_STATUS.EXITED]}
                  </Select.Option>
                  <Select.Option key={EMPLOYEE_STATUS.ACTIVE} value={EMPLOYEE_STATUS.ACTIVE}>
                    {EMPLOYEE_STATUS_DISPLAY[EMPLOYEE_STATUS.ACTIVE]}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {isCHNUser && (
              <Col span={12}>
                <Form.Item name="probationStatus" label={locale.probationStatus.label}>
                  <Select
                    showArrow
                    allowClear
                    suffixIcon={<CaretDownOutlined />}
                    clearIcon={<ClearIcon />}
                    placeholder={locale.probationStatus.placeholder}
                  >
                    <Select.Option
                      key={EMPLOYEE_STATUS.UNDER_PROBATION}
                      value={EMPLOYEE_STATUS.UNDER_PROBATION}
                    >
                      {EMPLOYEE_STATUS_DISPLAY[EMPLOYEE_STATUS.UNDER_PROBATION]}
                    </Select.Option>
                    <Select.Option
                      key={EMPLOYEE_STATUS.NOT_UNDER_PROBATION}
                      value={EMPLOYEE_STATUS.NOT_UNDER_PROBATION}
                    >
                      {EMPLOYEE_STATUS_DISPLAY[EMPLOYEE_STATUS.NOT_UNDER_PROBATION]}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={40}>
            <Col span={12}>
              <Form.Item name={'nonEngagement'} label={locale.noneEngagement.label}>
                <Select {...selectProps} placeholder={locale.noneEngagement.placeholder}>
                  {[NON_ENGAGED_SELECTIONS.OPENED_BY_TBP, NON_ENGAGED_SELECTIONS.NON_ENGAGED].map(
                    status => (
                      <Select.Option key={status} value={status}>
                        {CYCLE_STATUS_DESCRIPTION[status]}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="new-dashboard-reviews-filter-operation">
          <span className="more" onClick={onToggleExpand}>
            {isExpand ? locale.less : locale.more}
          </span>
          <div className="filter-operation-buttons">
            <Button onClick={onClearFilter}>{locale.clearText}</Button>
            <Button htmlType="submit" type="primary" loading={props.searching}>
              {locale.searchText}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default memo(DashboardCyclesFilter);
