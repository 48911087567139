import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import locale from '@@/_new_src_/local/landing/en_US';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { userInfo } from '@@/_new_src_/store/userSlice';
import { isNaNOrNull } from '@@/_new_src_/utils';
import './index.less';

export const V1CycleButtonAndTip = () => {
  const history = useHistory();
  const { futureCycleVersion, currentOpeningCycleId, loading } = useSelector(userInfo);

  const gotoReviews = useCallback(() => {
    if (isNaNOrNull(currentOpeningCycleId)) {
      history.push(CYCLE_PAGE_LINK.OLD_CYCLES_FORM);
      return;
    }
    history.push(CYCLE_PAGE_LINK.MY_CYCLES);
  }, [currentOpeningCycleId, history, futureCycleVersion]);

  return (
    <div className={'landing-v1-cycle'}>
      {loading && <Button type="primary" className="landing-v1-cycle-button" loading />}
      {!loading && (
        <Button type="primary" className="landing-v1-cycle-button" onClick={gotoReviews}>
          {isNaNOrNull(currentOpeningCycleId) ? locale.v1.startReview : locale.checkReview}
        </Button>
      )}
      <p className="landing-v1-cycle-tip">{locale.v1.tip}</p>
    </div>
  );
};
