import { createSlice } from '@reduxjs/toolkit';
import { getNonEngagedCyclesAsync } from '@@/_new_src_/store/nonEngagedCyclesSlice/asyncThunk';
import { INonEngagedCycles, IState } from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';
import {
  DEFAULT_TABLE_CONFIG,
  NON_ENGAGED_CYCLES_TAB_INDEX,
  TAB_INFO,
} from '@@/_new_src_/constants/overdueAndNonEngaged';
import { INITIAL_ASYNC_STATUS } from '@@/_new_src_/constants/common';
import { SORT_DIRECTION_FOR_BE } from '@@/_new_src_/constants/table';

const initialState: INonEngagedCycles = {
  nonEngagedType: 'OVERDUE',
  isEditByTbp: false,
  currentTab: '0',
  overdueTab: {
    ...TAB_INFO({}),
  },
  assessmentTab: {
    ...TAB_INFO({ sortColumn: DEFAULT_TABLE_CONFIG.SORT_COLUMN_FOR_ASSESSMENT_NON_ENGAGED }),
  },
  expectationTab: {
    ...TAB_INFO({
      sortOrder: SORT_DIRECTION_FOR_BE.descend,
      sortColumn: DEFAULT_TABLE_CONFIG.SORT_COLUMN_FOR_EXPECTATION_NON_ENGAGED,
    }),
  },
  ...INITIAL_ASYNC_STATUS,
  content: [],
  total: 0,
};

const nonEngagedCyclesSlice = createSlice({
  name: 'nonEngagedCycles',
  initialState: { ...initialState },
  reducers: {
    setOverdueTab: (state, action) => {
      state.overdueTab = {
        ...state.overdueTab,
        ...action.payload,
      };
    },
    setAssessmentTab: (state, action) => {
      state.assessmentTab = {
        ...state.assessmentTab,
        ...action.payload,
      };
    },
    setExpectationTab: (state, action) => {
      state.expectationTab = {
        ...state.expectationTab,
        ...action.payload,
      };
    },
    setNonEngagedType: (state, action) => {
      state.nonEngagedType = action.payload as string;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload as string;
    },
    setIsEditByTbp: (state, action) => {
      state.isEditByTbp = action.payload as boolean;
    },
  },
  extraReducers: builder => {
    builder.addCase(getNonEngagedCyclesAsync.pending, state => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getNonEngagedCyclesAsync.fulfilled, (state, action) => {
      state.content = action.payload.content;
      switch (state.currentTab) {
        case NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED:
          state.assessmentTab.pageNumber = action.payload.pageNumber;
          state.assessmentTab.pageSize = action.payload.pageSize;
          break;
        case NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED:
          state.expectationTab.pageNumber = action.payload.pageNumber;
          state.expectationTab.pageSize = action.payload.pageSize;
          break;
        default:
          state.overdueTab.pageNumber = action.payload.pageNumber;
          state.overdueTab.pageSize = action.payload.pageSize;
      }
      state.total = action.payload.total;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getNonEngagedCyclesAsync.rejected, state => {
      state.loading = false;
      state.success = false;
    });
  },
});

export const nonEngagedCycles = (state: IState) => state.nonEngagedCycles;

export const {
  setNonEngagedType,
  setCurrentTab,
  setIsEditByTbp,
  setOverdueTab,
  setAssessmentTab,
  setExpectationTab,
} = nonEngagedCyclesSlice.actions;

export default nonEngagedCyclesSlice.reducer;
