import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import './index.less';
import cls from 'classnames';

const WarningText = (props: { warningText: string; className?: string }) => {
  const { warningText, className = '' } = props;
  return (
    <div className={cls('warning-content', className)}>
      <InfoCircleFilled className="tip-icon" />
      <div className="tip-text">{warningText}</div>
    </div>
  );
};
export default WarningText;
