/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPastFeedbackSorts,
  setV1FeedbackModalInfo,
  V2FeedbackStore,
} from '@@/_new_src_/store/V2FeedbackSlice';
import {
  FEEDBACK_SORT_COLUMNS_NAME,
  FEEDBACK_VERSION,
  FeedbackStatusColorMap,
  FeedbackStatusLabel,
  RequestFeedbackListOptions,
} from '@@/_new_src_/constants/feedback';
import { Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import locale from '@@/_new_src_/local/feedback/en_US';
import { IFeedback } from '@@/_new_src_/store/V2FeedbackSlice/interface';
import informationCopyIcon from '@@/assets/images/information-copy-3.svg';
import { get, isEmpty } from 'lodash';
import './index.less';
import { useHistory } from 'react-router';
import { getCurrentCycleFeedbackListAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  SORT_DIRECTION_FOR_BE,
} from '@@/_new_src_/constants/table';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { getFeedbackStatus } from '@@/_new_src_/utils/feature';
import { IPageParams, ITableConfig, ITableSort } from '@@/_new_src_/types/common';

const PastFeedbackList = (props: { cycleId: string }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { cycleId } = props;

  const { pastCycles } = locale;

  const { pastFeedbacks, pastFeedbackSorts } = useSelector(V2FeedbackStore);
  const pastFeedbackContent = pastFeedbacks[cycleId];

  const [isViewAll, setIsViewAll] = useState(false);

  const renderNoFeedbackForPastReviews = () => (
    <div className="no-feedback-for-past-reviews-container">
      <img src={informationCopyIcon} className="no-feedback-icon" alt="information-copy-3" />
      <div className="no-feedback-content">{pastCycles.noFeedbackForPastReview.content}</div>
    </div>
  );

  const getSortConfigForPastReview = (sortColumnName: string) => ({
    ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
    key: sortColumnName,
  });

  const handleTableChange = useCallback(
    (_pagination: IPageParams, _: object, sorter: ITableSort) => {
      const sortMap = {} as { [id: string]: ITableConfig };
      sortMap[cycleId] = {
        pageNumber: 1,
        pageSize: 1,
        sortColumnName: get(sorter, 'column.key', FEEDBACK_SORT_COLUMNS_NAME.default),
        sortOrder: sorter.order,
      };

      const sortDirection = sorter.order
        ? SORT_DIRECTION_FOR_BE[sorter.order]
        : SORT_DIRECTION_FOR_BE.ascend;

      dispatch(setPastFeedbackSorts(sortMap));
      dispatch(
        getCurrentCycleFeedbackListAsync({
          type: RequestFeedbackListOptions[1].value,
          cycleId: parseInt(cycleId),
          sortColumnName: get(sorter, 'column.key', FEEDBACK_SORT_COLUMNS_NAME.default),
          sortOrder: sortDirection,
          pageSize: 100000,
          pageNumber: 1,
        }),
      );
    },
    [],
  );

  const requestFeedbackListForPastReviewColumn = [
    {
      title: pastCycles.feedbackListForPastReview.name,
      dataIndex: 'providerName',
      defaultSortOrder:
        pastFeedbackSorts[cycleId]?.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.providerName
          ? pastFeedbackSorts[cycleId]?.sortOrder
          : '',
      ...getSortConfigForPastReview(FEEDBACK_SORT_COLUMNS_NAME.providerName),
      ellipsis: {
        showTitle: false,
      },
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: pastCycles.feedbackListForPastReview.role,
      dataIndex: 'providerRole',
      defaultSortOrder:
        pastFeedbackSorts[cycleId]?.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.providerRole
          ? pastFeedbackSorts[cycleId]?.sortOrder
          : '',
      ...getSortConfigForPastReview(FEEDBACK_SORT_COLUMNS_NAME.providerRole),
      ellipsis: {
        showTitle: false,
      },
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: pastCycles.feedbackListForPastReview.requestDate,
      dataIndex: 'requestDate',
      defaultSortOrder:
        pastFeedbackSorts[cycleId]?.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.requestDate
          ? pastFeedbackSorts[cycleId]?.sortOrder
          : '',
      ...getSortConfigForPastReview(FEEDBACK_SORT_COLUMNS_NAME.requestDate),
      render: (requestDate: string) => dayjs(requestDate).format('YYYY-MM-DD'),
    },
    {
      title: pastCycles.feedbackListForPastReview.dueDate,
      dataIndex: 'dueDate',
      defaultSortOrder:
        pastFeedbackSorts[cycleId]?.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.dueDate
          ? pastFeedbackSorts[cycleId]?.sortOrder
          : '',
      ...getSortConfigForPastReview(FEEDBACK_SORT_COLUMNS_NAME.dueDate),
      render: (dueDate: string) => dayjs.utc(dueDate).format('YYYY-MM-DD'),
    },
    {
      title: pastCycles.feedbackListForPastReview.completionDate,
      dataIndex: 'completionDate',
      defaultSortOrder:
        pastFeedbackSorts[cycleId]?.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.completionDate
          ? pastFeedbackSorts[cycleId]?.sortOrder
          : '',
      ...getSortConfigForPastReview(FEEDBACK_SORT_COLUMNS_NAME.completionDate),
      render: (completionDate: string) =>
        isEmpty(completionDate) ? '' : dayjs.utc(completionDate).format('YYYY-MM-DD'),
    },
    {
      title: pastCycles.feedbackListForPastReview.status,
      dataIndex: 'status',
      width: 150,
      defaultSortOrder:
        pastFeedbackSorts[cycleId]?.sortColumnName === FEEDBACK_SORT_COLUMNS_NAME.status
          ? pastFeedbackSorts[cycleId]?.sortOrder
          : '',
      ...getSortConfigForPastReview(FEEDBACK_SORT_COLUMNS_NAME.status),
      render: (status: string) => (
        <div className="status-section">
          <div className="dot" style={{ background: FeedbackStatusColorMap[status] }} />
          <>{FeedbackStatusLabel[status]}</>
        </div>
      ),
    },
  ];

  const onRowClick = (record: IFeedback) => {
    const { isSubmittedFeedback } = getFeedbackStatus(record.status);
    if (record.version === FEEDBACK_VERSION.V2 && isSubmittedFeedback) {
      history.push(FEEDBACK_PAGE_LINK.DETAIL + record.feedbackId.toString());
    }

    if (
      (record.version === FEEDBACK_VERSION.V1 && isSubmittedFeedback) ||
      record.providerRole === 'Client'
    ) {
      dispatch(
        setV1FeedbackModalInfo({
          isSubmittedFeedbackDetailModalVisible: true,
          feedbackId: record.feedbackId,
          isCurrentCycle: false,
        }),
      );
    }
  };

  return (
    <div className={'feedback-for-past-reviews-container'}>
      {pastFeedbackContent.length > 0 ? (
        <div className="feedback-for-past-reviews-table">
          <Table
            columns={requestFeedbackListForPastReviewColumn}
            dataSource={pastFeedbackContent}
            rowKey={item => item.feedbackId}
            onChange={handleTableChange}
            pagination={{
              current: 1,
              pageSize: isViewAll ? pastFeedbackContent.length : 5,
              hideOnSinglePage: true,
              showSizeChanger: false,
              position: ['bottomCenter'],
            }}
            onRow={record => ({
              onClick: () => onRowClick(record),
            })}
          />
          <div className="feedback-for-past-reviews-tips">
            {pastFeedbackContent.length > 5 && (
              <button
                className="tips-view-all-or-less"
                tabIndex={0}
                onClick={() => setIsViewAll(!isViewAll)}
              >
                {isViewAll
                  ? pastCycles.feedbackListForPastReview.viewLessRequests
                  : `${pastCycles.feedbackListForPastReview.viewAllRequests} (${pastFeedbackContent.length})`}
              </button>
            )}
          </div>
        </div>
      ) : (
        renderNoFeedbackForPastReviews()
      )}
    </div>
  );
};

export default PastFeedbackList;
