import { readRequestRejected, updateReviewByReviewer } from '@@/api/review';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import RequestRejectedTips from '@@/common/component/RequestRejectedTips';
import ReviewDetailPreview, {
  DEFAULT_OPERATION_CONFIG,
} from '@@/common/component/ReviewDetailPreview';
import ReviewInputTab from '@@/common/component/ReviewInputTab';
import { PP_CHECK_CYCLE, AP_CHECK_CYCLE, WRITE_ASSESSMENT } from '@@/common/constant/qualaroo';
import { GET_CYCLE_DETAIL_API_PATH, LIMIT_PARTNER_NUM } from '@@/common/constant/review';
import { TABS_KEY } from '@@/common/constant/reviewee';
import { trigSurvey } from '@@/common/qualarooTrigger';
import { RevieweeTableFilterContext } from '@@/context/revieweeTableFilter.context';
import { UserInfoContext } from '@@/context/userInfo.context';
import usePerfModal from '@@/hooks/usePerfModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  selectActiveTab,
  selectAdditionalTalentInformation,
  selectCurrentReviewInputId,
  selectIsCheckedGC,
  selectIsCheckedHP,
  selectIsCheckedNoTalent,
  selectIsEditRatingSummary,
  selectIsEditReviewInput,
  selectReviewInput,
  selectSelectedRating,
  selectSummaryState,
  setActiveTab,
  setCurrentReviewInputId,
  setIsEditRatingSummary,
  setIsEditReviewInput,
  setReviewInput,
} from '@@/redux/slice/revieweeSlice';
import { Button, Tooltip } from 'antd';
import LoadingWrapper from 'common/component/LoadingWrapper';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './index.less';
import LimitMultiPartnerModal from './LimitMultiPartnerModal';
import SubmitAssessmentModal from './SubmitAssessmentModal';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import CycleDetailByIdLayout from '@@/features/performance/reviews/cycleDetailByIdLayout';
import { CYCLE_STATUS_ENUM, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { getCycleByCycleIdAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import { getPerformancePartnerCycleDetailByIdAsync } from '@@/redux/slice/reviewSlice';
import { MY_CYCLE_DETAIL_API, TWER_I_SUPPORT_BY_PP_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { sendRequestData } from '@@/_new_src_/store/cycleDetailSlice';
import GoBackBanner from '@@/_new_src_/features/GoBackBanner';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { getUTCTimestamp } from '@@/_new_src_/utils/common/date';

export default function RevieweeDetail() {
  const activeTab = useSelector(selectActiveTab);
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackEvent } = useMatomo();
  const { id: cycleIdByPathname } = useParams();
  const isEditRatingSummary = useSelector(selectIsEditRatingSummary);
  const currentReviewInputId = useSelector(selectCurrentReviewInputId);
  const selectedRating = useSelector(selectSelectedRating);
  const summaryState = useSelector(selectSummaryState);
  const isCheckedHP = useSelector(selectIsCheckedHP);
  const isCheckedGC = useSelector(selectIsCheckedGC);
  const isCheckedNoTalent = useSelector(selectIsCheckedNoTalent);
  const additionalTalentInformation = useSelector(selectAdditionalTalentInformation);

  const reviewInput = useSelector(selectReviewInput);
  const isEditReviewInput = useSelector(selectIsEditReviewInput);

  const [reviewInputFromBackend, setReviewInputFromBackend] = useState('');
  const [recentSubmittedReviewInputId, setRecentSubmittedReviewInputId] = useState('');
  const [reviewDetailNeedActivatedTab, setReviewDetailNeedActivatedTab] = useState(
    TABS_KEY.REVIEW_DETAIL,
  );
  const [submitAssessmentLoading, setSubmitAssessmentLoading] = useState(false);

  const { reviewees: revieweesLocale, reviewHistory } = useSelector(selectLocaleResource);
  const { detail: locale, multiPartnerModalContent: modalLocale } = revieweesLocale;
  const { updateIsFromDetailPageInitialed } = useContext(RevieweeTableFilterContext);

  const [
    isSubmitAssessmentModalVisible,
    onOpenSubmitAssessmentModal,
    onCloseSubmitAssessmentModal,
  ] = usePerfModal();

  const { userId } = useContext(UserInfoContext);

  const {
    currentCycleDetail: {
      id: cycleId,
      cycleVersion,
      cycleDetailLoading,
      editRequestRejected,
      additionalPartnerAssessments = [],
      assessment,
      additionalPartners,
      twer,
      isOpenCycle,
      isDoneCycle,
      isDraftCycle,
      isClosedCycle,
      isTwer,
      isAdditionalPartners,
      isPerformancePartner,
      duration,
      isProbation,
      createBySystem,
    },
  } = useSelector(cycleDetailByVersion);
  const { isGetRequestLoading: isGetRequestToEditLoading } = useSelector(sendRequestData);
  const getCurrentDetailFunc = useCallback(() => {
    if (cycleVersion === CYCLE_VERSION.NEW) {
      cycleIdByPathname && dispatch(getCycleByCycleIdAsync(+cycleIdByPathname));
    } else {
      cycleIdByPathname && dispatch(getPerformancePartnerCycleDetailByIdAsync(+cycleIdByPathname));
    }
  }, [cycleVersion, cycleIdByPathname]);

  const onBackToList = () => {
    history.push('/performance/twerisupport', { isFromDetailPage: true });
  };

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    updateIsFromDetailPageInitialed(true);
  }, [updateIsFromDetailPageInitialed]);

  const editable = useMemo(
    () => !cycleDetailLoading && !isDoneCycle,
    [isDoneCycle, cycleDetailLoading],
  );

  const onEditRatingSummary = useCallback(() => {
    scrollToTop();
    dispatch(setIsEditRatingSummary(true));
    dispatch(setActiveTab(TABS_KEY.REVIEW_DETAIL));
    history.replace(
      `/performance/twerisupport/${cycleIdByPathname}?activeTab=${TABS_KEY.REVIEW_DETAIL}`,
    );
  }, [dispatch, scrollToTop]);

  const onCancelEditRatingSummary = () => {
    dispatch(setIsEditRatingSummary(false));
    scrollToTop();
  };
  const onEditReviewInput = useCallback(
    (reviewInputId, reviewInputContent) => {
      dispatch(setIsEditReviewInput(true));
      dispatch(setCurrentReviewInputId(reviewInputId));
      dispatch(setReviewInput(reviewInputContent));
      setReviewInputFromBackend(reviewInputContent);
    },
    [dispatch],
  );

  const onClickSubmitAssessment = useCallback(async () => {
    setSubmitAssessmentLoading(true);
    isPerformancePartner &&
      trackEvent({
        category: TWER_I_SUPPORT_BY_PP_API.category,
        action: TWER_I_SUPPORT_BY_PP_API.action.PP_SUBMITS_ASSESSMENT_SUMMARY,
      });
    await updateReviewByReviewer(cycleIdByPathname, {
      rating: selectedRating || assessment.rating,
      summary: summaryState?.trim() || assessment.summary,
      additionalTalentInformation: !isEmpty(additionalTalentInformation)
        ? additionalTalentInformation
        : assessment.additionalTalentInformation,
      targetStatus: CYCLE_STATUS_ENUM.UNREAD,
      // current time is timestamp with 8h after
      currentTime: getUTCTimestamp(dayjs()),
    }).finally(() => setSubmitAssessmentLoading(false));
    perfMessage.success(locale.submitAssessmentModal.successMessage);
    onBackToList();
    trigSurvey(WRITE_ASSESSMENT);
  }, [
    locale.submitAssessmentModal.successMessage,
    onBackToList,
    additionalTalentInformation,
    assessment,
    cycleIdByPathname,
    selectedRating,
    summaryState,
  ]);

  const onProvideReviewInput = useCallback(() => {
    history.replace(
      `/performance/twerisupport/${cycleIdByPathname}?activeTab=${TABS_KEY.REVIEW_INPUT}`,
    );
    dispatch(setIsEditReviewInput(true));
    setReviewDetailNeedActivatedTab(TABS_KEY.REVIEW_INPUT);
    dispatch(setActiveTab(TABS_KEY.REVIEW_INPUT));
    dispatch(setCurrentReviewInputId(''));
  }, [dispatch, history, cycleIdByPathname]);

  const isDisableSubmit = useMemo(() => {
    if (!isEditRatingSummary && isEmpty(assessment)) {
      return true;
    }
    if (
      !isEditRatingSummary &&
      assessment.rating &&
      assessment.summary &&
      assessment.additionalTalentInformation
    ) {
      return false;
    }
    return !(
      isEditRatingSummary &&
      selectedRating &&
      summaryState?.trim() &&
      (isCheckedGC || isCheckedHP || isCheckedNoTalent)
    );
  }, [
    assessment,
    isCheckedGC,
    isCheckedHP,
    isCheckedNoTalent,
    isEditRatingSummary,
    selectedRating,
    summaryState,
  ]);

  const isProvidedReviewInput = useMemo(() => {
    return additionalPartnerAssessments
      ?.map(({ additionalPartnerId }) => additionalPartnerId)
      .includes(userId);
  }, [additionalPartnerAssessments, userId]);

  const onSaveRatingSummary = () => {
    scrollToTop();
    dispatch(setIsEditRatingSummary(false));
    getCurrentDetailFunc();
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onSubmitClick = useCallback(() => {
    if (additionalPartners?.length > LIMIT_PARTNER_NUM) {
      setIsModalVisible(true);
      return;
    }
    onOpenSubmitAssessmentModal();
  }, [additionalPartners, onOpenSubmitAssessmentModal]);

  const titleTip = useMemo(() => {
    if (isProbation) {
      return locale.probationCannotSubmitTooltip;
    } else {
      const endDate = dayjs.utc(new Date(duration?.endTime));
      if (endDate.month() === 5) {
        return locale.h1CannotSubmitTooltip;
      }
      if (endDate.month() === 11) {
        return locale.h2CannotSubmitTooltip;
      }
    }
  }, [duration, isProbation]);

  const createBySystemOrProbationDisabled = useMemo(() => {
    if (isProbation) {
      const endDate = dayjs.utc(new Date(duration?.endTime));
      const currentDate = dayjs.utc(new Date());
      return currentDate < endDate;
    } else {
      const endDate = dayjs.utc(new Date(duration?.endTime));
      if (endDate.month() === 5) {
        return !dayjs.utc(new Date()).isAfter(dayjs.utc(new Date(endDate.year(), 4, 15, 0, 0, 0)));
      }
      if (endDate.month() === 11) {
        return !dayjs.utc(new Date()).isAfter(dayjs.utc(new Date(endDate.year(), 10, 15, 0, 0, 0)));
      }
    }
  }, [duration, isProbation]);

  const renderOperationButton = useCallback(() => {
    if (isClosedCycle) {
      return null;
    }
    if (isAdditionalPartners && !isEditReviewInput && !isProvidedReviewInput) {
      return (
        <Button
          type="primary"
          onClick={onProvideReviewInput}
          className="reviewee-detail-header-button"
        >
          {locale.provideReviewInput}
        </Button>
      );
    }
    if (!isAdditionalPartners) {
      if (isEditRatingSummary || isDraftCycle) {
        return (createBySystem || isProbation) && createBySystemOrProbationDisabled ? (
          <Tooltip title={titleTip}>
            <Button type="primary" disabled className="reviewee-detail-header-button">
              {locale.submitNow}
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            onClick={onSubmitClick}
            disabled={isDisableSubmit}
            className="reviewee-detail-header-button"
          >
            {locale.submitNow}
          </Button>
        );
      }
      return (
        <Button
          type="primary"
          onClick={onEditRatingSummary}
          className="reviewee-detail-header-button"
        >
          {locale.assessNow}
        </Button>
      );
    }
    return null;
  }, [
    isClosedCycle,
    isAdditionalPartners,
    isEditReviewInput,
    isProvidedReviewInput,
    onProvideReviewInput,
    locale.provideReviewInput,
    locale.assessNow,
    locale.submitNow,
    isEditRatingSummary,
    isDraftCycle,
    onEditRatingSummary,
    onSubmitClick,
    isDisableSubmit,
    titleTip,
    isProbation,
    createBySystemOrProbationDisabled,
  ]);

  const onReadRequestRejected = useCallback(
    id => () => {
      isTwer &&
        trackEvent({
          category: MY_CYCLE_DETAIL_API.category,
          action:
            MY_CYCLE_DETAIL_API.action
              .TWER_READS_REJECT_TO_CYCLE_EDITING_REQUEST_IN_THE_CYCLE_DETAIL_PAGE,
        });
      isPerformancePartner &&
        trackEvent({
          category: TWER_I_SUPPORT_BY_PP_API.category,
          action: TWER_I_SUPPORT_BY_PP_API.action.PP_READS_REJECT_TO_CYCLE_IN_CYCLE_DETAIL,
        });
      readRequestRejected(id).then();
    },
    [],
  );

  const onCancelReviewInput = useCallback(() => {
    if (isEmpty(additionalPartnerAssessments)) {
      dispatch(setActiveTab(TABS_KEY.REVIEW_DETAIL));
    }
    dispatch(setIsEditReviewInput(false));
    dispatch(setReviewInput(''));
    setReviewInputFromBackend('');
    dispatch(setCurrentReviewInputId(''));
    scrollToTop();
  }, [scrollToTop, additionalPartnerAssessments, dispatch]);

  const onSubmitReviewInput = useCallback(
    async submittedReviewInputId => {
      await getCurrentDetailFunc();
      scrollToTop();
      setRecentSubmittedReviewInputId(submittedReviewInputId);
      dispatch(setIsEditReviewInput(false));
      dispatch(setReviewInput(''));
      setReviewInputFromBackend('');
      dispatch(setCurrentReviewInputId(''));
    },
    [getCurrentDetailFunc, scrollToTop, dispatch],
  );

  const renderExtra = useCallback(() => {
    if (editRequestRejected && isDoneCycle) {
      return (
        <RequestRejectedTips
          message={reviewHistory.requestRejectedTips}
          onClose={onReadRequestRejected(cycleId)}
        />
      );
    }
    return null;
  }, [
    isDoneCycle,
    onReadRequestRejected,
    editRequestRejected,
    cycleId,
    reviewHistory.requestRejectedTips,
  ]);

  const renderReviewInputTabContent = useCallback(() => {
    if (
      !isEmpty(additionalPartnerAssessments) ||
      activeTab === TABS_KEY.REVIEW_INPUT ||
      isEditReviewInput
    ) {
      return (
        <ReviewInputTab
          isProvideReviewInput={
            editable && isAdditionalPartners && isEditReviewInput && !currentReviewInputId
          }
          reviewInputEditorProps={{
            reviewInput,
            currentReviewInputId,
            onReviewInputChange: value => dispatch(setReviewInput(value)),
            onCancelReviewInput,
            onSubmitReviewInput,
            onEditReviewInput,
            recentSubmittedReviewInputId,
            reviewInputFromBackend,
          }}
        />
      );
    }
    return null;
  }, [
    activeTab,
    currentReviewInputId,
    editable,
    isAdditionalPartners,
    isEditReviewInput,
    onCancelReviewInput,
    onEditReviewInput,
    onSubmitReviewInput,
    recentSubmittedReviewInputId,
    reviewInput,
    reviewInputFromBackend,
    dispatch,
  ]);

  useEffect(() => {
    cycleVersion === CYCLE_VERSION.NEW &&
      isOpenCycle &&
      isAdditionalPartners &&
      trigSurvey(AP_CHECK_CYCLE);
    cycleVersion === CYCLE_VERSION.NEW &&
      isOpenCycle &&
      isPerformancePartner &&
      trigSurvey(PP_CHECK_CYCLE);
  }, [cycleVersion, isAdditionalPartners, isPerformancePartner, isOpenCycle]);

  const isDetailLoading = useMemo(() => {
    if (cycleVersion === CYCLE_VERSION.OLD) {
      return cycleDetailLoading;
    } else {
      return cycleDetailLoading || isGetRequestToEditLoading;
    }
  }, [cycleDetailLoading, isGetRequestToEditLoading]);

  return (
    <CycleDetailByIdLayout v1ApiPath={GET_CYCLE_DETAIL_API_PATH.isReviewer}>
      <div className="reviewee-detail">
        <GoBackBanner
          title={get(twer, 'name', '')}
          goToBackFun={onBackToList}
          onKeyPress={onBackToList}
          children={editable && renderOperationButton()}
        />
        <div className="reviewee-detail-content">
          <LoadingWrapper loading={isDetailLoading}>
            <ReviewDetailPreview
              isRevieweeReviewDetail
              showFeedbackTabThoughNoSubmittedFeedback={false}
              defaultExpand
              ratingSummaryEditable={isEditRatingSummary}
              onSaveRatingSummary={onSaveRatingSummary}
              onCancelEditRatingSummary={onCancelEditRatingSummary}
              onEditRatingSummary={onEditRatingSummary}
              onSendEditRequestSuccess={getCurrentDetailFunc}
              onCheckInNotesModalSuccess={getCurrentDetailFunc}
              onRevieweeReviewEditCancel={scrollToTop}
              needActivatedTab={reviewDetailNeedActivatedTab}
              extra={renderExtra()}
              operation={{ ...DEFAULT_OPERATION_CONFIG }}
              reviewInputTab={renderReviewInputTabContent()}
              isSubmitModalVisible={isSubmitAssessmentModalVisible}
            />
          </LoadingWrapper>
        </div>
        <SubmitAssessmentModal
          visible={isSubmitAssessmentModalVisible}
          onCancel={onCloseSubmitAssessmentModal}
          onClickSubmit={onClickSubmitAssessment}
          loading={submitAssessmentLoading}
        />
        <LimitMultiPartnerModal
          isModalVisible={isModalVisible}
          modalLocale={modalLocale}
          onCloseClick={() => setIsModalVisible(false)}
          onClickConfirm={() => setIsModalVisible(false)}
        />
      </div>
    </CycleDetailByIdLayout>
  );
}
