import { ajax } from '@@/common/ajax';
import { getApiV2Path } from '@@/api/utils';
import { IV2SelfAssessment } from '../store/myCyclesSlice/interface';
import { IAssessment, IPatchRequestParam } from '@@/_new_src_/store/cycleDetailSlice/interface';

export const getOngoingCycle = () => ajax.get(getApiV2Path(`/ongoing-cycle`));

export const getCycleByCycleId = (cycleId: number) => ajax.get(getApiV2Path(`/cycles/${cycleId}`));

export const postRequestToEdit = (cycleId: number, reason: string) =>
  ajax.post(getApiV2Path(`/cycles/${cycleId}/edit-requests`), { reason: reason });

export const getRequestToEditByCycleId = (cycleId: number) =>
  ajax.get(getApiV2Path(`/cycles/${cycleId}/edit-requests/latest`));

export const postSelfAssessment = (cycleId: number, selfAssessment: IV2SelfAssessment) =>
  ajax.post(getApiV2Path(`/cycles/${cycleId}/self-assessment`), {
    content: selfAssessment.content,
    attachmentName: selfAssessment.attachmentName,
    attachmentStorageName: selfAssessment.attachmentStorageName,
  });

export const putSelfAssessment = (selfAssessmentId: number, selfAssessment: IV2SelfAssessment) =>
  ajax.put(getApiV2Path(`/cycles/self-assessment/${selfAssessmentId}`), {
    content: selfAssessment.content,
    attachmentName: selfAssessment.attachmentName,
    attachmentStorageName: selfAssessment.attachmentStorageName,
  });

export const getEditRequestByRequestId = (requestId: number) =>
  ajax.get(getApiV2Path(`/edit-requests/${requestId}`));

export const patchEditRequestByRequestId = (params: IPatchRequestParam) =>
  ajax.patch(getApiV2Path(`/edit-requests/${params.requestId}`), {
    status: params.status,
    reason: params.reason,
  });

export const patchAssessmentByPP = (cycleId: number, assessment: IAssessment) =>
  ajax.patch(getApiV2Path(`/done-cycles/${cycleId}`), {
    rating: assessment.rating,
    summary: assessment.summary,
    additionalTalentInformation: assessment.additionalTalentInformation,
  });

export const postUnmarkAssessmentNonEngagedByTbp = (cycleId: number, assessment: IAssessment) =>
  ajax.post(getApiV2Path(`/cycles/${cycleId}/unmark-assessment-non-engaged`), {
    rating: assessment.rating,
    summary: assessment.summary,
    additionalTalentInformation: assessment.additionalTalentInformation,
    assessmentNonEngaged: assessment.assessmentNonEngaged,
    lastUpdatedAt: assessment.lastUpdatedAt,
  });
