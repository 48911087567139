import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import AvatarImg from '@@/assets/images/avatar.jpg';
import TabPaneComponent from '@@/_new_src_/components/TabPaneComponent';
import { UserInfoContext } from '@@/context/userInfo.context';
import './index.less';
import { Button, Table, TableProps, Tooltip, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  nonEngagedCycles,
  setCurrentTab,
  setNonEngagedType,
  setOverdueTab,
  setAssessmentTab,
  setExpectationTab,
} from '@@/_new_src_/store/nonEngagedCyclesSlice';
import { getNonEngagedCyclesAsync } from '@@/_new_src_/store/nonEngagedCyclesSlice/asyncThunk';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  SORT_DIRECTION_FOR_BE,
  SORT_DIRECTION_FOR_FE,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import { SorterResult } from 'antd/lib/table/interface';
import { nonEngagedCyclesTableSortColumnsName } from '@@/types/antdComponent';
import nonEngagedCyclesLocale from '@@/_new_src_/local/nonEngagedCycles/en_US';
import {
  DEFAULT_TABLE_CONFIG,
  NON_ENGAGED_CYCLES_TAB,
  NON_ENGAGED_CYCLES_TAB_INDEX,
  TAB_INFO,
} from '@@/_new_src_/constants/overdueAndNonEngaged';
import { get, omit } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { setCycleVersion } from '@@/_new_src_/store/myCyclesSlice';
import { getUserListApiWithExited } from '@@/api/user';
import AutoComplete from '@@/common/component/AutoComplete';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import ClearIcon from '@@/common/component/ClearIcon';
import {
  IAutoCompleteOption,
  ICycleDuration,
  INonEngagedCyclesData,
  INonEngagedCycleTableData,
  ITabInfo,
} from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';

import { ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/es/table/interface';
import { formatRangeDuration } from '@@/_new_src_/utils/common/date';
import Tip from '@@/_new_src_/components/Tip';

const NonEngagedCyclesContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userForFilter, setUserForFilter] = useState([] as IAutoCompleteOption[]);
  const { name, role } = useContext(UserInfoContext) as {
    name: string;
    role: string;
  };
  const {
    nonEngagedCycles: {
      tabName,
      tabText,
      operation,
      expectationNonEngaged: expectationNonEngagedLocale,
    },
  } = nonEngagedCyclesLocale;
  const [isGoToDetail, setIsGoToDetail] = useState(false);
  const {
    content,
    loading,
    overdueTab,
    assessmentTab,
    expectationTab,
    nonEngagedType,
    total,
    currentTab,
  } = useSelector(nonEngagedCycles);

  const notChangeTab = useMemo(
    () => nonEngagedType === get(NON_ENGAGED_CYCLES_TAB, currentTab),
    [nonEngagedType, currentTab],
  );

  const updateTabInfo = useCallback(
    ({ updateInfo, tabValue }: { updateInfo: ITabInfo; tabValue?: string }) => {
      const currentTabValue = tabValue || currentTab;
      dispatch(setNonEngagedType(NON_ENGAGED_CYCLES_TAB[currentTabValue]));
      switch (currentTabValue) {
        case NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED:
          dispatch(setAssessmentTab(updateInfo));
          break;
        case NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED:
          dispatch(setExpectationTab(updateInfo));
          break;
        default:
          dispatch(setOverdueTab(updateInfo));
      }
    },
    [currentTab],
  );

  const getTabInfo = useMemo(() => {
    switch (currentTab) {
      case NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED:
        return expectationTab;
      case NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED:
        return assessmentTab;
      default:
        return overdueTab;
    }
  }, [expectationTab, assessmentTab, overdueTab, currentTab]);

  const sortDirection = SORT_DIRECTION_FOR_FE[getTabInfo.sortOrder] as SortOrder;

  const location: { state: { isFromNonEngagedCycleDetail: boolean } } = useLocation();

  useEffect(() => {
    const isFromNonEngagedCycleDetail = get(location, 'state.isFromNonEngagedCycleDetail', false);
    let currentParams = {
      ...omit(getTabInfo, ['employees']),
      employeeIds: getTabInfo.employees.map(item => item.value),
      nonEngagedType: get(NON_ENGAGED_CYCLES_TAB, currentTab),
    };

    if (isFromNonEngagedCycleDetail) {
      setUserForFilter(getTabInfo.employees);
    } else {
      dispatch(setOverdueTab({}));
      dispatch(setAssessmentTab({}));
      dispatch(setExpectationTab({}));
      dispatch(setCurrentTab(NON_ENGAGED_CYCLES_TAB_INDEX.OVERDUE));
      dispatch(setNonEngagedType(get(NON_ENGAGED_CYCLES_TAB, currentTab)));
      currentParams = {
        ...omit(TAB_INFO({}), ['employees']),
        employeeIds: TAB_INFO({}).employees.map(item => item.value),
        nonEngagedType: get(NON_ENGAGED_CYCLES_TAB, NON_ENGAGED_CYCLES_TAB_INDEX.OVERDUE),
      };
    }
    dispatch(
      getNonEngagedCyclesAsync({
        ...currentParams,
      }),
    );
  }, []);

  const getNonEngagedCyclesApiFun = (currentParams: ITabInfo, currentParamsTab: string) => {
    setUserForFilter(currentParams.employees);
    dispatch(
      getNonEngagedCyclesAsync({
        ...currentParams,
        employeeIds: currentParams.employees.map(item => item.value),
        nonEngagedType: get(NON_ENGAGED_CYCLES_TAB, currentParamsTab),
      }),
    );
  };

  const initialUserForFilter =
    !isGoToDetail && !location.state?.isFromNonEngagedCycleDetail ? [] : getTabInfo.employees;

  const createNonEngagedCyclesTableColumn: ColumnType<INonEngagedCyclesData>[] = [
    {
      title: "Twer's name",
      dataIndex: 'twerName',
      key: 'twerName',
      width: 100,
      ellipsis: { showTitle: false },
      defaultSortOrder:
        notChangeTab && getTabInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.twerName
          ? sortDirection
          : undefined,
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (twerName: string) => (
        <Tooltip placement="topLeft" title={twerName}>
          {twerName}
        </Tooltip>
      ),
    },
    {
      title: "PP's name",
      dataIndex: 'performancePartnerName',
      key: 'performancePartnerName',
      width: 100,
      ellipsis: { showTitle: false },
      defaultSortOrder:
        notChangeTab &&
        getTabInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.performancePartnerName
          ? sortDirection
          : undefined,
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (performancePartnerName: string) => (
        <Tooltip placement="topLeft" title={performancePartnerName}>
          {performancePartnerName}
        </Tooltip>
      ),
    },
    {
      title: 'Supervisory Org',
      dataIndex: 'superOrgName',
      key: 'superOrgName',
      width: 140,
      ellipsis: { showTitle: false },
      defaultSortOrder:
        notChangeTab && getTabInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.superOrgName
          ? sortDirection
          : undefined,
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (superOrgName: string) => (
        <Tooltip placement="topLeft" title={superOrgName}>
          {superOrgName}
        </Tooltip>
      ),
    },
    {
      title: 'Home office',
      dataIndex: 'office',
      key: 'office',
      width: 100,
      ellipsis: { showTitle: false },
      defaultSortOrder:
        notChangeTab && getTabInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.office
          ? sortDirection
          : undefined,
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (office: string) => {
        return (
          <Tooltip placement="topLeft" title={office}>
            {office}
          </Tooltip>
        );
      },
    },
  ];

  const action = {
    title: 'Actions',
    key: 'actions',
    width: 190,
    ellipsis: { showTitle: false },
    render: ({ canBeUnmarked }: { canBeUnmarked: boolean }) => {
      const backgroundColor = canBeUnmarked ? '#1A73EB' : '#00000066';
      return <div style={{ color: backgroundColor }}>{operation}</div>;
    },
  };

  const cycleDurationColumn = {
    title: 'Cycle duration',
    key: 'cycleDuration',
    dataIndex: 'cycleDuration',
    width: 190,
    ellipsis: { showTitle: false },
    defaultSortOrder:
      notChangeTab && getTabInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.cycleDuration
        ? sortDirection
        : undefined,
    ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
    render: (cycleDuration: ICycleDuration) => {
      const { startTime, endTime } = cycleDuration;
      return <div>{formatRangeDuration(startTime, endTime)}</div>;
    },
  };

  const createTableColumns = useMemo(() => {
    const currentColumns = createNonEngagedCyclesTableColumn;
    if (currentTab === NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED) {
      currentColumns.push(action);
    }

    if (currentTab === NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED) {
      currentColumns.push(cycleDurationColumn);
    }

    return currentColumns;
  }, [currentTab]);

  const handleOverdueTableChange: TableProps<INonEngagedCyclesData>['onChange'] = (
    pagination,
    _,
    sorter,
  ) => {
    const { columnKey, order } = sorter as SorterResult<INonEngagedCyclesData>;

    const defaultSortColumn =
      (currentTab === NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED &&
        nonEngagedCyclesTableSortColumnsName.actions) ||
      (currentTab === NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED &&
        nonEngagedCyclesTableSortColumnsName.cycleDuration) ||
      nonEngagedCyclesTableSortColumnsName.superOrgName;

    const currentParams = {
      sortColumn: order
        ? nonEngagedCyclesTableSortColumnsName[
            columnKey as keyof typeof nonEngagedCyclesTableSortColumnsName
          ]
        : defaultSortColumn,
      sortOrder: order ? SORT_DIRECTION_FOR_BE[order] : SORT_DIRECTION_FOR_BE.ascend,
      pageNumber: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
      employees: getTabInfo.employees || userForFilter,
    };
    updateTabInfo({ updateInfo: currentParams });
    dispatch(setNonEngagedType(get(NON_ENGAGED_CYCLES_TAB, currentTab)));
    getNonEngagedCyclesApiFun(currentParams, currentTab);
  };

  const handleSelectRow = (record: { cycleId: number; cycleVersion: number }) => {
    dispatch(setCycleVersion(record.cycleVersion));
    setIsGoToDetail(true);
    history.push({
      pathname: `nonEngagedCycles/${record.cycleId}`,
      state: { isFromNonEngagedCycleDetail: true },
    });
  };

  const {
    dashboardV2: {
      reviews: { filter: locale },
    },
  } = useSelector(selectLocaleResource);

  const autoCompleteProps = {
    showArrow: false,
    showSearch: true,
    notFoundContent: null,
    clearIcon: <ClearIcon />,
  };

  const findNonEngagedCyclesByUser = () => {
    updateTabInfo({
      updateInfo: {
        ...getTabInfo,
        employees: userForFilter,
      },
    });
    dispatch(
      getNonEngagedCyclesAsync({
        sortColumn: getTabInfo.sortColumn,
        sortOrder: getTabInfo.sortOrder,
        pageNumber: DEFAULT_TABLE_CONFIG.DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_TABLE_CONFIG.DEFAULT_PAGE_SIZE,
        employeeIds: userForFilter.map(item => item.value),
        nonEngagedType: get(NON_ENGAGED_CYCLES_TAB, currentTab),
      } as INonEngagedCycleTableData),
    );
  };
  const renderOverdueCyclesContainer = () => {
    return (
      <div className={'overdue-cycles-container'}>
        <div className={'non-engaged-cycle-filter'}>
          <div className={'title'}>Filter area</div>
          <div className={'sub-title'}>By Twer's name</div>
          <div className={'filter-by-twers-name'}>
            <AutoComplete
              mode="multiple"
              {...autoCompleteProps}
              placeholder={locale.reviewees.placeholder}
              fetchApi={getUserListApiWithExited()}
              onChange={setUserForFilter}
              initialInputValue={initialUserForFilter}
            >
              {(list: { userId: number; name: string }[], Option: typeof Select.Option) => {
                return list.map(({ userId, name }) => (
                  <Option key={String(userId)} label={name} value={userId}>
                    {`${name}, ${userId}`}
                  </Option>
                ));
              }}
            </AutoComplete>
            <Button className={'find-button'} onClick={() => findNonEngagedCyclesByUser()}>
              <i className="ri-search-line" />
              <div>Find</div>
            </Button>
          </div>
        </div>
        {currentTab === NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED && (
          <div className={'tip-block'}>
            <Tip
              wording={expectationNonEngagedLocale.tip}
              className={'expectation-non-engaged-tip'}
            />
          </div>
        )}
        <div className="total-section">
          {content.length > 0 && (
            <div>
              <span className="total-two-month-overdue-twers">{`${total} ${tabText(
                currentTab,
              )}`}</span>
            </div>
          )}
        </div>
        <Table<INonEngagedCyclesData>
          columns={createTableColumns}
          dataSource={content}
          onChange={handleOverdueTableChange}
          onRow={record => {
            return {
              onClick: () => {
                handleSelectRow(record);
              },
            };
          }}
          pagination={
            total !== undefined && total > TABLE_DEFAULT_INFO.PAGE_SIZE
              ? {
                  ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
                  total: total,
                  current: getTabInfo.pageNumber,
                  pageSize: getTabInfo.pageSize,
                }
              : false
          }
        />
      </div>
    );
  };

  const changeTab = (value: string) => {
    dispatch(setCurrentTab(value));
    const getCurrentTab =
      (value === NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED && assessmentTab) ||
      (value === NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED && expectationTab) ||
      overdueTab;
    updateTabInfo({
      updateInfo: {
        ...getCurrentTab,
      },
      tabValue: value,
    });
    getNonEngagedCyclesApiFun(getCurrentTab, value);
  };

  return (
    <LoadingWrapper loading={loading}>
      <div className="non-engaged-and-overdue-page">
        <div className="user-info">
          <div className="avatar">
            <img src={AvatarImg} alt="avatar" />
          </div>
          <div>
            <div className="username">{name}</div>
            <div style={{ fontSize: 16 }}>{role}</div>
          </div>
        </div>
        <div className="non-engaged-and-overdue-cycles-container">
          <TabPaneComponent
            tabList={[
              tabName.OverdueCycles,
              tabName.assessmentNonEngagedCycles,
              tabName.CyclesWithTemplateExpectations,
            ]}
            children={[
              renderOverdueCyclesContainer(),
              renderOverdueCyclesContainer(),
              renderOverdueCyclesContainer(),
            ]}
            activeKey={currentTab}
            updateActiveKey={changeTab}
            size={'large'}
          />
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default NonEngagedCyclesContainer;
