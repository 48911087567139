import { useLocalStorageState } from 'ahooks';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { IPersonInfo } from '@@/_new_src_/store/myCyclesSlice/interface';
import { IUploadContext } from '@@/_new_src_/types/myCycles';
import { IV2Project } from '@@/_new_src_/types/common';
export const prefix = 'CYCLE_INFO';
export default function useLocalStorageCycle() {
  const [performancePartnerState, setPerformancePartnerState] =
    useLocalStorageState<IPersonInfo | null>(`${prefix}_PERFORMANCE_PARTNER`, null);

  const [additionalPartnersState, setAdditionalPartnersState] = useLocalStorageState<
    IPersonInfo[] | null
  >(`${prefix}_ADDITIONAL_PARTNERS`, null);

  const [projectState, setProjectState] = useLocalStorageState<IV2Project | null>(
    `${prefix}_PROJECT`,
    null,
  );
  const [durationState, setDurationState] = useLocalStorageState<string[]>(
    `${prefix}_DURATION`,
    [],
  );

  const [contextAttachmentState, setContextAttachmentState] =
    useLocalStorageState<IUploadContext | null>(`${prefix}_CONTEXT_ATTACHMENT`, null);

  const [contextContentState, setContextContentState] = useLocalStorageState(
    `${prefix}_CONTEXT_CONTENT`,
    '',
  );

  const isLocalCycleEmpty = useMemo(() => {
    return (
      isEmpty(performancePartnerState) &&
      isEmpty(additionalPartnersState) &&
      isEmpty(projectState) &&
      isEmpty(durationState) &&
      isEmpty(contextAttachmentState) &&
      isEmpty(contextContentState)
    );
  }, [
    additionalPartnersState,
    projectState,
    durationState,
    performancePartnerState,
    contextAttachmentState,
    contextContentState,
  ]);

  const removeLocalCycle = useCallback(() => {
    setPerformancePartnerState();
    setAdditionalPartnersState();
    setProjectState();
    setDurationState();
    setContextAttachmentState();
    setContextContentState();
  }, [
    setAdditionalPartnersState,
    setProjectState,
    setDurationState,
    setPerformancePartnerState,
    setContextAttachmentState,
    setContextContentState,
  ]);

  return {
    performancePartnerState,
    setPerformancePartnerState,
    additionalPartnersState,
    setAdditionalPartnersState,
    projectState,
    setProjectState,
    durationState,
    setDurationState,
    isLocalCycleEmpty,
    removeLocalCycle,
    contextAttachmentState,
    setContextAttachmentState,
    contextContentState,
    setContextContentState,
  };
}
