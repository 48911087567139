import { getReviewDetailById } from '@@/api/review';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import RevieweeReviewEditor from '@@/common/component/RevieweeReviewEditor';
import { CREATE_CYCLE } from '@@/common/constant/qualaroo';
import { REVIEW_STEP } from '@@/common/constant/steps';
import { trigSurvey } from '@@/common/qualarooTrigger';
import { ReviewStepsContext } from '@@/context/reviewSteps.context';
import { useRequest } from 'ahooks';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './index.less';
import { goToCycleDetailPage } from '@@/_new_src_/utils/feature/cycle';
import { UserInfoContext } from '@@/context/userInfo.context';

const SetReview = () => {
  const history = useHistory();
  const { id: reviewId } = useParams();

  const {
    loading,
    data: reviewDetail,
    run: getReviewDetail,
  } = useRequest(params => getReviewDetailById(params), {
    manual: true,
    initialData: {},
    formatResult: response => response.data,
  });

  const { currentOpeningCycleId } = useContext(UserInfoContext);
  const reviewStepsContext = useContext(ReviewStepsContext);
  const { probationStatus: cycleProbationStatus } = reviewDetail;

  useEffect(() => {
    reviewStepsContext.setCurrent(REVIEW_STEP.EXPECTATION_ALIGNMENT);
  }, [reviewStepsContext]);

  useEffect(() => {
    if (reviewId) {
      getReviewDetail(reviewId);
    }
  }, [getReviewDetail, reviewId]);

  const backToCycleDetailPage = () => {
    const goToCycleDetailPageLink = goToCycleDetailPage({
      currentOpeningCycleId: currentOpeningCycleId ? +currentOpeningCycleId : null,
      cycleId: +reviewId,
    });
    history.push(goToCycleDetailPageLink);
  };

  const onEditSuccess = () => {
    backToCycleDetailPage();
  };

  const onEditSuccessAndTriggerQualaroo = () => {
    onEditSuccess();
    trigSurvey(CREATE_CYCLE);
  };

  const onEditCancel = () => {
    backToCycleDetailPage();
  };

  const classConfig = {
    name: 'set-review',
  };

  return (
    <LoadingWrapper loading={loading}>
      <div className="page-set-review">
        {isEmpty(reviewDetail) ? (
          <RevieweeReviewEditor
            classConfig={classConfig}
            onEditSuccess={onEditSuccessAndTriggerQualaroo}
            onEditCancel={onEditCancel}
          />
        ) : (
          <RevieweeReviewEditor
            review={reviewDetail}
            updatedMode
            onEditSuccess={onEditSuccess}
            onEditCancel={onEditCancel}
            classConfig={classConfig}
            cycleProbationStatus={cycleProbationStatus}
          />
        )}
      </div>
    </LoadingWrapper>
  );
};

export default SetReview;
