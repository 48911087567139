import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  SORT_DIRECTION_FOR_BE,
  SORT_DIRECTION_FOR_FE,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import { Table, TableProps, Tooltip } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import './index.less';
import { isEmpty } from 'lodash';
import {
  FEEDBACK_VERSION,
  FEEDBACK_SORT_COLUMNS_NAME,
  FeedbackStatusColorMap,
  FeedbackStatusLabel,
} from '@@/_new_src_/constants/feedback';
import { IGivenFeedback } from '@@/_new_src_/store/V2FeedbackSlice/interface';
import locale from '@@/_new_src_/local/feedback/en_US';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  V2FeedbackStore,
  setFeedbackDetail,
  setV1FeedbackModalInfo,
} from '@@/_new_src_/store/V2FeedbackSlice';
import { SorterResult } from 'antd/lib/table/interface';
import { getGivenFeedbackRequestsAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import V1DetailModal from '@@/_new_src_/page/feedback/component/V1DetailModal';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { onEnter } from '@@/_new_src_/utils';
import { getFeedbackStatus } from '@@/_new_src_/utils/feature';

const GiveFeedbackTableList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    feedback: {
      giveFeedbackTab: {
        title: {
          requestName,
          requesterRole,
          dueDate,
          completionDate,
          status: statusLocale,
          action,
        },
      },
      actions: { edit, view },
    },
  } = locale;

  const {
    givenFeedbacksStore: {
      givenFeedbackRes: { total, pageNumber, pageSize, content },
      tableConfig: { givenFeedbackSortColumn, status, sortOrder: tableSortOrder },
    },
  } = useSelector(V2FeedbackStore);

  const sortDirection = SORT_DIRECTION_FOR_FE[tableSortOrder];

  const [isVisibleForV1DetailModal, setVisibleForV1DetailModal] = useState(false);
  const [curFeedbackId, setCurFeedbackId] = useState(NaN);

  const handleTableChange: TableProps<IGivenFeedback>['onChange'] = (pagination, _, sorter) => {
    const { columnKey, order } = sorter as SorterResult<IGivenFeedback>;
    const sortColumnName = order
      ? FEEDBACK_SORT_COLUMNS_NAME[columnKey as keyof typeof FEEDBACK_SORT_COLUMNS_NAME]
      : FEEDBACK_SORT_COLUMNS_NAME.default;
    const sortOrder = order ? SORT_DIRECTION_FOR_BE[order] : SORT_DIRECTION_FOR_BE.ascend;
    const pageNumber = pagination.current || 1;
    const pageSize = pagination.pageSize || 10;

    dispatch(
      getGivenFeedbackRequestsAsync({
        status: status,
        givenFeedbackSortColumn: sortColumnName,
        sortOrder: sortOrder,
        pageSize: pageSize,
        pageNumber: pageNumber,
      }),
    );
  };

  const goToDetailPage = (id: number, version: number | null) => {
    const isV2Feedback = version === FEEDBACK_VERSION.V2;
    if (isV2Feedback) {
      const detailLink = FEEDBACK_PAGE_LINK.DETAIL + id.toString();
      history.push(detailLink);
    } else {
      setCurFeedbackId(id);
      setVisibleForV1DetailModal(true);
    }
  };

  const goToGivenPage = (id: number, version: number | null) => {
    const isV2Feedback = version === FEEDBACK_VERSION.V2;
    if (isV2Feedback) {
      const givenLink = FEEDBACK_PAGE_LINK.GIVEN + id.toString();
      history.push(givenLink);
    } else {
      dispatch(
        setV1FeedbackModalInfo({
          isWriteFeedbackModalVisible: true,
          feedbackId: id,
        }),
      );
    }
  };

  const requestFeedbackListColumn = [
    {
      title: requestName,
      dataIndex: 'requesterName',
      key: 'requesterName',
      ellipsis: {
        showTitle: false,
      },
      sortOrder:
        givenFeedbackSortColumn == FEEDBACK_SORT_COLUMNS_NAME.requesterName ? sortDirection : '',
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: requesterRole,
      dataIndex: 'requesterRole',
      key: 'requesterRole',
      ellipsis: {
        showTitle: false,
      },
      sortOrder:
        givenFeedbackSortColumn == FEEDBACK_SORT_COLUMNS_NAME.requesterRole ? sortDirection : '',
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: dueDate,
      dataIndex: 'dueDate',
      key: 'dueDate',
      sortOrder: givenFeedbackSortColumn == FEEDBACK_SORT_COLUMNS_NAME.dueDate ? sortDirection : '',
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (dueDate: string) => dayjs.utc(dueDate).format('YYYY-MM-DD'),
    },
    {
      title: completionDate,
      dataIndex: 'completionDate',
      key: 'completionDate',
      sortOrder:
        givenFeedbackSortColumn == FEEDBACK_SORT_COLUMNS_NAME.completionDate ? sortDirection : '',
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (completionDate: string) =>
        isEmpty(completionDate) ? '' : dayjs.utc(completionDate).format('YYYY-MM-DD'),
    },
    {
      title: statusLocale,
      dataIndex: 'status',
      key: 'status',
      sortOrder: givenFeedbackSortColumn == FEEDBACK_SORT_COLUMNS_NAME.status ? sortDirection : '',
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (status: string) => (
        <div className="status-section">
          <div className="dot" style={{ background: FeedbackStatusColorMap[status] }} />
          <>{FeedbackStatusLabel[status]}</>
        </div>
      ),
    },
    {
      title: action,
      width: 150,
      key: 'action',
      render: ({
        status,
        feedbackId,
        version,
        cycleStatus,
      }: {
        status: string;
        feedbackId: number;
        version: number | null;
        cycleStatus: string;
      }) => {
        const { isPendingFeedback, isDraftedFeedback, isSubmittedFeedback } =
          getFeedbackStatus(status);

        if (isPendingFeedback || isDraftedFeedback) {
          return (
            <div className="action-section">
              <div
                tabIndex={0}
                aria-label={'edit give feedback'}
                onKeyDown={e => onEnter(e, () => goToGivenPage(feedbackId, version))}
                className="edit"
                onClick={() => {
                  goToGivenPage(feedbackId, version);
                }}
              >
                {edit}
              </div>
            </div>
          );
        }

        if (isSubmittedFeedback) {
          const { isOpenOrDraftCycle } = getCycleStatus(cycleStatus);
          return (
            <>
              {isOpenOrDraftCycle ? (
                <div className="action-section">
                  <div
                    tabIndex={0}
                    aria-label={'edit give feedback'}
                    onKeyDown={e => onEnter(e, () => goToGivenPage(feedbackId, version))}
                    className="edit"
                    onClick={() => {
                      goToGivenPage(feedbackId, version);
                    }}
                  >
                    {edit}
                  </div>
                </div>
              ) : (
                <div className="action-section">
                  <div
                    tabIndex={0}
                    aria-label={'view give feedback'}
                    onKeyDown={e => onEnter(e, () => goToDetailPage(feedbackId, version))}
                    className="view"
                    onClick={() => {
                      goToDetailPage(feedbackId, version);
                    }}
                  >
                    {view}
                  </div>
                </div>
              )}
            </>
          );
        }

        return null;
      },
    },
  ];

  return (
    <div className={'give-feedback-table-container'}>
      <Table
        className={'table'}
        columns={requestFeedbackListColumn}
        dataSource={content}
        rowKey={item => item.feedbackId}
        onChange={handleTableChange}
        pagination={{
          ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
          total: total || TABLE_DEFAULT_INFO.TOTAL,
          current: pageNumber || TABLE_DEFAULT_INFO.PAGE_NUMBER,
          pageSize: pageSize || TABLE_DEFAULT_INFO.PAGE_SIZE,
        }}
      />

      <V1DetailModal
        feedbackId={curFeedbackId}
        onCancel={() => {
          setVisibleForV1DetailModal(false);
          dispatch(setFeedbackDetail({}));
        }}
        visible={isVisibleForV1DetailModal}
      />
    </div>
  );
};

export default GiveFeedbackTableList;
