import './index.less';
import React, { useEffect, useMemo } from 'react';
import { Button } from 'antd';
import {
  currentCycleId,
  isPostOpenSuccess,
  setCurrentUpdatedExpectation,
  setCycleDetailData,
  setCycleDisplayPart,
  setIsPostOpenSuccess,
} from '@@/_new_src_/store/myCyclesSlice';
import { CYCLE_DISPLAY_PART } from '@@/_new_src_/constants/myCycles';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmModalType,
  setConfirmModalLoading,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IExpectationItem, IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import { postOpenNormalCycleAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import {
  cycleDetailByVersion,
  setCurrentCycleDetail,
} from '@@/redux/slice/cycleDetailByVersionSlice';
import { isEmpty } from 'lodash';
import { trigAutoSurvey } from '@@/common/qualarooTrigger';
import { MY_CYCLE_LIST_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const AddAnotherExpectationModal = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    myCycles: {
      cycleModal: { doneAddCycle },
      addAnotherExpectationModal: { addContent, doneContent },
    },
  } = localeResource as IMyCyclesLocale;

  const currentConfirmModalType = useSelector(confirmModalType);
  const cycleId = useSelector(currentCycleId) as number;
  const openSuccess = useSelector(isPostOpenSuccess);

  const addAnotherExpectationHandler = () => {
    dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.EXPECTATION_FORM));
    dispatch(setCurrentUpdatedExpectation(null));
  };

  const doneHandler = () => {
    dispatch(setConfirmModalStatus(true));
    dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.DONE_CYCLE));
  };

  const handleOkFunction = () => {
    const items = [230861, 230862, 230786];
    trigAutoSurvey(items);

    dispatch(setConfirmModalLoading(true));
    trackEvent({
      category: MY_CYCLE_LIST_API.category,
      action: MY_CYCLE_LIST_API.action.TWER_OPENS_V2_CYCLE,
    });
    dispatch(postOpenNormalCycleAsync(cycleId));
  };

  useEffect(() => {
    if (openSuccess) {
      dispatch(setIsPostOpenSuccess(false));
      dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.DEFAULT_DISPLAY));
      dispatch(setCurrentCycleDetail({}));
      dispatch(setCycleDetailData({}));
      window.open(CYCLE_PAGE_LINK.MY_CYCLES, '_self');
    }
    dispatch(setConfirmModalLoading(false));
    if (
      !(
        currentConfirmModalType === CONFIRM_MODAL_TYPE_INFO.OVERDUE_CYCLE ||
        currentConfirmModalType === CONFIRM_MODAL_TYPE_INFO.ASSESSMENT_NON_ENGAGED_CYCLE ||
        currentConfirmModalType === CONFIRM_MODAL_TYPE_INFO.EXPECTATION_NON_ENGAGED_CYCLE
      )
    ) {
      dispatch(setConfirmModalStatus(false));
      dispatch(setConfirmModalType(''));
    }
  }, [openSuccess]);

  const confirmModalProps = () => {
    return {
      ...doneAddCycle,
      handleOkFunc: handleOkFunction,
    };
  };

  const {
    currentCycleDetail: { expectations: expectationListData, isNewCycle },
  } = useSelector(cycleDetailByVersion) as {
    currentCycleDetail: { expectations: IExpectationItem[]; isNewCycle: boolean };
  };

  const isShowAddAnotherButton = useMemo(() => {
    return !isEmpty(expectationListData) && isNewCycle;
  }, [expectationListData, isNewCycle]);

  return (
    <>
      {isShowAddAnotherButton && (
        <div className={'add-another-expectation-modal'}>
          <Button
            className={'add-another-expectation-button'}
            onClick={addAnotherExpectationHandler}
          >
            <i className="ri-add-circle-fill" />
            <div className="content">{addContent}</div>
          </Button>
          <Button type={'primary'} className="done-button" onClick={doneHandler}>
            <i className="ri-checkbox-circle-fill" />
            <div className="content">{doneContent}</div>
          </Button>
          {currentConfirmModalType === CONFIRM_MODAL_TYPE_INFO.DONE_CYCLE && (
            <ConfirmModal {...confirmModalProps()} />
          )}
        </div>
      )}
    </>
  );
};

export default AddAnotherExpectationModal;
