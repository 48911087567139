import { Checkbox, Table, Tag, Tooltip } from 'antd';
import { find, get } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalStorageState } from 'ahooks';
import { useDispatch, useSelector } from 'react-redux';
import DarkSelect from '@@/common/component/DarkSelect';
import { EMPLOYEE_STATUS, REVIEWEE_SORT_FIELD, TABS_KEY } from '@@/common/constant/reviewee';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  SORT_DIRECTION_FOR_BE,
  SORT_DIRECTION_FOR_FE,
} from '@@/_new_src_/constants/table';
import { RevieweeTableFilterContext } from '@@/context/revieweeTableFilter.context';
import './index.less';
import {
  setActiveTab,
  setCurrentReviewInputId,
  setIsCheckedGC,
  setIsCheckedHP,
  setIsCheckedNoTalent,
  setIsEditRatingSummary,
  setIsEditReviewInput,
  setReviewInput,
  setSelectedRating,
  setSummaryState,
} from '@@/redux/slice/revieweeSlice';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { overdueAndNonEngagedTip, setCycleVersion } from '@@/_new_src_/store/myCyclesSlice';
import { getInputInSelectDom, updateAriaForSelect } from '@@/_new_src_/utils/common/axe';
import RequestToEditTag from '@@/_new_src_/features/RequestToEditTag';
import { userStore } from '@@/_new_src_/store/userSlice';
import { getPPListAsync } from '@@/_new_src_/store/userSlice/asyncThunk';
import Notification from '@@/_new_src_/components/Notification';
import commonLocale from '@@/_new_src_/local/common/en_US';
import myCyclesLocale from '@@/_new_src_/local/myCycles/en_US';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { CYCLE_STATUS_ENUM } from '@@/_new_src_/constants/myCycles';
import { REVIEWER_TYPE } from '@@/common/constant/review';
import CycleStatusBadge from '@@/_new_src_/features/CycleStatusBadge';
import { formatRangeDuration } from '@@/_new_src_/utils/common/date';
import { getUserId } from '@@/_new_src_/utils/common/auth';
import { PROBATION_STATUS } from '@@/_new_src_/constants/userEnum';
import { getAssessmentNonEngagedTips } from '@@/_new_src_/utils/feature';

const getSortConfig = (sortedInfo = {}, sortColumnName) => ({
  ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  key: sortColumnName,
  sortOrder:
    sortedInfo.sortColumnName === sortColumnName && SORT_DIRECTION_FOR_FE[sortedInfo.sortOrder],
});

const {
  tag: { assessmentNonEngagedTag, nonEngagedCycleByTBPTag },
} = commonLocale;

const ReviewStatusBadgeWrapper = ({ tooltip, status, children }) => (
  <div className="operation">
    <Tooltip title={tooltip} placement="topLeft">
      <CycleStatusBadge status={status} isShortStatus />
      <span className="wrapper">{children}</span>
    </Tooltip>
  </div>
);

const createColumns = (locale, tableFilter, showRating) =>
  [
    {
      title: locale.revieweeName,
      width: 227,
      ellipsis: { showTitle: false },
      ...getSortConfig(tableFilter, REVIEWEE_SORT_FIELD.REVIEWEE_NAME),
      render: ({ reviewee, probationStatus, noneEngaged, assessmentNonEngaged }) => (
        <div>
          {assessmentNonEngaged && (
            <div className={'assessment-non-engagement-tag'}>{assessmentNonEngagedTag}</div>
          )}
          <div className="name-and-tag">
            {noneEngaged && (
              <Tooltip placement="topLeft" title={nonEngagedCycleByTBPTag}>
                <div>
                  <Tag className="non-engaged-cycle-tag" key="cycle-opened-by-TBP">
                    {nonEngagedCycleByTBPTag}
                  </Tag>
                </div>
              </Tooltip>
            )}
            <Tooltip placement="topLeft" title={reviewee.name}>
              <div className="name-column">
                <div className="name">{reviewee.name}</div>
                {reviewee.status === EMPLOYEE_STATUS.EXITED && (
                  <div className="exited-tip">{locale.exitedTip}</div>
                )}
                {probationStatus === PROBATION_STATUS.UNDER_PROBATION && (
                  <div className="probation-tip">{locale.probationTip}</div>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: locale.role,
      dataIndex: 'reviewee',
      width: 180,
      ellipsis: { showTitle: false },
      ...getSortConfig(tableFilter, REVIEWEE_SORT_FIELD.ROLE),
      render: ({ role }) => (
        <Tooltip placement="topLeft" title={role}>
          {role}
        </Tooltip>
      ),
      visible: true,
    },
    {
      title: locale.duration,
      width: 250,
      ...getSortConfig(tableFilter, REVIEWEE_SORT_FIELD.REVIEW_DURATION),
      render: ({ duration: { startTime, endTime } } = {}) =>
        formatRangeDuration(startTime, endTime),
    },
    {
      title: locale.rating,
      dataIndex: 'assessment',
      width: 210,
      ellipsis: { showTitle: false },
      ...getSortConfig(tableFilter, REVIEWEE_SORT_FIELD.RATING),
      render: assessment => assessment && assessment.rating,
      hidden: !showRating,
    },
    {
      title: locale.status,
      width: 317,
      render: (
        { status, overdue, canRequestToEdit, canEdit },
        { contributorReviewInputCount, reviewers },
      ) => {
        const isReviewer = get(reviewers, 'reviewer.id') === getUserId();
        const { isDoneCycle, isOpenOrDraftCycle, isOverdueCycle } = getCycleStatus(status, overdue);

        if (isReviewer && contributorReviewInputCount && isOpenOrDraftCycle) {
          return (
            <ReviewStatusBadgeWrapper
              tooltip={locale.contributorReviewInputCountTip(contributorReviewInputCount)}
              status={status}
            >
              <span className="count">{contributorReviewInputCount}</span>
            </ReviewStatusBadgeWrapper>
          );
        }

        if (isReviewer && isDoneCycle) {
          return (
            <div className="operation-request-to-edit">
              <CycleStatusBadge status={status} isShortStatus />
              <RequestToEditTag
                isDoneCycle={isDoneCycle}
                canRequestToEdit={canRequestToEdit}
                canEdit={canEdit}
              />
            </div>
          );
        }

        return (
          <div className="operation">
            <CycleStatusBadge
              status={isOverdueCycle ? CYCLE_STATUS_ENUM.OVERDUE : status}
              isShortStatus
            />
          </div>
        );
      },
    },
  ].filter(column => !column.hidden);

const TwerISupport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showRating, setShowRating] = useLocalStorageState('SHOW_RATING', false);
  const { reviewees: locale } = useSelector(selectLocaleResource);
  const {
    PPList: { loading, total, content },
  } = useSelector(userStore);
  const { hasAssessmentNonEngagedCycleAsPP } = useSelector(overdueAndNonEngagedTip);
  const [showAssessmentNonEngagedTip, setShowAssessmentNonEngagedTip] = useState(
    hasAssessmentNonEngagedCycleAsPP,
  );
  const {
    tableFilter,
    updateTableFilter,
    isFromDetailPageInitialed,
    updateIsFromDetailPageInitialed,
  } = useContext(RevieweeTableFilterContext);

  const availableTypeList = useMemo(() => {
    const selectLocale = locale.list.reviewerTypeSelect;
    return Object.keys(REVIEWER_TYPE).map(key => {
      const name = key.toLowerCase();
      return {
        label: selectLocale[name].label,
        value: REVIEWER_TYPE[key],
        tips: selectLocale[name].tips,
      };
    });
  }, [locale.list.reviewerTypeSelect]);

  const getRevieweesData = useCallback(
    async ({ pageNumber, pageSize, sortColumnName, sortOrder = '', reviewerType = '' }) => {
      const requestParams = {
        ...DEFAULT_TABLE_PAGINATION,
        pageNumber,
        pageSize,
        sortColumnName: sortOrder
          ? REVIEWEE_SORT_FIELD[sortColumnName]
          : REVIEWEE_SORT_FIELD.DEFAULT,
        sortOrder,
        reviewerType,
      };
      updateTableFilter(requestParams);
      dispatch(getPPListAsync(requestParams));
    },
    [updateTableFilter],
  );

  const isFromDetailPage = useMemo(() => get(location, 'state.isFromDetailPage'), [location]);

  const onGotoRevieweeDetailPage = useCallback(
    ({ id, cycleVersion }) =>
      () => {
        dispatch(setCycleVersion(cycleVersion));
        history.push(`/performance/twerisupport/${id}`);
        dispatch(setIsEditReviewInput(false));
        dispatch(setReviewInput());
        dispatch(setActiveTab(TABS_KEY.REVIEW_DETAIL));
        dispatch(setCurrentReviewInputId());
        dispatch(setIsEditRatingSummary(false));
        dispatch(setSummaryState(undefined));
        dispatch(setSelectedRating(undefined));
        dispatch(setIsCheckedHP(undefined));
        dispatch(setIsCheckedGC(undefined));
        dispatch(setIsCheckedNoTalent(undefined));
      },
    [dispatch, history],
  );

  const columns = useMemo(
    () => createColumns(locale.list.tableColumn, tableFilter, showRating),
    [locale.list.tableColumn, tableFilter, showRating],
  );

  const handleReviewerTypeChange = useCallback(
    reviewerType => {
      getRevieweesData({ ...tableFilter, reviewerType: reviewerType.value, pageNumber: 1 });
    },
    [getRevieweesData, tableFilter],
  );

  const handleTableChange = useCallback(
    (pagination, _, sorter) => {
      getRevieweesData({
        reviewerType: tableFilter.reviewerType,
        pageNumber: pagination.current,
        pageSize: pagination.pageSize,
        sortColumnName: sorter.columnKey,
        sortOrder: SORT_DIRECTION_FOR_BE[sorter.order],
      });
    },
    [getRevieweesData, tableFilter],
  );

  useEffect(() => {
    if (!isFromDetailPageInitialed || !isFromDetailPage) {
      getRevieweesData({});
    }
  }, [getRevieweesData]);

  useEffect(() => {
    if (isFromDetailPageInitialed && isFromDetailPage) {
      getRevieweesData(tableFilter);
      updateIsFromDetailPageInitialed(false);
    }
  }, [
    isFromDetailPageInitialed,
    getRevieweesData,
    tableFilter,
    isFromDetailPage,
    updateIsFromDetailPageInitialed,
  ]);

  const reviewerTypeSelectValue = useMemo(
    () => find(availableTypeList, item => item.value === tableFilter.reviewerType),
    [tableFilter, availableTypeList],
  );

  useEffect(() => {
    const tableSelectDom = getInputInSelectDom('table');
    const pageHeaderSelectDom = getInputInSelectDom('reviewees-header');
    if (total !== 0 && !loading) {
      updateAriaForSelect({ inputWrapper: tableSelectDom, ariaLabelText: 'table page' });
    }
    if (!loading) {
      updateAriaForSelect({ inputWrapper: pageHeaderSelectDom, ariaLabelText: 'table page' });
    }
  }, [loading, total]);

  const assessmentNonEngagedNotification = () => {
    const { title, firstContent, secondContent } =
      myCyclesLocale.assessmentNonEngagedNotificationAsPp;
    const content = getAssessmentNonEngagedTips(firstContent, secondContent);

    return {
      title: title,
      content: content,
      visible: showAssessmentNonEngagedTip,
      hideNotification: () => {
        setShowAssessmentNonEngagedTip(false);
      },
    };
  };

  return (
    <div className="reviewees">
      <div className={'reviewees-header-container'}>
        <div className="reviewees-header">
          <DarkSelect
            value={reviewerTypeSelectValue}
            onChange={handleReviewerTypeChange}
            optionList={availableTypeList}
          />
          <span className="tips">{reviewerTypeSelectValue.tips}</span>
        </div>
      </div>
      <Notification {...assessmentNonEngagedNotification()} />
      <div className={'table-container'}>
        <div className="top-header">
          <div className="total">{locale.list.total(total)}</div>
          <Checkbox
            className="show-rating-check-box"
            checked={showRating}
            onChange={() => setShowRating(!showRating)}
          >
            {locale.list.showRating}
          </Checkbox>
        </div>
        <div className="table">
          <Table
            rowKey="id"
            columns={columns}
            dataSource={content}
            onChange={handleTableChange}
            loading={loading}
            pagination={{
              ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
              total: total,
              current: tableFilter.pageNumber,
              pageSize: tableFilter.pageSize,
            }}
            onRow={record => ({
              onClick: onGotoRevieweeDetailPage(record),
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default TwerISupport;
