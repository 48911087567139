import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  getCurrentCycleDetailByVersionAsync,
  setCurrentCycleDetail,
} from '@@/redux/slice/cycleDetailByVersionSlice';
import {
  getCycleDetailByIdAsync,
  getPerformancePartnerCycleDetailByIdAsync,
  reviewState,
  setCyclesCycleDetailV1,
} from '@@/redux/slice/reviewSlice';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { getCycleByCycleIdAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import { myCycles, setCycleDetailData, setCycleVersion } from '@@/_new_src_/store/myCyclesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserInfoContext } from '@@/context/userInfo.context';
import { dashboard, setDashboardCycleDetail } from '@@/redux/slice/dashboardSlice';
import { GET_CYCLE_DETAIL_API_PATH } from '@@/common/constant/review';
import { getCycleDetailsV1Async } from '@@/redux/slice/dashboardSlice/asyncThunk';

const CycleDetailByIdLayout = props => {
  const { children, v1ApiPath } = props;
  const dispatch = useDispatch();
  const cycleIdByPathname = useParams().id || useParams().cycleId;
  const { userId } = useContext(UserInfoContext);
  const { cycleDetailData } = useSelector(myCycles);
  const { cyclesCycleDetailV1 } = useSelector(reviewState);
  const { dashboardCycleDetail } = useSelector(dashboard);
  const [v1CycleDetail, setV1CycleDetail] = useState({ loading: false, data: {} });

  const {
    data: { cycleVersion },
  } = v1CycleDetail;

  const { isPerformanceReviews, isReviewee, isReviewer } = GET_CYCLE_DETAIL_API_PATH;

  const getV1CycleDetailFunc = () => {
    if (v1ApiPath === isPerformanceReviews) {
      cycleIdByPathname && dispatch(getCycleDetailsV1Async(+cycleIdByPathname));
    }
    if (v1ApiPath === isReviewee) {
      cycleIdByPathname && dispatch(getCycleDetailByIdAsync(+cycleIdByPathname));
    }
    if (v1ApiPath === isReviewer) {
      cycleIdByPathname && dispatch(getPerformancePartnerCycleDetailByIdAsync(+cycleIdByPathname));
    }
  };

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetCycleDetailFunc = () => {
    dispatch(
      setDashboardCycleDetail({
        data: {},
        loading: false,
      }),
    );
    dispatch(
      setCyclesCycleDetailV1({
        data: {},
        loading: false,
      }),
    );
    dispatch(
      setCycleDetailData({
        data: {},
        loading: false,
      }),
    );
    dispatch(setCurrentCycleDetail({}));
  };

  useEffect(() => {
    scrollToTop();
    dispatch(setCurrentCycleDetail({}));
    getV1CycleDetailFunc();
    return () => {
      resetCycleDetailFunc();
    };
  }, []);

  useEffect(() => {
    if (v1ApiPath === isPerformanceReviews) {
      setV1CycleDetail(dashboardCycleDetail);
    }
    if (v1ApiPath === isReviewee || v1ApiPath === isReviewer) {
      setV1CycleDetail(cyclesCycleDetailV1);
    }
  }, [cyclesCycleDetailV1, dashboardCycleDetail]);

  useEffect(() => {
    if (cycleVersion === CYCLE_VERSION.NEW) {
      dispatch(getCycleByCycleIdAsync(+cycleIdByPathname));
    }
  }, [cycleVersion]);

  useEffect(() => {
    dispatch(setCycleVersion(cycleVersion));
    const currentData =
      cycleVersion === CYCLE_VERSION.NEW ? cycleDetailData.data : v1CycleDetail.data;
    userId &&
      currentData &&
      dispatch(
        getCurrentCycleDetailByVersionAsync({
          userId,
          cycleVersion,
          cycleDetailByVersion1: v1CycleDetail,
          cycleDetailByVersion2: cycleDetailData,
        }),
      );
  }, [userId, cycleVersion, cycleDetailData, v1CycleDetail]);

  return <div>{children}</div>;
};

export default CycleDetailByIdLayout;
