import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { Collapse, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { DownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import PermissionIcon from '@@/assets/images/permission.svg';
import UserFilled from '@@/assets/images/user-filled.svg';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import { GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN } from '@@/common/constant/matomo';
import { dateWithYearMonthTime } from '@@/_new_src_/constants/dateFormatter';
import { CLASSIFY_TITLE_KEY, PanelType } from '@@/_new_src_/constants/adminEnum';
import { USER_ROLE_ENUM, UserRoleInfo } from '@@/_new_src_/constants/userEnum';
import {
  People,
  PeopleInUnitOrRegionLevel,
  RolesOfManagementForGlobalFunction,
  UnitGroup,
  UserPermissionInfoListType,
} from '@@/types/admin';
import { ModalInfoParams } from '../../../type';
import { FormDataModal } from '../../../common/AddOrEditModal/type';
import useUserRoleInfo from '@@/hooks/useUserRoleInfo';
import usePerfModal from '@@/hooks/usePerfModal';
import {
  deletePeopleByGlobalFunctionSLAdmin,
  getServiceLineByGlobalFunction,
  postAddFunctionAndServiceLevel,
  postAddGlobalHOPOrTBP,
} from '@@/_new_src_/api/admin';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import perfModalConfirm from '@@/common/component/PerfModalConfirm';
import PerfTextButton from '@@/common/component/PerfTextButton';
import AddOrEditModal from '@@/features/performance/v2/admin/common/AddOrEditModal';
import PermissionsModal from '../../PermissionsModal';
import modalInfo from './modalInfo';
import './index.less';

const { Panel } = Collapse;

interface RoleType {
  name: string;
  list: People[] | PeopleInUnitOrRegionLevel[] | undefined;
  hideSupervisor: boolean;
  openPermissionsModal: () => void;
  openAssignPeopleModal: () => void;
  removeGlobalRoleMatomo: string;
}

interface RolesPanelForGlobalFunctionSLProps {
  rolesOfManagementForGlobalFunctionOrSL: RolesOfManagementForGlobalFunction;
  extendedGlobalLeadershipListLength: number;
  globalPeopleTeamListLength: number;
  runGetRolesOfManagementForGlobalFunctionOrSL: () => void;
}

export interface RoleInfoMessage {
  modalTitle: string;
  hierarchyLabel: string;
  hierarchyPlaceholder: string;
  nameLabel: string;
  assignPeopleModalPlaceholder: string;
  assignPeopleModalTip: string;
  successMessage: string;
  errorLimitAssignedNumberPeople?: string;
  errorRequireName?: string;
  alreadyAssignedMessage?: string;
  unitLabel?: string;
  unitPlaceholder?: string;
  placeholder?: string;
}

const RolesPanelForGlobalFunctionSL = (props: RolesPanelForGlobalFunctionSLProps) => {
  const { trackEvent } = useMatomo();
  const { rolesOfManagementForGlobalFunctionOrSL, runGetRolesOfManagementForGlobalFunctionOrSL } =
    props;
  const [activeKey, setActiveKey] = useState<string | string[]>();
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [isPermissionsModalVisible, setIsPermissionsModalVisible] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [permissionsModalInfo, setPermissionsModalInfo] = useState<UserPermissionInfoListType>(
    {} as UserPermissionInfoListType,
  );

  const { userInfo } = useUserRoleInfo();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { updateUserInfo } = userInfo;

  const [assignPeopleModalVisible, onOpenAssignPeopleModal, onCloseAssignPeopleModal] =
    usePerfModal();

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    rolesPanelForGlobalFunctionSL,
    roleAccessAssignedByGlobalFunctionServiceLine: {
      talentBusinessPartnerAccess,
      GFSLLeaderShipAccess,
      chiefTalentOfficerAccess,
      globalTalentBusinessPartnerAccess,
    },
    assignPeopleModalToSpecifiedHierarchyModal: {
      talentBusinessPartnerInfo,
      GFSLLeaderShipInfo,
      ChiefTalentOfficerInfo,
      GlobalTalentBusinessPartnerInfo,
    },
  } = localeResource as {
    roleAccessAssignedByGlobalFunctionServiceLine: {
      talentBusinessPartnerAccess: UserPermissionInfoListType;
      GFSLLeaderShipAccess: UserPermissionInfoListType;
      chiefTalentOfficerAccess: UserPermissionInfoListType;
      globalTalentBusinessPartnerAccess: UserPermissionInfoListType;
    };
    rolesPanelForGlobalFunctionSL: {
      columns: {
        name: string;
        functionServiceLine: string;
        addedBy: string;
        addedAt: string;
        actions: string;
      };
      removePeople: {
        removeAction: string;
        cancelText: string;
        okText: string;
        successMessage: (roleName: string) => string;
        confirmContent: (roleName: string) => {
          contentTips: string;
        };
      };
    };
    assignPeopleModalToSpecifiedHierarchyModal: {
      talentBusinessPartnerInfo: RoleInfoMessage;
      GFSLLeaderShipInfo: RoleInfoMessage;
      ChiefTalentOfficerInfo: RoleInfoMessage;
      GlobalTalentBusinessPartnerInfo: RoleInfoMessage;
    };
    admin: {
      manageAdminRole: {
        addAdminModal: { nameNotFound: string };
      };
    };
  };

  const {
    action: {
      GLOBAL_ROLE_REMOVES_CTO,
      GLOBAL_ROLE_REMOVES_GLOBAL_FUNCTION_SL_LEADERSHIP,
      GLOBAL_ROLE_REMOVES_TBP,
      GLOBAL_ROLE_REMOVES_GLOBAL_TBP,
    },
    category: GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN_CATEGORY,
  } = GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN as {
    action: {
      GLOBAL_ROLE_REMOVES_CTO: string;
      GLOBAL_ROLE_REMOVES_GLOBAL_FUNCTION_SL_LEADERSHIP: string;
      GLOBAL_ROLE_REMOVES_TBP: string;
      GLOBAL_ROLE_REMOVES_GLOBAL_TBP: string;
    };
    category: string;
  };

  const {
    run: runGetSupervisorList,
    data: supervisorListData,
    loading: getSupervisorListLoading,
  } = useRequest(roleName => getServiceLineByGlobalFunction(roleName), {
    formatResult: (res: { data: UnitGroup[] }) => res.data,
    manual: true,
  });

  const openFunctionLevelAssignPeopleModal = async (roleName: string) => {
    onOpenAssignPeopleModal();
    await runGetSupervisorList(roleName);
  };

  const openGlobalLevelAssignPeopleModal: (roleName: string) => Promise<void> = async (
    roleName: string,
  ) => {
    if (roleName === USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE) {
      const alreadyAssignedCTONumber = get(
        rolesOfManagementForGlobalFunctionOrSL,
        'globalLevelDTOList[16].length',
        NaN,
      ) as number;
      if (
        alreadyAssignedCTONumber >= UserRoleInfo[USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE].maxNumber
      ) {
        perfMessage.error(ChiefTalentOfficerInfo.errorLimitAssignedNumberPeople, 7);
        return;
      }
    }
    onOpenAssignPeopleModal();
    await runGetSupervisorList(roleName);
  };

  const handleAssignPeopleSuccess = () => {
    let successMessage = '';
    switch (selectedRole) {
      case USER_ROLE_ENUM.GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP:
        successMessage = GFSLLeaderShipInfo.successMessage;
        break;
      case USER_ROLE_ENUM.TALENT_BP:
        successMessage = talentBusinessPartnerInfo.successMessage;
        break;
      case USER_ROLE_ENUM.GLOBAL_TALENT_BP:
        successMessage = GlobalTalentBusinessPartnerInfo.successMessage;
        break;
    }

    perfMessage.success(successMessage);
    onCloseAssignPeopleModal();
    runGetRolesOfManagementForGlobalFunctionOrSL();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    updateUserInfo();
  };

  const handleAssignPeople = async (data: FormDataModal) => {
    const membersEmails = get(data, 'searchInputValues') as string[];
    const unitId = get(data, 'dropdownInputValue');
    const addGlobalRoleMatomo = get(data, 'addEventMatomo', '');

    setSubmitLoading(true);
    try {
      addGlobalRoleMatomo &&
        trackEvent({
          category: GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN_CATEGORY,
          action: addGlobalRoleMatomo,
        });

      if (
        selectedRole === USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE ||
        selectedRole === USER_ROLE_ENUM.GLOBAL_TALENT_BP
      ) {
        await postAddGlobalHOPOrTBP(selectedRole, membersEmails);
      }

      if (
        selectedRole === USER_ROLE_ENUM.GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP ||
        selectedRole === USER_ROLE_ENUM.TALENT_BP
      ) {
        await postAddFunctionAndServiceLevel({
          superOrgId: unitId,
          membersEmails,
          roleName: selectedRole,
        });
      }

      setSubmitLoading(false);
      handleAssignPeopleSuccess();
    } finally {
      setSubmitLoading(false);
    }
  };

  const levelList = [
    {
      name: CLASSIFY_TITLE_KEY.GLOBAL_LEVEL,
      roles: [
        {
          name: PanelType.GLOBAL_HEAD_OF_PEOPLE,
          list: rolesOfManagementForGlobalFunctionOrSL?.globalLevelDTOList[
            UserRoleInfo[USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE].id
          ],
          hideSupervisor: true,
          openPermissionsModal: () => {
            setIsPermissionsModalVisible(true);
            setPermissionsModalInfo(chiefTalentOfficerAccess);
          },
          openAssignPeopleModal: () => {
            setSelectedRole(USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE);
            void openGlobalLevelAssignPeopleModal(USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE);
          },
          removeGlobalRoleMatomo: GLOBAL_ROLE_REMOVES_CTO,
        },

        {
          name: PanelType.GLOBAL_TALENT_BUSINESS_PARTNER,
          list: rolesOfManagementForGlobalFunctionOrSL?.globalLevelDTOList[
            UserRoleInfo[USER_ROLE_ENUM.GLOBAL_TALENT_BP].id
          ],
          hideSupervisor: true,
          openPermissionsModal: () => {
            setIsPermissionsModalVisible(true);
            setPermissionsModalInfo(globalTalentBusinessPartnerAccess);
          },
          openAssignPeopleModal: () => {
            setSelectedRole(USER_ROLE_ENUM.GLOBAL_TALENT_BP);
            void openGlobalLevelAssignPeopleModal(USER_ROLE_ENUM.GLOBAL_TALENT_BP);
          },
          removeGlobalRoleMatomo: GLOBAL_ROLE_REMOVES_GLOBAL_TBP,
        },
      ],
    },
    {
      name: CLASSIFY_TITLE_KEY.FUNCTION_LEVEL,
      roles: [
        {
          name: PanelType.GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP,
          list: rolesOfManagementForGlobalFunctionOrSL?.functionLevelDTOList[
            UserRoleInfo[USER_ROLE_ENUM.GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP].id
          ],
          hideSupervisor: false,
          openPermissionsModal: () => {
            setIsPermissionsModalVisible(true);
            setPermissionsModalInfo(GFSLLeaderShipAccess);
          },
          openAssignPeopleModal: () => {
            setSelectedRole(USER_ROLE_ENUM.GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP);
            void openFunctionLevelAssignPeopleModal(
              USER_ROLE_ENUM.GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP,
            );
          },
          removeGlobalRoleMatomo: GLOBAL_ROLE_REMOVES_GLOBAL_FUNCTION_SL_LEADERSHIP,
        },
        {
          name: PanelType.TALENT_BUSINESS_PARTNER,
          list: rolesOfManagementForGlobalFunctionOrSL?.functionLevelDTOList[
            UserRoleInfo[USER_ROLE_ENUM.TALENT_BP].id
          ],
          hideSupervisor: false,
          openPermissionsModal: () => {
            setIsPermissionsModalVisible(true);
            setPermissionsModalInfo(talentBusinessPartnerAccess);
          },
          openAssignPeopleModal: () => {
            setSelectedRole(USER_ROLE_ENUM.TALENT_BP);
            void openFunctionLevelAssignPeopleModal(USER_ROLE_ENUM.TALENT_BP);
          },
          removeGlobalRoleMatomo: GLOBAL_ROLE_REMOVES_TBP,
        },
      ],
    },
  ];

  const onCollapseChange = (currentActiveKey: string | string[]) => {
    setActiveKey(currentActiveKey);
  };

  const renderPanelHeader = (name: string, count: number) => {
    const isActive = activeKey?.includes(name);
    return (
      <>
        <img className="user-filled-icon" src={UserFilled} alt="" />
        <span className="header-text">{`${name} (${count})`}</span>
        <DownOutlined className="down-icon" rotate={isActive ? 180 : 0} />
      </>
    );
  };
  const renderPanelExtra = (
    onClickViewPermissions: () => void,
    onClickAssignPeople: () => void,
  ) => {
    return (
      <>
        <div
          className="panel-button"
          onClick={e => {
            e.stopPropagation();
            onClickViewPermissions();
          }}
        >
          <img className="icon permission-icon" src={PermissionIcon} alt="" />
          <span>View Permissions</span>
        </div>
        <div
          className="panel-button"
          onClick={e => {
            e.stopPropagation();
            onClickAssignPeople();
          }}
        >
          <PlusCircleOutlined className="icon plus-icon" />
          <span>Assign People</span>
        </div>
      </>
    );
  };

  const removePeople = (userRoleId: number, roleName: string, removeGlobalRoleMatomo: string) => {
    const { contentTips } = rolesPanelForGlobalFunctionSL.removePeople.confirmContent(roleName);
    const closeModal = perfModalConfirm({
      cancelText: rolesPanelForGlobalFunctionSL.removePeople.cancelText,
      okText: rolesPanelForGlobalFunctionSL.removePeople.okText,
      className: 'level-panel-removePeopleConfirm',
      content: (
        <div className="delete-modal-content">{contentTips && <div>{contentTips}</div>}</div>
      ),
      onOk: async () => {
        trackEvent({
          category: GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN_CATEGORY,
          action: removeGlobalRoleMatomo,
        });
        await deletePeopleByGlobalFunctionSLAdmin(userRoleId).finally(() => closeModal());
        perfMessage.success(rolesPanelForGlobalFunctionSL.removePeople.successMessage(roleName));
        runGetRolesOfManagementForGlobalFunctionOrSL();
        updateUserInfo();
      },
      centered: true,
      onCancel: () => {},
    });
  };

  const getColumns = (
    hideSupervisor: boolean,
    roleName: string,
    removeGlobalRoleMatomo: string,
  ) => {
    const columns = [
      {
        title: rolesPanelForGlobalFunctionSL.columns.name,
        dataIndex: 'name',
        key: 'name',
        // width: 250,
        sortDirections: DEFAULT_TABLE_COLUMN_SORT_CONFIG.sortDirections,
        sorter: hideSupervisor
          ? false
          : (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name),
        render: (value: string) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
      },
      {
        title: rolesPanelForGlobalFunctionSL.columns.functionServiceLine,
        dataIndex: 'superOrgName',
        key: 'superOrgName',
        width: 300,
        sortDirections: DEFAULT_TABLE_COLUMN_SORT_CONFIG.sortDirections,
        sorter: (a: { superOrgName: string }, b: { superOrgName: string }) =>
          a.superOrgName.localeCompare(b.superOrgName),
        render: (value: string) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
        hidden: hideSupervisor,
      },
      {
        title: rolesPanelForGlobalFunctionSL.columns.addedBy,
        dataIndex: 'createBy',
        key: 'createBy',
        width: 250,
        render: (value: string) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
        hidden: hideSupervisor,
      },
      {
        title: rolesPanelForGlobalFunctionSL.columns.addedAt,
        dataIndex: 'createAt',
        key: 'createAt',
        width: 250,
        sortDirections: DEFAULT_TABLE_COLUMN_SORT_CONFIG.sortDirections,
        sorter: (a: { createAt: string }, b: { createAt: string }) =>
          dayjs(a.createAt).unix() - dayjs(b.createAt).unix(),
        render: (time: string) => {
          return dayjs(time).format(dateWithYearMonthTime);
        },
        hidden: hideSupervisor,
      },
      {
        title: rolesPanelForGlobalFunctionSL.columns.actions,
        width: 150,
        align: 'center',
        key: 'actions',
        render: (text: string, { userRoleId }: { userRoleId: number }) => {
          return (
            <PerfTextButton
              color="red"
              className="operation-remove"
              onClick={() => {
                removePeople(userRoleId, roleName, removeGlobalRoleMatomo);
              }}
            >
              {rolesPanelForGlobalFunctionSL.removePeople.removeAction}
            </PerfTextButton>
          );
        },
      },
    ].filter(column => !column.hidden);
    return columns as ColumnsType<PeopleInUnitOrRegionLevel> | ColumnsType<People>;
  };

  const displayUnitItem = (name: string, isNotReady: boolean) => {
    if (isNotReady) {
      return `${name} (Not ready)`;
    }
    return name;
  };

  const getSupervisorDisplayList = () => {
    return (
      supervisorListData &&
      supervisorListData.map(groupItem => ({
        groupName: groupItem.name,
        groupMemberList:
          groupItem.content &&
          groupItem.content.map(({ unitInfo: { unitId, name } }) => {
            const isNotReady = name === 'Global DEISSC' || name === 'Global Legal';
            return {
              id: unitId,
              displayValue: displayUnitItem(name, isNotReady),
              disable: isNotReady,
              value: unitId,
            };
          }),
      }))
    );
  };

  const renderModal = () => {
    const modalParams: ModalInfoParams = {
      locale: localeResource,
      visible: assignPeopleModalVisible,
      onClose: onCloseAssignPeopleModal,
      handleSubmit: handleAssignPeople,
      submitLoading,
      modalLoading: getSupervisorListLoading,
      dropdownListData: getSupervisorDisplayList(),
    };
    const modalConfig = modalInfo(modalParams);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentModalByRole = modalConfig[selectedRole];
    const modalProps = currentModalByRole && currentModalByRole.modalInfo;

    return <AddOrEditModal {...modalProps} />;
  };

  return (
    <div className="level-panel-container-for-global-function-service-line">
      {levelList.map(level => (
        <div key={level.name}>
          <div className="level-name">{`${level.name}`}</div>
          <Collapse
            className="custom-collapse"
            onChange={onCollapseChange}
            activeKey={activeKey}
            ghost
          >
            {level.roles.map((role: RoleType) => (
              <Panel
                className="custom-panel"
                header={renderPanelHeader(role.name, role.list?.length || 0)}
                key={role.name}
                extra={renderPanelExtra(
                  role.openPermissionsModal as () => void,
                  role.openAssignPeopleModal as () => void,
                )}
                showArrow={false}
              >
                <Table<PeopleInUnitOrRegionLevel>
                  columns={getColumns(role.hideSupervisor, role.name, role.removeGlobalRoleMatomo)}
                  dataSource={role.list}
                  rowKey={(people: People) => people.userRoleId}
                  pagination={
                    (role.list?.length as number) > TABLE_DEFAULT_INFO.PAGE_SIZE
                      ? {
                          ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
                        }
                      : false
                  }
                />
              </Panel>
            ))}
          </Collapse>
        </div>
      ))}
      <PermissionsModal
        key="tbp-permissions-modal-key"
        title={permissionsModalInfo.title}
        visible={isPermissionsModalVisible}
        onCancel={() => setIsPermissionsModalVisible(false)}
        permissionList={permissionsModalInfo.permissionList}
      />

      {renderModal()}
    </div>
  );
};

export default RolesPanelForGlobalFunctionSL;
