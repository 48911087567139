export const INITIAL_ASYNC_STATUS = {
  loading: false,
  success: false,
  rejected: false,
};

export enum CONFIRM_MODAL_TYPE_INFO {
  OVERDUE_CYCLE = 'overdueCycle',
  ASSESSMENT_NON_ENGAGED_CYCLE = 'assessmentNonEngagedCycle',
  EXPECTATION_NON_ENGAGED_CYCLE = 'expectationNonEngagedCycle',
  CANCEL_EDIT_CYCLE = 'cancelEditCycle',
  CANCEL_CYCLE = 'cancelCycle',
  DONE_CYCLE = 'doneAddCycle',
  DELETE_EXPECTATION = 'deleteExpectation',
  UNDO_CHANGE = 'undoChange',
}

export enum CYCLE_AUTO_OPEN_DATE {
  januaryOpenDate = 'January 1st',
  julyOpenDate = 'July 1st',
}

export enum OVERDUE_DEADLINE {
  januaryDeadline = 'January 15th',
  julyDeadline = 'July 15th',
}

export enum ASSESSMENT_NON_ENGAGED_DEADLINE {
  januaryDeadline = 'January 31st',
  julyDeadline = 'July 31st',
}

export enum REQUEST_TO_EDIT_DEADLINE {
  januaryDeadline = 'January 31st',
  julyDeadline = 'July 31st',
}

export interface ICycleStatusInfo {
  status: string;
  needSuffix: boolean;
}

export type ICycleStatusProps = ICycleStatusInfo;
