import { useMount } from 'ahooks';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withOktaAuth } from '@okta/okta-react';
import { addInterceptors } from '@@/common/ajax';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import './App.less';

/*
  This is the root component of your app. Here you define the overall layout
  and the container of the react router.
  You should adjust it according to the requirement of your app.
*/
const App = props => {
  const { oktaAuth } = props;
  const [isTokenReady, setIsTokenReady] = useState(false);

  useMount(async () => {
    await addInterceptors(oktaAuth);
    setIsTokenReady(true);
  });

  return (
    <div className="home-app">
      <div className="page-container">
        <LoadingWrapper loading={!isTokenReady} />
        {isTokenReady && props.children}
      </div>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.node,
};

App.defaultProps = {
  children: '',
};

export default withOktaAuth(App);
