import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import { useHistory } from 'react-router';
import { CaretLeftOutlined } from '@ant-design/icons';

import { Table, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useParams } from 'react-router-dom';
import { TableProps } from 'antd/es';
import ImportListIcon from '@@/assets/images/import_list.svg';

import LoadingWrapper from '@@/common/component/LoadingWrapper';
import PerfTextButton from '@@/common/component/PerfTextButton';
import {
  GroupMemberTableColumnsName,
  SortParamsForRegionalLevelGroup,
} from '@@/types/antdComponent';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  SORT_DIRECTION_FOR_BE,
} from '@@/_new_src_/constants/table';
import CreateEditGroupModal from '../components/CreateEditGroupModal';
import usePerfModal from '@@/hooks/usePerfModal';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import perfModalConfirm from '@@/common/component/PerfModalConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { getPerformancePath } from '@@/common/utils';
import { REGIONAL_LEVEL_GROUP_MANAGEMENT } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { regionalLevelGroupDetailData } from '@@/redux/slice/adminSlice';

import { getRegionalLevelGroupDetailAsync } from '@@/redux/slice/adminSlice/asyncThunk';
import { IGroupMembersType } from '@@/types/admin';
import { IPageParams } from '@@/_new_src_/types/common';
import { deleteGroupMemberByMemberId } from '@@/_new_src_/api/admin';
import { ERROR_PAGE } from '@@/_new_src_/constants/pagePath';

interface GroupsMemberTableLocale {
  name: string;
  employeeID: string;
  addedAt: string;
  actions: string;
  remove: string;
}

interface adminLocale {
  admin: {
    manageRegionalLevelGroups: {
      editGroupForGroupManager: {
        title: string;
        groupManager: string;
        editGroupMemberList: {
          totalTWers: (total: number) => string;
          importList: string;
          table: {
            name: string;
            employeeID: string;
            addedAt: string;
            actions: string;
            remove: string;
          };
        };
        deleteGroupMemberModel: {
          cancelText: string;
          okText: string;
          contentText: (userName: string, groupName: string) => string;
          successMessage: string;
        };
      };
    };
  };
}

const RegionalLevelGroupManage = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const history = useHistory();
  const [
    importRegionalLevelGroupMembersModalVisible,
    onOpenRegionalLevelGroupImportMembersModal,
    onCloseRegionalLevelGroupImportMembersModal,
  ] = usePerfModal() as [boolean, () => void, () => void];

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: {
      manageRegionalLevelGroups: { editGroupForGroupManager: locale },
    },
  } = localeResource as adminLocale;

  const [currentPagination, setCurrentPagination] = useState<IPageParams>(DEFAULT_TABLE_PAGINATION);
  const [currentSorter, setCurrentSorter] = useState<SortParamsForRegionalLevelGroup>({
    sortColumnName: GroupMemberTableColumnsName.default,
    sortOrder: SORT_DIRECTION_FOR_BE.descend,
  });

  const { groupId } = useParams<{ groupId: string }>();

  const { loading, groupName, managerName, regionGroupMembersCount, regionGroupMembers } =
    useSelector(regionalLevelGroupDetailData);

  const removeGroupMember = (groupMemberId: number, name: string) => {
    const modelLocale = locale.deleteGroupMemberModel;
    const closeModal = perfModalConfirm({
      cancelText: modelLocale.cancelText,
      okText: modelLocale.okText,
      className: 'deleteGroupMemberConfirm',
      content: (
        <div className="delete-modal-content">{modelLocale.contentText(name, groupName || '')}</div>
      ),
      onOk: async () => {
        trackEvent({
          category: REGIONAL_LEVEL_GROUP_MANAGEMENT.category,
          action: REGIONAL_LEVEL_GROUP_MANAGEMENT.action.DELETE_GROUP_MEMBER,
        });
        await deleteGroupMemberByMemberId(groupMemberId).finally(() => closeModal());
        perfMessage.success(modelLocale.successMessage);
        dispatch(getRegionalLevelGroupDetailAsync({ groupId, currentSorter, currentPagination }));
      },
      centered: true,
      onCancel: null,
    });
  };

  useEffect(() => {
    if (groupId === undefined) {
      history.replace(ERROR_PAGE.IS_404);
    } else {
      dispatch(getRegionalLevelGroupDetailAsync({ groupId, currentSorter, currentPagination }));
    }
  }, [currentSorter, currentPagination, groupId]);

  const createColumns = (tableLocale: GroupsMemberTableLocale): ColumnsType<IGroupMembersType> => {
    return [
      {
        title: tableLocale.name,
        dataIndex: 'name',
        width: '30%',
        ellipsis: { showTitle: false },
        ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
        render: (name: string) => {
          return (
            <Tooltip placement="topLeft" title={name}>
              {name}
            </Tooltip>
          );
        },
      },
      {
        title: tableLocale.employeeID,
        dataIndex: 'employeeId',
        width: '30%',
        ellipsis: { showTitle: false },
        ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
        render: (employeeId: string) => {
          return (
            <Tooltip placement="topLeft" title={employeeId}>
              {employeeId}
            </Tooltip>
          );
        },
      },
      {
        title: tableLocale.addedAt,
        dataIndex: 'createdAt',
        width: '30%',
        ellipsis: { showTitle: false },
        ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
        render: (createdAt: string) => (
          <Tooltip placement="topLeft" title={createdAt}>
            {createdAt}
          </Tooltip>
        ),
      },
      {
        title: tableLocale.actions,
        dataIndex: 'actions',
        render: (text: string, { id, name }: { id: number; name: string }) => (
          <PerfTextButton color="red" onClick={() => removeGroupMember(id, name)}>
            {tableLocale.remove}
          </PerfTextButton>
        ),
      },
    ];
  };

  const handleImportMembersGroupSuccess = () => {
    onCloseRegionalLevelGroupImportMembersModal();
    dispatch(getRegionalLevelGroupDetailAsync({ groupId, currentSorter, currentPagination }));
  };

  const handleTableChange: TableProps<IGroupMembersType>['onChange'] = (
    pagination,
    filters,
    sorter: SorterResult<IGroupMembersType> | SorterResult<IGroupMembersType>[],
  ) => {
    const pageNumberAndSize: IPageParams = {
      pageNumber: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
    };
    const { field, order } = sorter as SorterResult<IGroupMembersType>;
    const sortColumnAndOrder = {
      sortColumnName: order
        ? GroupMemberTableColumnsName[field as keyof typeof GroupMemberTableColumnsName]
        : GroupMemberTableColumnsName.default,
      sortOrder: order ? SORT_DIRECTION_FOR_BE[order] : SORT_DIRECTION_FOR_BE.descend,
    };
    setCurrentPagination(pageNumberAndSize);
    setCurrentSorter(sortColumnAndOrder);
  };

  const onGoToGroupListPages = useCallback(() => {
    history.push(getPerformancePath('/admin/'));
  }, [history]);

  const EditGroupHeaderRender = () => {
    return (
      <header className="group-header">
        <section className="group-header-content" onClick={onGoToGroupListPages}>
          <span className="group-header-arrow-wrapper">
            <CaretLeftOutlined className="group-header-arrow" />
          </span>
          <b className="group-header-name">{locale.title}</b>
        </section>
      </header>
    );
  };

  const RegionalLevelGroupInfoRender = () => {
    return (
      <section className="group-detail">
        <div className="group-name">{groupName}</div>
        <section className="leads-row">
          <div className="group-manager">{locale.groupManager}</div>
          <div className="manager-name">{managerName}</div>
        </section>
      </section>
    );
  };

  return (
    <LoadingWrapper className loading={loading}>
      <div className="regional-level-group-manage">
        {EditGroupHeaderRender()}
        {RegionalLevelGroupInfoRender()}
        <section className="group-member">
          <section className="group-member-count-and-operation">
            {regionGroupMembersCount > 0 && (
              <div className="total-count">
                {locale.editGroupMemberList.totalTWers(regionGroupMembersCount)}
              </div>
            )}

            <div
              className="import-list"
              onClick={() => onOpenRegionalLevelGroupImportMembersModal()}
            >
              <img alt="import_list" src={ImportListIcon} className="import-list-icon" />
              <span>{locale.editGroupMemberList.importList}</span>
            </div>
          </section>
          <Table<IGroupMembersType>
            columns={createColumns(locale.editGroupMemberList.table)}
            dataSource={regionGroupMembers}
            rowKey={record => record.id}
            onChange={handleTableChange}
            pagination={
              regionGroupMembersCount > 10
                ? {
                    ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
                    total: regionGroupMembersCount,
                    current: currentPagination.pageNumber,
                    pageSize: currentPagination.pageSize,
                  }
                : false
            }
          />
        </section>
      </div>
      {importRegionalLevelGroupMembersModalVisible && (
        <CreateEditGroupModal
          visible={importRegionalLevelGroupMembersModalVisible}
          onSuccess={handleImportMembersGroupSuccess}
          onCancel={onCloseRegionalLevelGroupImportMembersModal}
          groupId={groupId}
          isEdit
        />
      )}
    </LoadingWrapper>
  );
};

export default RegionalLevelGroupManage;
