/*
eslint-disable
@typescript-eslint/no-unsafe-call,
@typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-explicit-any,
@typescript-eslint/no-unsafe-assignment
 */
import { Editor } from '@tinymce/tinymce-react';
import cls from 'classnames';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import tinyMce from 'tinymce/tinymce';
import './index.less';

const initConfig = {
  menubar: false,
  branding: false,
  elementpath: false,
  statusbar: false,
  plugins: 'lists link table fullscreen wordcount',
  font_size_formats: '12px 14px 16px 18px 24px',
  toolbar:
    'fontsize | bold Italic Underline | numlist bullist blockquote | indent outdent align | link table | undo redo | fullscreen',
  height: 400,
};

interface propsType {
  initialValue: string;
  lastDoneInfo?: string | undefined;
  updateHtmlInfo: (info: string) => void;
  updateTextInfo?: (info: string) => void | undefined;
  updateLastDoneInfo?: (info: string) => void | undefined;
  maxLength: number;
  id?: string;
  height?: number;
  placeholder?: string;
  customPlaceholderStyle?: string;
}

const TinyMceEditor = (props: propsType) => {
  const {
    initialValue,
    lastDoneInfo,
    updateHtmlInfo,
    updateTextInfo,
    updateLastDoneInfo,
    maxLength,
    placeholder,
    height,
    id,
    customPlaceholderStyle,
  } = props;

  const sizeLimit = maxLength ?? 50;
  const [value, setValue] = React.useState(initialValue ?? '');
  const [contentLength, setLength] = React.useState(0);

  useEffect(() => {
    if (!isEmpty(lastDoneInfo)) {
      lastDoneInfo && tinyMce.activeEditor?.insertContent(lastDoneInfo);
      updateLastDoneInfo && updateLastDoneInfo('');
    }
  }, [lastDoneInfo, updateLastDoneInfo]);

  const handleUpdate = (content: string, editor: any) => {
    const noImgContent = content.replace(/<\/?(img)[^>]*>/g, '');
    const textInfo = editor.getContent({ format: 'text' }).trim();
    const textInfoLength = textInfo.length;
    setValue(noImgContent);
    updateHtmlInfo(noImgContent);
    updateTextInfo && updateTextInfo(textInfo);
    setLength(textInfoLength);
  };

  const handleNodeChange = (editor: any) => {
    const targetElement = editor.element as HTMLElement;
    if (targetElement.tagName === 'TD') {
      const tablePluginDom = document.getElementsByClassName('tox-tbtn tox-tbtn--select');
      if (tablePluginDom.length > 0 && tablePluginDom[2]) {
        tablePluginDom[2].setAttribute(
          'style',
          'user-select: none; width: 48px; pointer-events: none;',
        );
        tablePluginDom[2].setAttribute('class', 'tox-tbtn tox-tbtn--disabled tiny-table-custom');
      }
    } else {
      const tablePluginDom = document.getElementsByClassName(
        'tox-tbtn tox-tbtn--disabled tiny-table-custom',
      );
      if (tablePluginDom.length > 0) {
        tablePluginDom[0].setAttribute('style', 'user-select: none; width: 48px;');
        tablePluginDom[0].setAttribute('class', 'tox-tbtn tox-tbtn--select');
      }
    }
  };

  return (
    <div className="tinyMceEditor" tabIndex={0}>
      <Editor
        id={id || 'tinyMceEditor'}
        inline={false}
        initialValue={initialValue}
        value={value}
        init={{
          ...initConfig,
          height: height,
          placeholder: placeholder,
          init_instance_callback: editor => {
            const textInfo = editor.getContent({ format: 'text' }).trim();
            updateTextInfo && updateTextInfo(textInfo);
            updateHtmlInfo && updateHtmlInfo(initialValue);
            setLength(textInfo.length);
            setValue(initialValue);
          },
          content_style:
            `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before
            {
              color: rgba(0, 0, 0, 0.65) !important;
              font-weight: 340 ;
              font-style: italic !important;
              font-size: 16.5px !important;
              ${customPlaceholderStyle || ''}
            }` +
            `.mce-content-body { font-family: 'Open Sans', sans-serif; }` +
            `.mce-content-body img{ width: auto;max-width: 100% }`,
          font_family_formats: `'Open Sans', sans-serif`,
        }}
        onEditorChange={handleUpdate}
        onNodeChange={handleNodeChange}
      />
      <div className="input-count-wrapper">
        <span
          className={cls('input-count', {
            'input-count-max-length': sizeLimit < contentLength,
          })}
        >
          {contentLength}/{sizeLimit}
        </span>
      </div>
    </div>
  );
};

export default TinyMceEditor;
