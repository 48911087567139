import React, { useEffect } from 'react';
import { useRequest } from 'ahooks';
import { RolesOfManagementForGlobalFunction, UseRequestResponse } from '@@/types/admin';
import { GroupMemberTableColumnsName } from '@@/types/antdComponent';
import { DEFAULT_TABLE_PAGINATION, SORT_DIRECTION_FOR_BE } from '@@/_new_src_/constants/table';
import useUserRoleInfo from '@@/hooks/useUserRoleInfo';
import { getGroupDetail, getRolesOfManagementForGlobalFunction } from '@@/_new_src_/api/admin';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import RolesPanelForGlobalFunctionSL from './RolesPanelForGlobalFunctionSL';
import './index.less';

interface RolesOfManagementResponse {
  data: RolesOfManagementForGlobalFunction;
}

const ManageGlobalFunctionSLRolesTab = () => {
  const { groupIdsForGlobalFunctionAdmin } = useUserRoleInfo();
  const { globalManagementTeamId, globalPeopleTeamId } = groupIdsForGlobalFunctionAdmin as {
    globalManagementTeamId: number;
    globalPeopleTeamId: number;
  };
  const {
    run: runGetRolesOfManagementForGlobalFunctionOrSL,
    data: rolesOfManagementForGlobalFunctionOrSL,
    loading: getRolesOfManagementForGlobalFunctionOrSLLoading,
  } = useRequest(() => getRolesOfManagementForGlobalFunction(), {
    formatResult: (res: RolesOfManagementResponse) => res.data,
    manual: true,
  });

  const {
    loading: getOnePagePeopleFromExtendedGlobalLeadershipLoading,
    data: extendedGlobalLeadershipListData,
    run: runGetExtendedGlobalLeadershipList,
  } = useRequest(
    () =>
      getGroupDetail(
        {
          sortColumn: GroupMemberTableColumnsName.default,
          sortOrder: SORT_DIRECTION_FOR_BE.ascend,
        },
        DEFAULT_TABLE_PAGINATION,
        globalManagementTeamId,
      ),
    {
      manual: true,
      formatResult: (res: UseRequestResponse) => res.data,
    },
  );

  const {
    loading: getOnePagePeopleFromGlobalPeopleTeamLoading,
    data: globalPeopleTeamListData,
    run: runGetGlobalPeopleTeamList,
  } = useRequest(
    () =>
      getGroupDetail(
        {
          sortColumn: GroupMemberTableColumnsName.default,
          sortOrder: SORT_DIRECTION_FOR_BE.ascend,
        },
        DEFAULT_TABLE_PAGINATION,
        globalPeopleTeamId,
      ),
    {
      manual: true,
      formatResult: (res: UseRequestResponse) => res.data,
    },
  );

  useEffect(() => {
    const get: () => void = async () => {
      await runGetRolesOfManagementForGlobalFunctionOrSL();
      await runGetExtendedGlobalLeadershipList();
      await runGetGlobalPeopleTeamList();
    };
    get();
  }, [
    runGetExtendedGlobalLeadershipList,
    runGetGlobalPeopleTeamList,
    runGetRolesOfManagementForGlobalFunctionOrSL,
  ]);

  return (
    <div className="manage-global-function-service-line-roles-tab-container">
      <LoadingWrapper
        className="modal-loading"
        loading={
          getRolesOfManagementForGlobalFunctionOrSLLoading ||
          getOnePagePeopleFromExtendedGlobalLeadershipLoading ||
          getOnePagePeopleFromGlobalPeopleTeamLoading
        }
      >
        <RolesPanelForGlobalFunctionSL
          rolesOfManagementForGlobalFunctionOrSL={
            rolesOfManagementForGlobalFunctionOrSL as RolesOfManagementForGlobalFunction
          }
          runGetRolesOfManagementForGlobalFunctionOrSL={
            runGetRolesOfManagementForGlobalFunctionOrSL
          }
          globalPeopleTeamListLength={globalPeopleTeamListData?.groupMemberInfo.total || 0}
          extendedGlobalLeadershipListLength={
            extendedGlobalLeadershipListData?.groupMemberInfo.total || 0
          }
        />
      </LoadingWrapper>
    </div>
  );
};

export default ManageGlobalFunctionSLRolesTab;
