import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { CYCLE_STEP_ORDER, CYCLE_STEP } from '@@/_new_src_/constants/myCycles';
import { IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cls from 'classnames';
import { get, map, size } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import './index.less';

const StepsBar = () => {
  const {
    myCycles: { cycleStepsBar: locale },
  } = (useSelector(selectLocaleResource) as IMyCyclesLocale) || {};

  const {
    currentCycleDetail: { isNewCycle, isOpenCycle, isUnreadCycle },
  } = useSelector(cycleDetailByVersion) as {
    currentCycleDetail: { isNewCycle: boolean; isOpenCycle: boolean; isUnreadCycle: boolean };
  };

  const [currentStep, setCurrentStep] = useState<string>(CYCLE_STEP.CYCLE_INFORMATION);
  const [isShowBar, setShowBar] = useState<boolean>(false);

  useEffect(() => {
    setShowBar(false);
    if (isNewCycle) {
      setCurrentStep(CYCLE_STEP.EXPECTATION_ALIGNMENT);
      setShowBar(true);
    }
    if (isOpenCycle) {
      setCurrentStep(CYCLE_STEP.CHECKIN_FEEDBACK);
      setShowBar(true);
    }
    if (isUnreadCycle) {
      setCurrentStep(CYCLE_STEP.GET_ASSESSMENT);
      setShowBar(true);
    }
  }, [isNewCycle, isOpenCycle, isUnreadCycle]);

  const currentStepOrder = get(CYCLE_STEP_ORDER, `${currentStep}`) as number;

  const stepStateInference = (label: string, step: string) => {
    const stepOrder = get(CYCLE_STEP_ORDER, `${step}`) as number;
    const isLastStep = stepOrder === size(CYCLE_STEP_ORDER);
    const isActive = currentStep === step;
    const isDone = currentStepOrder > stepOrder || (isActive && isLastStep);

    return {
      isActive: isActive,
      isDone: isDone,
      isLastStep: isLastStep,
    };
  };

  return (
    <>
      {isShowBar && (
        <div className="cycle-steps">
          {map(locale, ({ label, desc }, step) => {
            const stepOrder = get(CYCLE_STEP_ORDER, `${step}`) as number;

            const { isActive, isDone, isLastStep } = stepStateInference(label, step);

            return (
              <div
                className={cls('cycle-steps-item', {
                  'cycle-steps-item-active': isActive,
                  'cycle-steps-item-done': isDone,
                })}
                key={label}
              >
                <div className="cycle-steps-item-dot">
                  {isDone ? <i className="ri-check-line cycle-steps-icon-done" /> : null}
                </div>
                <div className="cycle-steps-item-title">
                  <span>{label}</span>
                  <Tooltip
                    arrow={true}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
                    title={desc}
                    placement={isLastStep ? 'topRight' : 'topLeft'}
                    align={{
                      offset: isLastStep ? [12, -10] : [-12, -10],
                    }}
                    overlayClassName={cls({ 'not-first-step': stepOrder !== 1 })}
                  >
                    <InfoCircleFilled />
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default memo(StepsBar);
