import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRequest } from 'ahooks';
import { Tag } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { AUTOMATED_GROUP_MANAGEMENT } from '@@/common/constant/matomo';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { localAdmin } from '@@/features/performance/v2/admin/common/interface';
import {
  AutomatedManagerCLTType,
  AutomatedManagerResponse,
  AutomatedMemberList,
  AutomatedMemberResponse,
} from './interface';
import PerfTextButton from '@@/common/component/PerfTextButton';
import EditBlueIcon from '@@/assets/images/edit-blue.svg';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import { get } from 'lodash';
import usePerfModal from '@@/hooks/usePerfModal';
import AddOrEditModal from '../../common/AddOrEditModal';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import TableRender from '@@/features/performance/v2/admin/common/Table';
import { ColumnsType } from 'antd/es/table';
import { AutomatedMemberTableColumnsName } from '@@/types/antdComponent';
import {
  DEFAULT_TABLE_PAGINATION,
  SORT_DIRECTION_FOR_BE,
  SORT_DIRECTION_LOWER_CASE,
} from '@@/_new_src_/constants/table';
import { ICurrentTableSort } from '@@/features/performance/v2/admin/common/Table/interface';
import { FormDataModal, SearchInputValueType } from '../../common/AddOrEditModal/type';
import { CLTMemberType, SelectTagRenderItem } from '../AutomatedGroups/interface';
import {
  formatSearchInputList,
  onPreventMouseDown,
  orangeCircleIcon,
} from '../AutomatedGroups/common';
import { selectRoleInfoType } from '@@/features/performance/v2/admin/type';
import { ADMIN_TAB_INDEX } from '@@/_new_src_/constants/adminEnum';
import { IPageParams } from '@@/_new_src_/types/common';
import { ISortParamsForAutomatedMembersColumns } from '@@/types/admin';
import {
  getAutomatedManager,
  getAutomatedMembers,
  getMemberByName,
  putUpdateCLTMembers,
} from '@@/_new_src_/api/admin';
import './index.less';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const AutomatedGroupsDetail = () => {
  const { trackEvent } = useMatomo();
  const history = useHistory();
  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: {
      automatedGroupsOfTBP: {
        manageCLT,
        noCLT,
        backTitle,
        detail: {
          clientLeadershipTeam,
          tableTotal,
          table: { employeeId, name, role },
        },
      },
      assignCLTModalInfo,
      manageAdminRole: {
        addAdminModal: { nameNotFound, save },
      },
    },
  } = localeResource as localAdmin;

  const { id: accountId } = useParams<{ id: string }>();
  const { selectRole, selectHierarchyId } = JSON.parse(
    sessionStorage.getItem('selectedRoleInAdminPage') as string,
  ) as selectRoleInfoType;

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [hasExistValues, setHasExistValues] = useState<boolean>(false);
  const [assignModalVisible, onOpenAssignModal, onCloseAssignModal] = usePerfModal();
  const [isShowSearchInputInfoMessage, setIsShowSearchInputInfoMessage] = useState<boolean>(false);

  const [currentPagination, setCurrentPagination] = useState<IPageParams>(DEFAULT_TABLE_PAGINATION);
  const [currentSorter, setCurrentSorter] = useState<ISortParamsForAutomatedMembersColumns>({
    sortColumn: AutomatedMemberTableColumnsName.default,
    sortOrder: SORT_DIRECTION_FOR_BE.ascend,
  });

  const {
    loading: getAutomatedManagerLoading,
    data: automatedManagerDetail,
    run: runGetAutomatedManager,
  } = useRequest(() => getAutomatedManager(+accountId), {
    manual: true,
    formatResult: (res: AutomatedManagerResponse) => res.data,
  });

  const {
    loading: getAutomatedMemberLoading,
    data: automatedMemberData,
    run: runGetAutomatedMember,
  } = useRequest(
    () =>
      getAutomatedMembers(
        accountId,
        currentSorter,
        currentPagination,
        selectRole,
        selectHierarchyId,
      ),
    {
      manual: true,
      formatResult: (res: AutomatedMemberResponse) => res.data,
    },
  );

  const getAutomatedManagerFun: () => void = async () => {
    await runGetAutomatedManager();
  };

  const getAutomatedMemberFun: () => void = async () => {
    await runGetAutomatedMember();
  };

  useEffect(() => {
    getAutomatedManagerFun();
  }, []);

  useEffect(() => {
    getAutomatedMemberFun();
  }, [accountId, currentSorter, currentPagination, selectRole, selectHierarchyId]);

  const getMembersByNameAndAccountId = (name: string) =>
    getMemberByName(name, get(automatedManagerDetail, 'id') as number);

  const onGoBack = () => {
    history.push(MENU_PAGE_LINK.ADMIN_PAGE);
    localStorage.setItem(ADMIN_TAB_INDEX, '1');
  };

  const handleCloseModal = () => {
    setHasExistValues(false);
    setIsShowSearchInputInfoMessage(false);
    onCloseAssignModal();
  };

  const onClickManageCLT = () => {
    setHasExistValues(true);
    onOpenAssignModal();
  };

  const CLTStringFun = () => {
    const clientLeadershipTeam = get(automatedManagerDetail, 'clientLeadershipTeam') || [];

    if (clientLeadershipTeam.length > 0) {
      const CLSTString = clientLeadershipTeam
        .map((item: AutomatedManagerCLTType) => {
          return item.name;
        })
        .join(', ');
      return <>{CLSTString}</>;
    } else {
      return <span className={'no-CLT'}>{noCLT}</span>;
    }
  };

  const handleAssignCLT = async (data: FormDataModal) => {
    try {
      setSubmitLoading(true);
      const memberIds = get(data, 'searchInputValues') as number[];
      const accountId = get(automatedManagerDetail, 'id', 0);
      trackEvent({
        category: AUTOMATED_GROUP_MANAGEMENT.category,
        action: AUTOMATED_GROUP_MANAGEMENT.action.TBP_EDITS_AUTOMATED_GROUP_IN_DETAIL_PAGE,
      });
      await putUpdateCLTMembers(accountId, {
        employeeIds: memberIds,
        lastUpdateAt: get(automatedManagerDetail, 'lastUpdatedAt', NaN) as number,
      });
      setSubmitLoading(false);
      handleCloseModal();
      getAutomatedManagerFun();
      getAutomatedMemberFun();
      perfMessage.success(assignCLTModalInfo.successMessage);
    } finally {
      setSubmitLoading(false);
    }
  };

  const checkExistMemberInAccountMembers = (selectedValues: Array<SearchInputValueType>) => {
    const isExistMemberInOtherAccount =
      selectedValues && selectedValues.some(item => item.isInAccountMembers);
    setIsShowSearchInputInfoMessage(isExistMemberInOtherAccount);
  };

  const onChangeMembers = (selectedValues: Array<SearchInputValueType>) => {
    checkExistMemberInAccountMembers(selectedValues);
  };

  const customRenderSelectedCLT: (
    currentMember: SelectTagRenderItem,
    selectMemberValues: Array<SearchInputValueType>,
  ) => ReactElement | null = (currentMember, selectMemberValues = []) => {
    if (!currentMember) {
      return null;
    }

    const { label, value: selectedId, closable, onClose } = currentMember;
    const memberIsInAccountMembers =
      selectMemberValues &&
      selectMemberValues.some(item => item.value === selectedId && item.isInAccountMembers);

    checkExistMemberInAccountMembers(selectMemberValues);

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        icon={memberIsInAccountMembers ? (orangeCircleIcon as ReactElement) : null}
        className="selected-member-tag-render"
      >
        {label}
      </Tag>
    );
  };

  const renderAssignCLTModal = () => {
    const existedAssignMembers = (
      get(automatedManagerDetail, 'clientLeadershipTeam', []) as Array<CLTMemberType>
    ).map((leader: CLTMemberType) => ({
      key: leader.employeeId,
      label: leader.name,
      value: leader.employeeId,
      isInAccountMembers: leader.isInAccountMembers,
    })) as Array<SearchInputValueType>;
    const memberInOtherAccountMessage = (
      <div className="exist-member-message">
        {orangeCircleIcon}
        <span className={'hint-text'}>{assignCLTModalInfo.memberInOtherAccount}</span>
      </div>
    );
    return (
      <AddOrEditModal
        modalTitle={assignCLTModalInfo.modalTitle}
        visible={assignModalVisible}
        searchInput={{
          label: assignCLTModalInfo.nameLabel,
          fetchApi: getMembersByNameAndAccountId,
          notFoundText: nameNotFound,
          mode: 'multiple',
          currentValues: existedAssignMembers,
          fieldNameValue: 'employeeId',
          placeholder: assignCLTModalInfo.placeholder,
          isRequire: false,
          isExistCurrentValues: hasExistValues,
          formatSearchData: formatSearchInputList,
          customRender: customRenderSelectedCLT,
          isShowSearchInputInfoMessage: isShowSearchInputInfoMessage,
          checkShowSearchInputTips: checkExistMemberInAccountMembers,
          searchInputInfoTips: memberInOtherAccountMessage,
          onChange: onChangeMembers,
        }}
        permissionList={assignCLTModalInfo.permissionList}
        customButtons={{
          cancelButton: { handleClick: handleCloseModal },
          okButton: { label: save, handleClick: handleAssignCLT, loading: submitLoading },
        }}
      />
    );
  };

  const columns: ColumnsType<AutomatedMemberList> = [
    {
      title: employeeId,
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: 256,
      ellipsis: { showTitle: true },
      sorter: (rowA, rowB) => rowA.employeeId - rowB.employeeId,
    },
    {
      title: name,
      dataIndex: 'name',
      key: 'name',
      width: 600,
      ellipsis: { showTitle: true },
      sorter: (rowA, rowB) => rowA.name.localeCompare(rowB.name),
    },
    {
      title: role,
      dataIndex: 'role',
      key: 'role',
      ellipsis: { showTitle: true },
      sorter: (rowA, rowB) => rowA.role.localeCompare(rowB.role),
    },
  ];

  const handleTableChange = (pageInfo: IPageParams, sortInfo: ICurrentTableSort) => {
    const sortOrder = get(sortInfo, 'sortOrder') || SORT_DIRECTION_LOWER_CASE.ascend;
    const sortColumnName = get(sortInfo, 'sortColumnName') || 'default';
    const curColumn = (get(AutomatedMemberTableColumnsName, sortColumnName) ||
      AutomatedMemberTableColumnsName.default) as string;
    setCurrentSorter({
      sortColumn: curColumn,
      sortOrder: SORT_DIRECTION_FOR_BE[sortOrder],
    });
    setCurrentPagination(pageInfo);
  };

  return (
    <LoadingWrapper
      className="loading"
      loading={getAutomatedManagerLoading || getAutomatedMemberLoading}
    >
      <div className={'automated-groups-detail'}>
        <div className="detail-back-header">
          <div className="detail-back-header-wrapper" onClick={onGoBack}>
            <span className="detail-back-header-icon">
              <CaretLeftOutlined />
            </span>
            {backTitle}
          </div>
        </div>
        <div className="detail-info">
          <div className="detail-info-title">
            <h3>{automatedManagerDetail?.name || null}</h3>
            <PerfTextButton
              className="talent-button show-button"
              color="blue"
              onClick={onClickManageCLT}
              icon={<img src={EditBlueIcon} className="edit-icon" alt="" />}
            >
              {manageCLT}
            </PerfTextButton>
          </div>
          <div className="detail-info-list">
            <h4>{clientLeadershipTeam}:</h4>
            <p>{CLTStringFun()}</p>
          </div>
        </div>
        <div className="list-content">
          <p className="total-info">{tableTotal(automatedMemberData?.total || 0)}</p>
          <TableRender
            column={columns}
            listData={automatedMemberData}
            onChange={handleTableChange}
          />
        </div>
      </div>
      {renderAssignCLTModal()}
    </LoadingWrapper>
  );
};
export default AutomatedGroupsDetail;
