import React from 'react';
import { useSelector } from 'react-redux';

import locale from '@@/_new_src_/local/myCycles/en_US';

import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

import { userInfo } from '@@/_new_src_/store/userSlice';
import { currentCycleDetail } from '@@/redux/slice/cycleDetailByVersionSlice';

import './index.less';
import { getOverdueTips } from '@@/_new_src_/utils/feature';

const OverdueNotification = () => {
  const { overdueCycleId } = useSelector(userInfo);
  const { id: currentCycleId } = useSelector(currentCycleDetail);

  const { overdueWarning } = locale;

  const overdueTipsText = () => {
    const isCurrentCycleOverdue = overdueCycleId === currentCycleId;
    const cycleHref = CYCLE_PAGE_LINK.CYCLE_HISTORY_DETAIL + '/' + overdueCycleId;

    const overdueTipsBeginning = isCurrentCycleOverdue
      ? overdueWarning.currentCycleOverdueTipsBeginning
      : overdueWarning.historyCycleOverdueTipsBeginning(cycleHref);

    const cycleDetailOverdueTextP1 = overdueTipsBeginning + overdueWarning.firstParagraph;

    return getOverdueTips(cycleDetailOverdueTextP1, overdueWarning.secondParagraph);
  };

  return (
    <div className="overdue-warning-box">
      <i className="ri-error-warning-fill" />
      <div className={'over-text'} dangerouslySetInnerHTML={{ __html: overdueTipsText() }}></div>
    </div>
  );
};

export default OverdueNotification;
