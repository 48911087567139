import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { Button, Select, Tag, Tooltip } from 'antd';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import PerfTextButton from '@@/common/component/PerfTextButton';
import TableRender from '../../common/Table';

import './index.less';
import {
  localAdmin,
  TabChildrenComponentProps,
} from '@@/features/performance/v2/admin/common/interface';
import { ICurrentTableSort } from '@@/features/performance/v2/admin/common/Table/interface';
import {
  clientLeaderShipTeam,
  AutomatedGroupsList,
  AutomatedGroupsResponse,
  CLIGroupType,
  CLTMemberType,
  SelectTagRenderItem,
} from './interface';

import InformationIcon from '@@/assets/images/information.svg';
import { useRequest } from 'ahooks';
import { get } from 'lodash';
import dayjs from 'dayjs';

import type { ColumnsType } from 'antd/es/table';

import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import usePerfModal from '@@/hooks/usePerfModal';
import AddOrEditModal from '../../common/AddOrEditModal';
import { FormDataModal, SearchInputValueType } from '../../common/AddOrEditModal/type';
import { formatSearchInputList, onPreventMouseDown, orangeCircleIcon } from './common';
import { getPerformancePath } from '@@/common/utils';
import perfModalConfirm from '@@/common/component/PerfModalConfirm';
import { CaretDownOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { AUTOMATED_GROUP_MANAGEMENT } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { ADMIN_VISIBLE_STATE } from '@@/_new_src_/constants/adminEnum';
import {
  getAutomatedGroups,
  getAutomatedManager,
  getMemberByName,
  postAddAccountGroupToHideList,
  postRemoveGroupFromHideList,
  putUpdateCLTMembers,
} from '@@/_new_src_/api/admin';

const DEFAULT_VISIBLE = 1;

const AutomatedGroups = (props: TabChildrenComponentProps) => {
  const { trackEvent } = useMatomo();
  const history = useHistory();
  const localeResource = useSelector(selectLocaleResource) as unknown;
  const hierarchyType = get(props, 'hierarchyType') || '';
  const hierarchyId = get(props, 'hierarchyId') || '';
  const {
    admin: {
      automatedGroupsOfTBP: {
        totalGroups,
        tableDescribe,
        noCLT,
        table: locale,
        manageCLT,
        hideGroups,
        showGroups,
        hideGroupsTips,
        showGroupsTips,
        clientLeadershipTeamTips,
        confirmHideAccountGroupPageModal: {
          leaveText,
          showLeaveText,
          stayText,
          hideContentText,
          showContentText,
        },
        visibleSelector: { allVisibleGroups, allHiddenGroups },
      },
      assignCLTModalInfo,
      manageAdminRole: {
        addAdminModal: { nameNotFound, save },
      },
    },
  } = localeResource as localAdmin;

  const visibleSelectorOptions = [
    { label: allVisibleGroups, value: 1 },
    { label: allHiddenGroups, value: 0 },
  ];

  const [currentSorter, setCurrentSorter] = useState<ICurrentTableSort>({
    sortColumnName: '',
    sortOrder: null,
  });
  const defaultVisible = localStorage.getItem(ADMIN_VISIBLE_STATE) || DEFAULT_VISIBLE;
  const [visible, setVisible] = useState(+defaultVisible);

  const updateCurrentSorter = (sorter: ICurrentTableSort) => {
    setCurrentSorter(sorter);
  };

  const {
    loading: getAutomatedGroupsLoading,
    data: automatedGroupsData,
    run: runGetAutomatedGroups,
  } = useRequest(() => getAutomatedGroups(visible, hierarchyType, hierarchyId), {
    manual: true,
    formatResult: (res: AutomatedGroupsResponse) => res.data,
  });

  const getAutomatedGroupsFun: () => void = async () => {
    await runGetAutomatedGroups();
  };

  useEffect(() => {
    getAutomatedGroupsFun();
  }, [visible, hierarchyId]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [groupDetail, setGroupDetail] = useState<CLIGroupType>();
  const [hasExistValues, setHasExistValues] = useState<boolean>(false);
  const [isShowSearchInputInfoMessage, setIsShowSearchInputInfoMessage] = useState<boolean>(false);

  const [assignModalVisible, onOpenAssignModal, onCloseAssignModal] = usePerfModal();

  const onClickManageCLT = async (event: { stopPropagation: () => void }, accountId: number) => {
    event.stopPropagation();
    onOpenAssignModal();
    try {
      setModalLoading(true);
      setHasExistValues(false);
      const cltGroupResponse = await getAutomatedManager(accountId);
      setGroupDetail(get(cltGroupResponse, 'data'));

      setHasExistValues(true);
      setModalLoading(false);
    } catch (e) {
      setModalLoading(false);
    }
  };

  const columns: ColumnsType<AutomatedGroupsList> = [
    {
      title: locale.name,
      dataIndex: 'name',
      key: 'name',
      width: 256,
      ellipsis: { showTitle: true },
      className: 'automated-table-header-name',
      sorter: (rowA, rowB) => rowA.name.localeCompare(rowB.name),
      sortOrder: currentSorter?.sortColumnName === 'name' ? currentSorter?.sortOrder : null,
    },
    {
      title: () => {
        return (
          <div className={'client-LST-title'}>
            <Tooltip title={clientLeadershipTeamTips} overlayStyle={{ width: '176px' }}>
              <img src={InformationIcon} alt="" className="tips-icon" />
            </Tooltip>
            <p>{locale.clientLeadershipTeam}</p>
          </div>
        );
      },
      dataIndex: 'clientLeaderShipTeam',
      key: 'clientLeaderShipTeam',
      width: 344,
      className: 'automated-table-header-clt',
      ellipsis: { showTitle: true },
      render: (record: clientLeaderShipTeam[]) => {
        if (record.length > 0) {
          const CLSTString = record
            .filter((item: clientLeaderShipTeam) => {
              return item.name;
            })
            .map((item: clientLeaderShipTeam) => {
              return item.name;
            })
            .join(', ');
          return <>{CLSTString}</>;
        } else {
          return <span className={'no-CLT'}>{noCLT}</span>;
        }
      },
    },
    {
      title: locale.memberCount,
      dataIndex: 'memberCount',
      key: 'memberCount',
      width: 152,
      ellipsis: { showTitle: true },
      className: 'automated-table-header-nmb',
      sorter: (rowA, rowB) => +rowA.memberCount - +rowB.memberCount,
      sortOrder: currentSorter?.sortColumnName === 'memberCount' ? currentSorter?.sortOrder : null,
    },
    {
      title: locale.createdAt,
      dataIndex: 'createAt',
      key: 'createAt',
      width: 160,
      className: 'automated-table-header-create-at',
      sorter: (rowA, rowB) => new Date(rowA.createAt).getTime() - new Date(rowB.createAt).getTime(),
      sortOrder: currentSorter?.sortColumnName === 'createAt' ? currentSorter?.sortOrder : null,
      render: (createdAt: string) => dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: locale.actions,
      dataIndex: 'actions',
      width: 104,
      className: 'automated-table-header-action',
      render: (_, { id }: { id: number }) => {
        return (
          <PerfTextButton
            color="blue"
            className="operation-edit"
            onClick={async event => {
              await onClickManageCLT(event, id);
            }}
          >
            {manageCLT}
          </PerfTextButton>
        );
      },
    },
  ];

  const goToDetail = (record: { id: number }) => {
    history.push(getPerformancePath(`/admin/automatedGroupsDetail/${record.id}`));
    localStorage.setItem(ADMIN_VISIBLE_STATE, `${visible}`);
  };

  const total = get(automatedGroupsData, 'total') || 0;

  const getMembersByNameAndAccountId = (name: string) =>
    getMemberByName(name, get(groupDetail, 'id') as number);

  const handleCloseModal = () => {
    setHasExistValues(false);
    setIsShowSearchInputInfoMessage(false);
    onCloseAssignModal();
  };

  const handleAssignCLT = async (data: FormDataModal) => {
    try {
      setSubmitLoading(true);
      const memberIds = get(data, 'searchInputValues') as number[];
      const accountId = get(groupDetail, 'id', 0);
      trackEvent({
        category: AUTOMATED_GROUP_MANAGEMENT.category,
        action: AUTOMATED_GROUP_MANAGEMENT.action.TBP_EDITS_AUTOMATED_GROUP,
      });
      await putUpdateCLTMembers(accountId, {
        employeeIds: memberIds,
        lastUpdateAt: get(groupDetail, 'lastUpdatedAt') as number,
      });
      setSubmitLoading(false);
      handleCloseModal();
      perfMessage.success(assignCLTModalInfo.successMessage);
      getAutomatedGroupsFun();
    } finally {
      setSubmitLoading(false);
    }
  };

  const checkExistMemberInAccountMembers = (selectedValues: Array<SearchInputValueType>) => {
    const isExistMemberInOtherAccount =
      selectedValues && selectedValues.some(item => item.isInAccountMembers);
    setIsShowSearchInputInfoMessage(isExistMemberInOtherAccount);
  };

  const onChangeMembers = (selectedValues: Array<SearchInputValueType>) => {
    checkExistMemberInAccountMembers(selectedValues);
  };

  const customRenderSelectedCLT: (
    currentMember: SelectTagRenderItem,
    selectMemberValues: Array<SearchInputValueType>,
  ) => ReactElement | null = (currentMember, selectMemberValues = []) => {
    if (!currentMember) {
      return null;
    }

    const { label, value: selectedId, closable, onClose } = currentMember;
    const memberIsInAccountMembers =
      selectMemberValues &&
      selectMemberValues.some(item => item.value === selectedId && item.isInAccountMembers);

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        icon={memberIsInAccountMembers ? orangeCircleIcon : null}
        className="selected-member-tag-render"
      >
        {label}
      </Tag>
    );
  };

  const renderAssignCLTModal = () => {
    const existedAssignMembers = (
      get(groupDetail, 'clientLeadershipTeam', []) as Array<CLTMemberType>
    ).map((leader: CLTMemberType) => ({
      key: leader.employeeId,
      label: leader.name,
      value: leader.employeeId,
      isInAccountMembers: leader.isInAccountMembers,
    })) as Array<SearchInputValueType>;
    const memberInOtherAccountMessage = (
      <div className="exist-member-message">
        {orangeCircleIcon}
        <span className={'hint-text'}>{assignCLTModalInfo.memberInOtherAccount}</span>
      </div>
    );

    return (
      <AddOrEditModal
        loading={modalLoading}
        modalTitle={assignCLTModalInfo.modalTitle}
        visible={assignModalVisible}
        searchInput={{
          label: assignCLTModalInfo.nameLabel,
          fetchApi: getMembersByNameAndAccountId,
          notFoundText: nameNotFound,
          mode: 'multiple',
          currentValues: existedAssignMembers,
          fieldNameValue: 'employeeId',
          placeholder: assignCLTModalInfo.placeholder,
          isRequire: false,
          isExistCurrentValues: hasExistValues,
          formatSearchData: formatSearchInputList,
          customRender: customRenderSelectedCLT,
          isShowSearchInputInfoMessage: isShowSearchInputInfoMessage,
          checkShowSearchInputTips: checkExistMemberInAccountMembers,
          searchInputInfoTips: memberInOtherAccountMessage,
          onChange: onChangeMembers,
        }}
        permissionList={assignCLTModalInfo.permissionList}
        customButtons={{
          cancelButton: { handleClick: handleCloseModal },
          okButton: { label: save, handleClick: handleAssignCLT, loading: submitLoading },
        }}
      />
    );
  };

  const hideGroupsFun = useCallback(() => {
    const closeModal = perfModalConfirm({
      content: visible ? hideContentText : showContentText,
      okText: visible ? leaveText : showLeaveText,
      cancelText: stayText,
      centered: true,
      onOk: async () => {
        try {
          trackEvent({
            category: AUTOMATED_GROUP_MANAGEMENT.category,
            action: visible
              ? AUTOMATED_GROUP_MANAGEMENT.action.TBP_HIDES_AUTOMATED_GROUP
              : AUTOMATED_GROUP_MANAGEMENT.action.TBP_SHOW_AUTOMATED_GROUP,
          });
          const accountIds = selectedRowKeys as number[];
          visible
            ? await postAddAccountGroupToHideList(accountIds, hierarchyId, hierarchyType)
            : await postRemoveGroupFromHideList(accountIds, hierarchyId, hierarchyType);

          setSelectedRowKeys([]);
          getAutomatedGroupsFun();
        } catch {
          setLoading(false);
        } finally {
          closeModal();
        }
      },
      onCancel: null,
    });
  }, [showContentText, hideContentText, leaveText, selectedRowKeys]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onVisibleChanged = (value: number) => {
    setVisible(value);
    setSelectedRowKeys([]);
  };

  return (
    <LoadingWrapper className="loading" loading={getAutomatedGroupsLoading}>
      <div className={'automated-groups'}>
        <div className={'automated-groups-info'}>
          <h3 className={'title'}>{totalGroups(total)}</h3>
          <div className="info">
            <p className={'describe'}>{tableDescribe}</p>
          </div>
        </div>
        <div className={'automated-groups-list'}>
          <div className={'title'}>
            <Select
              defaultValue={visible}
              className={'visible-selector'}
              options={visibleSelectorOptions}
              onChange={onVisibleChanged}
              suffixIcon={
                <CaretDownOutlined
                  style={{ color: '#2f3542', fontSize: '16px', marginTop: '2.5px' }}
                />
              }
              aria-label="account groups hidden selector"
              aria-expanded="false"
              aria-autocomplete="none"
            />
            <Tooltip
              title={visible ? hideGroupsTips : showGroupsTips}
              overlayStyle={{ width: '168px' }}
            >
              <Button
                className={'automated-groups-button'}
                type="primary"
                onClick={hideGroupsFun}
                disabled={!hasSelected}
                loading={loading}
              >
                {visible === 1 && (
                  <>
                    <EyeInvisibleOutlined className={'eyes-icon'} />
                    {hideGroups}
                  </>
                )}

                {visible === 0 && (
                  <>
                    <EyeOutlined className={'eyes-icon'} />
                    {showGroups}
                  </>
                )}
              </Button>
            </Tooltip>
          </div>
          <TableRender
            column={columns}
            listData={automatedGroupsData}
            onRowClick={goToDetail}
            currentSort={updateCurrentSorter}
            keepTableStatus={true}
            rowSelection={rowSelection}
          />
        </div>
      </div>
      {renderAssignCLTModal()}
    </LoadingWrapper>
  );
};

export default AutomatedGroups;
