import PeopleIcon from '@@/assets/images/icon_leadship_team.svg';
import LoadingWrapper from '@@/common/component/LoadingWrapper';
import { RolesOfManagementContext } from '@@/context/rolesOfManagement.context';
import { UserInfoContext } from '@@/context/userInfo.context';
import usePerfModal from '@@/hooks/usePerfModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { PermissionItem, RegionalLevelGroupItem } from '@@/types/admin';
import { UserInfo } from '@@/types/user';
import { useMount, useRequest } from 'ahooks';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import EditRegionalLevelGroupNameOrManager from './EditRegionalLevelGroupsModal';
import './index.less';
import { getRegionalLevelGroups } from '@@/_new_src_/api/admin';

interface ManageRegionalGroupTabProps {
  regionId: number;
}

interface RegionalLevelGroupAccessType {
  title: string;
  permissionList: PermissionItem[];
}

export interface RegionalLevelGroupsResponse {
  data: Array<RegionalLevelGroupItem>;
}

const ManageRegionalGroupTab = (props: ManageRegionalGroupTabProps) => {
  const { regionId } = props;
  const [selectedRegionalGroup, setSelectedRegionalGroup] = useState<RegionalLevelGroupItem>(
    {} as RegionalLevelGroupItem,
  );
  const [editGroupModalVisible, onOpenEditGroupModal, onCloseEditGroupModal] = usePerfModal() as [
    boolean,
    () => void,
    () => void,
  ];
  const { updateUserInfo } = useContext(UserInfoContext) as UserInfo;
  const { updateLoadedRegionsForGroupManager } = useContext(RolesOfManagementContext);
  const localeResource = useSelector(selectLocaleResource) as unknown;
  const { regionalLevelGroupAccess } = localeResource as {
    regionalLevelGroupAccess: RegionalLevelGroupAccessType;
  };

  const {
    admin: {
      manageGroups: { regionalGroupsTypeTitle, regionalLevelProgram },
    },
  } = localeResource as {
    admin: {
      manageGroups: {
        regionalGroupsTypeTitle: [];
        regionalLevelProgram: {
          edit: string;
        };
      };
    };
  };

  const {
    loading: getRegionalLevelGroupsLoading,
    data: regionalLevelGroups,
    run: runGetRegionalLevelGroups,
  } = useRequest(() => getRegionalLevelGroups({ id: regionId }), {
    manual: true,
    ready: !!regionId,
    formatResult: (res: RegionalLevelGroupsResponse) => res.data,
    initialData: [],
  });

  useMount(() => {
    const getRegionalLevelGroupsFun: () => void = async () => {
      await runGetRegionalLevelGroups();
    };
    getRegionalLevelGroupsFun();
  });

  const handleClickEditButton = (
    groupId: number,
    groupName: string,
    managerName: string,
    managerEmail: string,
  ) => {
    setSelectedRegionalGroup({ groupId, groupName, managerName, managerEmail });
    onOpenEditGroupModal();
  };

  const handleEditGroupSuccess = async () => {
    onCloseEditGroupModal();
    await runGetRegionalLevelGroups();
    updateUserInfo();
    updateLoadedRegionsForGroupManager();
  };

  function renderRegionalGroupTypeTitle(index: number) {
    return <div className="regional-group-type-title">{regionalGroupsTypeTitle[index]}</div>;
  }

  function renderRegionalLevelGroups() {
    return (
      <div className="regional-level-people">
        {regionalLevelGroups?.map(({ groupId, groupName, managerName, managerEmail }) => (
          <div className="regional-level-people-item" key={groupId}>
            <div className="group-name">
              <img className="user-filled-icon" src={PeopleIcon} alt="" />
              <span className="header-text">{groupName}</span>
            </div>
            <div className="group-info">
              <div className="manager">{`Manager：${managerName || 'None'}`}</div>
              <div
                className="edit-button"
                onClick={() =>
                  handleClickEditButton(+groupId, groupName, managerName, managerEmail)
                }
              >
                {regionalLevelProgram.edit}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  const { groupId, groupName, managerName, managerEmail } = selectedRegionalGroup;
  const selectedGroupLeaders =
    managerName === null
      ? undefined
      : {
          key: managerEmail,
          label: managerName,
          value: managerEmail,
        };

  return (
    <LoadingWrapper className="modal-loading" loading={getRegionalLevelGroupsLoading}>
      <div className="manage-regional-groups">
        {renderRegionalGroupTypeTitle(0)}
        {renderRegionalLevelGroups()}
      </div>
      <EditRegionalLevelGroupNameOrManager
        visible={editGroupModalVisible}
        onSuccess={handleEditGroupSuccess}
        onCancel={onCloseEditGroupModal}
        groupLeaders={selectedGroupLeaders}
        groupName={groupName}
        groupId={+groupId}
        permissions={regionalLevelGroupAccess.permissionList}
      />
    </LoadingWrapper>
  );
};

export default ManageRegionalGroupTab;
