import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import './index.less';
import { Modal } from 'antd';
import { IConfirmModalProps } from '@@/_new_src_/types/common';
import {
  confirmModalLoading,
  confirmModalStatus,
  setConfirmModalLoading,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';

const ConfirmModal = (popupProps: IConfirmModalProps) => {
  const {
    title,
    content,
    okText,
    cancelText,
    width = 900,
    handleOkFunc,
    handleCancelFunc,
    modalClassName,
    confirmIconClassName,
    closeIcon = <i className="ri-close-line" style={{ width: 40 }} />,
    maskClosable = true,
  } = popupProps;
  const dispatch = useDispatch();
  const currentVisible = useSelector(confirmModalStatus);
  const loading = useSelector(confirmModalLoading);

  const handleOk = () => {
    if (handleOkFunc) {
      handleOkFunc();
    }
  };

  const handleCancel = () => {
    if (handleCancelFunc) {
      handleCancelFunc();
      return;
    }
    dispatch(setConfirmModalStatus(false));
    dispatch(setConfirmModalLoading(false));
    dispatch(setConfirmModalType(''));
  };

  return (
    <>
      {currentVisible && (
        <Modal
          className={cls('confirm-modal', modalClassName)}
          centered={true}
          title={title}
          open={currentVisible}
          confirmLoading={loading}
          width={width}
          maskClosable={maskClosable}
          closeIcon={closeIcon}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <i
                className={cls(
                  confirmIconClassName ? confirmIconClassName : 'ri-checkbox-circle-fill',
                  'confirm-modal-btn',
                )}
              />
              {okText}
            </div>
          }
          cancelText={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <i className="ri-close-circle-fill confirm-modal-btn" />
              {cancelText}
            </div>
          }
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Modal>
      )}
    </>
  );
};

export default ConfirmModal;
