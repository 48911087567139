import React from 'react';
import './index.less';

interface IProps {
  title: string;
  goToBackFun: () => void;
  onKeyPress: () => void;
  children?: React.ReactElement;
}
const GoBackBanner = (props: IProps) => {
  const { title, goToBackFun, onKeyPress, children } = props;
  const goToBackPage = () => {
    goToBackFun();
  };
  return (
    <div className="go-back-banner">
      <div className="banner-wrapper">
        <div className="back" onClick={goToBackPage} tabIndex={0} onKeyPress={onKeyPress}>
          <div className="back-icon">
            <i className="ri-arrow-left-s-fill arrow-left" />
          </div>
          <span className={'back-title'}>{title}</span>
        </div>
        {children}
      </div>
    </div>
  );
};
export default GoBackBanner;
